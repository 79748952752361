<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="submissions-page-container">
    <div class="row">
      <div class="column small-12 medium-3">
        <profile-strength
          :percentage="models.profileStrength.percentage"
          class="mbm"
        />
        <manage-account-menu :menu="models.menu" />
      </div>
      <div class="column small-12 medium-9">
        <SubmissionsCard
          :submissions="currentPage"
          :brief-filter-data="models.briefFilterData"
          :count="totalResultsCount"
          :api-base-url="pagination.apiBaseUrl"
          :urls="models.urls"
          :icons="icons"
          :min-qa-star-rating="models.minQaStarRating"
          :help-article-star-ratings-url="models.helpArticleStarRatingsUrl"
          :approval-status="models.approvalStatus"
          :is-loading="isLoading"
          @apo-updated="apoUpdated"
        />
        <Pagination
          class="mtm"
          :max-page="pagination.maxPage"
          :current="pagination.current"
          :start="pagination.start"
          :end="pagination.end"
          :start-of-page="pagination.startOfPage"
          :number-of-results="pagination.numberOfResults"
          :total-results-count="pagination.totalResultsCount"
          :description="pagination.description"
          :base-url="pagination.baseUrl"
          :api-base-url="pagination.apiBaseUrl"
          :left-arrow-icon-url="icons.paginationLeftArrow"
          :right-arrow-icon-url="icons.paginationRightArrow"
          :api-post-object="apiPostObject"
          @page-link-click="onPaginationClick"
          @is-loading="onIsLoading"
        />
      </div>
    </div>
  </fragment>
</template>

<script>
import SubmissionsCard from '../../components/my-account/designer/submissions/SubmissionsCard.vue';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    ManageAccountMenu,
    ProfileStrength,
    Pagination,
    SubmissionsCard,
  },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      apiPostObject: {
        page: 1,
        status: null,
        sortBy: null,
      },
      currentPage: this.models.submissions,
      pagination: this.models.pagination,
      totalResultsCount: this.models.pagination.totalResultsCount,
    };
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
      this.totalResultsCount = e.pagination.totalResultsCount;
    },
    apoUpdated(apo) {
      this.isLoading = true;
      this.apiPostObject = apo;
      this.isLoading = false;
    },
    onIsLoading(loading) {
      this.isLoading = loading;
    },
  },
};
</script>
