<template>
  <div>
    <a @click="isVisible = !isVisible">
      <img
        class="mlm mrn small-2 shrink"
        :src="ellipsisIconUrl"
        alt="ellipsis icon"
        data-test-role="ellipsis-icon"
      />
    </a>
    <ul
      v-if="isVisible"
      v-click-outside="closeOptions"
      class="design-submission__designer-menu"
    >
      <li>
        <a @click="onClickEliminateDesigner()"> Eliminate Designer </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    isOpenDesignerOptions: {
      type: Boolean,
      default: false,
    },
    ellipsisIconUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    closeOptions() {
      this.isVisible = false;
    },
    onClickEliminateDesigner() {
      this.$emit('eliminate-designer');
    },
  },
};
</script>
