import designSubmissionApi from '../clients/design-submission-api';

export default {
  data() {
    return {
      feedbackText: '',
      feedbackPlaceholder: 'Enter your feedback here...',
      feedbackErrorMessage: 'Please send your feedback with a message',
      feedbackThreadCount: 0,
      isFeedbackSubmitting: false,
      feedbackError: false,
      autoReject: false,
    };
  },
  methods: {
    async submitFeedback(briefId, designId, starRating) {
      if (!this.isFeedbackSubmitting) {
        if (this.feedbackText) {
          this.isFeedbackSubmitting = true;
          this.feedbackError = false;
          this.feedbackThreadCount += 1;
          const saveFeedbackRes = await designSubmissionApi.saveFeedbackAsync(
            briefId,
            designId,
            starRating,
            this.feedbackText,
            this.autoReject,
          );

          if (saveFeedbackRes) {
            this.autoReject = false;
            this.feedbackText = '';
            this.$emit('feedback-added', {
              designId: this.designId,
              feedbackCount: this.feedbackThreadCount,
            });
            this.isFeedbackSubmitting = false;
          }
        } else {
          this.feedbackThreadCount -= 1;
          this.feedbackError = true;
        }
      }
    },
  },
};
