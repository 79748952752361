<template>
  <Modal
    :title="`Design #${designId}`"
    class-name="extra-large modal--image-preview modal--design-preview"
    :close-icon="closeIconUrl"
    :is-loading="isLoading"
    :is-visible="isVisible"
    @closed="onClose"
  >
    <template #body>
      <div class="row collapse expanded">
        <div class="column small-12 medium-7">
          <div
            :class="{
              'modal__design-preview': true,
              'has-linked-designs': hasLinkedDesigns,
            }"
          >
            <a :href="designPreviewUrl" target="_blank">
              <img :src="designPreviewUrl" alt="Design image preview" />
              <span class="show-for-sr"> Design image preview </span>
            </a>
            <div class="modal__design-preview-tools">
              <a
                class="design-project-summary__icon design-project-summary__icon--zoom"
                :href="designPreviewUrl"
                target="_blank"
              >
                <span class="show-for-sr">Zoom in</span>
              </a>
            </div>
          </div>
          <!-- Check for more than one linked design since it always includes the selected design itself -->
          <div v-if="hasLinkedDesigns" class="modal__linked-designs">
            <p class="text-center medium-text-left">
              <strong class="text-medium text-black mlm">
                This design has {{
                  linkedDesigns.length
                }} linked submissions
              </strong>
            </p>
            <div class="modal__linked-designs-previews pbm">
              <div
                v-for="(design, index) in linkedDesigns"
                :key="index"
                :class="{
                  'modal__linked-design-thumbnail': true,
                  'modal__linked-design-thumbnail--selected':
                    designPreviewUrl === design.designImageLocation,
                }"
              >
                <img
                  :src="design.designThumbnailImageLocation"
                  alt="design preview"
                  @click="loadDesignDetails(design.designId)"
                />
                <span class="text-small mts"
                  >Design {{ design.designId }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="column small-12 medium-5 text-left">
          <div
            :ref="observerRootRef"
            :class="{
              'modal__design-preview-body pln prn': true,
            }"
          >
            <div class="column small-12">
              <div class="user-profile user-profile--small mbm mhn pls prs">
                <div class="row collapse align-middle">
                  <div class="column shrink">
                    <div
                      v-if="designerProfileThumbnailPath"
                      class="user-profile__image mrm"
                    >
                      <div class="responsive-embed square">
                        <img
                          class="responsive-embed__image"
                          :src="designerProfileThumbnailPath"
                          alt="Profile picture"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="u-truncate-ellipsis">
                      <span class="user-profile__small-text">
                        <a :href="designerPageUrl">
                          {{ designerDisplayName }}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column small-12">
              <p class="mbm pls prs">
                {{ designDescription }}
              </p>
            </div>
            <div
              class="column small-12 pbm plm"
              data-test-role="modal-star-rating"
            >
              <StarRatingSelectable
                size="medium"
                :rating="starRating"
                @click="onClickStarRating($event)"
              />
            </div>
            <div
              v-if="stockItems && stockItems.length > 0"
              class="column small-12"
            >
              <h6>Stock Images Used</h6>
              <ol>
                <li v-for="(stockItem, index) in stockItems" :key="index">
                  {{ stockItem.description }} -
                  <a :href="stockItem.sourceUrl">view link</a>
                  <span v-if="stockItem.mustPurchase">
                    (Client must purchase)</span
                  >
                  <span v-if="!stockItem.mustPurchase">
                    (Purchase not required)</span
                  >
                </li>
              </ol>
            </div>
            <div class="row mlm mrm mbs">
              <div
                v-if="buyDesignUrl"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <button class="button small mrm" @click="onClickBuyDesign">
                  Buy this Design
                </button>
              </div>
              <div
                v-if="deSelectDesignUrl"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a
                  class="button secondary hollow small mrm"
                  :href="deSelectDesignUrl"
                >
                  Deselect Design
                </a>
              </div>
              <div
                v-if="selectDesignUrl"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a
                  class="button small mrm"
                  data-test-cta="modal-select-design"
                  :href="selectDesignUrl"
                >
                  Select As Winner
                </a>
              </div>
              <div
                v-if="eliminateDesignUrl"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a class="button alert small mrm" :href="eliminateDesignUrl">
                  Eliminate Design
                </a>
              </div>
              <div
                v-if="unEliminateDesignUrl"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a class="button alert small mrm" :href="unEliminateDesignUrl">
                  Uneliminate Design
                </a>
              </div>
              <div
                v-if="!isDesignShared(designId) && !isWinner"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a
                  class="button secondary hollow mbn small"
                  @click="onClickShare(designId)"
                >
                  Share
                </a>
              </div>
              <div
                v-if="isDesignShared(designId) && !isWinner"
                class="small-12 column medium-shrink pln prn mbm"
              >
                <a class="button secondary mbn small" @click="onClickUnshare">
                  Unshare
                </a>
              </div>
            </div>
            <hr class="mts" />
            <div class="row mlm mrm">
              <label
                :for="`modal-feedback${_uid}`"
                class="small-12 medium-9 prm pts mbs"
              >
                <textarea
                  :id="`modal-feedback${_uid}`"
                  v-model="feedbackText"
                  rows="1"
                  class="mbn"
                  :placeholder="feedbackPlaceholder"
                >
                </textarea>
              </label>
              <div class="small-12 medium-3 pts">
                <button
                  :class="{
                    ['button mbn']: true,
                    ['button-processing']: isFeedbackSubmitting,
                  }"
                  class="mrm flex-container align-center"
                  @click="onSubmitFeedback"
                >
                  <img
                    v-if="isFeedbackSubmitting && processingIconUrl"
                    :src="processingIconUrl"
                    alt="Processing Icon"
                  />
                  Send
                </button>
              </div>
              <span v-if="feedbackError" class="is-deleted">
                {{ feedbackErrorMessage }}
              </span>
            </div>
            <div
              v-if="feedbackThreadCount && feedbackThreadCount > 0"
              class="column small-12"
            >
              <hr class="mbm mrs mls" />
              <h6
                v-if="feedbackThreads && feedbackThreads.length > 0"
                class="mbm"
              >
                {{ feedbackTitle }}
              </h6>
              <div v-if="feedbackList">
                <div
                  v-for="(feedback, index) in feedbackList"
                  :key="index"
                  :class="{
                    ['row collapse']: true,
                    mbm: index < feedbackList.length - 1,
                  }"
                  :data-mark-as-read="feedback.markAsReadApiCall"
                >
                  <div class="column shrink">
                    <div class="user-profile user-profile--tiny">
                      <div
                        v-if="designerProfileThumbnailPath"
                        class="user-profile__image mrs"
                      >
                        <div class="responsive-embed square">
                          <img
                            class="responsive-embed__image"
                            :src="feedback.fromProfilePicThumbnailLocation"
                            alt="Profile picture"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="row">
                      <div class="column">
                        <strong class="text-black mrs">
                          {{ feedback.fromDisplayName }}
                        </strong>
                        <span class="text-small">
                          {{ feedback.messageDateText }}
                        </span>
                      </div>
                      <div class="column small-12" v-html="feedback.content" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import Modal from '../../../shared/Modal/Modal.vue';
import StarRatingSelectable from '../../../shared/StarRatingSelectable/StarRatingSelectable.vue';
import feedbackMixin from '../../../../mixins/feedbackMixin';

export default {
  components: {
    Modal,
    StarRatingSelectable,
  },
  mixins: [feedbackMixin],
  props: {
    briefId: {
      type: Number,
      required: true,
    },
    submissionId: {
      type: Number,
      required: true,
    },
    designId: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
    isVisible: {
      type: Boolean,
      default: undefined,
    },
    designPreviewUrl: {
      type: String,
      required: true,
    },
    linkedDesigns: {
      type: Array,
      default: () => [],
    },
    designerProfileThumbnailPath: {
      type: String,
      default: undefined,
    },
    designerPageUrl: {
      type: String,
      default: undefined,
    },
    designerDisplayName: {
      type: String,
      default: undefined,
    },
    designDescription: {
      type: String,
      default: undefined,
    },
    stockItems: {
      type: Array,
      default: () => [],
    },
    rating: {
      type: Number,
      default: undefined,
    },
    feedbackThreads: {
      type: Array,
      default: () => [],
    },
    deSelectDesignUrl: {
      type: String,
      default: undefined,
    },
    selectDesignUrl: {
      type: String,
      default: undefined,
    },
    buyDesignUrl: {
      type: String,
      default: undefined,
    },
    eliminateDesignUrl: {
      type: String,
      default: undefined,
    },
    unEliminateDesignUrl: {
      type: String,
      default: undefined,
    },
    isWinner: {
      type: Boolean,
      default: undefined,
    },
    isShared: {
      type: Boolean,
      default: undefined,
    },
    closeIconUrl: {
      type: String,
      default: undefined,
    },
    processingIconUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      markAsReadObserver: null,
      observerRootRef: 'js-observer-root-ref',
      feedbackList: [],
      feedbackThreadCount: 0,
      starRating: null,
      feedbackText: null,
    };
  },
  computed: {
    ...mapGetters('designs', ['isDesignShared']),
    feedbackTitle() {
      return this.feedbackThreads[0]?.title;
    },
    hasLinkedDesigns() {
      return this.linkedDesigns && this.linkedDesigns.length > 1;
    },
  },
  watch: {
    isLoading(loading) {
      if (!loading && this.isVisible) {
        this.$nextTick(() => this.initializeModalData());
      }
    },
  },
  mounted() {
    this.initializeModalData();
  },
  methods: {
    initializeModalData() {
      this.starRating = this.rating;
      this.feedbackThreadCount = this.feedbackThreads.length;
      this.restartMarkAsReadObserver();
      this.getFeedbackList();
    },
    getFeedbackList() {
      const feedbackList = [];
      this.feedbackThreads.forEach((thread) => {
        thread.messages.forEach((message) => feedbackList.push(message));
      });
      this.feedbackList = feedbackList;
    },
    async restartMarkAsReadObserver() {
      if (this.markAsReadObserver !== null) {
        this.markAsReadObserver.disconnect();
        this.markAsReadObserver = null;
      }
      const options = {
        root: this.$refs[this.observerRootRef],
        rootMargin: '0px',
        threshold: 1.0,
      };
      const callback = (changes) => {
        changes.forEach((change) => {
          if (change.isIntersecting) {
            axios.get(change.target.dataset.markAsRead);
            this.markAsReadObserver.unobserve(change.target);
          }
        });
      };
      this.markAsReadObserver = new IntersectionObserver(callback, options);
      const unreads = document.querySelectorAll('[data-mark-as-read]');
      unreads.forEach((i) => {
        if (i) {
          this.markAsReadObserver.observe(i);
        }
      });
    },
    loadDesignDetails(designId) {
      this.$emit('load-design-details', designId);
    },
    onClickStarRating(rating) {
      this.starRating = rating;
      this.$emit('star-rating-updated', rating);
    },
    onClickBuyDesign() {
      this.$emit('buy-design');
    },
    onClickShare() {
      this.$emit('share', {
        designId: this.designId,
        submissionId: this.submissionId,
      });
    },
    onClickUnshare() {
      this.$emit('unshare', {
        designId: this.designId,
        submissionId: this.submissionId,
      });
    },
    onClose() {
      this.$emit('close');
    },
    async onSubmitFeedback() {
      await this.submitFeedback(
        this.briefId,
        this.designId,
        this.starRating,
      );
      this.$emit('feedback-updated', this.feedbackThreadCount);
    },
  },
};
</script>
