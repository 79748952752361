<template>
  <div class="column">
    <div class="mrs mbm flex-container">
      <span class="text-left medium-3 small-12">
        <h6>Payout Logs</h6>
        Earned: {{ earnings }}
      </span>
      <div class="text-right medium-9 small-12">
        <div class="row">
          <div class="small-12 medium-6 flex-container align-middle">
            <label for="status">Status:</label>
            <select v-model="status" :disabled="!enabled" class="mbn mls mrs">
              <option
                v-for="(option, index) in statusOptions"
                :key="index"
                :value="option.value"
                :disabled="!enabled"
                class="mls mr"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="small-12 medium-6 flex-container align-middle">
            <label for="sortBy" style="white-space: nowrap"
              >Sort By:</label
            >
            <select v-model="sortBy" :disabled="!enabled" class="mbn mls">
              <option
                v-for="(option, index) in sortOptions"
                :key="index"
                :value="option.value"
                :disabled="!enabled"
                class="mls mrs"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    earnings: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: null,
      sortBy: null,
      statusOptions: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Interim',
          value: 'Interim',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Approved',
          value: 'Approved',
        },
        {
          label: 'Completed',
          value: 'Completed',
        },
        {
          label: 'Held',
          value: 'Held',
        },
        {
          label: 'Failed',
          value: 'Failed',
        },
      ],
      sortOptions: [
        {
          label: 'Date Selected',
          value: null,
        },
        {
          label: 'Payment Date',
          value: 'Payment Date',
        },
        {
          label: 'Status',
          value: 'Status',
        },
      ],
    };
  },
  watch: {
    async status(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update();
      }
    },
    async sortBy(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update();
      }
    },
  },
  methods: {
    async update() {
      if (!this.enabled) {
        return;
      }
      this.$emit('filter-update', {
        status: this.status,
        sortBy: this.sortBy,
      });
    },
  },
};
</script>
