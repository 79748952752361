<template>
  <div>
    <div class="mrs mbm flex-container">
      <span class="text-left medium-6">
        {{ count }} submissions found
      </span>
      <div class="text-right medium-6">
        <span class="mls">
          <a @click="clearFilters"> Clear Filters </a>
        </span>
      </div>
    </div>
    <div class="flex-container align-middle mbm">
      <label for="filters">Filters:</label>
      <select
        v-model="filterResultIndex"
        class="mbn mls mrs"
        :disabled="disabled"
        @change="updateFilters"
      >
        <option
          v-for="(option, index) in submissionResult"
          :key="index"
          :value="index"
          class="mls mrs"
        >
          {{ option.label }}
        </option>
      </select>
      <select
        v-model="filterPaymentIndex"
        class="mbn mls"
        :disabled="disabled"
        @change="updateFilters"
      >
        <option
          v-for="(option, index) in paymentStatus"
          :key="index"
          :value="index"
          class="mls mrs"
        >
          {{ option.label }}
        </option>
      </select>
      <select
        v-model="filterProjectStatusIndex"
        class="mbn mls"
        :disabled="disabled"
        @change="updateFilters"
      >
        <option
          v-for="(option, index) in projectStatus"
          :key="index"
          :value="index"
          class="mls mrs"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="flex-container align-middle mbm">
      <select
        v-model="filterBriefIndex"
        class="mbn"
        :disabled="disabled"
        @change="updateFilters"
      >
        <option
          v-for="(option, index) in briefFilter"
          :key="index"
          :value="index"
          class="mls mrs"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    briefs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterResultIndex: 0,
      filterPaymentIndex: 0,
      filterProjectStatusIndex: 0,
      filterBriefIndex: 0,
      submissionResult: [
        {
          label: `Filter submission result`,
          value: null,
          apo: null,
        },
        {
          label: `1st Place`,
          value: '1st place',
          apo: { first: true },
        },
        {
          label: `2nd Place`,
          value: '2nd place',
          apo: { second: true },
        },
        {
          label: `3rd Place`,
          value: '3rd place',
          apo: { third: true },
        },
        {
          label: `Participation Payment`,
          value: 'participation payment',
          apo: { participation: true },
        },
        {
          label: `Not Selected`,
          value: 'not selected',
          apo: { notSelected: true },
        },
        {
          label: `On Hold`,
          value: 'on hold',
          apo: { onHold: true },
        },
      ],
      projectStatus: [
        {
          label: `Filter project status`,
          value: null,
          apo: null,
        },
        {
          label: `Open`,
          value: 'open',
          apo: { briefOpen: true },
        },
        {
          label: `Closed`,
          value: 'closed',
          apo: { briefClosed: true },
        },
      ],
      paymentStatus: [
        {
          label: `Filter payment status`,
          value: null,
          apo: null,
        },
        {
          label: `Paid`,
          value: 'paid',
          apo: { paymentPaid: true },
        },
        {
          label: `Not Paid (Approved and Pending)`,
          value: 'not paid',
          apo: { paymentApproved: true },
        },
        {
          label: `Not Paid (Awaiting Approval)`,
          value: 'awaiting approval',
          apo: { paymentAwaitingApproval: true },
        },
      ],
    };
  },
  computed: {
    briefFilter() {
      return [
        {
          label: `Filter By Projects`,
          value: null,
        },
        ...this.briefs.map((brief) => ({
          label: brief.title,
          value: brief.briefId,
          selected: brief.selected,
          apo: { briefId: brief.briefId },
        })),
      ];
    },
  },
  mounted() {
    this.filterBriefIndex = this.briefFilter.findIndex(
      (brief) => brief.selected,
    );
    if (this.filterBriefIndex === -1) {
      this.filterBriefIndex = 0;
    }
  },
  watch: {},
  methods: {
    clearFilters() {
      this.filterResultIndex = 0;
      this.filterPaymentIndex = 0;
      this.filterProjectStatusIndex = 0;
      this.filterBriefIndex = 0;
      this.$emit('apo-updated', { triggerPagination: true, page: 1 });
    },
    updateFilters() {
      const apo = {
        triggerPagination: true,
        page: 1,
        ...this.submissionResult[this.filterResultIndex].apo,
        ...this.paymentStatus[this.filterPaymentIndex].apo,
        ...this.projectStatus[this.filterProjectStatusIndex].apo,
        ...this.briefFilter[this.filterBriefIndex].apo,
      };
      this.$emit('apo-updated', apo);
    },
  },
};
</script>
