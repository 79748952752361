var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',{staticClass:"show-for-medium"},[_c('td',[_vm._v(_vm._s(_vm.briefId))]),_c('td',[_vm._v(_vm._s(_vm.item))]),_c('td',[_vm._v(_vm._s(_vm.pages))]),_c('td',[_vm._v(_vm._s(_vm.domainName))]),_c('td',[_vm._v(_vm._s(_vm.cost))]),_c('td',[(_vm.status)?_c('span',{class:{ [`label ${_vm.statusClass}`]: true }},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.briefId))])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.item))])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.pages))])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(3),_c('td',[_vm._v(_vm._s(_vm.domainName))])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm.cost))])]),_c('tr',{staticClass:"hide-for-medium"},[_vm._m(5),_c('td',[(_vm.status)?_c('span',{class:{ [`label ${_vm.statusClass}`]: true }},[_vm._v(" "+_vm._s(_vm.status)+" ")]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Brief ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Item")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Pages")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Domain Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Cost")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('strong',{staticClass:"text-black"},[_vm._v("Status")])])
}]

export { render, staticRenderFns }