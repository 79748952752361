<template>
  <div class="submit-design row">
    <div class="column small-12 large-8 text-center">
      <h3 class="heading heading--underline text-left mbl">
        Submit your Design Files
      </h3>
    </div>
    <div class="column small-12 large-8 text-center">
      <div class="my-project-card">
        <div class="my-project-card__header">
          <strong class="text-black">Submissions overview</strong>
        </div>
        <div class="row pam">
          <div class="column small-12 large-4">
            <img :src="models.imageUrl" alt="" width="300" />
          </div>
          <div class="column small-12 large-8">
            <p class="text-left">
              <strong class="text-black text-left">Date Created</strong
              ><br />
              {{ models.date }}<br />
              <strong class="text-black text-left"
                >Design Description</strong
              ><br />
              {{ models.designDescription }}
            </p>
          </div>
        </div>
      </div>
      <div class="my-project-card mbm">
        <div class="my-project-card__header">
          <strong class="text-black">
            Upload Print & Source Files
          </strong>
        </div>
        <div class="row pam">
          <div class="column small-12 text-left">
            <template v-if="models.fileUploaders.previewFront">
              <strong class="text-black"
                >Preview File ({{
                  models.fileUploaders.previewFront
                    .AcceptedFileExtensionsDisplay
                }})</strong
              >
              <p>
                {{ unPrettierNuggets.previewFrontCopy }}
              </p>
              <FileUploader
                :options="models.fileUploaders.previewFront"
                :icons="icons"
              ></FileUploader>
              <ErrorList :errors="form.errors.previewFront" />
            </template>
            <strong class="text-black"
              >Print File ({{
                models.fileUploaders.printFront.AcceptedFileExtensionsDisplay
              }})</strong
            >
            <p>
              {{ unPrettierNuggets.printFileCopy }}
            </p>
            <FileUploader
              :options="models.fileUploaders.printFront"
              :icons="icons"
            ></FileUploader>
            <ErrorList :errors="form.errors.printFront" />
            <strong class="text-black"
              >Source Files ({{
                models.fileUploaders.sourceFront.AcceptedFileExtensionsDisplay
              }})</strong
            >
            <p>
              {{ unPrettierNuggets.sourceFileCopy }}
            </p>
            <FileUploader
              :options="models.fileUploaders.sourceFront"
              :icons="icons"
            ></FileUploader>
            <ErrorList :errors="form.errors.sourceFront" />
          </div>
        </div>
      </div>
      <div v-if="models.requiresBackSide" class="my-project-card mbm">
        <div class="my-project-card__header">
          <strong class="text-black">
            Upload Back-side Print & Source Files
          </strong>
        </div>
        <div class="row pam">
          <div class="column small-12 text-left">
            <strong class="text-black"
              >Preview File ({{
                models.fileUploaders.previewBack.AcceptedFileExtensionsDisplay
              }})</strong
            >
            <p>
              {{ unPrettierNuggets.previewBackCopy }}
            </p>
            <FileUploader
              :options="models.fileUploaders.previewBack"
              :icons="icons"
            ></FileUploader>
            <ErrorList :errors="form.errors.previewBack" />
            <strong class="text-black"
              >Print File ({{
                models.fileUploaders.printBack.AcceptedFileExtensionsDisplay
              }})</strong
            >
            <p>
              {{ unPrettierNuggets.printFileCopy }}
            </p>
            <FileUploader
              :options="models.fileUploaders.printBack"
              :icons="icons"
            ></FileUploader>
            <ErrorList :errors="form.errors.printBack" />
            <strong class="text-black"
              >Source Files ({{
                models.fileUploaders.sourceBack.AcceptedFileExtensionsDisplay
              }})</strong
            >
            <p>
              {{ unPrettierNuggets.sourceFileCopy }}
            </p>
            <FileUploader
              :options="models.fileUploaders.sourceBack"
              :icons="icons"
            ></FileUploader>
            <ErrorList :errors="form.errors.sourceBack" />
          </div>
        </div>
      </div>
      <div
        class="hide-for-small-only frame frame--border-alt frame--padding-small"
      >
        <div class="column mbm">
          <label class="text-left">
            <input
              v-model="agreedToTerms"
              type="checkbox"
              class="input-type--checkbox"
              @click.stop
            />
            <span>I agree to</span>
          </label>
          <ul class="text-left column pll">
            <li>
              <a :href="models.urls.userAgreement" target="_blank"
                >DesignCrowd's User Agreement</a
              >
            </li>
            <li>
              <a :href="models.urls.minimumDesignStandards" target="_blank"
                >DesignCrowd's Minimum Design Standards</a
              >
            </li>
            <li>
              {{ unPrettierNuggets.ownershipCopy }}
            </li>
          </ul>
          <ErrorList
            :classes="['text-left']"
            :errors="form.errors.agreedToTerms"
          />
        </div>
        <div class="horizontal-rule frame__full-width-container">
          <hr class="hr hr--alt" />
        </div>
        <div class="row">
          <div class="column text-center">
            <button
              class="button mbn mtm"
              :disabled="form.disabled"
              @click="save"
            >
              Submit and Upload
            </button>
            <ErrorList
              :classes="['text-left']"
              :errors="form.errors.submit"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="column small-12 large-4">
      <div class="my-project-card">
        <div class="my-project-card__header">
          <strong class="text-black"> Submitted to Brief </strong>
        </div>
        <div class="pam">
          <div class="row">
            <div class="column small-4 text-left">
              <strong class="text-black">Name</strong>
            </div>
            <div class="column small-6 text-left">{{ models.briefName }}</div>
          </div>
          <div class="row">
            <div class="column small-4 text-left">
              <strong class="text-black">Description</strong>
            </div>
            <div class="column small-6 text-left">
              {{ models.briefDescription }}
            </div>
          </div>
          <div class="row">
            <div class="column small-4 text-left">
              <strong class="text-black">Deadline</strong>
            </div>
            <div class="column small-6 text-left">
              {{ models.briefDeadline }}
            </div>
          </div>
          <div class="row">
            <div class="column small-4 text-left">
              <strong class="text-black">Budget</strong>
            </div>
            <div class="column small-6 text-left">
              {{ models.briefBudget }}
            </div>
          </div>
          <div class="row">
            <div class="column small-12 text-right">
              <a :href="models.urls.jobUrl">View Brief</a>
            </div>
          </div>
        </div>
      </div>
      <div class="my-project-card">
        <div class="my-project-card__header">
          <strong class="text-black"> Linked Designs </strong>
        </div>
        <div class="pam">
          <p v-if="models.linkedDesigns.length === 0">
            No Linked Designs Found
          </p>
          <div
            v-for="(design, index) in models.linkedDesigns"
            v-else
            :key="index"
            class="pbs"
          >
            <p>
              <a :href="design.designPageUrl"
                >Design #{{ design.designId }}</a
              >
              Added {{ design.designCreatedDateUtcDisplay }}
            </p>
          </div>
          <div class="row">
            <div class="column small-12 text-right">
              <a :href="models.urls.submitLinkedDesign"
                >Submit a Linked Design</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="my-project-card">
        <div class="my-project-card__header">
          <strong class="text-black"> Need help? </strong>
        </div>
        <div class="pam">
          <p v-html="unPrettierNuggets.needHelpCopy"></p>
        </div>
      </div>
      <div
        class="show-for-small-only frame frame--border-alt frame--padding-small"
      >
        <div class="column mbm">
          <label class="text-left">
            <input
              v-model="agreedToTerms"
              type="checkbox"
              class="input-type--checkbox"
              @click.stop
            />
            <span>I agree to</span>
          </label>
          <ul class="text-left column pll">
            <li>
              <a :href="models.urls.userAgreement" target="_blank"
                >DesignCrowd's User Agreement</a
              >
            </li>
            <li>
              <a :href="models.urls.minimumDesignStandards" target="_blank"
                >DesignCrowd's Minimum Design Standards</a
              >
            </li>
            <li>
              {{ unPrettierNuggets.ownershipCopy }}
            </li>
          </ul>
          <ErrorList
            :classes="['text-left']"
            :errors="form.errors.agreedToTerms"
          />
        </div>
        <div class="horizontal-rule frame__full-width-container">
          <hr class="hr hr--alt" />
        </div>
        <div class="row">
          <div class="column text-center">
            <button
              button="button"
              class="button mbn mtm"
              :disabled="form.disabled"
              @click="save"
            >
              Submit and Upload
            </button>
            <ErrorList
              :classes="['text-left']"
              :errors="form.errors.submit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FileUploader from '../../../shared/FileUploader/FileUploader.vue';
import ErrorList from '../../../shared/ErrorList/ErrorList.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: { FileUploader, ErrorList },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    const errorDefault = {};
    return {
      form: {
        disabled: false,
        errorDefault,
        errors: errorDefault,
      },
      unPrettierNuggets: {
        printFileCopy: `The print files must be fully scalable and print-ready for use with professional printers. In addition, all fonts must be outlined to allow the customer to edit the shape of the text without needing the font to be installed on their machine`,
        sourceFileCopy: `These are the original source files used to create your design that can be used to modify the design in the future. In addition, if the customer has requested any other files include them in a zip file here.`,
        needHelpCopy: `We have posted a number of articles to assist you with any problems you might have submitting files. Check them out <a href="${this.models.urls.helpUrlTopicSubmittingDesigns}" target="_blank">here</a>`,
        ownershipCopy: `My work and all aspects of my work are original and owned by me`,
        previewFrontCopy: `This is the preview file used to display your front-side design on the web site and to the customer.`,
        previewBackCopy: `This is the preview file used to display the back-side of your design on the web site and to the customer.`,
      },
      agreedToTerms: false,
    };
  },
  methods: {
    file(uploader) {
      if (!uploader)
        return null;
      const uploaderJson = document.querySelector(
        `[data-upload-id='${uploader.Id}'] input[name='filesJson']`,
      ).value;
      const files = JSON.parse(uploaderJson);
      let file = null;
      if (files.length) [file] = files;
      return file;
    },
    async save() {
      this.form.disabled = true;
      const data = {
        previewFront: this.file(this.models.fileUploaders.previewFront),
        previewBack: this.file(this.models.fileUploaders.previewBack),
        printFront: this.file(this.models.fileUploaders.printFront),
        printBack: this.file(this.models.fileUploaders.printBack),
        sourceFront: this.file(this.models.fileUploaders.sourceFront),
        sourceBack: this.file(this.models.fileUploaders.sourceBack),
        agreedToTerms: this.agreedToTerms,
      };
      const res = await axios.post(
        this.models.urls.submitSourceApiUrl,
        data,
        axiosConfig,
      );
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };
      if (res.data.errors) {
        this.form.disabled = false;
      } else {
        window.location.href = res.data.redirectUrl;
      }
    },
  },
};
</script>
