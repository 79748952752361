<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="designer-services-edit-page-container">
    <div class="row designer-services">
      <div class="column small-12 medium-3">
        <profile-strength
          :percentage="models.profileStrength.percentage"
          class="mbm"
        />
        <manage-account-menu :menu="models.menu" />
      </div>
      <div class="column small-12 medium-9">
        <div class="designer-services__card_container">
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Service Priority</h6>
            </div>
            <div class="row small-12 large-6 mrn mln gap-15">
              <select v-model="priority" class="mbn mls mrs">
                <option
                  v-for="(label, key, index) in models.priorityLabels"
                  :key="index"
                  :value="key"
                  class="mls mr"
                >
                  {{ label === '' ? key : label }}
                </option>
              </select>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Design Type</h6>
            </div>
            <div class="row small-up-2 medium-up-3 large-up-5">
              <div
                v-for="(group, index) in models.designSubTypeGroups"
                :key="index"
                class="column"
              >
                <div
                  class="tile text-center mtm mbs"
                  :class="{
                    'tile--selected':
                      subTypeGroupDisplayName === group.displayName,
                  }"
                  @click="onSelectSubTypeGroup(group)"
                >
                  <img :src="group.imageUrl" :alt="group.displayName" />
                  <p>{{ group.displayName }}</p>
                </div>
              </div>
            </div>
            <div
              class="row small-12 large-12 designer-services__checkboxes mrn mln"
              :disabled="models.selectedService"
            >
              <div
                v-for="(subType, index) in designTypefilters.designSubTypes"
                :key="index"
                class="flex-container align-center-middle gap-15"
              >
                <label>
                  <input
                    v-model="selectedSubTypeId"
                    type="radio"
                    class="input-type--radio"
                    name="selectedSubTypeId"
                    checked="checked"
                    :value="subType.id"
                    :disabled="models.selectedService"
                  />
                  <span class="text-medium">{{ subType.name }}</span>
                </label>
                <input
                  v-if="subType.name === 'Other'"
                  v-model="customDesignServiceName"
                  type="text"
                  name="customDesignServiceName"
                  :disabled="models.selectedService"
                />
              </div>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Price Package</h6>
              <span>&nbsp;(All prices are in USD)</span>
            </div>
            <div
              class="row small-up-2 medium-up-3 large-up-4"
              :disabled="models.selectedService"
            >
              <div
                v-for="(pkg, packagesIndex) in designSubTypeGroupPackages"
                :key="packagesIndex"
                class="column"
                @click="onSelectPackage(pkg)"
              >
                <div
                  class="mtm mbs tile text-center"
                  :class="{
                    'tile--selected': packageId === pkg.id,
                  }"
                >
                  <img
                    :src="icons.packages[packagesIndex]"
                    alt="icon-package"
                    width="100"
                    class="prs pls"
                  />
                  <h6>${{ pkg.budget }}</h6>
                  <span>{{ pkg.maxDesigns }}&nbsp;Concept</span>
                  <br />
                  <span v-if="pkg.maxRevisions > 1"
                    >{{ pkg.minRevisions }} -
                    {{ pkg.maxRevisions }}&nbsp;Revisions</span
                  >
                  <span v-else-if="pkg.maxRevisions > 3"
                    >{{ pkg.minRevisions }}+&nbsp;Revisions</span
                  >
                  <span v-else
                    >{{ pkg.minRevisions }}&nbsp;Revisions</span
                  >
                  <input id="" v-model="packageId" type="text" class="hide" />
                </div>
              </div>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Banner Image</h6>
              <span
                >&nbsp;({{
                  `${models.fileUploaders.bannerImage.options.PixelLimits.MaxWidth}px x ${models.fileUploaders.bannerImage.options.PixelLimits.MaxHeight}px`
                }})</span
              >
            </div>
            <div v-if="form.errors.bannerImage" class="small-12">
              <ul
                v-for="(error, index) in form.errors.bannerImage"
                :key="index"
                class="text-color-red text-medium"
              >
                <li>{{ error }}</li>
              </ul>
            </div>
            <div class="row small-12 large-12 mrn mln gap-15 w-100">
              <FileUploader
                :options="models.fileUploaders.bannerImage.options"
                :icons="icons"
              ></FileUploader>
            </div>
            <div
              v-if="bannerImageUrl"
              class="row small-12 large-12 mrn mln gap-15 flex-container align-center"
            >
              <div
                class="designer-services__add_image_container text-center mln mrn"
              >
                <img width="130" :src="bannerImageUrl" :alt="bannerImageUrl" />
              </div>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Service Description</h6>
              <span>&nbsp;(Minimum 300 characters)</span>
            </div>
            <div v-if="form.errors.description" class="small-12">
              <ul
                v-for="(error, index) in form.errors.description"
                :key="index"
                class="text-color-red text-medium"
              >
                <li>{{ error }}</li>
              </ul>
            </div>
            <div class="row small-12 large-12 mrn mln gap-15">
              <textarea v-model="description" rows="3"></textarea>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Thumbnail Image</h6>
              <span
                >&nbsp;({{
                  `${models.fileUploaders.thumbnailImage.options.PixelLimits.MaxWidth}px x ${models.fileUploaders.thumbnailImage.options.PixelLimits.MaxHeight}px`
                }})</span
              >
            </div>
            <div v-if="form.errors.thumbnailImage" class="small-12">
              <ul
                v-for="(error, index) in form.errors.thumbnailImage"
                :key="index"
                class="text-color-red text-medium"
              >
                <li>{{ error }}</li>
              </ul>
            </div>
            <div class="row small-12 large-12 mrn mln gap-15 w-100">
              <FileUploader
                :options="models.fileUploaders.thumbnailImage.options"
                :icons="icons"
              ></FileUploader>
            </div>
            <div
              v-if="thumbnailImageUrl"
              class="row small-12 large-12 mrn mln gap-15 flex-container align-center"
            >
              <div
                class="designer-services__add_image_container text-center mln mrn"
              >
                <img
                  width="130"
                  :src="thumbnailImageUrl"
                  :alt="thumbnailImageUrl"
                />
              </div>
            </div>
          </div>
          <div class="row designer-services__row_divider">
            <div class="small-12 large-12 flex-center pls">
              <h6 class="mbn">Example Designs</h6>
            </div>
            <div class="small-12 large-12 flex-center pls">
              <p>{{ unPrettierNuggets.exampleDesigns }}:</p>
              <br />
            </div>
            <div class="row small-12 large-12 mrn mln gap-15 align-middle">
              <button
                class="button secondary hollow small mbn"
                @click="initialisePortfolio({ page: 1, getFilters: true })"
              >
                <span>Browse Designs</span>
              </button>
              <span
                >Design Selected:
                {{
                  portfolio.selectedDesignIds.length > 0
                    ? portfolio.selectedDesignIds.length
                    : 0
                }}
                (Max 8)</span
              >
            </div>
            <div v-if="form.errors.selectedDesignIds" class="small-12">
              <ul
                v-for="(error, index) in form.errors.selectedDesignIds"
                :key="index"
                class="text-color-red text-medium"
              >
                <li>{{ error }}</li>
              </ul>
            </div>
          </div>
          <div class="row designer-services__card_footer">
            <div
              class="row small-12 large-12 mrn mln align-center gap-15 ptm pbm"
            >
              <button
                class="button primary small mbn"
                :disabled="form.disabled"
                @click="save"
              >
                <span>Save</span>
              </button>
              <button
                class="button secondary hollow small mbn"
                :disabled="form.disabled"
                @click="cancel"
              >
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <PortfolioModal
        :is-visible="portfolio.visible"
        :pagination="portfolio.pagination"
        :icons="icons"
        :api-post-object="portfolio.apiPostObject"
        :designs="portfolio.designs"
        :design-type-options="portfolio.designTypeOptions"
        :brief-options="portfolio.briefOptions"
        :selected-design-ids="portfolio.selectedDesignIds"
        :can-select="portfolioCanSelect"
        :can-select-all="portfolioCanSelectPageOfDesigns"
        :can-de-select-all="portfolioCanDeSelectPageOfDesigns"
        :errors="portfolio.errors"
        @on-pagination-click="loadDesigns"
        @closed="portfolio.visible = false"
        @select-this-page="selectThisPageOfPortfolioDesigns"
        @de-select-all="deSelectAllPortfolioDesigns"
        @select-design="selectPortfolioDesign"
        @de-select-design="deSelectPortfolioDesign"
        @update-filter="initialisePortfolio"
      />
    </div>
  </fragment>
</template>

<script>
import axios from 'axios';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import PortfolioModal from './PortfolioModal.vue';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import FileUploader from '../../components/shared/FileUploader/FileUploader.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const portfolioMaxDesigns = 8;

export default {
  components: {
    ManageAccountMenu,
    ProfileStrength,
    FileUploader,
    PortfolioModal,
  },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    const errorDefault = {
      bannerImage: [],
      thumbnailImage: [],
      description: '',
      selectedDesignIds: [],
    };
    const data = {
      selectedSubTypeId:
        (this.models.selectedService || {}).subTypeId ||
        this.models.designSubTypeGroups[0].designSubTypes[0].id,
      subTypeGroupDisplayName:
        (this.models.selectedService || {}).subTypeGroupDisplayName ||
        this.models.designSubTypeGroups[0].displayName,
      packageId:
        (this.models.selectedService || {}).packageId ||
        this.models.designSubTypeGroups[0].designSubTypes[0].packages[0].id,
      description: (this.models.selectedService || {}).description || null,
      customDesignServiceName:
        (this.models.selectedService || {}).customDesignServiceName || null,
      priority: (this.models.selectedService || {}).priority || 1,
      bannerImageUrl: (this.models.selectedService || {}).bannerImageUrl,
      thumbnailImageUrl: (this.models.selectedService || {}).thumbnailImageUrl,
      form: {
        disabled: false,
        errorDefault,
        errors: errorDefault,
      },
      unPrettierNuggets: {
        exampleDesigns:
          'Select designs to display on the services page to demonstrate your quality of work',
      },
      portfolio: {
        visible: false,
        designs: [],
        errors: [],
        pagination: {
          maxPage: 0,
          current: 0,
          start: 0,
          end: 0,
          startOfPage: 0,
          numberOfResults: 0,
          totalResultsCount: 0,
          description: '',
          baseUrl: '',
          apiBaseUrl: '',
        },
        apiPostObject: {
          page: 1,
          designTypeDisplayName: null,
          briefId: null,
          getFilters: false,
          designerServiceId: (this.models.selectedService || {}).id || null,
        },
        designTypeOptions: [],
        briefOptions: [],
        selectedDesignIds: this.models.selectedDesignIds,
      },
    };
    return data;
  },
  computed: {
    designTypefilters() {
      const results = this.models.designSubTypeGroups.find((c) => {
        return c.displayName === this.subTypeGroupDisplayName;
      });
      return results || [];
    },
    designSubTypeGroupPackages() {
      const results = this.models.designSubTypeGroups
        .filter((item) => item.displayName === this.subTypeGroupDisplayName)
        .map((x) =>
          x.designSubTypes.find((st) => st.id === this.selectedSubTypeId),
        );
      return results[0].packages;
    },
    portfolioCanSelect() {
      return this.portfolio.selectedDesignIds.length < portfolioMaxDesigns;
    },
    portfolioCanSelectPageOfDesigns() {
      const unSelectedOnThisPage = this.portfolio.selectedDesignIds.map(
        (x) => this.portfolio.designs.indexOf(x.designId) === -1,
      ).length;
      return (
        this.portfolio.selectedDesignIds.length + unSelectedOnThisPage <=
        portfolioMaxDesigns
      );
    },
    portfolioCanDeSelectPageOfDesigns() {
      return (
        this.portfolio.selectedDesignIds.map(
          (x) => this.portfolio.designs.indexOf(x.designId) !== -1,
        ).length > 0
      );
    },
  },
  watch: {
    designSubTypeGroupPackages(newDesignSubTypeGroupPackages) {
      this.packageId = newDesignSubTypeGroupPackages[0].id;
    },
  },
  methods: {
    selectPortfolioDesign(designId) {
      if (this.portfolio.selectedDesignIds.length < portfolioMaxDesigns) {
        this.portfolio.selectedDesignIds.push(designId);
      } else {
        this.portfolio.errors = [
          `You can only select a maximum of 8 designs`,
        ];
      }
    },
    deSelectPortfolioDesign(designId) {
      const index = this.portfolio.selectedDesignIds.indexOf(designId);
      if (index !== -1) this.portfolio.selectedDesignIds.splice(index, 1);
      if (this.portfolion.selectedDesignIds.length <= portfolioMaxDesigns)
        this.portfolio.errors = [];
    },
    selectThisPageOfPortfolioDesigns() {
      const nSelectableToMax =
        portfolioMaxDesigns - this.portfolio.selectedDesignIds.length;
      const nToSlice = Math.min(
        this.portfolio.designs.length,
        nSelectableToMax,
      );
      const selectable = this.portfolio.designs
        .filter(
          (x) => this.portfolio.selectedDesignIds.indexOf(x.designId) === -1,
        )
        .map((x) => x.designId)
        .slice(0, nToSlice);
      this.portfolio.selectedDesignIds = [
        ...this.portfolio.selectedDesignIds,
        ...selectable,
      ];
    },
    deSelectAllPortfolioDesigns() {
      this.portfolio.selectedDesignIds = [];
    },
    async initialisePortfolio(newAPO) {
      this.portfolio.apiPostObject = {
        ...this.portfolio.apiPostObject,
        ...newAPO,
      };
      this.portfolio.visible = true;
      const res = await axios.post(
        this.models.urls.selector,
        this.portfolio.apiPostObject,
        axiosConfig,
      );
      await this.loadDesigns(res.data);
    },
    async loadDesigns(data) {
      this.portfolio.designs = data.items;
      this.portfolio.pagination = data.pagination;
      if (!data.filters) return;
      if (data.filters.briefOptions)
        this.portfolio.briefOptions = data.filters.briefOptions;
      if (data.filters.designTypeOptions)
        this.portfolio.designTypeOptions = data.filters.designTypeOptions;
    },
    onSelectSubTypeGroup(subTypGrp) {
      if (this.models.service) return;
      this.subTypeGroupDisplayName = subTypGrp.displayName;
      this.selectedSubTypeId = subTypGrp.designSubTypes[0].id;
      this.packageId = subTypGrp.designSubTypes[0].packages[0].id;
    },
    onSelectPackage(pkg) {
      this.packageId = pkg.id;
    },
    cancel() {
      window.location.href = this.models.urls.servicesPage;
    },
    async save() {
      this.form.disabled = true;

      const bannerImageJson = document.querySelector(
        `[data-upload-id='${this.models.fileUploaders.bannerImage.options.Id}'] input[name='filesJson']`,
      ).value;
      const bannerImages = JSON.parse(bannerImageJson);
      let bannerImage = null;
      if (bannerImages.length) [bannerImage] = bannerImages;

      const thumbnailImageJson = document.querySelector(
        `[data-upload-id='${this.models.fileUploaders.thumbnailImage.options.Id}'] input[name='filesJson']`,
      ).value;
      const thumbnailImages = JSON.parse(thumbnailImageJson);
      let thumbnailImage = null;
      if (thumbnailImages.length) [thumbnailImage] = thumbnailImages;

      const data = {
        DesignerServiceId: (this.models.selectedService || {}).id || null,
        Priority:
          (this.models.selectedService || {}).priority === this.priority
            ? null
            : this.priority,
        DesignSubTypeId: this.models.selectedService
          ? null
          : this.selectedSubTypeId,
        PackageId:
          (this.models.selectedService || {}).packageId === this.packageId
            ? null
            : this.packageId,
        CustomName:
          (this.models.selectedService || {}).customDesignServiceName ===
          this.CustomDesignServiceName
            ? null
            : this.customDesignServiceName,
        ServiceDescription:
          (this.models.selectedService || {}).description === this.description
            ? null
            : this.description,
        ExampleDesignIds: this.portfolio.selectedDesignIds,
        BannerImgUpload: bannerImage,
        ThumbnailImgUpload: thumbnailImage,
      };

      const res = await axios.post(this.models.urls.save, data, axiosConfig);
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };

      if (res.data.errors) {
        this.form.disabled = false;
      } else {
        window.location.href = res.data.redirectUrl;
      }
    },
  },
};
</script>
