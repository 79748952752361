<template>
  <div>
    <PayoutLogsFilter
      :earnings="earnings"
      :enabled="!isLoading"
      @filter-update="onFilterUpdate"
    />
    <table v-if="payoutLogs.length" class="table table--striped">
      <thead>
        <tr>
          <th>Date Selected</th>
          <th>Payment For</th>
          <th>Amount</th>
          <th class="show-for-medium">Method</th>
          <th class="show-for-medium">Transaction ID</th>
          <th class="show-for-medium">Payment Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in payoutLogs" :key="index">
          <td class="prs">{{ item.dateSelected }}</td>
          <td class="prs">
            <a :href="item.contestLink">{{ item.projectDetails }}</a>
            <br />
            {{ item.placement }}
            <br />
            {{ item.projectType }}
            <br />
          </td>
          <td class="prs">{{ item.amount }}</td>
          <td class="prs show-for-medium">
            {{ item.method ? item.method : '-' }}
          </td>
          <td class="prs show-for-medium">
            {{ item.transactionId ? item.transactionId : '-' }}
          </td>
          <td class="prs show-for-medium">
            {{ item.paymentDate ? item.paymentDate : '-' }}
          </td>
          <td class="prs">
            <div class="inline-tooltip">
              <span :class="{ [`label ${setStatus(item.status)}`]: true }">
                {{ item.status }}
              </span>
              <div
                role="tooltip"
                class="tooltip tooltip__payout bottom align-center"
              >
                <p>{{ setStatusText(item.status) }}</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <ManageAccountEmptyCard
      v-else
      title="No items"
      :message="emptyCardMessage"
    />
  </div>
</template>

<script>
import axios from 'axios';
import PayoutLogsFilter from './PayoutLogsFilter.vue';
import ManageAccountEmptyCard from '../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    ManageAccountEmptyCard,
    PayoutLogsFilter
  },
  props: {
    earnings: {
      type: String,
      required: true,
    },
    payoutLogs: {
      type: Array,
      required: true,
    },
    apiBaseUrl: {
      type: String,
      required: true,
    },
    apiPostObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      axiosConfig,
      emptyCardMessage: null,
    };
  },
  computed: {
    nothingToShow() {
      return this.payoutLogs.length === 0;
    },
    somethingToShow() {
      return this.payoutLogs.length > 0;
    },
  },
  methods: {
    setStatus(status) {
      if (status === 'Approved') {
        this.statusClass = 'success';
      } else if (status === 'Pending') {
        this.statusClass = 'primary';
      } else if (status === 'Interim' || status === 'Completed') {
        this.statusClass = '';
      } else if (status === 'Held') {
        this.statusClass = 'warning';
      } else {
        this.statusClass = '';
      }
      return this.statusClass;
    },
    setStatusText(status) {
      if (status === 'Approved') {
        this.text =
          `DesignCrowd staff have approved payment for your submission.`;
      } else if (status === 'Pending') {
        this.text =
          `Payment for your submission has been approved by the customer.`;
      } else if (status === 'Interim') {
        this.text = `Your submission has been selected by the customer.`;
      } else if (status === 'Completed') {
        this.text = `Payment has been made to your account.`;
      }
      return this.text;
    },
    async onFilterUpdate(transientFilterState) {
      if (this.apiBaseUrl && !this.isLoading) {
        this.isLoading = true;

        const apiPostObject =
        { ...transientFilterState,
          ...{ page: 1 }
        };

        const res = await axios.post(
          this.apiBaseUrl,
          apiPostObject,
          axiosConfig,
        );

        this.isLoading = false;

        if (res?.data) {
          if (!res?.data.items.length) {
            this.emptyCardMessage = 'No items matched your filter.';
          } else {
            this.emptyCardMessage = null;
          }
          this.$emit('table-update', { resData: res.data, apiPostObject });
        }
      }
    },
  },
};
</script>
