<template>
  <div class="row">
    <div class="column small-12 large-3">
      <manage-account-menu
        :menu="menu"
        :close-icon-url="icons.close"
        :twitter-icon-url="icons.socialMediaTwitter"
        :facebook-icon-url="icons.socialMediaFacebook"
        :linkedin-icon-url="icons.socialMediaLinkedin"
        invite-link-url="https://placeholderurl.com"
        twitter-link-url="https://placeholderurl.com"
        facebook-link-url="https://placeholderurl.com"
        linkedin-link-url="https://placeholderurl.com"
      />
    </div>
    <div class="column small-12 large-9">
      <ManageAccountEmptyCard
        v-if="currentPage.length === 0"
        :icon-url="icons.emptyProjects"
        icon-alt="No projects"
        title="No Projects"
      >
        <p>
          Looks like you don't have any projects yet.
          <a :href="orderSystemUrl">Post a project now</a>
        </p>
      </ManageAccountEmptyCard>
      <div v-else class="row">
        <div class="column small-12">
          <my-project-card
            v-for="project in currentPage"
            :key="project.briefId + project.draftBriefId"
            :name="project.name"
            :active-step="project.activeStep"
            :days-left="project.daysLeft"
            :date-added="project.dateAdded"
            :comment-count="project.commentCount"
            :status="project.briefStatus"
            :content-mode="project.contentMode.toLowerCase()"
            :package-cost="project.packageCost"
            :design-thumbnail-url="project.designThumbnailImageLocation"
            :designer-display-name="project.designerDisplayName"
            :submission-count="project.submissionCount"
            :show-go-private="project.showGoPrivate"
            :go-private-url="project.goPrivateUrl"
            :status-copy="project.statusCopy"
            :whitelabelling="project.isWhitelabel"
            :whitelabelling-designs-shared="
              project.clientPortalSelectedDesignsCount
            "
            :whitelabelling-feedback="project.clientPortalFeedbackCount"
            :print-upsell-button-copy="project.printUpsellButtonCopy"
            :print-upsell-url="project.printUpsellUrl"
            :access-files-url="project.linkAccessFilesUrl"
            :delete-draft-url="project.linkDeleteDraftUrl"
            :get-more-designs-url="project.linkGetMoreDesignsUrl"
            :view-invoice-url="project.linkInvoiceUrl"
            :manage-project-url="project.linkManageProjectUrl"
            :post-an-update-url="project.linkPostAnUpdateUrl"
            :resume-order-url="project.linkResumeOrderUrl"
            :upgrade-project-url="project.linkUpgradeProjectUrl"
            :view-brief-url="project.linkViewBriefUrl"
            :new-project-with-designer-url="project.newProjectWithDesignerUrl"
            :view-comments-url="project.linkCommentsUrl"
            :active-step-urls="getActiveStepUrls(project)"
            :project-progress-step1-icon-url="icons.projectProgressStep1"
            :project-progress-step2-icon-url="icons.projectProgressStep2"
            :project-progress-step3-icon-url="icons.projectProgressStep3"
            :project-progress-step4-icon-url="icons.projectProgressStep4"
            :question-icon-url="icons.question"
          />
        </div>
        <div class="column small-12 text-center">
          <Pagination
            v-if="pagination"
            :max-page="pagination.maxPage"
            :current="pagination.current"
            :start="pagination.start"
            :end="pagination.end"
            :start-of-page="pagination.startOfPage"
            :number-of-results="pagination.numberOfResults"
            :total-results-count="pagination.totalResultsCount"
            :description="pagination.description"
            :base-url="pagination.baseUrl"
            :api-base-url="pagination.apiBaseUrl"
            :api-query-data="status"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            @page-link-click="onPaginationClick($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import MyProjectCard from '../../components/my-account/customer/my-projects/MyProjectCard/MyProjectCard.vue';
import ManageAccountEmptyCard from '../../components/my-account/ManageAccountEmptyCard/ManageAccountEmptyCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    ManageAccountMenu,
    MyProjectCard,
    ManageAccountEmptyCard,
    Pagination,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    projects: {
      type: Array,
      default: () => [],
    },
    orderSystemUrl: {
      type: String,
      required: true,
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
    icons: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.projects,
      pagination: this.paginationObject,
    };
  },
  computed: {
    status() {
      const status = this.$route.query && this.$route.query.status;
      return status ? { status } : {};
    },
  },
  methods: {
    getActiveStepUrls(project) {
      return {
        step1: project.step1Url,
        step2: project.step2Url,
        step3: project.step3Url,
        step4: project.step4Url,
      };
    },
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
  },
};
</script>
