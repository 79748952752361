<template>
  <Modal
    title="Purchase this design"
    :is-visible="isModalVisible"
    :close-icon="closeIcon"
    @closed="onCloseBuythisDesignModal"
  >
    <template v-slot:body>
      <form
        :ref="purchaseDesignFormRef"
        :action="buyThisDesignUrl"
        class="text-center"
        method="get"
        data-abide
      >
        <img :src="designThumbnailImagePath" alt="Design thumbnail" />
        <p>
          Enter the amount you would like to pay for this design submission (min {{ currencyAbbreviation }}100):
        </p>
        <div class="row align-center">
          <div class="column small-12 medium-5">
            <div class="input-group">
              <span class="input-group-label">
                {{ currencyAbbreviation }}
              </span>
              <label :for="`amount-${_uid}`">
                <input
                  :id="`amount-${_uid}`"
                  v-model="purchaseAmount"
                  class="input-group-field"
                  type="number"
                  name="amount"
                  :min="minPurchaseAmount"
                  required
                />
              </label>
              <span class="input-group-label">.00</span>
            </div>
          </div>
          <div v-if="!isPurchaseAmountDefined" class="column small-12">
            <span class="u-block form-error">
              Please enter a purchase amount
            </span>
          </div>
          <div
            v-if="isPurchaseAmountDefined && !isPurchaseAmountOverMinimum"
            class="column small-12"
          >
            <span class="u-block form-error">
              Please enter a minimum amount of {{ currencyAbbreviation }}100
            </span>
          </div>
        </div>
        <p>After payment, you will own the rights to this design.</p>
      </form>
    </template>
    <template v-slot:footer>
      <div class="row align-center">
        <div class="column shrink">
          <button
            class="button tiny mbn"
            :disabled="isFormDisabled"
            @click="onClickPurchaseDesign"
          >
            Proceed to secure payment
          </button>
        </div>
        <button
          type="button"
          class="button secondary tiny hollow"
          data-close
          @click="onCancelBuythisDesignModal"
        >
          Cancel
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../shared/Modal/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    buyThisDesignUrl: {
      type: String,
      default: undefined,
    },
    designThumbnailImagePath: {
      type: String,
      default: undefined,
    },
    currencyAbbreviation: {
      type: String,
      required: true,
    },
    closeIcon: {
      type: String,
      default: undefined,
    },
    buttonClass: {
      type: String,
      default: undefined,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      purchaseDesignFormRef: 'js-purchase-design-form',
      purchaseAmount: 100,
      minPurchaseAmount: 100,
    };
  },
  computed: {
    purchaseAmountInt() {
      return parseInt(this.purchaseAmount, 10);
    },
    isPurchaseAmountDefined() {
      return !!this.purchaseAmountInt;
    },
    isPurchaseAmountOverMinimum() {
      return this.purchaseAmountInt >= this.minPurchaseAmount;
    },
    isFormDisabled() {
      return !(
        this.isPurchaseAmountDefined && this.isPurchaseAmountOverMinimum
      );
    },
  },
  methods: {
    onClickPurchaseDesign() {
      if (!this.isFormDisabled) {
        this.$refs[this.purchaseDesignFormRef].submit();
      }
      this.$emit('modal-closed');
    },
    onCloseBuythisDesignModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('modal-closed');
    },
    onCancelBuythisDesignModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit('modal-closed');
      this.$emit('modal-not-visible');
    },
  },
};
</script>
