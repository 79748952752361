<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="overview-page-container">    
      <div class="row">
          <div class="column small-12 medium-3">
              <a href="/jobs" class="button full-width large mbm" data-test-browsejobs>Browse Jobs</a>
              <profile-strength
                v-if="flags.profileStrength"
                :percentage="models.profileStrength.percentage"
                class="mbm"
              />
              <manage-account-menu :menu="models.menu" />
          </div>
          <div class="column small-12 medium-9">
              <div v-if="flags.welcome" class="frame frame--padding-tight mbl">
                <h2>Welcome to DesignCrowd!</h2>
                <p>
                  You are now a part of a global community of over {{ models.welcome.designerCount }} creatives, we're happy to have you on board.
                </p>
              </div>
              <div v-if="flags.skillPreferences" class="frame frame--padding-tight mbl">
                <DesignerSkillSelect
                  :categories-to-display="categoriesToDisplay"
                  :categories="models.skillSelector.all"
                  :button-loading-img-url="icons.processing"
                  @submit="updateSkills"
                />
              </div>
              <OverviewStats
                v-if="flags.stats"
                :model="models.overviewStats"
              />
              <hr v-if="flags.stats" class="mtn mbl"/>
              <ProfileCompleteness
                v-if="flags.completeYourProfile"
                :tick-icon="icons.tick"
                :items="models.profileStrength.conditions"
                :percentage="models.profileStrength.percentage"
              />
              <RecommendedJobsList
                v-if="flags.jobs"
                :total-payments="models.recommendedJobs.totalPaymentsForDesigner"
                :briefs="models.recommendedJobs.briefs"
                :submission-count-icon-url="icons.submissionCount"
                :time-left-icon-url="icons.timeLeft"
                :project-budget-icon-url="icons.projectBudget"
                :watchlist-hollow-icon-url="icons.watchlistHollow"
                :watchlist-filled-icon-url="icons.watchlistFilled"
                :add-to-watchlist-api="models.recommendedJobs.addToWatchlistApi"
                :remove-from-watchlist-api="models.recommendedJobs.removeFromWatchlistApi"
              />
          </div>
      </div>
  </fragment>
</template>

<script>
import axios from 'axios';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import DesignerSkillSelect from '../../components/my-account/designer/overview/DesignerSkillSelect/DesignerSkillSelect.vue';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import OverviewStats from '../../components/my-account/designer/overview/OverviewStats/OverviewStats.vue';
import ProfileCompleteness from '../../components/my-account/designer/overview/ProfileCompleteness/ProfileCompleteness.vue';
import RecommendedJobsList from '../../components/my-account/designer/overview/RecommendedJobsList/RecommendedJobsList.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    DesignerSkillSelect,
    ManageAccountMenu,
    OverviewStats,
    ProfileCompleteness,
    RecommendedJobsList,
    ProfileStrength,
  },
  props: {
    models: {
      type: Object,
      required: true,
    },
    flags: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categoriesToDisplay() {
      const categories = [];
      this.models.skillSelector.all.forEach((category) => {
        const index = this.models.skillSelector.subset.indexOf(category.name);
        if (index > -1) {
          categories[index] = category;
        }
      });

      return categories;
    },
  },
  methods: {
    async updateSkills(designTypes) {
      const res = await axios.post(
        '/manage-profile/api/update-skills?source=overview',
        {
          designTypes,
        },
        axiosConfig,
      );

      if (res && res.status === 200 && res.data?.redirectUrl) {
        window.location = res.data?.redirectUrl;
      } else {
        const msg =
          `Something unexpected happened and the request could not be completed.`;
        alert(msg);
      }
    },
  },
};
</script>
