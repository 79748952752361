<template>
  <ModalV2
    :title="title"
    :visible="isVisible"
    :is-loading="isLoading"
    @close="close"
  >
    <template #body>
      <div class="row align-center">
        <img :src="imageUrl" alt="Image Preview" />
      </div>
    </template>
    <template #footer>
      <div class="row align-center">
        <div class="column shrink">
          <button class="button tiny mbn" @click="close">Close</button>
        </div>
      </div>
    </template>
  </ModalV2>
</template>

<script>
import ModalV2 from '../../../shared/Modal/ModalV2.vue';

export default {
  components: {
    ModalV2
},
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    isLoading() {
      return !this.imageUrl;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
