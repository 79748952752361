<template>
  <fieldset
    :key="thingThatICanIncrementToForceAnImmediateRedraw"
    class="starability-basic display"
    :class="{
      [`starability-basic--${size}`]: size,
    }"
  >
    <legend>Star rating:</legend>
    <input
      :id="`rate5-${_uid}`"
      :class="{ checked: rating === 5 }"
      name="rating"
      value="5"
    />
    <label
      :for="`rate5-${_uid}`"
      title="EXCELLENT - I would consider selecting this as the winning design"
      aria-label="EXCELLENT - I would consider selecting this as the winning design"
      data-test-rating="excellent"
    >
      5 stars
    </label>
    <input
      :id="`rate4-${_uid}`"
      :class="{ checked: rating === 4 }"
      name="rating"
      value="4"
    />
    <label
      :for="`rate4-${_uid}`"
      title="VERY GOOD - High quality submission that needs some work"
      aria-label="VERY GOOD - High quality submission that needs some work"
      data-test-rating="very-good"
    >
      4 stars
    </label>
    <input
      :id="`rate3-${_uid}`"
      :class="{ checked: rating === 3 }"
      name="rating"
      value="3"
    />
    <label
      :for="`rate3-${_uid}`"
      title="GOOD - Good attempt but not in contention unless changes are made"
      aria-label="GOOD - Good attempt but not in contention unless changes are made"
      data-test-rating="good"
    >
      3 stars
    </label>
    <input
      :id="`rate2-${_uid}`"
      :class="{ checked: rating === 2 }"
      name="rating"
      value="2"
    />
    <label
      :for="`rate2-${_uid}`"
      title="POOR - I dont like this design but would like designer to try again"
      aria-label="POOR - I dont like this design but would like designer to try again"
      data-test-rating="poor"
    >
      2 stars
    </label>
    <input
      :id="`rate1-${_uid}`"
      :class="{ checked: rating === 1 }"
      name="rating"
      value="1"
    />
    <label
      :for="`rate1-${_uid}`"
      title="VERY POOR - Design not following the brief or it's extremely low quality"
      aria-label="VERY POOR - Design not following the brief or it's extremely low quality"
      data-test-rating="very-poor"
    >
      1 star
    </label>
  </fieldset>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: undefined,
    },
    rating: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      thingThatICanIncrementToForceAnImmediateRedraw: 0,
    };
  },
};
</script>
