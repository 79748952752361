<template>
  <div class="row">
    <div v-if="alerts && alerts.length > 0" class="column small-12">
      <Callout
        v-for="(alert, index) in alerts"
        :key="index"
        :type="alert.type"
        :title="alert.title"
        :icon-url="icons[`${alert.type}Icon`]"
        closable
        @close="alerts = []"
      >
        <template #message>{{ alert.message }}</template>
      </Callout>
    </div>
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <div class="frame frame--padding-small ptn">
        <div class="row column">
          <ul class="menu menu--tabs menu--tabs-white u-block full-width">
            <li
              v-for="(tab, index) in Object.keys(TABS)"
              :key="index"
              :class="{
                'menu__active-tab': isTabVisible(TABS[tab]),
              }"
              @click="openTab(TABS[tab])"
            >
              <a>{{ TABS[tab] }}</a>
            </li>
          </ul>
          <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
        </div>
        <AccountDetailsForm
          v-if="isTabVisible(TABS.ACCOUNT_DETAILS)"
          :address="userAccountDetails.address"
          :city="userAccountDetails.city"
          :company-name="userAccountDetails.companyName"
          :country="userAccountDetails.country"
          :email="accountDetails.email"
          :first-name="userAccountDetails.firstName"
          :last-name="userAccountDetails.lastName"
          :phone="userAccountDetails.phone"
          :postcode="userAccountDetails.postcode"
          :state="userAccountDetails.state"
          :password-system-generated="
            userAccountDetails.passwordSystemGenerated
          "
          :countries="countries"
          :reset-change-password-form="resetChangePasswordForm"
          :processing="processing"
          @update-password="
            updatePassword(
              $event.password,
              $event.newPassword,
              $event.confirmPassword,
            )
          "
          @update-details="updateDetails"
        />
        <EmailSubscriptionsForm
          v-if="isTabVisible(TABS.EMAIL_SUBSCRIPTION)"
          :email="accountDetails.email"
          :product-offer-and-promotion-enabled="
            userEmailSubscriptions.productOfferAndPromotionEnabled
          "
          :newsletter-enabled="userEmailSubscriptions.newsletterEnabled"
          :general-project-alert-enabled="
            userEmailSubscriptions.generalProjectAlertEnabled
          "
          :new-design-notification-enabled="
            userEmailSubscriptions.newDesignNotificationEnabled
          "
          :daily-summary-enabled="userEmailSubscriptions.dailySummaryEnabled"
          :invite-notification-enabled="
            userEmailSubscriptions.inviteNotificationEnabled
          "
          @update-subscription="updateSubscriptions"
        />
        <StoredCards
          v-if="isTabVisible(TABS.STORED_CARDS)"
          :stored-cards="userStoredCards"
          :is-disabled="!canRemoveCards"
          @remove-card="removeCard($event.card, $event.index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import AccountDetailsForm from '../../components/my-account/customer/account-settings/AccountDetailsForm/AccountDetailsForm.vue';
import EmailSubscriptionsForm from '../../components/my-account/customer/account-settings/EmailSubscriptionsForm/EmailSubscriptionsForm.vue';
import StoredCards from '../../components/my-account/customer/account-settings/StoredCards/StoredCards.vue';
import Callout from '../../components/shared/Callout/Callout.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    ManageAccountMenu,
    AccountDetailsForm,
    EmailSubscriptionsForm,
    StoredCards,
    Callout,
  },
  props: {
    icons: {
      type: Object,
      default: () => {},
    },
    menu: {
      type: Object,
      required: true,
    },
    accountDetails: {
      type: Object,
      required: true,
    },
    emailSubscriptions: {
      type: Object,
      required: true,
    },
    storedCards: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userAccountDetails: this.accountDetails,
      userEmailSubscriptions: this.emailSubscriptions,
      userStoredCards: this.storedCards,
      visibleTab: null,
      alerts: [],
      canRemoveCards: true,
      resetChangePasswordForm: false,
      processing: false,
    };
  },
  created() {
    this.TABS = {
      ACCOUNT_DETAILS:
        "ACCOUNT DETAILS",
      EMAIL_SUBSCRIPTION:
        "EMAIL SUBSCRIPTION",
      STORED_CARDS:
        "STORED CARDS",
    };
  },
  mounted() {
    this.visibleTab = this.TABS.ACCOUNT_DETAILS;
  },
  methods: {
    openTab(tab) {
      this.visibleTab = tab;
      this.alerts = [];
    },
    isTabVisible(tab) {
      return this.visibleTab === tab;
    },
    pushSuccessAlert(alert) {
      this.alerts = [];
      this.alerts.push({
        type: 'success',
        message: alert,
        title: 'Success',
      });
    },
    pushErrorAlerts(alerts) {
      this.alerts = [];

      alerts.forEach((alert) => {
        this.alerts.push({
          type: 'warning',
          message: alert,
          title: 'Warning',
        });
      });
    },
    async updatePassword(password, newPassword, confirmPassword) {
      this.resetChangePasswordForm = false;

      const res = await axios.post(
        this.accountDetails.updatePasswordEndpoint,
        {
          Password: password,
          NewPassword: newPassword,
          ConfirmPassword: confirmPassword,
        },
        axiosConfig,
      );

      if (res && res.status === 200) {
        if (res.data?.successMessage) {
          this.resetChangePasswordForm = true;
          this.pushSuccessAlert(res.data.successMessage);
        }
        if (res.data?.errors) {
          this.pushErrorAlerts(res.data.errors);
        }
      }
    },
    async updateDetails(accountDetails) {
      this.processing = true;
      const res = await axios.post(
        this.accountDetails.updateDetailsEndpoint,
        {
          Email: accountDetails.email,
          FirstName: accountDetails.firstName,
          LastName: accountDetails.lastName,
          Phone: accountDetails.phone,
          CompanyName: accountDetails.companyName,
          Address: accountDetails.address,
          City: accountDetails.city,
          State: accountDetails.state,
          Postcode: accountDetails.postcode,
          Country: accountDetails.country,
        },
        axiosConfig,
      );

      if (res && res.status === 200) {
        this.userAccountDetails = accountDetails;

        if (res.data?.successMessage) {
          this.pushSuccessAlert(res.data.successMessage);
        }
        if (res.data?.errors) {
          this.pushErrorAlerts(res.data.errors);
        }
      }

      this.processing = false;
    },
    async updateSubscriptions(emailSubscriptionDetails) {
      const res = await axios.post(
        this.emailSubscriptions.updateEndpoint,
        {
          ProductOfferAndPromotionEnabled:
            emailSubscriptionDetails.productOfferAndPromotionEnabled,
          NewsletterEnabled: emailSubscriptionDetails.newsletterEnabled,
          GeneralProjectAlertEnabled:
            emailSubscriptionDetails.generalProjectAlertEnabled,
          NewDesignNotificationEnabled:
            emailSubscriptionDetails.newDesignNotificationEnabled,
          DailySummaryEnabled: emailSubscriptionDetails.dailySummaryEnabled,
          InviteNotificationEnabled:
            emailSubscriptionDetails.inviteNotificationEnabled,
        },
        axiosConfig,
      );

      if (res && res.status === 200) {
        this.userEmailSubscriptions = emailSubscriptionDetails;

        if (res.data?.successMessage) {
          this.pushSuccessAlert(res.data.successMessage);
        }
        if (res.data?.errors) {
          this.pushSuccessAlert(res.data.errors);
        }
      }
    },
    async removeCard(card, index) {
      this.userStoredCards.splice(index, 1);

      if (card) {
        this.canRemoveCards = false;
        const { cardId, currency, stripeCustomerId } =
          card.removeStoredCardData;

        const res = await axios.post(
          card.removeStoredCardEndpoint,
          {
            CardId: cardId,
            Currency: currency,
            StripeCustomerId: stripeCustomerId,
          },
          axiosConfig,
        );

        if (res && res.status === 200) {
          this.canRemoveCards = true;
          this.pushSuccessAlert(
            'Your card has successfully been removed from your account',
          );
        }
      }
    },
  },
};
</script>
