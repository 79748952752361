<template>
  <ModalV2
    title="Switch to yearly plan"
    :close-icon="icons.close"
    :visible="isVisible"
    @close="close"
  >
    <template #body>
      <div class="text-center">
        <img alt="An image" class="mbs" :src="icons.commonLargeAnnualPremium" />
        <h4 class="mbm">
          {{ unPrettierNuggets.saveOverYearlyCopy(subscription) }}
        </h4>
        <div class="flex-container align-center">
          <div class="text-left text-medium">
            <p class="mbs">
              <img alt="An image" :src="icons.commonSmallCheckBlue" />
              {{ unPrettierNuggets.annualUpgradeOnlyCopy(subscription) }}
            </p>
            <p class="mbs">
              <img alt="An image" :src="icons.commonSmallCheckBlue" />
              {{ unPrettierNuggets.saveOverMonthToMonthCopy(subscription) }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row align-center">
        <div class="column shrink">
          <button
            v-if="subscription.subscriptionAnnualUpgradeUrl"
            class="mbs button tiny hollow secondary tiny full-width form-stacking__item"
            @click="next"
          >
            Cancel subscription
          </button>
        </div>
        <div class="column shrink">
          <button
            type="button"
            class="button tiny mbn prm plm"
            @click="upgrade"
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </template>
  </ModalV2>
</template>

<script>
import ModalV2 from '../../../../shared/Modal/ModalV2.vue';

export default {
  components: {
    ModalV2,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
    cancelSubscriptionFlow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      unPrettierNuggets: {
        saveOverYearlyCopy: (subscription) => {
          return `Save ${subscription.percentSavedWithYearly}% with a yearly plan`;
        },
        annualUpgradeOnlyCopy: (subscription) => {
          return `${subscription.subscriptionFor} annual upgrade is only ${subscription.annualYearlyCostCopy}/year`;
        },
        saveOverMonthToMonthCopy: (subscription) => {
          return `Save ${subscription.yearlySavingOverMonthToMonthCopy}/year over month-to-month!`;
        },
      },
    };
  },
  computed: {
    isVisible() {
      return this.visible;
    },
  },
  methods: {
    upgrade() {
      window.location.href = this.subscription.subscriptionAnnualUpgradeUrl;
    },
    close() {
      this.$emit('close');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
