import axios from 'axios';

const getDesignDetailAsync = async (designId) => {
  const response = await axios({
    method: 'POST',
    url: `/manage-project/api/get-design-detail/${designId}`,
    params: { timestamp: new Date().getTime() },
  });

  if (response && response.status === 200) {
    return response.data;
  }

  return null;
};

export default {
  axios,
  getDesignDetailAsync,
};
