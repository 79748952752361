<template>
  <div class="frame frame--border-alt frame--padding-small">
    <fragment v-if="projectName" name="thread-detail-designer-name">
      <h6>Project Name</h6>
      <a :href="projectNameUrl">
        {{ projectName }}
      </a>
      <div class="horizontal-rule frame__full-width-container">
        <hr class="hr hr--alt" />
      </div>
    </fragment>
    <fragment v-if="subject" name="thread-detail-subject">
      <h6>Subject</h6>
      {{ subject }}
      <div class="horizontal-rule frame__full-width-container">
        <hr class="hr hr--alt" />
      </div>
    </fragment>
    <fragment v-if="designerDisplayName" name="thread-detail-designer">
      <h6>Designer</h6>
      <div
        class="user-profile user-profile--designer-portfolio user-profile--tiny"
      >
        <div class="row align-middle">
          <div class="column shrink">
            <div class="user-profile__image">
              <img :src="designerProfilePic" :alt="displayPictureAlt" />
            </div>
          </div>
          <div class="column">
            <a :href="designerNameUrl">
              {{ designerDisplayName }}
            </a>
          </div>
        </div>
      </div>
    </fragment>
  </div>
</template>

<script>
export default {
  props: {
    projectName: {
      type: String,
      default: undefined,
    },
    projectNameUrl: {
      type: String,
      default: undefined,
    },
    subject: {
      type: String,
      default: undefined,
    },
    designerProfilePic: {
      type: String,
      default: undefined,
    },
    designerDisplayName: {
      type: String,
      default: undefined,
    },
    designerNameUrl: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    displayPictureAlt() {
      return `${this.fromDisplayName}'s profile picture`;
    },
  },
};
</script>
