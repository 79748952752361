import { render, staticRenderFns } from "./SubmitDesign.vue?vue&type=template&id=14f52dd4&"
import script from "./SubmitDesign.vue?vue&type=script&lang=js&"
export * from "./SubmitDesign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports