<template>
  <div class="subscription-card form-stacking">
    <div class="subscription-card__header">
      <div class="row align-justify">
        <div class="column shrink form-stacking__item">
          <h4 class="u-inline-block mrs mbn">{{ subscription.title }}</h4>
          <span :class="{ [`label ${statusClass} mts mls`]: true }">
            {{ subscription.status.toUpperCase() }}
          </span>
          <span
            v-if="subscription.pauseEffectiveDateCopy"
            class="label warning mts mls"
            v-html="unPrettierNuggets.pausePillCopy"
          ></span>
        </div>
      </div>
    </div>
    <div class="subscription-card__body">
      <div
        v-if="subscription.showAnnualUpgrade"
        class="row card-info mrn mln mbm"
      >
        <div
          class="column small-12 medium-3 flex-container align-center justify-center"
        >
          <img
            :src="icons.commonLargeAnnualPremium"
            class="form-stacking__item"
            alt="icon"
          />
        </div>
        <div class="column small-12 medium-6">
          <h5>
            {{ unPrettierNuggets.upsellHeading }}
          </h5>
          <p>
            {{ unPrettierNuggets.upsellCopy }}
          </p>
        </div>
        <div
          class="column small-12 medium-3 flex-container align-center-middle"
        >
          <form>
            <button
              class="button hollow secondary tiny"
              type="button"
              :disabled="processing"
              @click="annualUpgradeSubscription"
            >
              Upgrade Now
            </button>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="column small-12 medium-3">
          <img
            v-if="subscription.thumbnailUrl"
            :src="subscription.thumbnailUrl"
            class="form-stacking__item full-width"
            alt="subscription thumbnail"
          />
        </div>
        <div class="column small-12 medium-expand">
          <div class="row">
            <div class="column small-12 medium-8">
              <h5>{{ subscription.subscriptionFor }}</h5>
              <p v-html="subscription.autoRenewalCopy"></p>
              <div class="flex-container gap-10">
                <button
                  v-if="subscription.showAnnualUpgrade"
                  class="button hollow secondary tiny"
                  type="button"
                  :disabled="processing"
                  @click="annualUpgradeSubscription"
                >
                  Change Plan
                </button>
                <button
                  v-if="subscription.showUnCancel"
                  class="button primary tiny"
                  type="button"
                  :disabled="processing"
                  @click="unCancelSubscription"
                >
                  Reactivate Now
                </button>
                <button
                  v-if="subscription.showReactivate"
                  class="button primary tiny"
                  type="button"
                  :disabled="processing"
                  @click="reactivateSubscription"
                >
                  Reactivate Now
                </button>
                <button
                  v-if="subscription.showUnPause"
                  class="button primary tiny"
                  type="button"
                  :disabled="processing"
                  @click="unPauseSubscription"
                >
                  Un-pause Now
                </button>
                <div
                  v-if="
                    subscription.subscriptionUpdateCardUrl ||
                    subscription.showCancelCard
                  "
                >
                  <button
                    class="button hollow secondary tiny pas flex-container align-center-middle"
                    @click="onClickOptions"
                  >
                    <img
                      class="mrn small-2 shrink"
                      :src="icons.ellipsis"
                      alt="ellipsis icon"
                      width="20px"
                    />
                  </button>
                  <ul
                    v-if="isDesignOptions"
                    v-click-outside="onClickOptions"
                    class="subscription-card__menu"
                    :class="{
                      'subscription-card__menu-left':
                        !subscription.showReactivate &&
                        !subscription.showUnCancel &&
                        !subscription.showAnnualUpgrade,
                    }"
                  >
                    <li v-if="subscription.subscriptionUpdateCardUrl">
                      <a :href="subscription.subscriptionUpdateCardUrl">
                        <img
                          :src="icons.paymentIcon"
                          alt="delete icon"
                          class="mrs"
                        />
                        Update payment method
                      </a>
                    </li>
                    <li v-if="subscription.showCancelCard">
                      <button
                        :disabled="processing"
                        @click="
                          if (subscription.subscriptionAnnualUpgradeUrl)
                            showCancelSubscriptionFlowModal1Upsell = true;
                          else showCancelSubscriptionFlowModal2Dissuade = true;
                        "
                      >
                        <img
                          :src="icons.deleteIcon"
                          alt="delete icon"
                          class="mrs"
                        />
                        Cancel subscription
                      </button>
                    </li>
                    <li v-if="subscription.showPause">
                      <button
                        :disabled="processing"
                        @click="
                          showCancelSubscriptionFlowModalStandAlone = true;
                          showCancelSubscriptionFlowModal3Pause = true;
                        "
                      >
                        <img
                          :src="icons.pauseIcon"
                          alt="pause icon"
                          class="mrs"
                        />
                        Pause subscription
                      </button>
                    </li>
                    <li v-else-if="subscription.showUnPause">
                      <button
                        :disabled="processing"
                        @click="unPauseSubscription"
                      >
                        <img
                          :src="icons.unpauseIcon"
                          alt="unpause icon"
                          class="mrs"
                        />
                        Un-pause subscription
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Reactivate subscription with discount feature coming soon... -->
              <!-- <div
                v-if="false"
                class="frame frame--border frame--padding-small mbm"
              >
                <div class="row">
                  <div class="column shrink">
                    <img :src="icons.discount" alt="discount icon" />
                  </div>
                  <div class="column">
                    <div class="row">
                      <div class="column small-12">
                        <strong>Reactivate subscription</strong>
                        Get 45% off when you subscribe again
                      </div>
                      <div class="column small-12">
                        <button class="button primary tiny mtm mbn">
                          Reactivate Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- End reactivate subscription with discount feature -->

              <div class="form-stacking__item">
                <h5>Payment Method</h5>
                <img
                  class="mrs"
                  :src="subscription.paymentMethodIconUrl"
                  alt="payment method icon"
                />
                <span class="mls">{{ subscription.paymentMethodCopy }}</span>
              </div>
            </div>
            <div class="column small-12 medium-4">
              <h5>Billing History</h5>
              <div
                v-for="(item, index) in subscription.billingItems"
                :key="index"
                class="row"
              >
                <div class="column shrink">{{ item.date }}</div>
                <div
                  class="column small-6 medium-expand medium-text-right text-left"
                >
                  {{ item.amount }}
                </div>
              </div>
              <a
                v-if="
                  subscription.viewMoreBillingItems.length > 0 &&
                  !showMoreBillingItems
                "
                @click="showMoreBillingItems = true"
              >
                view more...
              </a>
              <div v-if="showMoreBillingItems">
                <div
                  v-for="(item, index) in subscription.viewMoreBillingItems"
                  :key="index"
                  class="row"
                >
                  <div class="column shrink">{{ item.date }}</div>
                  <div
                    class="column small-6 medium-expand medium-text-right text-left"
                  >
                    {{ item.amount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CancelSubscriptionFlowModal1Upsell
      :cancel-subscription-flow="cancelSubscriptionFlow"
      :subscription="subscription"
      :icons="icons"
      :visible="showCancelSubscriptionFlowModal1Upsell"
      @next="
        showCancelSubscriptionFlowModal1Upsell = false;
        showCancelSubscriptionFlowModal2Dissuade = true;
      "
      @close="closeCancelSubscriptionFlowModals"
    />
    <CancelSubscriptionFlowModal2Dissuade
      :cancel-subscription-flow="cancelSubscriptionFlow"
      :subscription="subscription"
      :icons="icons"
      :visible="showCancelSubscriptionFlowModal2Dissuade"
      @next="
        showCancelSubscriptionFlowModal2Dissuade = false;
        if (subscription.showPause)
          showCancelSubscriptionFlowModal3Pause = true;
        else showCancelSubscriptionFlowModal4CancelReactivate = true;
      "
      @close="closeCancelSubscriptionFlowModals"
    />
    <CancelSubscriptionFlowModal3Pause
      :cancel-subscription-flow="cancelSubscriptionFlow"
      :subscription="subscription"
      :icons="icons"
      :visible="showCancelSubscriptionFlowModal3Pause"
      :standalone="showCancelSubscriptionFlowModalStandAlone"
      @next="
        showCancelSubscriptionFlowModal3Pause = false;
        showCancelSubscriptionFlowModal4CancelReactivate = true;
      "
      @close="closeCancelSubscriptionFlowModals"
    />
    <CancelSubscriptionFlowModal4CancelReactivate
      :cancel-subscription-flow="cancelSubscriptionFlow"
      :subscription="subscription"
      :icons="icons"
      :visible="showCancelSubscriptionFlowModal4CancelReactivate"
      @close="closeCancelSubscriptionFlowModals"
    />
  </div>
</template>

<script>
import axios from 'axios';
import CancelSubscriptionFlowModal1Upsell from '../CancelSubscriptionModal/CancelSubscriptionFlowModal1Upsell.vue';
import CancelSubscriptionFlowModal2Dissuade from '../CancelSubscriptionModal/CancelSubscriptionFlowModal2Dissuade.vue';
import CancelSubscriptionFlowModal3Pause from '../CancelSubscriptionModal/CancelSubscriptionFlowModal3Pause.vue';
import CancelSubscriptionFlowModal4CancelReactivate from '../CancelSubscriptionModal/CancelSubscriptionFlowModal4CancelReactivate.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    CancelSubscriptionFlowModal1Upsell,
    CancelSubscriptionFlowModal2Dissuade,
    CancelSubscriptionFlowModal3Pause,
    CancelSubscriptionFlowModal4CancelReactivate,
  },
  props: {
    icons: {
      type: Object,
      required: true,
    },
    cancelSubscriptionFlow: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMoreBillingItems: false,
      processing: false,
      isDesignOptions: false,

      showCancelSubscriptionFlowModal1Upsell: false,
      showCancelSubscriptionFlowModal2Dissuade: false,
      showCancelSubscriptionFlowModal3Pause: false,
      showCancelSubscriptionFlowModal4CancelReactivate: false,
      showCancelSubscriptionFlowModalStandAlone: false,

      unPrettierNuggets: {
        upsellHeading: `Save ${this.subscription.percentSavedWithYearly}% with a yearly plan`,
        upsellCopy: `The annual price for this subscription is only ${this.subscription.annualYearlyCostCopy}/year. This saves you ${this.subscription.yearlySavingOverMonthToMonthCopy}/year compared to your monthly subscription`,
        pausePillCopy: `Will pause between ${this.subscription.pauseEffectiveDateCopy} and ${this.subscription.resumeEffectiveDateCopy}`,
      },
    };
  },
  computed: {
    statusClass() {
      if (this.subscription.status.toLowerCase() === 'active') {
        return 'primary';
      }

      if (this.subscription.status.toLowerCase() === 'inactive') {
        return 'secondary';
      }

      return 'warning';
    },
  },
  methods: {
    closeCancelSubscriptionFlowModals() {
      this.showCancelSubscriptionFlowModal1Upsell = false;
      this.showCancelSubscriptionFlowModal2Dissuade = false;
      this.showCancelSubscriptionFlowModal3Pause = false;
      this.showCancelSubscriptionFlowModal4CancelReactivate = false;
      this.showCancelSubscriptionFlowModalStandAlone = false;
    },
    async unCancelSubscription() {
      if (this.processing) return;
      this.processing = true;

      const res = await axios
        .post(
          this.cancelSubscriptionFlow.unCancelSubscriptionApi,
          {
            subscriptionId: this.subscription.subscriptionId,
          },
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        window.location.href = res.data.redirectUrl;
      }
    },
    async reactivateSubscription() {
      if (this.processing) return;
      this.processing = true;

      const res = await axios
        .post(
          this.cancelSubscriptionFlow.reactivateSubscriptionApi,
          {
            subscriptionId: this.subscription.subscriptionId,
          },
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        window.location.href = res.data.redirectUrl;
      }
    },
    async annualUpgradeSubscription() {
      if (this.processing) return;
      this.processing = true;
      window.location.href = this.subscription.subscriptionAnnualUpgradeUrl;
    },
    onClickOptions(event) {
      event.preventDefault();
      this.isDesignOptions = !this.isDesignOptions;
    },
    async unPauseSubscription() {
      if (this.processing) return;
      this.processing = true;

      const res = await axios
        .post(
          this.cancelSubscriptionFlow.unPauseSubscriptionApi,
          {
            subscriptionId: this.subscription.subscriptionId,
          },
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        window.location.href = res.data.redirectUrl;
      }
    },
  },
};
</script>
