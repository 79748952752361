<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <div class="row text-center">
        <div
          v-if="currentPage.length === 0 && addons.length === 0"
          class="column"
        >
          <MyAccountEmptyCard
            :icon-url="icons.emptyOrders"
            icon-alt="No print orders"
          >
            <p>
              You need a design before you can order printing.
              <a :href="orderSystemUrl">Launch a Design Project</a>
            </p>
          </MyAccountEmptyCard>
        </div>
        <div v-else-if="currentPage.length === 0" class="column small-12">
          <MyAccountEmptyCard
            :icon-url="icons.emptyOrders"
            icon-alt="No print orders"
            title="No Print Orders"
          >
            <p>
              Looks like you don’t have any print orders yet. Choose an order below
            </p>
          </MyAccountEmptyCard>
        </div>
        <div v-else class="column small-12">
          <table class="table table--striped-body">
            <thead class="show-for-medium">
              <tr>
                <th>Brief ID</th>
                <th>Items</th>
                <th>Order Total</th>
                <th>Shipping</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <PrintOrderTableRow
              v-for="(order, index) in currentPage"
              :key="index"
              :brief-id="order.briefId"
              :items="order.lines"
              :price="order.total"
              :shipping="order.shipping"
              :is-completed="order.isCompleted"
              :is-in-progress="order.isInProgress"
              :is-refunded="order.isRefunded"
              :is-awaiting-design-approval="order.isAwaitingDesignApproval"
              :arrow-down-icon-url="icons.arrowDown"
              :arrow-up-icon-url="icons.arrowUp"
            />
          </table>
          <Pagination
            :max-page="pagination.maxPage"
            :current="pagination.current"
            :start="pagination.start"
            :end="pagination.end"
            :start-of-page="pagination.startOfPage"
            :number-of-results="pagination.numberOfResults"
            :total-results-count="pagination.totalResultsCount"
            :description="pagination.description"
            :base-url="pagination.baseUrl"
            :api-base-url="pagination.apiBaseUrl"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            @page-link-click="onPaginationClick($event)"
          />
        </div>
        <div class="column small-12">
          <div class="addon-group mtm">
            <PrintAddon
              v-for="(addon, key) in addons"
              :key="key"
              :icon-url="addon.iconUrl"
              :heading="addon.heading"
              :description="addon.description"
              :brief-list-label="addon.briefListLabel"
              :briefs="addon.briefs"
              :action-text="addon.actionText"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import PrintAddon from '../../components/my-account/customer/my-print-orders/PrintAddon/PrintAddon.vue';
import PrintOrderTableRow from '../../components/my-account/customer/my-print-orders/PrintOrderTableRow/PrintOrderTableRow.vue';
import MyAccountEmptyCard from '../../components/my-account/ManageAccountEmptyCard/ManageAccountEmptyCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    ManageAccountMenu,
    PrintAddon,
    PrintOrderTableRow,
    MyAccountEmptyCard,
    Pagination,
  },
  props: {
    icons: {
      type: Object,
      default: undefined,
    },
    menu: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    addons: {
      type: Array,
      default: () => [],
    },
    orderSystemUrl: {
      type: String,
      required: true,
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.orders,
      pagination: this.paginationObject,
    };
  },
  computed: {
    status() {
      const status = this.$route.query && this.$route.query.status;
      return { status } || {};
    },
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
  },
};
</script>
