import * as mutationTypes from './designs-mutation-types';

export default {
  resetFilters: ({ commit }) => {
    commit(mutationTypes.RESET_FILTERS);
  },
  setDesigns: ({ commit }, designs) => {
    commit(mutationTypes.SET_DESIGNS, designs);
  },
  setShowFilters: ({ commit }, showFilters) => {
    commit(mutationTypes.SET_SHOW_FILTERS, showFilters);
  },
  addToRatingsFilter: ({ commit, state }, rating) => {
    if (state.ratingsFilter.indexOf(rating) === -1) {
      commit(mutationTypes.ADD_TO_RATINGS_FILTER, rating);
    }
  },
  removeFromRatingsFilter: ({ commit, state }, rating) => {
    if (state.ratingsFilter.indexOf(rating) > -1) {
      commit(mutationTypes.REMOVE_FROM_RATINGS_FILTER, rating);
    }
  },
  filterDesignsByRatings: ({ commit, state }) => {
    if (state.ratingsFilter.length > 0) {
      commit(mutationTypes.FILTER_BY_RATINGS);
    } else {
      commit(mutationTypes.RESET_FILTERS);
    }
  },
  addToDesignersFilter: ({ commit, state }, designer) => {
    if (state.designersFilter.indexOf(designer) === -1) {
      commit(mutationTypes.ADD_TO_DESIGNERS_FILTER, designer);
    }
  },
  removeFromDesignersFilter: ({ commit, state }, designer) => {
    if (state.designersFilter.indexOf(designer) > -1) {
      commit(mutationTypes.REMOVE_FROM_DESIGNERS_FILTER, designer);
    }
  },
  filterDesignsByDesignerNames: ({ commit }) => {
    commit(mutationTypes.FILTER_BY_DESIGNERS);
  },
  setFeedbackFilter: ({ commit }, feedbackType) => {
    commit(mutationTypes.SET_FEEDBACK_FILTER, feedbackType);
  },
  setOrderFilter: ({ commit }, orderType) => {
    commit(mutationTypes.SET_ORDER_FILTER, orderType);
  },
  filterDesignsbyEliminated: ({ commit }, isEliminated) => {
    commit(mutationTypes.FILTER_BY_ELIMINATED, isEliminated);
  },
  setEliminatedFilter: ({ commit }, showEliminatedDesigns) => {
    commit(mutationTypes.SET_ELIMINATED_FILTER, showEliminatedDesigns);
  },
  applyAllFilters: ({ commit, state }) => {
    commit(mutationTypes.RESET_FILTERED_DESIGNS);

    if (state.ratingsFilter.length > 0) {
      commit(mutationTypes.FILTER_BY_RATINGS, state.filteredDesigns);
    }

    if (state.designersFilter.length > 0) {
      commit(mutationTypes.FILTER_BY_DESIGNERS, state.filteredDesigns);
    }

    commit(mutationTypes.FILTER_BY_FEEDBACK, state.filteredDesigns);
    commit(mutationTypes.FILTER_BY_ELIMINATED, state.filteredDesigns);

    commit(mutationTypes.FILTER_BY_ORDER, state.filteredDesigns);
  },
  addToSharedDesigns: ({ commit }, design) => {
    commit(mutationTypes.ADD_TO_SHARED_DESIGNS, design);
  },
  removeFromSharedDesigns: ({ commit }, design) => {
    commit(mutationTypes.REMOVE_FROM_SHARED_DESIGNS, design);
  },
  removeAllSharedDesigns: ({ commit }) => {
    commit(mutationTypes.REMOVE_ALL_SHARED_DESIGNS);
  },
};
