var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"column small-6 large-3 text-center"},[_c('img',{class:{
        'my-project-card__progress-step': !!_vm.activeStepUrls.step1,
        'my-project-card__progress-step--disabled': !_vm.activeStepUrls.step1,
      },attrs:{"src":_vm.projectProgressStep1IconUrl,"alt":"Project details"}})]),_c('div',{staticClass:"column small-6 large-3 text-center"},[_c('img',{class:{
        'my-project-card__progress-step': !!_vm.activeStepUrls.step2,
        'my-project-card__progress-step--disabled': !_vm.activeStepUrls.step2,
      },attrs:{"src":_vm.projectProgressStep2IconUrl,"alt":"Creative brief"}})]),_c('div',{staticClass:"column small-6 large-3 text-center"},[_c('img',{class:{
        'my-project-card__progress-step': !!_vm.activeStepUrls.step3,
        'my-project-card__progress-step--disabled': !_vm.activeStepUrls.step3,
      },attrs:{"src":_vm.projectProgressStep3IconUrl,"alt":"Design options"}})]),_c('div',{staticClass:"column small-6 large-3 text-center"},[_c('img',{class:{
        'my-project-card__progress-step': !!_vm.activeStepUrls.step4,
        'my-project-card__progress-step--disabled': !_vm.activeStepUrls.step4,
      },attrs:{"src":_vm.projectProgressStep4IconUrl,"alt":"Payment options"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }