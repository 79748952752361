<template>
  <div v-show="visible" class="designer-profile__invitation-banner text-center">
    <div
      class="designer-profile__invitation-banner--header pas flex-container align-center"
    >
      <h6 class="column shrink prn">You've been invited to a project</h6>
      <img
        class="column shrink"
        :src="closeIcon"
        alt="close icon image"
        @click="onCloseInvitations"
      />
    </div>
    <div
      v-if="!responded"
      class="designer-profile__invitation-banner--body ptm"
    >
      <p class="mbs p-fine-print">Invited to participate in:</p>
      <h6 class="mbm">{{ projectName }}</h6>
      <div class="flex-container align-center">
        <a class="button tiny mrs" @click="accept">
          <span class="text-white">Accept</span>
        </a>
        <a class="button secondary tiny hollow mls" @click="reject">
          Reject
        </a>
      </div>
    </div>
    <div v-else class="designer-profile__invitation-banner--body ptm pbs">
      <p class="mbs p-fine-print">Thanks!</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
export default {
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    closeIcon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      projectName: null,
      inviteId: null,
      responded: false,
    };
  },
  async mounted() {
    this.checkForInvite();
  },
  methods: {
    onCloseInvitations() {
      this.visible = false;
    },
    async accept() {
      setTimeout(() => {
        this.visible = false;
      }, 1000);
      this.responded = true;
      await axios.post(
        this.endpoint,
        { acceptInviteId: this.inviteId },
        axiosConfig,
      );
    },
    async reject() {
      setTimeout(() => {
        this.visible = false;
      }, 1000);
      this.responded = true;
      await axios.post(
        this.endpoint,
        { rejectInviteId: this.inviteId },
        axiosConfig,
      );
    },
    async checkForInvite() {
      const res = await axios.post(
        this.endpoint,
        {},
        axiosConfig,
      );

      if (res?.data.inviteId && res.data.projectName?.length) {
        this.projectName = res?.data.projectName;
        this.inviteId = res?.data.inviteId;
        this.visible = true;
      }
    },
  },
};
</script>
