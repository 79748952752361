<template>
  <div>
    <h3 class="heading heading--underline">Recommended Jobs</h3>
    <p>
      There is over <strong class="text-black">{{ totalPayments }}</strong> waiting for you on these jobs that don't have a submission yet.
    </p>
    <div class="frame frame--padding-tight frame--border">
      <JobListItem
        v-for="(brief, index) in briefs"
        :key="index"
        :brief-id="brief.briefId"
        :brief-link="brief.briefLink"
        :design-type-image-url="brief.designTypeImageUrl"
        :design-type="brief.designType"
        :brief-name="brief.briefName"
        :submission-count="brief.submissionCount"
        :days-left="brief.daysLeft"
        :total-payment="brief.totalPayment"
        :add-to-watchlist-api="addToWatchlistApi"
        :remove-from-watchlist-api="removeFromWatchlistApi"
        :submission-count-icon-url="submissionCountIconUrl"
        :time-left-icon-url="timeLeftIconUrl"
        :project-budget-icon-url="projectBudgetIconUrl"
        :watchlist-hollow-icon-url="watchlistHollowIconUrl"
        :watchlist-filled-icon-url="watchlistFilledIconUrl"
      />
    </div>
    <div class="text-center">
      <a class="button hollow secondary mtm" href="/jobs">More jobs</a>
    </div>
  </div>
</template>

<script>
import JobListItem from '../JobListItem/JobListItem.vue';

export default {
  components: {
    JobListItem,
  },
  props: {
    totalPayments: {
      type: String,
      required: true,
    },
    briefs: {
      type: Array,
      required: true,
    },
    addToWatchlistApi: {
      type: String,
      required: true,
    },
    removeFromWatchlistApi: {
      type: String,
      required: true,
    },
    submissionCountIconUrl: {
      type: String,
      required: true,
    },
    timeLeftIconUrl: {
      type: String,
      required: true,
    },
    projectBudgetIconUrl: {
      type: String,
      required: true,
    },
    watchlistHollowIconUrl: {
      type: String,
      required: true,
    },
    watchlistFilledIconUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
