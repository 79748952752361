<template>
  <div class="column">
    <div class="mrs mbm flex-container">
      <span class="text-left medium-6">
        You are watching {{ count }} projects.
      </span>
      <div class="text-right medium-6">
        <span class="mls">
          <a :disabled="!enabled" @click="removeAllCompleted">
            Remove All Completed
          </a>
          <span> |</span>
          <a :disabled="!enabled" @click="clearFilters">
            Clear Filters
          </a>
        </span>
      </div>
    </div>
    <div class="flex-container align-middle mbm">
      <label for="filters">Filters:</label>
      
      <select v-model="status" class="mbn mls mrs">
        <option
          v-for="(option, index) in briefOptions"
          :key="index"
          :value="option.value"
          :disabled="!enabled"
          class="mls mrs">
          {{ option.label }}
        </option>
      </select>
      
      <select v-model="daysLeft" class="mbn mls">
        <option
          v-for="(option, index) in dayOptions"
          :key="index"
          :value="option.value"
          :disabled="!enabled"
          class="mls mrs">
          {{ option.label }}
        </option>
      </select>
      
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      status: null,
      daysLeft: null,
      briefOptions: [
        {
          label: `Filter brief status`,
          value: null,
        },
        {
          label: `Open`,
          isSelected: false,
          value: 'Open',
        },
        {
          label: `Deciding`,
          value: 'Deciding',
        },
        {
          label: `Closed`,
          value: 'Closed',
        },
      ],
      dayOptions: [
        {
          label: `Filter by day(s) left`,
          value: null,
        },
        {
          label: `Less than 1 day`,
          value: '1',
        },
        {
          label: `Less than 3 days`,
          value: '3',
        },
        {
          label: `Less than 7 days`,
          value: '7',
        },
        {
          label: `Less than 10 days`,
          value: '10',
        },
        {
          label: `Less than 15 days`,
          value: '15',
        },
      ],
    };
  },
  watch: {
    async status(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update();
      }
    },
    async daysLeft(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update();
      }
    },
  },
  methods: {
    async update() {
      if (!this.enabled) {
        return;
      }
      this.$emit('filter-update', {
        status: this.status,
        daysLeft: this.daysLeft,
      });
    },
    removeAllCompleted() {
      this.$emit('remove-all-completed');
    },
    clearFilters() {
      this.status = null;
      this.daysLeft = null;
      this.update();
    },
  },
};
</script>
