<template>
  <div class="mbm">
    <div class="row medium-12 mrs align-right mbm">
      <span v-for="(filter, index) in filters" :key="index" class="mls">
        <a :disabled="!enabled" @click="changeAlertType(filter.api)">
          {{ filter.name }}
        </a>
        <span v-if="index != filters.length - 1"> |</span>
      </span>
    </div>
    <div class="row medium-12 mrs align-right">
      <label v-if="!isSelectedStatus" class="mrs">
        <input
          :checked="isSelectedStatus"
          type="checkbox"
          class="input-type--checkbox"
          @click="onClickSelectAll"
        />
        <span>Select All</span> |
      </label>
      <label v-else class="mrs">
        <input
          :checked="isSelectedStatus"
          type="checkbox"
          class="input-type--checkbox"
          @click="onClickDeselectAll"
        />
        <span>Deselect All </span> |
      </label>
      <span class="mls mrs">
        <a :disabled="!enabled" @click="update(true)">Delete</a> |
      </span>
      <label class="mls">
        <input
          v-model="urgentOnly"
          type="checkbox"
          class="input-type--checkbox"
          :disabled="!enabled"
          @change="changeUrgentOnly($event)"
        />
        <span>Show urgent messages only</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    selectedStatus: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSelectedStatus: false,
      urgentOnly: false,
      alertType: null,
    };
  },
  watch: {
    selectedStatus(status) {
      this.isSelectedStatus = status;
    },
    async urgentOnly(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update(this.enabled);
      }
    },
    async alertType(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.update(this.enabled);
      }
    },
  },
  methods: {
    async changeAlertType(alertType) {
      if (this.enabled) {
        this.alertType = alertType;
      }
    },
    async changeUrgentOnly($event) {
      if (this.enabled) {
        this.urgentOnly = $event.target.checked;
      } else {
        this.urgentOnly = !$event.target.checked;
      }
    },
    async update(deleteSelected) {
      if (!this.enabled) {
        return;
      }
      this.$emit('filter-update', {
        urgentOnly: this.urgentOnly,
        alertType: this.alertType,
        delete: deleteSelected,
      });
    },
    onClickSelectAll() {
      this.$emit('select-all');
    },
    onClickDeselectAll() {
      this.$emit('unselect-all');
    },
  },
};
</script>
