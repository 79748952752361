<template>
  <div>
    <input
      :id="id"
      class="hide"
      type="checkbox"
      name="subscription1"
      :checked="isChecked"
      @change="onChecked"
    />
    <div
      class="card-select card-select--padding-tight card-select--checkbox mbm"
    >
      <a @click="onClickRemoveDesigner">
        <i class="card-select__delete-button"></i>
        <span class="show-for-sr">Remove designer</span>
      </a>
      <label :for="id"></label>
      <div class="user-profile user-profile--small">
        <div class="row collapse align-middle">
          <div class="column shrink">
            <div class="user-profile__image">
              <div class="responsive-embed square">
                <img
                  :src="profilePicThumbnailUrl"
                  alt="Profile picture"
                  class="responsive-embed__image"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="row">
              <div class="column small-12">
                <div class="u-truncate-ellipsis">
                  <span>
                    <strong class="text-black">{{ displayName }}</strong>
                  </span>
                </div>
              </div>
              <div class="column small-12">
                <div class="u-truncate-ellipsis">
                  <span class="user-profile__small-text">{{ country }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-select__stats">
        <div>Ranked #{{ rank }}</div>
        <div>{{ feedbackPercentage }}% positive</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profilePicThumbnailUrl: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    rank: {
      type: Number,
      required: true,
    },
    feedbackPercentage: {
      type: Number,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return `designer-${this.displayName}`;
    },
  },
  methods: {
    onChecked(e) {
      this.$emit('input', e.target.checked);
    },
    onClickRemoveDesigner() {
      this.$emit('remove-designer');
    },
  },
};
</script>
