<template>
  <div>
    <NotificationsFilter
      :filters="filters"
      :selected-status="allSelected"
      :enabled="!loading"
      @filter-update="onFilterUpdate"
      @select-all="onFilterSelectAll"
      @unselect-all="unSelectAll"
    />
    <ManageAccountEmptyCard v-if="nothingToShow" :message="emptyCardMessage" />
    <div class="maker-spinner-container">
      <div v-if="loading" class="maker-spinner">
        <span class="maker-spinner__icon" />
      </div>
      <table v-if="somethingToShow" class="table table--striped">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>From</th>
            <th>Message</th>
            <th class="show-for-medium">Received</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in notifications" :key="index">
            <td class="prs">
              <label class="mrs">
                <input
                  v-model="checked[index]"
                  type="checkbox"
                  class="input-type--checkbox"
                  @click="onSelectCheckbox(item.id, index)"
                />
                <span> &nbsp; </span>
              </label>
            </td>
            <td class="prs">{{ item.type }}</td>
            <td class="prs">{{ item.from }}</td>
            <td class="prs">
              <a v-if="item.actionLink" :href="item.actionLink" data-test-message-subject>{{
                item.message
              }}</a>
              <span v-else>{{ item.message }}</span>
            </td>
            <td class="prs show-for-medium">{{ item.received }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NotificationsFilter from './NotificationsFilter.vue';
import ManageAccountEmptyCard from '../../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    NotificationsFilter,
    ManageAccountEmptyCard,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    apiBaseUrl: {
      type: String,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      selected: [], // where alert ids are stored
      allSelected: false,
      checked: [],
      loading: this.isLoading,
      axiosConfig,
      emptyCardMessage: null,
    };
  },
  computed: {
    nothingToShow() {
      return this.notifications.length === 0;
    },
    somethingToShow() {
      return this.notifications.length > 0;
    },
  },
  watch: {
    isLoading(loading) {
      this.loading = loading;
    },
  },
  methods: {
    onFilterSelectAll() {
      this.allSelected = true;
      this.notifications.forEach((alert, index) => {
        if (!this.selected.includes(alert.id)) {
          this.selected.push(alert.id);
        }
        this.checked[index] = true;
      });
    },
    unSelectAll() {
      this.allSelected = false;
      this.selected = [];
      this.checked = [];
    },
    onSelectCheckbox(value, index) {
      if (this.selected.length !== this.notifications.length) {
        this.allSelected = false;
      } else {
        this.allSelected = true;
      }
      if (!this.selected.includes(value)) {
        this.selected.push(value);
        this.checked[index] = true;
      } else {
        this.selected.splice(this.selected.indexOf(value), 1);
        this.checked[index] = false;
      }
    },
    async onFilterUpdate(filterState) {
      if (this.apiBaseUrl && !this.loading) {
        this.loading = true;
        const apiPostObject = {
          page: 1,
          alertType: filterState.alertType,
          urgentOnly: filterState.urgentOnly,
          delete: { alertIds: filterState.delete ? this.selected : [] },
        };
        const res = await axios.post(
          this.apiBaseUrl,
          apiPostObject,
          axiosConfig,
        );

        this.loading = false;

        if (res?.data) {
          this.unSelectAll();
          if (!res?.data.items.length) {
            this.emptyCardMessage = 'No items matched your filter.';
          } else {
            this.emptyCardMessage = null;
          }
          this.$emit('table-update', { resData: res.data, apiPostObject });
        }
      }
    },
  },
};
</script>
