import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const submitInvitesAsync = async (briefId, invites) => {
  const response = await axios.post(
    `/manage-project/api/invite-wizard/${briefId}`,
    invites,
    axiosConfig,
  );

  if (response && response.status === 200) {
    return true;
  }

  return null;
};

/* eslint-disable-next-line  import/prefer-default-export */
export default submitInvitesAsync;
