<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <div class="row">
        <div v-if="currentPage.length === 0" class="column small-12">
          <ManageAccountEmptyCard
            :icon-url="icons.emptyMessages"
            icon-alt="No messages"
            title="No messages"
          >
            <p>No messages have been received yet.</p>
          </ManageAccountEmptyCard>
        </div>
        <div v-else class="column small-12">
          <table class="table table--striped">
            <thead>
              <tr>
                <th>From</th>
                <th class="show-for-medium">Project</th>
                <th>Subject</th>
                <th>Received</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(message, index) in currentPage" :key="index">
                <td class="prs">{{ message.fromDesignerName }}</td>
                <td class="phs show-for-medium">{{ message.projectName }}</td>
                <td class="phs">
                  <a :href="message.messageLinkUrl" @click="onClickMessageLink">
                    {{ message.subject }}
                  </a>
                </td>
                <td class="pls" :title="message.dateReceivedTooltip">{{ message.dateReceived }}</td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="mtm"
            :max-page="pagination.maxPage"
            :current="pagination.current"
            :start="pagination.start"
            :end="pagination.end"
            :start-of-page="pagination.startOfPage"
            :number-of-results="pagination.numberOfResults"
            :total-results-count="pagination.totalResultsCount"
            :description="pagination.description"
            :base-url="pagination.baseUrl"
            :api-base-url="pagination.apiBaseUrl"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            @page-link-click="onPaginationClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import ManageAccountEmptyCard from '../../components/my-account/ManageAccountEmptyCard/ManageAccountEmptyCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';
import LOCAL_STORAGE_MESSAGES_PAGE_NO from '../../constants/constants';

export default {
  components: {
    ManageAccountMenu,
    ManageAccountEmptyCard,
    Pagination,
  },
  props: {
    icons: {
      type: Object,
      default: undefined,
    },
    menu: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.messages,
      pagination: this.paginationObject,
    };
  },
  methods: {
    onClickMessageLink() {
      localStorage.setItem(
        LOCAL_STORAGE_MESSAGES_PAGE_NO,
        this.pagination.current,
      );
    },
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
  },
};
</script>
