<template>
  <div class="row">
    <div class="column small-6 large-3 text-center">
      <img
        :class="{
          'my-project-card__progress-step': !!activeStepUrls.step1,
          'my-project-card__progress-step--disabled': !activeStepUrls.step1,
        }"
        :src="projectProgressStep1IconUrl"
        alt="Project details"
      />
    </div>
    <div class="column small-6 large-3 text-center">
      <img
        :class="{
          'my-project-card__progress-step': !!activeStepUrls.step2,
          'my-project-card__progress-step--disabled': !activeStepUrls.step2,
        }"
        :src="projectProgressStep2IconUrl"
        alt="Creative brief"
      />
    </div>
    <div class="column small-6 large-3 text-center">
      <img
        :class="{
          'my-project-card__progress-step': !!activeStepUrls.step3,
          'my-project-card__progress-step--disabled': !activeStepUrls.step3,
        }"
        :src="projectProgressStep3IconUrl"
        alt="Design options"
      />
    </div>
    <div class="column small-6 large-3 text-center">
      <img
        :class="{
          'my-project-card__progress-step': !!activeStepUrls.step4,
          'my-project-card__progress-step--disabled': !activeStepUrls.step4,
        }"
        :src="projectProgressStep4IconUrl"
        alt="Payment options"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    projectProgressStep1IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep2IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep3IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep4IconUrl: {
      type: String,
      default: '',
    },
    activeStepUrls: {
      type: Object,
      default: undefined,
    },
  },
};
</script>
