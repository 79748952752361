<template>
  <div
    :class="{
      ['shortlist shortlist--white']: true,
      ['is-open']: isVisible,
    }"
  >
    <div class="shortlist__content pbn">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
