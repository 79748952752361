<template>
  <div class="feedback-form row">
    <div class="column small-12 large-4 text-center">
      <h3 class="heading heading--underline text-left mbl">
        Design #{{ designId }}
      </h3>
      <div class="tile tile--thumbnail tile--no-hover mbs">
        <img :src="displayImage" alt="Design Preview" />
      </div>
    </div>
    <div class="column small-12 large-8">
      <div v-for="(item, feedbackIndex) in feedback" :key="feedbackIndex">
        <h3 class="heading heading--underline mbl" v-if="feedbackIndex === 0">
          Feedback
        </h3>
        <h3 class="heading heading--underline mbl mtl" v-if="feedbackIndex === 1">
          Older Feedback
        </h3>
        <div class="frame frame--padding-tight">
          <div class="row">
            <div class="column small-12 medium-12">
              <div class="row">
                <div class="column small-12 medium-11 large-10">
                  <p class="mbs">
                    <strong class="text-black text-large">Ratings</strong>
                  </p>
                  <div class="row align-justify">
                    <div class="column small-4">
                      <span class="text-medium">Quality</span>
                    </div>
                    <div class="row small-8">
                      <div v-for="(n, index) in 5" :key="index">
                        <img
                          v-if="n <= item.quality"
                          :src="icons.fullStarIcon"
                          alt=""
                        />
                        <img v-else :src="icons.emptyStarIcon" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="row align-justify">
                    <div class="column small-4">
                      <span class="text-medium">Creativity</span>
                    </div>
                    <div class="row small-8">
                      <div v-for="(n, index) in 5" :key="index">
                        <img
                          v-if="n <= item.creativity"
                          :src="icons.fullStarIcon"
                          alt=""
                        />
                        <img v-else :src="icons.emptyStarIcon" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="row align-justify">
                    <div class="column small-4">
                      <span class="text-medium">Fonts</span>
                    </div>
                    <div class="row small-8">
                      <div v-for="(n, index) in 5" :key="index">
                        <img
                          v-if="n <= item.fonts"
                          :src="icons.fullStarIcon"
                          alt=""
                        />
                        <img v-else :src="icons.emptyStarIcon" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="row align-justify">
                    <div class="column small-4">
                      <span class="text-medium">Colors</span>
                    </div>
                    <div class="row small-8">
                      <div v-for="(n, index) in 5" :key="index">
                        <img
                          v-if="n <= item.colors"
                          :src="icons.fullStarIcon"
                          alt=""
                        />
                        <img v-else :src="icons.emptyStarIcon" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column small-12 medium-11 large-10 mtm">
                  <p class="mbs">
                    <strong class="text-black text-large"
                      >General Comments</strong
                    >
                    <br />
                    <span v-html="item.comments"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="horizontal-rule horizontal-rule--form-frame-tight"
            v-if="feedbackIndex === 0"
          >
            <hr class="hr hr--alt" />
          </div>
          <div class="form-stacking" v-if="feedbackIndex === 0">
            <div class="row align-center">
              <div class="column small-12 medium-shrink">
                <a :href="urls.sendMessageUrl" class="button primary">
                  Send this customer a message</a
                >
              </div>
              <div class="column small-12 medium-shrink">
                <a
                  :href="urls.submitLinkedDesignUrl"
                  class="button secondary hollow mbn"
                >
                  Submit a revised design
                </a>
              </div>
            </div>
          </div>
          <div
            class="horizontal-rule horizontal-rule--form-frame-tight"
            v-if="feedbackIndex > 0 && feedbackIndex < feedback.length"
          >
            <hr class="hr hr--alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    feedback: {
      type: Array,
      default: () => [],
    },
    icons: {
      type: Object,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      designId: this.feedback[0].designId,
      displayImage: this.feedback[0].thumbnailUrl,
    };
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
