<template>
  <div>
    <ManageAccountEmptyCard v-if="nothingToShow" :message="emptyCardMessage" />
    <div class="maker-spinner-container">
      <div v-if="loading" class="maker-spinner">
        <span class="maker-spinner__icon" />
      </div>
      <table v-if="somethingToShow" class="table table--striped">
        <thead>
          <tr>
            <th>Brief</th>
            <th>From</th>
            <th>Subject</th>
            <th>Received</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in messages"
            :key="index"
            @click="readMessage(item)"
          >
            <td class="prs">
              {{ item.briefId }}
            </td>
            <td class="prs">
              {{ item.from }}
            </td>
            <td class="prs">
              <a>{{ item.subject }}</a>
            </td>
            <td class="prs">{{ item.received }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ManageAccountEmptyCard from '../../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

export default {
  components: {
    ManageAccountEmptyCard,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      emptyCardMessage: null,
      loading: this.isLoading,
    };
  },
  computed: {
    nothingToShow() {
      return this.messages.length === 0;
    },
    somethingToShow() {
      return this.messages.length > 0;
    },
  },
  watch: {
    isLoading(loading) {
      this.loading = loading;
    },
  },
  methods: {
    readMessage(item) {
      window.location.href = item.messageUrl;
    },
  },
};
</script>
