<template>
  <div>
    <ul v-if="menu.items && menu.items.length" class="user-account-menu">
      <li v-for="(item, key) in menu.items" :key="key">
        <a
          :href="item.url"
          @click="onClickItem"
          :data-test-nav="item.dataTestNav"
        >
          <div
            :class="{
              'user-account-menu__item': true,
              'is-selected': item.isSelected,
            }"
          >
            <img
              class="user-account-menu__item-image"
              :src="item.isSelected ? item.iconSelected : item.icon"
              :alt="item.iconAlt"
            />
            {{ item.name }}
            <span
              v-if="item.count !== undefined"
              class="user-account-menu__item-count"
            >
              {{ item.count }}
            </span>
          </div>
        </a>
        <ul v-if="item.submenu">
          <li v-for="(subItem, key2) in item.submenu" :key="key2">
            <a
              :href="subItem.url"
              @click="onClickItem"
              :data-test-nav="item.dataTestNav"
            >
              <div
                :class="{
                  'user-account-menu__item': true,
                  'is-selected': subItem.isSelected,
                }"
              >
                {{ subItem.name }}
                <span class="user-account-menu__item-count">
                  {{ subItem.count }}
                </span>
              </div>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <Modal
      v-if="menu.referAFriend"
      title="Refer & Get up to $100 off your next project"
      class-name="modal--share-preview-image"
      :close-ico="menu.referAFriend.closeIconUrl"
      :close-on-click-outside="false"
      :is-visible="menu.referAFriend.autoOpen"
    >
      <template #open>
        <button class="button small full-width mbl">
          Refer & get up to $100 off
        </button>
      </template>
      <template #body>
        <form
          :ref="referAFriendFormRef"
          :action="menu.referAFriend.referFriendsEndpoint"
          method="POST"
        >
          <div class="row">
            <div class="column small-12 text-center">
              <h6>Invite your friends via email</h6>
            </div>
            <div class="column small-12">
              <strong class="text-black">Your full name</strong>
            </div>
            <div class="column small-12">
              <input
                v-model="userFullName"
                type="text"
                name="name"
                disabled="disabled"
              />
            </div>
            <div class="column small-12">
              <strong class="text-black">
                Your friend's email address
              </strong>
            </div>
            <div class="column small-12">
              <textarea
                v-model="friendsEmailAddresses"
                class="mbn"
                name="emails"
                placeholder="Email addresses separated by a comma"
              ></textarea>
              <p v-if="errorMessage" class="text-color-red text-small mts">
                {{ errorMessage }}
              </p>
            </div>
            <div class="column small-12 text-center">
              <button class="button mvm" @click="onClickSendInvites">
                Invite friends
              </button>
            </div>
            <div class="column small-12 text-center">
              <strong class="text-black">
                More ways to invite your friends
              </strong>
            </div>
            <div class="column small-12">
              <div class="input-group input-group--medium">
                <input
                  class="input-group-field"
                  type="text"
                  :value="menu.referAFriend.referralLink"
                  readonly="readonly"
                />
                <div class="input-group-button pln">
                  <button
                    :ref="copyInvitelinkButtonRef"
                    class="button secondary"
                    :data-clipboard-text="menu.referAFriend.referralLink"
                    @click="onClickCopyInviteLinkButton"
                  >
                    {{ copyInviteLinkButtonText }}
                  </button>
                </div>
              </div>
            </div>
            <div class="column small-12">
              <div class="row align-center">
                <div class="column shrink">
                  <a :href="menu.referAFriend.shareByTwitterLink">
                    <img
                      :src="menu.referAFriend.twitterIconUrl"
                      alt="share via twitter"
                    />
                  </a>
                </div>
                <div class="column shrink">
                  <a :href="menu.referAFriend.shareByFacebookLink">
                    <img
                      :src="menu.referAFriend.facebookIconUrl"
                      alt="share via facebook"
                    />
                  </a>
                </div>
                <div class="column shrink">
                  <a :href="menu.referAFriend.shareByLinkedInLink">
                    <img
                      :src="menu.referAFriend.linkedInIconUrl"
                      alt="share via linkedin"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import Modal from '../../shared/Modal/Modal.vue';

export default {
  components: {
    Modal,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userFullName: this.menu?.referAFriend?.name,
      friendsEmailAddresses: '',
      copyInvitelinkButtonRef: 'js-copy-share-link-button',
      copyInviteLinkButtonText: 'Copy',
      referAFriendFormRef: 'js-refer-a-friend-modal',
      errorMessage: '',
    };
  },
  watch: {
    page(page) {
      this.currentPage = page;
    },
  },
  mounted() {
    if (this.menu.referAFriend) {
      // eslint-disable-next-line no-unused-vars
      const clipboard = new Clipboard(this.$refs[this.copyInvitelinkButtonRef]);
    }
  },
  methods: {
    onClickItem(name) {
      this.currentPage = name;
      this.$emit('on-click-menu-item', name);
    },
    validateEmails() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      const emailArray = this.friendsEmailAddresses.split(',');
      return emailArray.every((email) => {
        const testEmail = email.replace(/\s/g, '');
        return emailRegex.test(testEmail);
      });
    },
    onClickSendInvites(e) {
      e.preventDefault();
      this.errorMessage = '';

      if (!this.friendsEmailAddresses.trim()) {
        this.errorMessage =
          'Please enter at least one valid email address';
      } else if (!this.validateEmails()) {
        this.errorMessage =
          'One or more of the emails you entered is not valid';
      } else {
        this.$refs[this.referAFriendFormRef].submit();
      }
    },
    onClickCopyInviteLinkButton(e) {
      e.preventDefault();
      this.copyInviteLinkButtonText = 'Copied';
      setTimeout(() => {
        this.copyInviteLinkButtonText = 'Copy';
      }, 1000);
    },
  },
};
</script>
