<!-- eslint-disable prettier/prettier -->
<template>
    <fragment name="notifications-page-container">
        <div class="row">
            <div class="column small-12 medium-3">
              <profile-strength
                :percentage="models.profileStrength.percentage"
                class="mbm"
              />
              <manage-account-menu :menu="models.menu" />
            </div>
            <div class="column small-12 medium-9">
                <NotificationsTable
                  :notifications="currentPage"
                  :filters="filters"
                  :api-base-url="pagination.apiBaseUrl"
                  :is-loading="isLoading"
                  @table-update="onTableUpdate"
                />
                <Pagination
                  class="mtm"
                  :max-page="pagination.maxPage"
                  :current="pagination.current"
                  :start="pagination.start"
                  :end="pagination.end"
                  :start-of-page="pagination.startOfPage"
                  :number-of-results="pagination.numberOfResults"
                  :total-results-count="pagination.totalResultsCount"
                  :description="pagination.description"
                  :base-url="pagination.baseUrl"
                  :api-base-url="pagination.apiBaseUrl"
                  :left-arrow-icon-url="icons.paginationLeftArrow"
                  :right-arrow-icon-url="icons.paginationRightArrow"
                  :api-post-object="apiPostObject"
                  @page-link-click="onPaginationClick"
                  @is-loading="onIsLoading"
                />
            </div>
        </div>
    </fragment>
  </template>

<script>
import NotificationsTable from '../../components/my-account/designer/notifications/NotificationsTable/NotificationsTable.vue';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

const filters = [
  {
    name: 'All',
    api: null,
  },
  {
    name: 'Messages',
    api: 'Message',
  },
  {
    name: 'Project Alerts',
    api: 'ProjectAlert',
  },
  {
    name: 'Project Events',
    api: 'ProjectEvent',
  },
  {
    name: 'Feedback',
    api: 'Feedback',
  },
  {
    name: 'Invites',
    api: 'Invite',
  },
  {
    name: 'Payments',
    api: 'Payment',
  },
];

export default {
  components: {
    NotificationsTable,
    ManageAccountMenu,
    ProfileStrength,
    Pagination,
  },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      default: undefined,
    },
    flags: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      filters,
      apiPostObject: {
        urgentOnly: false,
        alertType: null,
      },
      currentPage: this.models.alerts,
      pagination: this.models.pagination,
      isLoading: false,
    };
  },
  methods: {
    onPaginationClick(e) {
      this.isLoading = true;
      this.currentPage = e.items;
      this.pagination = e.pagination;
      this.isLoading = false;
    },
    onTableUpdate(e) {
      this.isLoading = true;
      this.apiPostObject = e.apiPostObject;
      this.currentPage = e.resData.items;
      this.pagination = e.resData.pagination;
      this.isLoading = false;
    },
    onIsLoading(loading) {
      this.isLoading = loading;
    },
  },
};
</script>
