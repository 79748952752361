<template>
  <div>
    <div class="text-right">
      <button
        :disabled="isLoading"
        class="button primary small"
        @click="showForm"
      >
        <span v-if="!addEnabled">Add a payout method</span>
        <span v-else>Cancel</span>
      </button>
    </div>
    <table class="table table--striped">
      <thead v-if="addEnabled || payoutMethods.length">
        <tr>
          <th :colspan="2">Accounts</th>
          <th>Payouts under $100</th>
          <th>Payouts over $100</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="addEnabled">
          <td class="prs">
            <p for="account" class="tiny mbn">Select account:</p>
            <select v-model="newAccMethod" :disabled="isLoading">
              <option
                v-for="(option, index) in accountOptions"
                :key="index"
                :value="option.value"
                class="mls mrs"
              >
                {{ option.label }}
              </option>
            </select>
          </td>
          <td class="prs">
            <p for="email" class="tiny mbn">
              Email attached to your account:
            </p>
            <input
              v-model="newUsername"
              :disabled="isLoading"
              type="email"
              style="width: 90%"
              placeholder="What's your email address?"
            />
          </td>
          <td class="prs" style="vertical-align: top">
            <div class="inline-tooltip">
              <a class="mts">More Info</a>
              <div
                role="tooltip"
                class="tooltip tooltip__payout bottom align-center"
              >
                <p>
                  Select the account you want all payouts under $100 to be paid into. All currencies will be treated the same - i.e. under 100 units of that currency.
                </p>
              </div>
            </div>
            <a
              :disabled="isLoading"
              class="button tiny mbn mts"
              :class="setBelow ? 'primary' : 'secondary'"
              style="width: 90%"
              @click="toggleBelowThreshold"
            >
              {{ setBelow ? 'Selected' : 'Select' }}
            </a>
          </td>
          <td class="prs" style="vertical-align: top">
            <div class="inline-tooltip">
              <a class="mts">More Info</a>
              <div
                role="tooltip"
                class="tooltip tooltip__payout bottom align-center"
              >
                <p>
                  Select the account you want all payouts over $100 to be paid into. All currencies will be treated the same - i.e. over 100 units of that currency.
                </p>
              </div>
            </div>
            <a
              :disabled="isLoading"
              class="button tiny mts"
              :class="setAbove ? 'primary' : 'secondary'"
              style="width: 90%"
              @click="toggleAboveThreshold"
              >{{ setAbove ? 'Selected' : 'Select' }}
            </a>
          </td>
          <td class="prs">
            <a
              :disabled="isLoading"
              class="button primary tiny mbs"
              style="width: 90%"
              @click="addPayout"
            >
              Add
            </a>
          </td>
        </tr>
        <tr v-if="addEnabled && errors">
          <td colspan="5">
            <template v-if="hasUsernameError">
              <ul
                v-for="(message, index) in errors.NewUsername"
                :key="index"
                style="color: red"
              >
                <li>{{ message }}</li>
              </ul>
            </template>
            <template v-if="hasAccError">
              <ul
                v-for="(message, index) in errors.NewAccMethod"
                :key="index"
                style="color: red"
              >
                <li>{{ message }}</li>
              </ul>
            </template>
          </td>
        </tr>
        <tr v-for="(item, index) in payoutMethods" :key="index">
          <td class="prs">
            <img
              style="height: 26px;"
              class="mrs"
              :src="getIcon(item.serviceCompany)"
              alt="Service Icon"
            />
            {{ !getIcon(item.serviceCompany) ? item.serviceCompany : null }}
          </td>
          <td class="prs">
            {{ item.username }}
          </td>
          <td class="prs">
            <a
              v-if="(item.selectable || item.belowThreshold)"
              :disabled="isLoading"
              style="width: 90%"
              class="button tiny mbn"
              :class="item.belowThreshold ? 'primary' : 'secondary'"
              @click="setBelowThreshold(item)"
            >
              {{ item.belowThreshold ? 'Selected' : 'Select' }}
            </a>
          </td>
          <td class="prs">
            <a
              v-if="(item.selectable || item.aboveThreshold)"
              :disabled="isLoading"
              style="width: 90%"
              class="button primary tiny mbn"
              :class="item.aboveThreshold ? 'primary' : 'secondary'"
              @click="setAboveThreshold(item)"
            >
              {{ item.aboveThreshold ? 'Selected' : 'Select' }}
            </a>
          </td>
          <td class="prs">
            <img
              v-if="!item.belowThreshold && !item.aboveThreshold"
              :class="{
                'opacity-1/2': isLoading,
              }"
              :src="deleteIcon"
              alt="Delete Icon"
              @click="deletePayment(item)"
            />
          </td>
        </tr>
        <tr v-if="!addEnabled && !payoutMethods.length">
          <td colspan="5">
            <ManageAccountEmptyCard
              title="No items"
              :message="emptyCardMessage"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import ManageAccountEmptyCard from '../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const accountOptions = [
  {
    label: 'Payoneer',
    value: 'Payoneer',
  },
  {
    label: 'PayPal',
    value: 'PayPal',
  },
];

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: { ManageAccountEmptyCard },
  props: {
    payoutMethods: {
      type: Array,
      required: true,
    },
    deleteIcon: {
      type: String,
      required: true,
    },
    skrillIcon: {
      type: String,
      required: true,
    },
    paypalIcon: {
      type: String,
      required: true,
    },
    payoneerIcon: {
      type: String,
      required: true,
    },
    apiBaseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      accountOptions,
      isLoading: false,
      addEnabled: false,
      emptyCardMessage: null,
      newUsername: null,
      newAccMethod: null,
      setBelow: false,
      setAbove: false,
      errors: null,
      hasIcon: true,
    };
  },
  computed: {
    nothingToShow() {
      return this.payoutMethods.length === 0;
    },
    somethingToShow() {
      return this.payoutMethods.length > 0;
    },
    hasUsernameError() {
      return this.errors?.NewUsername?.length;
    },
    hasAccError() {
      return this.errors?.NewAccMethod?.length;
    },
  },
  methods: {
    getIcon(service) {
      let newIcon = '';
      if (service === 'Payoneer') {
        newIcon = this.payoneerIcon;
      } else if (service === 'PayPal') {
        newIcon = this.paypalIcon;
      } else if (service === 'Skrill' || service === 'Moneybookers') {
        newIcon = this.skrillIcon;
      } else {
        newIcon = '';
      }
      return newIcon;
    },
    showForm() {
      if (this.isLoading) return;
      this.addEnabled = !this.addEnabled;
      this.setAbove = false;
      this.setBelow = false;
      this.newAccMethod = null;
      this.newUsername = null;
    },

    toggleAboveThreshold() {
      if (this.isLoading) return;
      this.setAbove = !this.setAbove;
    },
    toggleBelowThreshold() {
      if (this.isLoading) return;
      this.setBelow = !this.setBelow;
    },

    async deletePayment(item) {
      if (this.isLoading) return;
      await this.callApi({ softDeleteId: item.id });
    },
    async setAboveThreshold(item) {
      if (this.isLoading) return;
      if (!item.aboveThreshold && item.selectable)
        await this.callApi({ aboveThresholdId: item.id });
    },
    async setBelowThreshold(item) {
      if (this.isLoading) return;
      if (!item.belowThreshold && item.selectable)
        await this.callApi({ belowThresholdId: item.id });
    },
    async addPayout() {
      if (this.isLoading) return;
      await this.callApi({
        add: true,
        newUsername: this.newUsername,
        newAccMethod: this.newAccMethod,
        setAbove: this.setAbove,
        setBelow: this.setBelow,
      });
    },
    async callApi(apiPostObject) {
      if (this.isLoading) return;
      if (this.apiBaseUrl) {
        this.isLoading = true;
        this.errors = null;
        const res = await axios.post(
          this.apiBaseUrl,
          {
            ...apiPostObject,
            page: 1,
          },
          axiosConfig,
        );
        this.isLoading = false;
        if (res?.data) {
          this.errors = res.data?.errors;
          if (!this.errors) {
            this.addEnabled = false;
          }
          if (!res.data?.items?.length) {
            this.emptyCardMessage = 'No items matched your filter.';
          } else {
            this.emptyCardMessage = null;
            this.$emit('table-update', { resData: res.data });
          }
        }
      }
    },
  },
};
</script>
