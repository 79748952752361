<template>
  <form action="" method="post" @submit.prevent>
    <div class="row">
      <div class="column small-12">
        <label class="mbm">
          <strong class="text-black">Email</strong>
          <br />
          <span>{{ email }}</span>
          <input type="hidden" name="Email" :value="email" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">First name</strong>
          <input
            v-model="firstNameValue"
            class="mts"
            type="text"
            name="FirstName"
            :disabled="this_processing"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Last name</strong>
          <input
            v-model="lastNameValue"
            class="mts"
            type="text"
            name="LastName"
            :disabled="this_processing"
          />
        </label>
      </div>
    </div>
    <div class="row">
      <div v-if="!isChangePasswordFormVisible" class="column small-12">
        <strong class="text-black">Password</strong>
        <br />
        <button
          class="button secondary tiny hollow mts"
          @click="showChangePasswordForm"
          :disabled="this_processing"
        >
          {{
            passwordSystemGenerated
              ? 'Set Password'
              : 'Change Password'
          }}
        </button>
      </div>
      <div v-if="isChangePasswordFormVisible" class="column small-12">
        <div class="row small-up-1 medium-up-2 large-up-3">
          <div class="column" v-if="!passwordSystemGenerated">
            <label>
              <strong class="text-black">Current password</strong>
              <input
                v-model="password"
                class="input-type input-type--password mts"
                type="password"
                name="currentPassword"
                placeholder="Current password"
                :disabled="this_processing"
              />
            </label>
          </div>
          <div class="column">
            <label>
              <strong class="text-black">New password</strong>
              <input
                v-model="newPassword"
                class="input-type input-type--password mts"
                type="password"
                name="newPassword"
                placeholder="New password"
                :disabled="this_processing"
              />
            </label>
          </div>
          <div class="column">
            <label>
              <strong class="text-black">Confirm new password</strong>
              <input
                v-model="confirmPassword"
                class="input-type input-type--password mts"
                type="password"
                name="confirmPassword"
                placeholder="Confirm new password"
                :disabled="this_processing"
              />
            </label>
          </div>
        </div>
        <div class="row align-center">
          <div class="column shrink">
            <span v-if="showPasswordWarning" class="text-color-red text-small">
              Your new password and confirmation password do not match
            </span>
          </div>
        </div>
        <div class="form-stacking mbm">
          <div class="row align-center">
            <div class="column small-12 medium-shrink">
              <button
                class="button secondary hollow form-stacking__item"
                @click="hideChangePasswordForm"
                :disabled="this_processing"
              >
                Cancel
              </button>
            </div>
            <div class="column small-12 medium-shrink">
              <button
                class="button form-stacking__item"
                @click="onSubmitUpdatePasswordForm"
                :disabled="this_processing"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Phone</strong>
          <input
            v-model="phoneValue"
            class="mts"
            type="text"
            name="Phone"
            :disabled="this_processing"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Company name</strong>
          <input
            v-model="companyNameValue"
            class="mts"
            type="text"
            name="CompanyName"
            :disabled="this_processing"
          />
        </label>
      </div>
    </div>
    <div class="row column">
      <label>
        <strong class="text-black">Address</strong>
        <textarea
          v-model="addressValue"
          class="full-width mts"
          name="Address"
          rows="3"
          :disabled="this_processing"
        >
        </textarea>
      </label>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">City</strong>
          <input
            v-model="cityValue"
            class="mts"
            type="text"
            name="City"
            :disabled="this_processing"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">State</strong>
          <input
            v-model="stateValue"
            class="mts"
            type="text"
            name="State"
            :disabled="this_processing"
          />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Postcode</strong>
          <input
            v-model="postcodeValue"
            class="mts"
            type="text"
            name="Postcode"
            :disabled="this_processing"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Country</strong>
          <select
            v-model="countryValue"
            class="mts"
            name="Country"
            :disabled="this_processing"
          >
            <option
              v-for="(countryName, index) in countries"
              :key="index"
              :value="countryName"
            >
              {{ countryName }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column text-center">
        <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
        <button
          v-if="!this_processing"
          class="button mbn"
          @click="onSubmitUpdateDetailsForm"
        >
          Update details
        </button>
        <button v-else class="button mbn button-processing">
          Processing...
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    address: {
      type: String,
      default: undefined,
    },
    city: {
      type: String,
      default: undefined,
    },
    companyName: {
      type: String,
      default: undefined,
    },
    country: {
      type: String,
      default: undefined,
    },
    email: {
      type: String,
      default: undefined,
    },
    firstName: {
      type: String,
      default: undefined,
    },
    lastName: {
      type: String,
      default: undefined,
    },
    phone: {
      type: String,
      default: undefined,
    },
    postcode: {
      type: String,
      default: undefined,
    },
    state: {
      type: String,
      default: undefined,
    },
    passwordSystemGenerated: {
      type: Boolean,
      default: undefined,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    resetChangePasswordForm: {
      type: Boolean,
      default: true,
    },
    processing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      this_processing: this.processing,
      showPasswordWarning: false,
      isChangePasswordFormVisible: false,
      firstNameValue: this.firstName,
      lastNameValue: this.lastName,
      password: '',
      newPassword: '',
      confirmPassword: '',
      phoneValue: this.phone,
      companyNameValue: this.companyName,
      addressValue: this.address,
      cityValue: this.city,
      stateValue: this.state,
      postcodeValue: this.postcode,
      countryValue: this.country,
    };
  },
  watch: {
    resetChangePasswordForm(resetChangePasswordForm) {
      if (resetChangePasswordForm) {
        this.password = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.isChangePasswordFormVisible = false;
        this.showPasswordWarning = false;
      }
    },
    processing(newValue) {
      this.this_processing = newValue;
    },
  },
  methods: {
    showChangePasswordForm() {
      this.isChangePasswordFormVisible = true;
    },
    hideChangePasswordForm() {
      this.isChangePasswordFormVisible = false;
    },
    onSubmitUpdatePasswordForm(e) {
      e.preventDefault();

      if (this.newPassword !== this.confirmPassword) {
        this.showPasswordWarning = true;
        return;
      }

      this.$emit('update-password', {
        password: this.password,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      });
    },
    onSubmitUpdateDetailsForm(e) {
      e.preventDefault();
      this.$emit('update-details', {
        email: this.email,
        firstName: this.firstNameValue,
        lastName: this.lastNameValue,
        phone: this.phoneValue,
        companyName: this.companyNameValue,
        address: this.addressValue,
        city: this.cityValue,
        state: this.stateValue,
        postcode: this.postcodeValue,
        country: this.countryValue,
      });
    },
  },
};
</script>
