<template>
  <div>
    <ModalV2
      :title="title"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="visible"
      :hide-close-button="true"
    >
      <template #body>
        <div>
          <p class="text-medium">
            Why are you cancelling your subscription?
          </p>
          <label
            v-for="(reason, index) in cancelSubscriptionFlow.cancelReasons"
            :key="index"
            class="text-medium mbs"
          >
            <input
              v-model="selectedReason"
              :disabled="processing"
              type="radio"
              name="reason"
              :value="reason.value"
            />
            {{ reason.name }}
          </label>
          <textarea
            v-model="comments"
            :disabled="processing"
            class="full-width text-medium"
            rows="3"
          ></textarea>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div class="column shrink">
              <button
                class="mbn button tiny secondary hollow"
                :disabled="!selectedReason || processing"
                @click="cancel"
              >
                Continue Cancelling
              </button>
            </div>
            <div class="column shrink">
              <button
                :disabled="processing"
                type="button"
                class="button tiny mbn"
                @click="close"
              >
                Keep Premium
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
    <ModalV2
      :title="title"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="isCancelledVisible"
      :hide-close-button="true"
    >
      <template #body>
        <div>
          <div class="text-center text-medium">
            <img alt="An image" :src="icons.commonLargeCancel" />
            <p
              v-html="unPrettierNuggets.subscriptionCancelled(subscription)"
            ></p>
            <p v-html="unPrettierNuggets.reactivateCopy"></p>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div class="column shrink">
              <button
                :disabled="processing"
                class="mbs button tiny secondary hollow prm plm"
                @click="reload"
              >
                No thanks, maybe later
              </button>
            </div>
            <div class="column shrink">
              <button
                :disabled="processing"
                type="button"
                class="button tiny prm plm mbs"
                @click="unCancelSubscription"
              >
                Reactivate Now
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
    <ModalV2
      :title="title"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="isUncancelledVisible"
      :hide-close-button="true"
    >
      <template #body>
        <div>
          <div class="text-center text-medium">
            <img alt="An image" :src="icons.commonLargeAnnualPremium" />
            <p
              v-html="unPrettierNuggets.subscriptionUnCancelled(subscription)"
            ></p>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div class="column shrink">
              <button
                class="mbs button tiny secondary hollow prm plm"
                type="button"
                @click="reload"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
  </div>
</template>

<script>
import axios from 'axios';
import ModalV2 from '../../../../shared/Modal/ModalV2.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    ModalV2,
  },
  props: {
    cancelSubscriptionFlow: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isVisible: this.visible,
      isCancelledVisible: false,
      isUncancelledVisible: false,
      isReasonChecked: false,
      comments: '',
      selectedReason: null,
      state: 'cancel',
      processing: false,
      unPrettierNuggets: {
        subscriptionCancelled: (subscription) => {
          return `Your <strong>${subscription.subscriptionFor}</strong> subscription has been cancelled. You will be able to access your subscription benefits until the end of your current subscription period, expiring on <strong>${this.subscription.renewalDateCopy}</strong>.`;
        },
        subscriptionUnCancelled: (subscription) => {
          return `Your <strong>${subscription.subscriptionFor}</strong> subscription has been reactivated. ${this.subscription.autoRenewalCopy}`;
        },
        reactivateCopy: `If you didn't mean to cancel, or would like to re-subscribe, you can quickly and easily do so by clicking below.`,
      },
      reloadUrl: null,
    };
  },
  computed: {
    title() {
      if (this.state === 'cancel') {
        return `We're sorry to see you go!`;
      }

      if (this.state === 'cancelled') {
        return `Cancellation confirmed`;
      }

      if (this.state === 'uncancelled') {
        return `Reactivated!`;
      }

      return '';
    },
  },
  methods: {
    async cancel(e) {
      e.preventDefault();

      if (!this.selectedReason) return;

      this.processing = true;

      const data = {
        subscriptionId: this.subscription.subscriptionId,
        reason: this.selectedReason,
        comments: this.comments,
      };
      const res = await axios
        .post(
          this.cancelSubscriptionFlow.cancelSubscriptionApi,
          data,
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });
      if (res.status === 200) {
        this.state = 'cancelled';
        this.close();
        this.isCancelledVisible = true;
        this.processing = false;
        this.reloadUrl = res.data.redirectUrl;
      }
    },
    async unCancelSubscription() {
      this.processing = true;

      const res = await axios
        .post(
          this.cancelSubscriptionFlow.unCancelSubscriptionApi,
          {
            subscriptionId: this.subscription.subscriptionId,
          },
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        this.state = 'uncancelled';
        this.close();
        this.isCancelledVisible = false;
        this.isUncancelledVisible = true;
        this.processing = false;
        this.reloadUrl = res.data.redirectUrl;
      }
    },
    async reload() {
      this.processing = true;
      window.location.href = this.reloadUrl;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
