<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <div class="frame frame--padding-small ptn">
        <div class="row column">
          <ul class="menu menu--tabs menu--tabs-white">
            <li
              v-for="(tab, index) in Object.keys(TABS)"
              :key="index"
              :class="{
                'menu__active-tab': isTabVisible(TABS[tab]),
              }"
              @click="openTab(TABS[tab])"
            >
              <a class="text-uppercase">{{ TABS[tab] }}</a>
            </li>
          </ul>
          <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
        </div>
        <CustomizeWhitelabelForm
          v-if="isTabVisible(TABS.CUSTOMIZE_WHITELABEL)"
          :question-icon-url="icons.question"
          :whitelabel-text="whitelabelText"
          :whitelabel-image-url="whitelabelImageUrl"
          :save-details-api="saveDetailsApi"
          :save-details-preview-pdf-api="saveDetailsPreviewPdfApi"
          :save-details-preview-client-portal-api="
            saveDetailsPreviewClientPortalApi
          "
        />
        <ClientPortalIntegrationForm
          v-if="isTabVisible(TABS.CLIENT_PORTAL_INTEGRATION)"
          :briefs="briefs"
          :get-iframe-code-api="getIframeCodeApi"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import CustomizeWhitelabelForm from '../../components/my-account/customer/whitelabel/CustomizeWhitelabelForm.vue';
import ClientPortalIntegrationForm from '../../components/my-account/customer/whitelabel/ClientPortalIntegrationForm.vue';

export default {
  components: {
    ManageAccountMenu,
    CustomizeWhitelabelForm,
    ClientPortalIntegrationForm,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      default: () => {},
    },
    briefs: {
      type: Array,
      default: () => [],
    },
    whitelabelText: {
      type: String,
      default: undefined,
    },
    whitelabelImageUrl: {
      type: String,
      default: undefined,
    },
    getIframeCodeApi: {
      type: String,
      default: undefined,
    },
    saveDetailsApi: {
      type: String,
      default: undefined,
    },
    saveDetailsPreviewPdfApi: {
      type: String,
      default: undefined,
    },
    saveDetailsPreviewClientPortalApi: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      visibleTab: null,
    };
  },
  created() {
    this.TABS = {
      CUSTOMIZE_WHITELABEL: 'Customize Whitelabel',
      CLIENT_PORTAL_INTEGRATION: 'Client Portal Integration',
    };
  },
  mounted() {
    this.visibleTab = this.TABS.CUSTOMIZE_WHITELABEL;
  },
  methods: {
    openTab(tab) {
      this.visibleTab = tab;
    },
    isTabVisible(tab) {
      return this.visibleTab === tab;
    },
  },
};
</script>
