<template>
  <fragment name="design-page-filters">
    <div class="row-300 align-justify align-middle">
      <div class="column shrink"><h5 class="mbn">Filters</h5></div>
      <div class="column shrink">
        <button
          class="button clear secondary pan mbn"
          @click="onClickResetFilters"
        >
          Reset filters
        </button>
      </div>
    </div>
    <div
      class="form-stacking form-stacking--medium form-stacking--responsive mbl"
    >
      <div class="form-stacking__item">
        <DropCheckbox
          id="star-rating-filter"
          value-key="rating"
          is-selected-key="isSelected"
          :options="ratingsFilterOptions"
          :label="ratingDropdownLabel"
          @input="ratingSelected"
        >
          <!--
            The slot in DropCheckbox is passing through an instance of each
            item in ratingsFilterOptions so that we can acces it here in the
            parent.

            We use v-slot:[slot-name-here] to acces it and here object destructuring
            is also used to streamline access to the "option" variable
          -->
          <template #default="{ option }">
            <fragment v-if="option.rating === 0" name="star-rating">
              <span :ref="`rating${option.rating}`">Not rated</span>
            </fragment>
            <span
              v-if="option.rating > 0"
              :ref="`rating${option.rating}`"
              class="star-rating"
            >
              <img
                v-for="(rating, index) in option.rating"
                :key="`starFilled${index}`"
                :src="starFilledIconUrl"
                alt="star filled"
              />
              <img
                v-for="(rating, index) in maxRating - option.rating"
                :key="`starHollow${index}`"
                :src="starHollowIconUrl"
                alt="star hollow"
              />
            </span>
          </template>
        </DropCheckbox>
      </div>
      <div class="form-stacking__item">
        <DropCheckbox
          id="designer-filter"
          value-key="name"
          is-selected-key="isSelected"
          :options="designerList"
          :label="designersDropdownLabel"
          @input="designerSelected"
        />
      </div>
      <div class="form-stacking__item">
        <Select
          id="feedback-filter"
          value-key="value"
          label-key="label"
          is-selected-key="isSelected"
          :options="feedbackFilterOptions"
          @input="feedbackFilterSelected"
        />
      </div>
      <div class="form-stacking__item">
        <Select
          id="eliminated-designs"
          value-key="value"
          label-key="label"
          is-selected-key="isSelected"
          :options="eliminatedFilterOptions"
          @input="eliminatedFilterSelected"
        />
      </div>
      <div class="form-stacking__item">
        <Select
          id="order-filter"
          value-key="value"
          label-key="label"
          is-selected-key="isSelected"
          :options="orderFilterOptions"
          @input="orderFilterSelected"
        />
      </div>
      <div class="form-stacking__item" :style="{ 'max-width': '120px' }">
        <div class="button-toggle button-toggle--secondary full-height mbn">
          <input
            id="grid-layout"
            type="radio"
            name="toggle-view"
            :checked="gridView"
            @input="onClickGridView"
          />
          <label class="full-height" for="grid-layout">
            <img
              height="30"
              width="30"
              :class="{ 'opacity-1/2': !gridView }"
              :src="gridView ? gridWhiteIconUrl : gridIconUrl"
              alt="Grid view icon"
            />
          </label>
          <input
            id="list-layout"
            type="radio"
            name="toggle-view"
            :checked="listView"
            @input="onClickListView"
          />
          <label class="button-toggle__float full-height" for="list-layout">
            <img
              height="30"
              width="30"
              :class="{ 'opacity-1/2': !listView }"
              :src="listView ? listWhiteIconUrl : listIconUrl"
              alt="List view icon"
            />
          </label>
      </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Select from '../../../shared/Select/Select.vue';
import DropCheckbox from '../../../shared/DropCheckbox/DropCheckbox.vue';

export default {
  components: {
    Select,
    DropCheckbox,
  },
  props: {
    designRatingKey: {
      type: String,
      required: true,
    },
    designerNameKey: {
      type: String,
      required: true,
    },
    designFeedbackCountKey: {
      type: String,
      required: true,
    },
    starFilledIconUrl: {
      type: String,
      default: undefined,
    },
    starHollowIconUrl: {
      type: String,
      default: undefined,
    },
    gridIconUrl: {
      type: String,
      default: undefined,
    },
    gridWhiteIconUrl: {
      type: String,
      default: undefined,
    },
    listIconUrl: {
      type: String,
      default: undefined,
    },
    listWhiteIconUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      maxRating: 5,
      ratingsFilterOptions: [],
      designerList: [],
      resetAllFilters: false,
      gridView: true,
      listView: false,
    };
  },
  computed: {
    ...mapState('designs', [
      'designs',
      'filteredDesigns',
      'showFilters',
      'feedbackFilter',
      'orderFilter',
      'eliminatedFilter',
    ]),
    designersDropdownLabel() {
      if (this.designerList.filter((e) => e.isSelected).length > 0)
        return this.designerList
          .filter((e) => e.isSelected)
          .map((x) => x.name)
          .join(', ');
      return `All designers`;
    },
    ratingDropdownLabel() {
      if (this.ratingsFilterOptions.filter((e) => e.isSelected).length > 0)
        return this.ratingsFilterOptions
          .filter((e) => e.isSelected)
          .map((x) => x.label)
          .join(', ');
      return `All star ratings`;
    },
    feedbackGivenCount() {
      const feedbackGivenDesigns = this.designs.filter(
        (design) => design[this.designFeedbackCountKey] > 0,
      );
      return feedbackGivenDesigns.length;
    },
    feedbackNotGivenCount() {
      const feedbackNotGivenDesigns = this.designs.filter(
        (design) => design[this.designFeedbackCountKey] === 0,
      );
      return feedbackNotGivenDesigns.length;
    },
    feedbackFilterOptions() {
      return [
        {
          value: null,
          label: 'All feedback',
          isSelected: this.feedbackFilter === null,
        },
        {
          value: 1,
          label: `Given`,
          isSelected: this.feedbackFilter === 1,
        },
        {
          value: 0,
          label: `Not Given`,
          isSelected: this.feedbackFilter === 0,
        },
      ];
    },
    orderFilterOptions() {
      return [
        {
          value: 'designer-name-asc',
          label: 'Designer name (alphabetical A-Z)',
          isSelected: false,
        },
        {
          value: 'rating-desc',
          label: `Rating (highest to lowest)`,
          isSelected: false,
        },
        {
          value: 'date-desc',
          label: `Date (newest first)`,
          isSelected: true,
        },
      ];
    },
    eliminatedFilterOptions() {
      return [
        {
          value: 'show-all',
          label: 'Show Eliminated Designs',
          isSelected: this.eliminatedFilter === 'show-all',
        },
        {
          value: 'only-eliminated',
          label: `Show Only Eliminated Designs`,
          isSelected: this.eliminatedFilter === 'only-eliminated',
        },
        {
          value: 'hide-eliminated',
          label: `Hide Eliminated Designs`,
          isSelected:
            this.eliminatedFilter === 'hide-eliminated' ||
            this.eliminatedFilter === null,
        },
      ];
    },
  },
  watch: {
    designs(designs) {
      this.initialiseDesignerList(designs);
    },
  },
  created() {
    this.initialiseFilters();
  },
  methods: {
    ...mapActions('designs', ['resetFilters']),
    initialiseFilters() {
      this.intialiseRatingsFilter();
      this.initialiseDesignerList(this.designs);
    },
    initialiseDesignerList(designs) {
      const designers = [];
      designs.forEach((design) => {
        if (designers.indexOf(design[this.designerNameKey]) === -1) {
          designers.push(design[this.designerNameKey]);
        }
      });

      this.designerList = designers
        .sort((a, b) => a.localeCompare(b))
        .map((designer) => ({
          name: designer,
          isSelected: false,
        }));
    },
    intialiseRatingsFilter() {
      this.ratingsFilterOptions = [
        {
          rating: 0,
          isSelected: false,
          label: `Not rated`,
        },
        {
          rating: 1,
          isSelected: false,
          label: `1 star`,
        },
        {
          rating: 2,
          isSelected: false,
          label: `2 star`,
        },
        {
          rating: 3,
          isSelected: false,
          label: `3 star`,
        },
        {
          rating: 4,
          isSelected: false,
          label: `4 star`,
        },
        {
          rating: 5,
          isSelected: false,
          label: `5 star`,
        },
      ];
    },
    ratingSelected({ selected, option }) {
      this.$emit('rating-selected', { selected, option });

      if (option) {
        const index = this.ratingsFilterOptions.indexOf(option);
        const newRatingOption = {
          ...this.ratingsFilterOptions[index],
          isSelected: selected,
        };
        this.$set(this.ratingsFilterOptions, index, newRatingOption);
      }
    },
    designerSelected({ selected, option }) {
      this.designerList.filter(
        (e) => e.name === option.name,
      )[0].isSelected = selected;
      this.$emit('designer-selected', { selected, option });
    },
    feedbackFilterSelected({ option }) {
      this.$emit('feedback-filter-option-selected', option);
    },
    orderFilterSelected({ option }) {
      this.$emit('order-filter-option-selected', option);
    },
    eliminatedFilterSelected({ option }) {
      this.$emit('eliminated-filter-option-selected', option);
    },
    onClickResetFilters() {
      this.resetFilters();
      this.resetAllFilters = true;
      this.initialiseFilters();
      this.$emit('reset-filters');
    },
    onClickGridView() {
      this.gridView = true;
      this.listView = false;
      this.$emit('grid-view-selected');
    },
    onClickListView() {
      this.gridView = false;
      this.listView = true;
      this.$emit('list-view-selected');
    },
  },
};
</script>
