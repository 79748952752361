<template>
  <div>
    <WatchlistFilter
      :count="count"
      :enabled="!isLoading"
      @filter-update="onFilterUpdate"
      @remove-all-completed="removeAllCompleted"
    />
    <ManageAccountEmptyCard v-if="nothingToShow " :message="emptyCardMessage" />
    <div class="maker-spinner-container">
      <div v-if="loading" class="maker-spinner">
        <span class="maker-spinner__icon" />
      </div>
      <table v-if="somethingToShow" class="table table--striped">
        <thead>
          <tr>
            <th>Brief Name</th>
            <th>Type</th>
            <th>Deadline</th>
            <th class="hide-for-small">Sub</th>
            <th class="hide-for-small">Status</th>
            <th class="hide-for-small">Link</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in watchlist" :key="index">
            <td class="prs">
              <a :href="item.link">{{ item.briefName }}</a>
            </td>
            <td class="prs">{{ item.type }}</td>
            <td class="prs">{{ item.deadline }}</td>
            <td class="prs">{{ item.sub }}</td>
            <td class="prs">{{ item.status }}</td>
            <td class="prs">
              <a v-if="item.status === 'Closed'" :href="item.link">
                View Results
              </a>
              <a v-else :href="item.link">View Brief</a>
            </td>
            <td class="prs">
              <img
                :src="deleteIcon"
                alt="Delete Icon"
                @click="deleteBrief(item.briefId)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import WatchlistFilter from './WatchlistFilter.vue';
import ManageAccountEmptyCard from '../../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    WatchlistFilter,
    ManageAccountEmptyCard,
  },
  props: {
    watchlist: {
      type: Array,
      required: true,
    },
    deleteIcon: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    apiBaseUrl: {
      type: String,
      required: true,
    },
    apiPostObject: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      axiosConfig,
      emptyCardMessage: null,
      loading: this.isLoading,
    };
  },
  computed: {
    nothingToShow() {
      return this.watchlist.length === 0;
    },
    somethingToShow() {
      return this.watchlist.length > 0;
    },
  },
  watch: {
    isLoading(loading) {
      this.loading = loading;
    },
  },
  methods: {
    async removeAllCompleted() {
      await this.onFilterUpdate({
        ...this.apiPostObject,
        ...{ delete: { allCompleted: true } },
      });
    },
    async deleteBrief(briefId) {
      await this.onFilterUpdate({
        ...this.apiPostObject,
        ...{ delete: { briefIds: [briefId] } },
      });
    },
    async onFilterUpdate(transientFilterState) {
      if (this.apiBaseUrl && !this.loading) {
        this.loading = true;

        const apiPostObject = { ...transientFilterState, ...{ page: 1 } };

        const res = await axios.post(
          this.apiBaseUrl,
          apiPostObject,
          axiosConfig,
        );

        this.loading = false;

        if (res?.data) {
          if (!res?.data.items.length) {
            this.emptyCardMessage = 'No items matched your filter.';
          } else {
            this.emptyCardMessage = null;
          }
          this.$emit('table-update', { resData: res.data, apiPostObject });
        }
      }
    },
  },
};
</script>
