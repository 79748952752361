<template>
  <div class="drop-search" v-click-outside="hideResults">
    <div class="input-type--search">
      <input
        v-model="searchTerm"
        type="search"
        @focus="onFocus"
      />
      <button>Search</button>
      <div
        class="background-white z-index-1"
        :class="{
          'drop-search__results': true,
          hide: !showResults,
        }"
      >
        <span
          v-for="(result, index) in results"
          :key="index"
          @click="onClickResult(result)"
        >
          {{ result }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
const searchTermPlaceholder = 'Select a design type...';

export default {
  props: {
    dataSet: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTerm: '',
      results: [],
      showResults: false,
    };
  },
  watch: {
    searchTerm(term) {
      if (term !== searchTermPlaceholder && !!term) {
        this.results = this.dataSet.filter(
          (data) => data.toLowerCase().indexOf(term.toLowerCase()) > -1,
        );

        if (this.results.length > 0) {
          this.showResults = true;
        }
      }
    },
  },
  mounted() {
    this.initialiseSearch();
  },
  methods: {
    initialiseSearch() {
      this.searchTerm = searchTermPlaceholder;
      this.results = this.dataSet;
    },
    onFocus() {
      this.searchTerm = '';
      this.showResults = true;
    },
    hideResults() {
      this.initialiseSearch();
      this.showResults = false;
    },
    onClickResult(result) {
      this.$emit('input', result);
    },
  },
};
</script>
