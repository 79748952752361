var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',{attrs:{"name":"profile-page-container"}},[_c('div',{staticClass:"row"},[(_vm.alerts && _vm.alerts.length > 0)?_c('div',{staticClass:"column small-12"},_vm._l((_vm.alerts),function(alert,index){return _c('Callout',{key:index,attrs:{"type":alert.type,"title":alert.title,"icon-url":_vm.icons[`${alert.type}Icon`],"closable":""},on:{"close":function($event){_vm.alerts = []}},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(_vm._s(alert.message))]},proxy:true}],null,true)})}),1):_vm._e()]),_c('div',{staticClass:"row account-settings"},[_c('div',{staticClass:"column small-12 medium-3"},[_c('profile-strength',{staticClass:"mbm",attrs:{"percentage":_vm.models.profileStrength.percentage}}),_c('ManageAccountMenu',{attrs:{"menu":_vm.models.menu}})],1),_c('div',{staticClass:"column small-12 large-9"},[_c('div',{staticClass:"frame frame--padding-small ptn"},[_c('div',{staticClass:"row column"},[_c('ul',{staticClass:"menu menu--tabs menu--tabs-white u-block full-width"},_vm._l((Object.keys(_vm.TABS)),function(tab,index){return _c('li',{key:index,class:{
                'menu__active-tab': _vm.isTabVisible(_vm.TABS[tab]),
              },on:{"click":function($event){return _vm.openTab(_vm.TABS[tab])}}},[_c('a',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.TABS[tab]))])])}),0),_c('hr',{staticClass:"horizontal-rule horizontal-rule--form-frame-small mbm"})]),_c('AccountDetailsForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabVisible(_vm.TABS.ACCOUNT_DETAILS)),expression:"isTabVisible(TABS.ACCOUNT_DETAILS)"}],attrs:{"address":_vm.models.accountDetails.address,"city":_vm.models.accountDetails.city,"company-name":_vm.models.accountDetails.companyName,"country":_vm.models.accountDetails.country,"email":_vm.models.accountDetails.email,"portfolio-url":_vm.models.accountDetails.portfolioUrl,"deactivate-url":_vm.models.accountDetails.deactivateUrl,"status":_vm.models.accountDetails.status,"first-name":_vm.models.accountDetails.firstName,"last-name":_vm.models.accountDetails.lastName,"phone":_vm.models.accountDetails.phone,"postcode":_vm.models.accountDetails.postcode,"state":_vm.models.accountDetails.state,"countries":_vm.models.countries,"reset-change-password-form":_vm.resetChangePasswordForm,"update-password-endpoint":_vm.models.accountDetails.updatePasswordEndpoint,"update-details-endpoint":_vm.models.accountDetails.updateDetailsEndpoint,"resend-verification-email-endpoint":_vm.models.accountDetails.resendVerificationEmailEndpoint},on:{"update-password":function($event){return _vm.updatePassword(
              $event.password,
              $event.newPassword,
              $event.confirmPassword,
            )},"show-api-success-message":_vm.pushSuccessAlert,"show-api-error-messages":_vm.pushErrorAlerts}}),_c('DesignerSkillSelect',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabVisible(_vm.TABS.CREATIVE_SKILLS)),expression:"isTabVisible(TABS.CREATIVE_SKILLS)"}],attrs:{"categories-to-display":_vm.categoriesToDisplay,"categories":_vm.models.skillSelector.all,"button-loading-img-url":_vm.icons.processing,"description":"Choose the types of design jobs you are most interested / skilled in&hellip;","submitting-prop":_vm.designerSkillsSubmitting},on:{"submit":_vm.updateSkills}}),_c('PublicProfileForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabVisible(_vm.TABS.PUBLIC_PROFILE)),expression:"isTabVisible(TABS.PUBLIC_PROFILE)"}],attrs:{"public-profile":_vm.models.publicProfile.data,"profile-image-uploader":_vm.models.publicProfile.profileImage,"save-endpoint":_vm.models.publicProfile.saveEndpoint,"icons":_vm.icons},on:{"show-api-success-message":_vm.pushSuccessAlert,"show-api-error-messages":_vm.pushErrorAlerts}}),_c('EmailSubscription',{directives:[{name:"show",rawName:"v-show",value:(_vm.isTabVisible(_vm.TABS.EMAIL_SUBSCRIPTION)),expression:"isTabVisible(TABS.EMAIL_SUBSCRIPTION)"}],attrs:{"jobs":_vm.models.emailSubscriptions.jobs,"emails":_vm.models.emailSubscriptions.emails,"invite-alerts":_vm.models.emailSubscriptions.inviteAlerts,"email-schedules":_vm.models.emailSubscriptions.emailSchedules,"watchlist-reminder":_vm.models.emailSubscriptions.watchlistReminder,"community-digest-enabled":_vm.models.emailSubscriptions.communityDigest,"newsletter-enabled":_vm.models.emailSubscriptions.newsletter,"show-jobs-section":_vm.models.emailSubscriptions.showJobsSection,"update-endpoint":_vm.models.emailSubscriptions.updateEndpoint},on:{"show-api-success-message":_vm.pushSuccessAlert}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }