var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice notice--flex"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isClosed),expression:"!isClosed"}],staticClass:"notice__overlay",on:{"click":_vm.closeNoticeTooltip}}),_c('div',{ref:_vm.noticeTooltipRef,class:{
      ['notice__tooltip']: true,
      ['notice__tooltip--hollow']: true,
      ['notice__tooltip--float-arrow']: _vm.floatArrow,
      [`notice__tooltip--arrow-${_vm.arrowSize}`]: _vm.arrowSize,
      [`notice__tooltip--${_vm.size}`]: _vm.size && !_vm.isSmall,
      [`align-${_vm.alignment}`]: true,
      [_vm.color]: true,
      [_vm.direction]: true,
      ['hide']: _vm.isClosed,
      ['eliminate-feedback']: _vm.eliminate,
      ['feedback-modal']: _vm.feedback,
    },on:{"click":_vm.onClickNoticeToolTip}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},[_vm._t("tooltip")],2)])]),_vm._t("target")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }