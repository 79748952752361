function MediaQueryPlugin() {
  this.query = null;

  (function __constructor(self) {
    // eslint-disable-next-line no-param-reassign
    self.query =
      (window && window.Foundation && window.Foundation.MediaQuery) || null;
    if (self.query !== null) {
      // eslint-disable-next-line no-underscore-dangle
      self.query._init();
    }
  })(this);
}

MediaQueryPlugin.prototype.isSmall = function isSmall() {
  return this.query !== null && this.query.is('small only');
};

MediaQueryPlugin.prototype.isMedium = function isMedium() {
  return this.query !== null && this.query.is('medium only');
};

MediaQueryPlugin.prototype.isAtMostMedium = function isAtMostMedium() {
  return (
    this.query !== null &&
    (this.query.is('small only') || this.query.is('medium only'))
  );
};

MediaQueryPlugin.prototype.isLarge = function isLarge() {
  return this.query !== null && this.query.is('large only');
};

MediaQueryPlugin.prototype.isAtMostLarge = function isAtMostMedium() {
  return (
    this.query !== null &&
    (this.query.is('small only') ||
      this.query.is('medium only') ||
      this.query.is('large only'))
  );
};

const mediaQuery = new MediaQueryPlugin();

export default mediaQuery;
