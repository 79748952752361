<template>
  <form method="post" class="account-settings" @submit.prevent>
    <h3>Email Options</h3>
    <div class="column small-12 large-12 mbm">
      <label>
        <strong class="text-black">Allow Emails</strong>
      </label>
      <div class="column">
        <label v-for="(email, index) in emails" :key="index" class="mrl">
          <input
            v-model="emailEnabled"
            :value="email.value"
            name="emailEnabled"
            type="radio"
            class="input-type--radio"
            :disabled="form.disabled"
          />
          <span class="text-medium">
            {{ email.label }}
          </span>
        </label>
      </div>
    </div>
    <div class="column small-12 medium-12 mbm">
      <label>
        <strong class="text-black">
          When do you want us to send you job emails:
        </strong>
      </label>
      <div class="column small-12 medium-12 mbm">
        <label
          v-for="(schedule, index) in emailSchedules"
          :key="index"
          class="mrl"
        >
          <input
            v-model="emailSchedulesEnabled"
            type="checkbox"
            class="input-type--checkbox"
            :value="schedule.value"
            :checked="schedule.selected"
            :disabled="form.disabled"
          />
          <span class="text-medium" v-html="schedule.label"> </span>
        </label>
      </div>
    </div>
    <div v-if="showJobsSection" class="column small-12 medium-12 mbm">
      <label>
        <strong class="text-black">
          What job types are you interested in
        </strong>
        (only filters instant emails)
      </label>
      <div class="grid-3-columns column">
        <label v-for="(job, index) in jobs" :key="index" class="mrl">
          <input
            v-model="jobInterest"
            type="checkbox"
            class="input-type--checkbox"
            :value="job.value"
            :checked="job.selected"
            :disabled="form.disabled"
          />
          <span class="text-medium">
            {{ job.label }}
          </span>
        </label>
      </div>
    </div>
    <div class="column small-12 large-12">
      <label>
        <strong class="text-black">Watchlist Reminder</strong>
      </label>
      <div class="column small-12 medium-12 mbm">
        <label
          v-for="(watchlist, index) in watchlistReminder"
          :key="index"
          class="mrl"
        >
          <input
            v-model="watchlistReminderEnabled"
            type="checkbox"
            class="input-type--checkbox"
            :value="watchlist.value"
            :checked="watchlist.selected"
            :disabled="form.disabled"
          />
          <span class="text-medium">
            {{ watchlist.label }}
          </span>
        </label>
      </div>
    </div>
    <div class="column small-12 large-12 mbm">
      <div class="column">
        <label>
          <input
            v-model="newsletter"
            type="checkbox"
            class="input-type--checkbox"
            :disabled="form.disabled"
          />
          <span class="text-medium">
            Subscribe to DesignCrowd monthly newsletter
          </span>
        </label>
      </div>
    </div>
    <div class="column small-12 large-12 mbm">
      <label class="mbm">
        <strong class="text-black">Invite Alerts</strong>
        Onsite Popup Settings
      </label>
      <div class="column">
        <select
          v-model="inviteNotificationEnabled"
          name="inviteNotificationEnabled"
          class="mbn"
        >
          <option
            v-for="(alert, index) in inviteAlerts"
            :key="index"
            :value="alert.value"
            :selected="alert.selected"
          >
            {{ alert.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="column text-center">
        <button class="button mbn" :disabled="form.disabled" @click="save">
          Save Settings
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
export default {
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    emails: {
      type: Array,
      default: () => [],
    },
    inviteAlerts: {
      type: Array,
      default: () => [],
    },
    emailSchedules: {
      type: Array,
      default: () => [],
    },
    watchlistReminder: {
      type: Array,
      default: () => [],
    },
    updateEndpoint: {
      type: String,
      required: true,
    },
    newsletterEnabled: {
      type: Object,
      required: true,
    },
    showJobsSection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const errorDefault = {
      jobInterest: [],
      watchlistReminderEnabled: [],
      emailSchedulesEnabled: [],
    };
    const original = {
      emailSelected: this.emails.filter((x) => x.selected).map((x) => x.value),
      emailSchedulesSelected: this.emailSchedules
        .filter((x) => x.selected)
        .map((x) => x.value),
      jobsSelected: this.jobs.filter((x) => x.selected).map((x) => x.value),
      watchlistReminderSelected: this.watchlistReminder
        .filter((x) => x.selected)
        .map((x) => x.value),
      inviteNotificationSelected: this.inviteAlerts
        .filter((x) => x.selected)
        .map((x) => x.value),
    };
    return {
      newsletter: this.newsletterEnabled.selected,
      emailSchedulesEnabled: original.emailSchedulesSelected,
      jobInterest: original.jobsSelected,
      watchlistReminderEnabled: original.watchlistReminderSelected,
      emailEnabled: original.emailSelected[0],
      inviteNotificationEnabled: original.inviteNotificationSelected
        ? original.inviteNotificationSelected[0]
        : 'EnabledBoth',
      form: {
        disabled: false,
        errorDefault,
        errors: errorDefault,
      },
    };
  },
  methods: {
    async save() {
      this.form.disabled = true;

      const data = {
        emailEnabled: this.emailEnabled === 'Enabled',
        emailSchedulesEnabled: this.emailSchedulesEnabled,
        jobInterest: this.jobInterest,
        watchlistReminderEnabled: this.watchlistReminderEnabled,
        newsletterEnabled: this.newsletter,
        inviteNotificationEnabled: this.inviteNotificationEnabled,
      };

      const res = await axios.post(this.updateEndpoint, data, axiosConfig);
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };

      if (res.data.errors) {
        this.$emit('show-api-success-message', null);
      } else {
        this.$emit('show-api-success-message', res.data.successMessage);
      }
      this.form.disabled = false;
    },
  },
};
</script>
