<template>
  <div class="frame frame--border frame--padding-small">
    <div class="row align-justify">
      <div class="column shrink"><h6 class="mbs">Profile Strength</h6></div>
      <div class="column shrink">
        <span class="text-small">{{ percentage }}% complete</span>
      </div>
    </div>
    <div
      role="progressbar"
      tabindex="0"
      :aria-valuenow="percentage"
      aria-valuemin="0"
      :aria-valuetext="percentageLabel"
      aria-valuemax="100"
      class="progress progress--small mvs"
    >
      <div class="progress-meter" :style="{ width: percentageValue }" />
    </div>
    <p class="mts" v-if="percentage < 100" v-html="message"></p>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    completeProfileUrl: {
      type: String,
      default: '/manage-profile/profile-completeness',
    },
  },
  computed: {
    percentageLabel() {
      return `${this.percentage} percent`;
    },
    percentageValue() {
      return `${this.percentage}%`;
    },
    message() {
      return `<a href="${this.completeProfileUrl}">Click here</a> to complete your profile`;
    }
  },
};
</script>
