<template>
  <div class="row">
    <div class="column small-12 large-3 text-center">
      <img
        class="my-project-card__design-preview"
        :src="designThumbnailUrl"
        alt="Logo design preview"
      />
    </div>
    <div class="column small-12 large-9">
      <div class="my-project-card__complete">
        <span v-if="printUpsellUrl" class="large-order-2">
          <a
            :href="printUpsellUrl"
            class="button secondary tiny tiny-expanded mbn"
          >
            {{ printUpsellButtonCopy }}
          </a>
        </span>
        <div class="big-numbers">
          <div class="row text-center large-text-left">
            <div class="column small-6 large-12">
              <div class="my-project-card__numbers">
                <div class="row align-middle">
                  <div class="column small-12 large-shrink large-order-2">
                    <em>
                      <a
                        class="big-numbers__remove-link-color"
                        :href="manageProjectUrl"
                      >
                        {{ submissionCount }}
                      </a>
                    </em>
                  </div>
                  <div class="column small-12 large-shrink large-order-1">
                    <span class="text-medium">
                      Designs<span class="show-for-large">:</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column small-6 large-shrink">
              <div class="my-project-card__numbers">
                <div class="row align-middle">
                  <div class="column small-12 large-shrink large-order-2">
                    <em>
                      <a
                        class="big-numbers__remove-link-color"
                        :href="manageProjectUrl"
                      >
                        {{ designerDisplayName }}
                      </a>
                    </em>
                  </div>
                  <div class="column small-12 large-shrink large-order-1">
                    <span class="text-medium">
                      Winner<span class="show-for-large">:</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column small-12 large-shrink align-self-middle">
              <a v-if="newProjectWithDesignerUrl" class="text-medium" :href="newProjectWithDesignerUrl">
                Start a new project with this designer
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    designThumbnailUrl: {
      type: String,
      default: '',
    },
    designerDisplayName: {
      type: String,
      default: '',
    },
    submissionCount: {
      type: Number,
      default: undefined,
    },
    printUpsellButtonCopy: {
      type: String,
      default: undefined,
    },
    printUpsellUrl: {
      type: String,
      default: undefined,
    },
    manageProjectUrl: {
      type: String,
      default: undefined,
    },
    newProjectWithDesignerUrl: {
      type: String,
      default: undefined,
    },
  },
};
</script>
