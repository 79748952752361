<template>
  <div class="addon form-stacking text-center medium-text-left">
    <div class="row align-center align-middle">
      <div class="column mall-12 medium-2">
        <div class="addon__left">
          <img
            :src="iconUrl"
            :alt="`${heading} icon`"
            height="100"
            width="100"
            loading="lazy"
          />
        </div>
      </div>
      <div class="column small-12 medium-10">
        <div class="addon__content">
          <h6 class="mbn">{{ heading }}</h6>
          <p class="mbs">{{ description }}</p>
        </div>
      </div>
    </div>
    <div class="row align-right align-bottom">
      <div class="column small-12 large-7">
        <div class="row">
          <div class="column small-12">
            <p class="text-small mbs">{{ briefListLabel }}</p>
          </div>
          <div class="column small-12">
            <select v-model="purchaseAddonAction">
              <option
                v-for="(brief, key) in briefs"
                :key="key"
                :value="brief.actionUrl"
                :selected="key === 0"
              >
                {{ brief.briefName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="column small-12 large-3">
        <a
          class="button secondary hollow expanded mbn"
          :href="purchaseAddonAction"
        >
          {{ actionText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    briefListLabel: {
      type: String,
      required: true,
    },
    briefs: {
      type: Array,
      required: true,
    },
    actionText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      purchaseAddonAction: '',
    };
  },
  mounted() {
    this.purchaseAddonAction = this.briefs[0].actionUrl;
  },
};
</script>
