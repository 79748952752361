<template>
  <div>
    <div @click="open">
      <slot name="open" />
    </div>
    <div
      :ref="modalRef"
      :class="{
        'reveal modal': true,
        'designer-services__portfolio_modal': isPortfolioModal,
        [className]: !!className,
      }"
      data-reveal
      :data-close-on-click="closeOnClickOutside ? 'true' : 'false'"
    >
      <header class="modal__header">
        {{ title }}
        <button
          v-if="closeIcon"
          class="close-button"
          data-close
          aria-label="Close modal"
          type="button"
        >
          <span v-if="closeIcon" aria-hidden="true">
            <img :src="closeIcon" alt="close icon" />
          </span>
          <span v-else aria-hidden="true">&times;</span>
        </button>
      </header>
      <div class="maker-spinner-container">
        <div v-if="isLoading" class="maker-spinner">
          <span class="maker-spinner__icon" />
        </div>
        <div class="modal__body">
          <slot name="body" />
        </div>
        <footer
          v-if="$slots.footer"
          class="modal__footer modal__footer--border-top"
        >
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: undefined,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    isPortfolioModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reveal: null,
      modalAbide: null,
      mounted: false,
    };
  },
  computed: {
    modalRef() {
      // eslint-disable-next-line no-underscore-dangle
      return `js-modal-${this._uid}`;
    },
  },
  watch: {
    isVisible(isVisible) {
      if (isVisible) {
        this.open();
      } else {
        this.reveal.close();
      }
    },
  },
  created() {
    this.EVENT_MODAL_MOUNTED = 'mounted';
    this.EVENT_MODAL_OPENED = 'opened';
    this.EVENT_MODAL_CLOSED = 'closed';
    this.EVENT_MODAL_LOADING = 'loading';
  },
  updated() {
    if (this.isLoading) {
      this.$emit(this.EVENT_MODAL_LOADING);
    }
  },
  mounted() {
    const callback = this.isVisible ? this.open : function empty() {};
    ((closureCallback) => {
      window.require(['www'], () => {
        window.require(['app/foundationInit'], () => {
          closureCallback();
        });
      });
    })(callback);
  },
  methods: {
    open() {
      // eslint-disable-next-line no-undef
      const modalElement = $(this.$refs[this.modalRef]);
      if (this.reveal && this.reveal.destroy)
        window.jQuery(modalElement).foundation('_destroy');
      this.reveal = new window.Foundation.Reveal(modalElement);
      this.reveal.open();

      this.modalAbide = new window.Foundation.Abide(
        // eslint-disable-next-line no-undef
        $(this.$refs[this.modalFormRef]),
      );

      // eslint-disable-next-line no-undef
      $(this.$refs[this.modalRef]).on('open.zf.reveal', () =>
        this.$emit(this.EVENT_MODAL_OPENED),
      );

      // eslint-disable-next-line no-undef
      $(this.$refs[this.modalRef]).on('closed.zf.reveal', () => {
        if (this.reveal && this.reveal.destroy)
          window.jQuery(modalElement).foundation('_destroy');
        this.$emit(this.EVENT_MODAL_CLOSED);
      });

      this.mounted = true;
      this.$emit(this.EVENT_MODAL_MOUNTED, this.reveal);
    },
  },
};
</script>
