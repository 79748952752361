<template>
  <div class="big-numbers">
    <div class="row">
      <div class="column small-6 large-3 text-center">
        <em>
          <span v-if="submissionCount === 0">
            {{ submissionCount }}
          </span>
          <a v-else class="big-numbers__remove-link-color" :href="manageProjectUrl">
            {{ submissionCount }}
          </a>
        </em>
        <br />
        <span>Designs</span>
      </div>
      <div class="column small-6 large-3 text-center">
        <em>{{ packageCost }}</em>
        <br />
        <span>Package cost</span>
      </div>
      <div class="column small-6 large-3 text-center">
        <em>
          <span v-if="commentCount === 0">
            {{ commentCount }}
          </span>
          <a v-else class="big-numbers__remove-link-color" :href="viewCommentsUrl">
            {{ commentCount }}
          </a>
        </em>
        <br />
        <span>Comments</span>
      </div>
      <div class="column small-6 large-3 text-center">
        <em>{{ daysLeft }}</em>
        <br />
        <span>Days left</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submissionCount: {
      type: Number,
      default: undefined,
    },
    packageCost: {
      type: String,
      default: '',
    },
    commentCount: {
      type: Number,
      default: undefined,
    },
    daysLeft: {
      type: Number,
      default: undefined,
    },
    manageProjectUrl: {
      type: String,
      default: undefined,
    },
    viewCommentsUrl: {
      type: String,
      default: undefined,
    },
  },
};
</script>
