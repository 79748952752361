var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',{attrs:{"name":"pagination"}},[_c('ul',{staticClass:"pagination text-center align-center align-middle pager",attrs:{"id":"paging","role":"navigation","aria-label":"Pagination"}},[_c('li',{class:{
        ['pagination-previous']: true,
        disabled: _vm.pagination.current === 1,
      }},[(_vm.pagination.current === 1)?_c('span',[_c('img',{attrs:{"src":_vm.leftArrowIconUrl,"alt":"Pagination left arrow"}}),_c('span',{staticClass:"show-for-sr"},[_vm._v("Go to previous page")])]):_c('a',{attrs:{"title":"Go to previous page"},on:{"click":function($event){return _vm.onClickPageLink(_vm.pagination.current - 1)}}},[_c('img',{attrs:{"src":_vm.leftArrowIconUrl,"alt":"Pagination left arrow"}}),_c('span',{staticClass:"show-for-sr"},[_vm._v("Go to previous page")])])]),(_vm.shouldRenderMinPageLink)?_c('li',[_c('a',{on:{"click":function($event){return _vm.onClickPageLink(1)}}},[_vm._v("1")])]):_vm._e(),(_vm.shouldRenderMinPageLink)?_c('li',{staticClass:"ellipsis"}):_vm._e(),_vm._l((_vm.pages),function(page){return _c('li',{key:page,class:{ current: _vm.pagination.current === page }},[(_vm.pagination.current === page)?_c('span',{attrs:{"name":"current-page-link"}},[_c('span',{staticClass:"show-for-sr"},[_vm._v("You're on page")]),_c('span',[_vm._v(_vm._s(page))])]):_c('a',{on:{"click":function($event){return _vm.onClickPageLink(page)}}},[_c('span',{staticClass:"show-for-sr"},[_vm._v("Go to page")]),_vm._v(" "+_vm._s(page)+" ")])])}),(_vm.shouldRenderMaxPageLink)?_c('li',{staticClass:"ellipsis"}):_vm._e(),(_vm.shouldRenderMaxPageLink)?_c('li',[_c('a',{on:{"click":function($event){return _vm.onClickPageLink(_vm.pagination.maxPage)}}},[_vm._v(" "+_vm._s(_vm.pagination.maxPage)+" ")])]):_vm._e(),_c('li',{staticClass:"pagination__page-no"},[_vm._v(" Page "+_vm._s(_vm.pagination.current)+" of "+_vm._s(_vm.pagination.maxPage)+" ")]),_c('li',{class:{
        ['pagination-next']: true,
        disabled: _vm.pagination.current === _vm.pagination.maxPage,
      }},[(_vm.pagination.current === _vm.pagination.maxPage)?_c('span',[_c('img',{attrs:{"src":_vm.rightArrowIconUrl,"alt":"pagination right arrow"}}),_c('span',{staticClass:"show-for-sr"},[_vm._v("Go to next page")])]):_c('a',{attrs:{"title":"Go to next page"},on:{"click":function($event){return _vm.onClickPageLink(_vm.pagination.current + 1)}}},[_c('img',{attrs:{"src":_vm.rightArrowIconUrl,"alt":"pagination right arrow"}}),_c('span',{staticClass:"show-for-sr"},[_vm._v("Go to next page")])])])],2),_c('p',{staticClass:"p-small text-center mbn"},[_vm._v(" "+_vm._s(_vm.pagination.startOfPage)+" - "+_vm._s(_vm.pagination.numberOfResults)+" of "+_vm._s(_vm.pagination.totalResultsCount)+" "+_vm._s(_vm.pagination.description)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }