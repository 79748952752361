<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="profile-page-container">
    <div class="row">
      <div v-if="alerts && alerts.length > 0" class="column small-12">
        <Callout
          v-for="(alert, index) in alerts"
          :key="index"
          :type="alert.type"
          :title="alert.title"
          :icon-url="icons[`${alert.type}Icon`]"
          closable
          @close="alerts = []"
        >
          <template #message>{{ alert.message }}</template>
        </Callout>
      </div>
    </div>
    <div class="row account-settings">
      <div class="column small-12 medium-3">
        <profile-strength
          :percentage="models.profileStrength.percentage"
          class="mbm"
        />
        <ManageAccountMenu :menu="models.menu" />
      </div>
      <div class="column small-12 large-9">
        <div class="frame frame--padding-small ptn">
          <div class="row column">
            <ul class="menu menu--tabs menu--tabs-white u-block full-width">
              <li
                v-for="(tab, index) in Object.keys(TABS)"
                :key="index"
                :class="{
                  'menu__active-tab': isTabVisible(TABS[tab]),
                }"
                @click="openTab(TABS[tab])"
              >
                <a class="text-uppercase">{{ TABS[tab] }}</a>
              </li>
            </ul>
            <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
          </div>
          <AccountDetailsForm
            v-show="isTabVisible(TABS.ACCOUNT_DETAILS)"
            :address="models.accountDetails.address"
            :city="models.accountDetails.city"
            :company-name="models.accountDetails.companyName"
            :country="models.accountDetails.country"
            :email="models.accountDetails.email"
            :portfolio-url="models.accountDetails.portfolioUrl"
            :deactivate-url="models.accountDetails.deactivateUrl"
            :status="models.accountDetails.status"
            :first-name="models.accountDetails.firstName"
            :last-name="models.accountDetails.lastName"
            :phone="models.accountDetails.phone"
            :postcode="models.accountDetails.postcode"
            :state="models.accountDetails.state"
            :countries="models.countries"
            :reset-change-password-form="resetChangePasswordForm"
            :update-password-endpoint="
              models.accountDetails.updatePasswordEndpoint
            "
            :update-details-endpoint="
              models.accountDetails.updateDetailsEndpoint
            "
            :resend-verification-email-endpoint="
              models.accountDetails.resendVerificationEmailEndpoint
            "
            @update-password="
              updatePassword(
                $event.password,
                $event.newPassword,
                $event.confirmPassword,
              )
            "
            @show-api-success-message="pushSuccessAlert"
            @show-api-error-messages="pushErrorAlerts"
          />
          <DesignerSkillSelect
            v-show="isTabVisible(TABS.CREATIVE_SKILLS)"
            :categories-to-display="categoriesToDisplay"
            :categories="models.skillSelector.all"
            :button-loading-img-url="icons.processing"
            description="Choose the types of design jobs you are most interested / skilled in&hellip;"
            @submit="updateSkills"
            :submitting-prop="designerSkillsSubmitting"
          />
          <PublicProfileForm
            v-show="isTabVisible(TABS.PUBLIC_PROFILE)"
            :public-profile="models.publicProfile.data"
            :profile-image-uploader="models.publicProfile.profileImage"
            :save-endpoint="models.publicProfile.saveEndpoint"
            :icons="icons"
            @show-api-success-message="pushSuccessAlert"
            @show-api-error-messages="pushErrorAlerts"
          />
          <EmailSubscription
            v-show="isTabVisible(TABS.EMAIL_SUBSCRIPTION)"
            :jobs="models.emailSubscriptions.jobs"
            :emails="models.emailSubscriptions.emails"
            :invite-alerts="models.emailSubscriptions.inviteAlerts"
            :email-schedules="models.emailSubscriptions.emailSchedules"
            :watchlist-reminder="models.emailSubscriptions.watchlistReminder"
            :community-digest-enabled="
              models.emailSubscriptions.communityDigest
            "
            :newsletter-enabled="models.emailSubscriptions.newsletter"
            :show-jobs-section="models.emailSubscriptions.showJobsSection"
            :update-endpoint="models.emailSubscriptions.updateEndpoint"
            @show-api-success-message="pushSuccessAlert"
          />
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import axios from 'axios';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import AccountDetailsForm from '../../components/my-account/designer/account-settings/AccountDetailsForm.vue';
import PublicProfileForm from '../../components/my-account/designer/account-settings/PublicProfileForm.vue';
import EmailSubscription from '../../components/my-account/designer/account-settings/EmailSubscription.vue';
import DesignerSkillSelect from '../../components/my-account/designer/overview/DesignerSkillSelect/DesignerSkillSelect.vue';
import Callout from '../../components/shared/Callout/Callout.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
export default {
  components: {
    ProfileStrength,
    AccountDetailsForm,
    ManageAccountMenu,
    DesignerSkillSelect,
    PublicProfileForm,
    EmailSubscription,
    Callout,
  },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userAccountDetails: this.accountDetails,
      userEmailSubscriptions: this.emailSubscriptions,
      userStoredCards: this.storedCards,
      visibleTab: null,
      alerts: [],
      canRemoveCards: true,
      resetChangePasswordForm: false,
      designerSkillsSubmitting: false,
    };
  },
  computed: {
    categoriesToDisplay() {
      const categories = [];
      this.models.skillSelector.all.forEach((category) => {
        const index = this.models.skillSelector.subset.indexOf(category.name);
        if (index > -1) {
          categories[index] = category;
        }
      });

      return categories;
    },
  },
  mounted() {
    if (this.models.initialTab === 'CREATIVE_SKILLS')
      this.visibleTab = this.TABS.CREATIVE_SKILLS;
    else if (this.models.initialTab === 'PUBLIC_PROFILE')
      this.visibleTab = this.TABS.PUBLIC_PROFILE;
    else if (this.models.initialTab === 'EMAIL_SUBSCRIPTION')
      this.visibleTab = this.TABS.EMAIL_SUBSCRIPTION;
    else this.visibleTab = this.TABS.ACCOUNT_DETAILS;
  },
  created() {
    this.TABS = {
      ACCOUNT_DETAILS: 'Account Details',
      CREATIVE_SKILLS:
        "Creative Skills",
      PUBLIC_PROFILE: 'Public Profile',
      EMAIL_SUBSCRIPTION: 'Email Subscription',
    };
  },
  methods: {
    async updateSkills(designTypes) {
      this.designerSkillsSubmitting = true;
      const res = await axios.post(
        '/manage-profile/api/update-skills?source=settings',
        {
          designTypes,
        },
        axiosConfig,
      );

      if (res && res.status === 200) {
        if (res.data?.successMessage) {
          this.pushSuccessAlert(res.data.successMessage);
          this.designerSkillsSubmitting = false;
        }
        if (res.data?.errors) {
          this.pushErrorAlerts(res.data.errors);
        }
      } else {
        const msg =
          `Something unexpected happened and the request could not be completed.`;
        alert(msg);
      }
    },
    openTab(tab) {
      this.visibleTab = tab;
      this.alerts = [];
    },
    isTabVisible(tab) {
      return this.visibleTab === tab;
    },
    pushSuccessAlert(alert) {
      this.alerts = [];
      if (alert) {
        this.alerts.push({
          type: 'success',
          message: alert,
          title: 'Success',
        });
        window.scrollTo(0, 0);
      }
    },
    pushErrorAlerts(alerts) {
      this.alerts = [];

      alerts.forEach((alert) => {
        this.alerts.push({
          type: 'warning',
          message: alert,
          title: 'Warning',
        });
        window.scrollTo(0, 0);
      });
    },
    async updatePassword(password, newPassword, confirmPassword) {
      this.resetChangePasswordForm = false;

      const res = await axios.post(
        this.models.accountDetails.updatePasswordEndpoint,
        {
          Password: password,
          NewPassword: newPassword,
          ConfirmPassword: confirmPassword,
        },
        axiosConfig,
      );

      if (res && res.status === 200) {
        if (res.data?.successMessage) {
          this.resetChangePasswordForm = true;
          this.pushSuccessAlert(res.data.successMessage);
        }
        if (res.data?.errors) {
          this.pushErrorAlerts(res.data.errors);
        }
      }
    },
  },
};
</script>
