<template>
  <div
    :id="id"
    :ref="dropdownElementRef"
    :class="{
      ['drop-checkbox mbn']: true,
      active: isVisible,
    }"
    @click="isVisible = !isVisible"
  >
    <label>{{ label || 'Options' }}</label>
    <ul v-click-outside="closeDropdown" class="drop-checkbox__dropdown">
      <li v-for="(option, index) in options" :key="index">
        <Checkbox
          :id="`${valueKey}${index}`"
          :value="option[valueKey]"
          :checked="option[isSelectedKey]"
          @input="onChange($event, option)"
        >
          <!--
            Use a slot to allow for custom content to be displayed next to the
            checkboxes in this dropdown.

            Additionally pass option into into the slot to make it available to
            the parent.
          -->
          <slot :option="option">
            <div class="u-truncate-ellipsis">
              <span>{{ option[valueKey] }}</span>
            </div>
          </slot>
        </Checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
import Checkbox from '../Checkbox/Checkbox.vue';

export default {
  components: {
    Checkbox,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
    valueKey: {
      type: String,
      required: true,
    },
    isSelectedKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      dropdownElementRef: 'js-dropdown-element',
    };
  },
  methods: {
    onChange(selected, option) {
      this.$emit('input', { selected, option });
    },
    closeDropdown(event) {
      if (
        event.target &&
        event.target.id !== this.$refs[this.dropdownElementRef].id
      ) {
        this.isVisible = false;
      }
    },
  },
};
</script>
