<template>
  <label :for="name || id">
    <input
      :id="id"
      :ref="checkboxRef"
      type="checkbox"
      class="input-type--checkbox"
      :name="name || id"
      :value="value"
      :checked="checked"
      @change="onClick($event)"
    />
    <span><slot /></span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    checked: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    checkboxRef() {
      // eslint-disable-next-line no-underscore-dangle
      return `checkbox-${this._uid}`;
    },
  },
  methods: {
    onClick(e) {
      this.$emit('input', e.target.checked);
    },
  },
};
</script>
