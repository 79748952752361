<template>
  <div>
    <div class="flex-container align-justify mbm">
      <a href="/manage-profile/settings?tab=email-options">View Invite Settings</a>
      <label class="mrs">
        <input
          type="checkbox"
          class="input-type--checkbox"
          @change="onFilterUpdate"
        />
        <span>Hide Submitted</span>
      </label>
    </div>
    <table v-if="invites.length" class="table table--striped">
      <thead>
        <tr>
          <th>Brief</th>
          <th>Status</th>
          <th>Type</th>
          <th class="show-for-medium">Submission Deadline</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in invites"
          :key="index"
          @click="viewInvite(item)"
        >
          <td class="prs">
            <a>{{ item.briefId }}</a>
          </td>
          <td class="prs">
            <a>{{ item.status }}</a>
          </td>
          <td class="prs">{{ item.type }}</td>
          <td class="prs show-for-medium">{{ item.submissionDeadline }}</td>
        </tr>
      </tbody>
    </table>
    <ManageAccountEmptyCard v-else title="No Invites" />
  </div>
</template>

<script>
import axios from 'axios';
import ManageAccountEmptyCard from '../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: { ManageAccountEmptyCard },
  props: {
    invites: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    apiBaseUrl: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    viewInvite(item) {
      window.location.href = item.inviteUrl;
    },
    async onFilterUpdate(e) {
      const excludeSubmitted = e.target.checked;
      if (this.apiBaseUrl && !this.isLoading) {
        this.isLoading = true;
        const apiPostObject = {
          page: 1,
          excludeSubmitted,
        };
        const res = await axios.post(
          this.apiBaseUrl,
          apiPostObject,
          axiosConfig,
        );

        this.isLoading = false;

        if (res?.data) {
          if (!res?.data.items.length) {
            this.emptyCardMessage = 'No items matched your filter.';
          } else {
            this.emptyCardMessage = null;
          }
          this.$emit('table-update', { resData: res.data, apiPostObject });
        }
      }
    },
  },
};
</script>
