<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <ManageAccountEmptyCard
        v-if="currentPage.length === 0 && !hasBriefs"
        :icon-url="icons.emptyOrders"
        icon-alt="No website orders"
        title="No Website Orders"
      >
        <p>
          You need a design before you can order a website.
          <a :href="orderSystemUrl">Launch a Design Project</a>
        </p>
      </ManageAccountEmptyCard>
      <ManageAccountEmptyCard
        v-else-if="currentPage.length === 0"
        :icon-url="icons.emptyOrders"
        icon-alt="No website orders"
        title="No Website Orders"
      >
        <p>
          Looks like you don’t have any website orders yet. Choose an order below
        </p>
      </ManageAccountEmptyCard>
      <div v-else class="row column">
        <table class="table table--striped-body mbs">
          <thead class="show-for-medium">
            <tr>
              <th>Brief ID</th>
              <th>Item</th>
              <th class="show-for-large">Pages</th>
              <th class="show-for-large">Domain Name</th>
              <th class="show-for-large">Cost</th>
              <th>Status</th>
            </tr>
          </thead>
          <WebsiteOrderTableRow
            v-for="(order, index) in currentPage"
            :key="index"
            :brief-id="order.briefId"
            :item="order.lines[0].name"
            :pages="order.pages"
            :domain-name="order.domainName"
            :cost="order.total"
            :is-completed="order.isCompleted"
            :is-in-progress="order.isInProgress"
            :is-refunded="order.isRefunded"
            :is-awaiting-design-approval="order.isAwaitingDesignApproval"
          />
        </table>
        <Pagination
          :max-page="pagination.maxPage"
          :current="pagination.current"
          :start="pagination.start"
          :end="pagination.end"
          :start-of-page="pagination.startOfPage"
          :number-of-results="pagination.numberOfResults"
          :total-results-count="pagination.totalResultsCount"
          :description="pagination.description"
          :base-url="pagination.baseUrl"
          :api-base-url="pagination.apiBaseUrl"
          :left-arrow-icon-url="icons.paginationLeftArrow"
          :right-arrow-icon-url="icons.paginationRightArrow"
          @page-link-click="onPaginationClick($event)"
        />
      </div>
      <div v-if="hasBriefs" class="column small-12">
        <div class="frame frame--border frame--no-padding mtm">
          <div
            class="frame__header frame__header--light frame__header--border-bottom text-center"
          >
            <h6 class="mbn">Get a website with hosting & domain</h6>
          </div>
          <div class="phm">
            <img
              class="mbs"
              :src="icons.webOrdersBackground"
              alt="Web orders"
            />
            <h4 class="text-left large-text-center">
              Get a new website designed by a professional designer:
            </h4>
            <div class="row align-center">
              <div class="column small-12 large-shrink">
                <ul class="list list--tick mln mbm">
                  <li>1 professional designer</li>
                  <li>Customized with logo and copy</li>
                  <li>Choose 1, 3 or 5 page website</li>
                </ul>
              </div>
              <div class="column small-12 large-shrink">
                <ul class="list list--tick mln mbm">
                  <li>FREE 12 months hosting</li>
                  <li>FREE domain name</li>
                  <li>FREE WordPress CMS</li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="mvn" />
          <div class="row row align-middle phm">
            <div class="column small-12 large-shrink">
              <p class="text-small mvm">
                Select the design you want for your website
              </p>
            </div>
            <div class="column small-12 large-expand">
              <select v-model="purchaseAddonCTA" class="mbn">
                <option
                  v-for="(brief, index) in addons[0].briefs"
                  :key="index"
                  :selected="index === 0"
                  :value="brief.actionUrl"
                >
                  {{ brief.briefName }}
                </option>
              </select>
            </div>
            <div class="column small-12 large-shrink">
              <a class="button secondary hollow mvm" :href="purchaseAddonCTA">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import WebsiteOrderTableRow from '../../components/my-account/customer/my-website-orders/WebsiteOrderTableRow/WebsiteOrderTableRow.vue';
import ManageAccountEmptyCard from '../../components/my-account/ManageAccountEmptyCard/ManageAccountEmptyCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    ManageAccountMenu,
    WebsiteOrderTableRow,
    ManageAccountEmptyCard,
    Pagination,
  },
  props: {
    icons: {
      type: Object,
      default: undefined,
    },
    menu: {
      type: Object,
      required: true,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    addons: {
      type: Array,
      default: () => [],
    },
    orderSystemUrl: {
      type: String,
      required: true,
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.orders,
      pagination: this.paginationObject,
      purchaseAddonCTA: '',
    };
  },
  computed: {
    hasBriefs() {
      return (
        this.addons &&
        this.addons.length > 0 &&
        this.addons[0]?.briefs.length > 0
      );
    },
  },
  mounted() {
    this.purchaseAddonCTA = this.addons[0].briefs[0].actionUrl;
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
  },
};
</script>
