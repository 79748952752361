<template>
  <div>
    <div class="row">
      <div class="column small-12">
        <p class="mbm">
          The following options will help you to integrate your client portal in your own website:
        </p>
        <label for="project-name">
          <strong class="text-black">Whitelabel Text</strong>
        </label>
        <span>Select the project you want to integrate into your website</span>
        <select id="project-name" v-model="briefId" name="project-name">
          <option
            v-for="(brief, index) in briefs"
            :key="index"
            :value="brief.briefId"
          >
            {{ brief.briefName }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 large-6">
        <label for="frame-height">
          <strong class="text-black">Frame height (pixels)</strong>
        </label>
        <span>The height of the frame (600 pixels recommended)</span>
        <input id="frame-height" type="number" value="600" />
      </div>
      <div class="column small-12 large-6">
        <label for="frame-width">
          <strong class="text-black">Frame width (pixels)</strong>
        </label>
        <span>The width of the frame (700 pixels recommended)</span>
        <input id="frame-width" type="number" value="700" />
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <strong class="text-black">Generated HTML Code</strong>
        <p>
          Copy and paste the following code into your website to display the client portal in a frame:
        </p>
        <p v-if="frameMarkup">
          <code>{{ frameMarkup }}</code>
        </p>
      </div>
    </div>
    <div class="row align-center">
      <div class="column small-12">
        <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
      </div>
      <div class="column small-12 large-shrink">
        <button
          class="button primary full-width mbn"
          @click="onClickGenerateFrameCode"
        >
          Generate Frame Code
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    briefs: {
      type: Array,
      default: () => [],
    },
    getIframeCodeApi: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      frameHeight: 600,
      frameWidth: 700,
      briefId: 0,
      frameMarkup: null,
    };
  },
  mounted() {
    this.briefId =
      this.briefs.length > 0 ? this.briefs[0]?.briefId : this.briefId;
  },
  methods: {
    async onClickGenerateFrameCode() {
      try {
        const res = await axios.post(this.getIframeCodeApi, {
          Height: this.frameHeight,
          Width: this.frameWidth,
          BriefId: this.briefId,
        });

        if (res && res.status === 200) {
          this.frameMarkup = res.data;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
