import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const saveFeedbackAsync = async (
  briefId,
  designId,
  qualityRating,
  feedbackText,
  eliminateDesigner,
) => {
  if (briefId) {
    const response = await axios.post(
      `/manage-project/api/post-feedback/${briefId}`,
      {
        designId,
        qualityRating,
        creativityRating: 0,
        colorsRating: 0,
        fontsRating: 0,
        feedbackText,
        eliminateDesigner,
      },
      axiosConfig,
    );

    if (response.status === 200) {
      return response;
    }
  }

  return null;
};

const saveStarRatingAsync = async ({
  briefId,
  designId,
  submissionId,
  starRating,
}) => {
  if (briefId) {
    const response = await axios.post(
      `/manage-project/api/set-star-rating/${briefId}`,
      {
        designId: designId || null,
        submissionId: submissionId || null,
        starRating,
      },
      axiosConfig,
    );

    if (response.status === 200) {
      return response;
    }
  }

  return null;
};

const eliminateDesignAsync = async (briefId, designId) => {
  if (briefId) {
    const response = await axios.post(
      `/manage-project/api/eliminate/${briefId}`,
      { designIds: [designId] },
      axiosConfig,
    );

    if (response.status === 200) {
      return response;
    }
  }

  return null;
};
const eliminateDesignerAsync = async (briefId, designerIds) => {
  if (briefId) {
    const response = await axios.post(
      `/manage-project/api/eliminate/${briefId}`,
      { designerIds: [designerIds] },
      axiosConfig,
    );

    if (response.status === 200) {
      return response;
    }
  }

  return null;
};
const eliminateBulkDesignsAsync = async (briefId, designIds) => {
  if (briefId) {
    const response = await axios.post(
      `/manage-project/api/eliminate/${briefId}`,
      { designIds },
      axiosConfig,
    );

    if (response.status === 200) {
      return response;
    }
  }

  return null;
};
const unEliminateDesignAsync = async (briefId, designId) => {
  const response = await axios.post(
    `/manage-project/api/eliminate/${briefId}`,
    { undo: { designIds: [designId] } },
    axiosConfig,
  );

  if (response.status === 200) {
    return response;
  }

  return null;
};
const eliminationFeedback = async (
  briefId,
  designId,
  reason,
  action,
  eliminationFeedbackText,
) => {
  const response = await axios.post(
    `/manage-project/api/elimination-feedback/${briefId}`,
    {
      designIds: [designId],
      reason,
      action,
      feedbackText: eliminationFeedbackText,
    },
    axiosConfig,
  );

  if (response.status === 200) {
    return response;
  }

  return null;
};

export default {
  saveFeedbackAsync,
  saveStarRatingAsync,
  eliminateDesignAsync,
  unEliminateDesignAsync,
  eliminateBulkDesignsAsync,
  eliminationFeedback,
  eliminateDesignerAsync,
};
