// Expose image load listeners with a promise
function watchImage(element, imgPlaceholder) {
  const el = element;
  return new Promise((resolve) => {
    el.onload = () => {
      resolve();
    };
    el.onerror = () => {
      if (imgPlaceholder) {
        el.src = imgPlaceholder.url;

        if (imgPlaceholder.class) {
          imgPlaceholder.class.split(' ').forEach((c) => el.classList.add(c));
        }
      }

      resolve();
    };
  });
}

function getImages(parent, dataId) {
  const children = [];

  if (!parent) {
    return children;
  }

  // Recursively look for child nodes
  const findChildByType = (element, type) => {
    for (let i = 0; i < element.childNodes.length; i += 1) {
      const el = element.childNodes[i];
      // Get children of that type only
      if (el.nodeName === type) {
        if (dataId && el.getAttribute(dataId)) {
          children.push(el);
        }

        if (!dataId) {
          children.push(el);
        }
      }
      // Recursive find children
      if (el.childNodes && el.childNodes.length > 0) {
        findChildByType(el, type);
      }
    }
  };

  findChildByType(parent, 'IMG' /* Node type img */);

  return children;
}

/**
  Attach listeners to all images given a parent
  @param container - The element that contains all of the images to load.
  @param dataId - Unique identifier to further specify which images to load in a given container
  @param imgPlaceholder - An object containing properties of an image placeholder
  to render if the loading image fails
  @param [imgPlaceholder.url] - The url of the placeholder image
  @param [imgPlaceholder.class] - Optional classes for
*/
export default function allImagesLoaded(parent, dataId, imgPlaceholder) {
  const images = getImages(parent, dataId);
  const notCached = images.filter((i) => !i.complete);
  if (notCached.length > 0) {
    // Watch all images simultaneously
    return Promise.all(notCached.map((c) => watchImage(c, imgPlaceholder)));
  }
  // All images are already cached and loaded, therefore resolve the listeners
  return Promise.resolve();
}
