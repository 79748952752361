import Vuex from 'vuex';
import designsStore from './designs/designs-store';

import * as namespaces from './namespaces';

// eslint-disable-next-line import/prefer-default-export
export const createDesignsStore = () => {
  return new Vuex.Store({
    modules: {
      [namespaces.designs]: designsStore,
    },
  });
};
