<template>
  <div class="row">
    <div class="column shrink">      
      <a :href="briefLink">
        <img
          height="55"
          width="55"
          :src="designTypeImageUrl"
          :alt="designType"
        />
      </a>
    </div>
    <div class="column">
      <div class="row">
        <div class="column">
          <div class="row">
            <div class="column">
              <a :href="briefLink">
                <p class="mbs">
                  <strong class="text-black">{{ briefName }}</strong>
                </p>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="column small-12 medium-shrink">
              <a :href="briefLink">
                <div class="flex-container">
                  <img
                    class="mrs"
                    :src="submissionCountIconUrl"
                    alt="Number of submissions"
                  />
                  <strong class="text-black text-medium">
                    {{ submissionCount }}
                  </strong>
                </div>
              </a>
            </div>
            <div class="column small-12 medium-shrink">
              <a :href="briefLink">
                <div class="flex-container">
                  <img
                    class="mrs"
                    :src="timeLeftIconUrl"
                    alt="Number of submissions"
                  />
                  <strong class="text-black text-medium">
                    {{ daysLeft }}
                  </strong>
                </div>
              </a>
            </div>
            <div class="column small-12 medium-shrink">
              <a :href="briefLink">
                <div class="flex-container">
                  <img
                    class="mrs"
                    :src="projectBudgetIconUrl"
                    alt="Number of submissions"
                  />
                  <strong class="text-black text-medium">
                    {{ totalPayment }}
                  </strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="column small-12 medium-shrink align-self-middle">
          <a class="flex-container pvm" :disabled="!enabled" @click="onClickWatchList">
            <img
              class="mrs"
              :src="
                isAddedToWatchlist
                  ? watchlistFilledIconUrl
                  : watchlistHollowIconUrl
              "
              alt="heart icon"
            />
            <strong class="text-black text-small">
              {{
                enabled
                  ? isAddedToWatchlist
                    ? 'REMOVE FROM WATCHLIST'
                    : 'ADD TO WATCHLIST'
                  : 'WORKING...'
              }}
            </strong>
          </a>
        </div>
      </div>
    </div>
    <div class="column small-12">
      <hr class="mvm" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  props: {
    briefId: {
      type: Number,
      required: true,
    },
    briefLink: {
      type: String,
      required: true,
    },
    submissionCount: {
      type: String,
      required: true,
    },
    designTypeImageUrl: {
      type: String,
      required: true,
    },
    designType: {
      type: String,
      required: true,
    },
    briefName: {
      type: String,
      required: true,
    },
    daysLeft: {
      type: String,
      required: true,
    },
    totalPayment: {
      type: String,
      required: true,
    },
    addToWatchlistApi: {
      type: String,
      default: undefined,
    },
    removeFromWatchlistApi: {
      type: String,
      default: undefined,
    },
    submissionCountIconUrl: {
      type: String,
      default: undefined,
    },
    timeLeftIconUrl: {
      type: String,
      default: undefined,
    },
    projectBudgetIconUrl: {
      type: String,
      default: undefined,
    },
    watchlistHollowIconUrl: {
      type: String,
      default: undefined,
    },
    watchlistFilledIconUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isAddedToWatchlist: false,
      enabled: true,
    };
  },
  methods: {
    async onClickWatchList(e) {
      if (!this.enabled)
        return;
      this.enabled = false;
      e.preventDefault();
      if (!this.isAddedToWatchlist) {
        await this.addToWatchlist();
      } else {
        await this.removeFromWatchlist();
      }
    },
    async addToWatchlist() {
      this.isAddedToWatchlist = true;
      const addToWatchlistRes = await axios.post(
        this.addToWatchlistApi,
        {
          briefId: this.briefId,
        },
        axiosConfig,
      );

      if (addToWatchlistRes.status === 200) {
        this.enabled = true;
      }
    },
    async removeFromWatchlist() {      
      this.isAddedToWatchlist = false;
      const addToWatchlistRes = await axios.post(
        this.removeFromWatchlistApi,
        {
          briefId: this.briefId,
        },
        axiosConfig,
      );

      if (addToWatchlistRes.status === 200) {
        this.enabled = true;
      }
    },
  },
};
</script>
