<template>
  <div>
    <p class="text-center mbm" v-html="description ? description : message"></p>
    <div class="row small-up-2 medium-up-4 large-up-6">
      <div
        v-for="(category, index) in displayedCategories"
        :key="index"
        class="column"
      >
        <Tile
          is-thumbnail
          :is-selected="category.selected"
          @selected="onClickDesignTile(category.name)"
        >
          <img
            width="75"
            class="pas"
            :src="category.iconUrl"
            :alt="category.name"
          />
          <p>
            <strong class="text-black text-medium">
              {{ category.name }}
            </strong>
          </p>
        </Tile>
      </div>
    </div>
    <ul class="tag-list tag-list--design-portfolio">
      <li v-for="(designType, index) in designTypes" :key="index">
        <span class="tag">
          <a>{{ designType }}</a>
          <a class="tag__close" @click="removeDesignType(designType)">
            <span class="show-for-sr">[[close]]</span>
          </a>
        </span>
      </li>
    </ul>
    <p class="text-center">
      Don't see your skillset? Select from many more:
    </p>
    <div class="row align-center">
      <div class="column small-12 medium-9 large-8">
        <DropdownSearch
          :data-set="designTypeNameList"
          @input="addDesignType($event)"
        />
      </div>
    </div>
    <p class="text-center">You can choose multiple types</p>
    <div class="text-center">
      <button
        v-if="submitting"
        type="button"
        class="button mbn button-processing"
        disabled
      >
        <img alt="processing" :src="buttonLoadingImgUrl" />
        Processing&hellip;
      </button>
      <button v-else-if="noSelection" type="button" class="button mbn" disabled>
        Select a skill
      </button>
      <button v-else class="button mbn" @click="submit">Continue</button>
    </div>
  </div>
</template>

<script>
import Tile from '../../../../shared/Tile/Tile.vue';
import DropdownSearch from '../../../../shared/DropdownSearch/DropdownSearch.vue';

export default {
  components: {
    Tile,
    DropdownSearch,
  },
  props: {
    buttonLoadingImgUrl: {
      type: String,
      required: true,
    },
    categoriesToDisplay: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: null,
    },
    submittingProp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      designTypes: [],
      displayedCategories: [],
      noSelection: true,
      submitting: this.submittingProp,
    };
  },
  watch: {
    submittingProp(submittingProp) {
      this.submitting = submittingProp;
    }
  },
  computed: {
    designTypeNameList() {
      return this.categories.map((category) => category.name);
    },
    message() {
      return `Choose what type of designer you are below then click continue to get started&hellip;<br />You will receive project invites and emails torelevant jobs based on your selection below.<br />You can manage your email preferences at anytime in <a href="/manage-profile/settings"><strong>Profile Settings</strong></a>.`;
    },
  },
  mounted() {
    this.displayedCategories = this.categoriesToDisplay.map((category) => ({
      ...category,
    }));

    this.categories
      .filter((cat) => cat.selected === true)
      .map((cat) => {
        return this.designTypes.push(cat.name);
      });
  },
  methods: {
    isInDesignTypes(designType) {
      if (this.designTypes.indexOf(designType) > -1) {
        return true;
      }
      return false;
    },
    addOrRemoveDesignType(designTypeName) {
      const index = this.designTypes.indexOf(designTypeName);
      let displayedCategoryIndex = 0;
      this.displayedCategories.find((item, i) => {
        if (item.name === designTypeName) {
          displayedCategoryIndex = i;
          return designTypeName;
        }
        return null;
      });

      if (index > -1) {
        this.designTypes.splice(index, 1);
        this.displayedCategories[displayedCategoryIndex].selected = false;
      } else {
        this.designTypes.push(designTypeName);
        this.displayedCategories[displayedCategoryIndex].selected = true;
      }
    },
    getDisplayedCategoryIndex(designTypeName) {
      let displayedCategoryIndex = -1;
      this.displayedCategories.forEach((item, i) => {
        if (item.name === designTypeName) {
          displayedCategoryIndex = i;
        }
      });

      return displayedCategoryIndex;
    },
    addDesignType(designType) {
      const index = this.designTypes.indexOf(designType);
      const displayedCategoryIndex = this.getDisplayedCategoryIndex(designType);

      if (index === -1) {
        this.designTypes.push(designType);

        if (displayedCategoryIndex > -1) {
          this.displayedCategories[displayedCategoryIndex].selected = true;
        }

        this.noSelection = this.designTypes.length === 0;
      }
    },
    removeDesignType(designType) {
      const index = this.designTypes.indexOf(designType);
      const displayedCategoryIndex = this.getDisplayedCategoryIndex(designType);

      if (index > -1) {
        this.designTypes.splice(index, 1);

        if (displayedCategoryIndex > -1) {
          this.displayedCategories[displayedCategoryIndex].selected = false;
        }
      }

      this.noSelection = this.designTypes.length === 0;
    },
    onClickDesignTile(designType) {
      if (this.designTypes.indexOf(designType) > -1) {
        this.removeDesignType(designType);
      } else {
        this.addDesignType(designType);
      }
    },
    submit() {
      this.submitting = true;
      this.$emit('submit', this.designTypes);
    },
  },
};
</script>
