<template>
  <Modal
    title="Examples"
    :is-visible="isVisible"
    :is-portfolio-modal="(isPortfolioModal = true)"
    :is-loading="isLoading"
    :close-icon="icons.close"
    @closed="close"
  >
    <template #body>
      <div
        class="flex-container align-middle mbm designer-services__portfolio_modal-body row"
      >
        <div
          class="small-12 row small-12 row flex-container align-center designer-services__portfolio_filters"
        >
          <div class="small-12 medium-1">
            <label for="filters">Filters:</label>
          </div>
          <select
            v-model="filterDesignTypeDisplayName"
            class="mbn mls mrs small-12 mas medium-3"
          >
            <option
              v-for="(option, index) in designTypeOptions"
              :key="index"
              :value="option.value"
              class="mls mrs"
            >
              {{ option.label }}
            </option>
          </select>

          <select v-model="filterBriefId" class="mbn mls small-12 mas medium-3">
            <option
              v-for="(option, index) in briefOptions"
              :key="index"
              :value="option.value"
              class="mls mrs"
            >
              {{ option.label }}
            </option>
          </select>
          <div class="small-12 medium-offset-1 medium-3">
            <button
              v-if="canSelectAll"
              class="button secondary hollow small mbn small-12"
              @click="selectAll()"
            >
              <span>Select All</span>
            </button>
            <button
              v-if="canDeSelectAll && !canSelectAll"
              class="button secondary hollow small mbn small-12"
              @click="deSelectAll()"
            >
              <span>Deselect All</span>
            </button>
          </div>
        </div>
        <div v-if="errors" class="row small-12">
          <ul
            v-for="(error, index) in errors"
            :key="index"
            class="text-color-red text-small mtm mbn"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
        <div class="row designer-services__portfolio_container">
          <div
            v-for="(design, index) in designs"
            :key="index"
            class="designer-services__portfolio_container-image text-center mln mrn column"
            :class="{
              'designer-services__portfolio_container-image--selected':
                selectedDesignIds.includes(design.designId),
            }"
          >
            <img :src="design.thumbnailUrl" alt="thumbnail" />
            <button
              v-if="selectedDesignIds.includes(design.designId)"
              class="button small mbn button--cta-red"
              @click="deSelectDesign(design.designId)"
            >
              Deselect Design
            </button>
            <button
              v-if="!selectedDesignIds.includes(design.designId)"
              class="button small mbn primary"
              :disabled="!canSelect"
              @click="selectDesign(design.designId)"
            >
              Select Design
            </button>
          </div>
        </div>
        <div>
          <Pagination
            class="mtm"
            :max-page="pagination.maxPage"
            :current="pagination.current"
            :start="pagination.start"
            :end="pagination.end"
            :start-of-page="pagination.startOfPage"
            :number-of-results="pagination.numberOfResults"
            :total-results-count="pagination.totalResultsCount"
            :description="pagination.description"
            :base-url="pagination.baseUrl"
            :api-base-url="pagination.apiBaseUrl"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            :api-post-object="apiPostObject"
            @page-link-click="onPaginationClick"
            @page-link-click-pre="onPaginationClickPre"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row align-center">
        <div class="column shrink">
          <button class="button tiny mbn" @click="close">
            Submit
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../components/shared/Modal/Modal.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    Modal,
    Pagination,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    canSelect: {
      type: Boolean,
      required: true,
    },
    canSelectAll: {
      type: Boolean,
      required: true,
    },
    canDeSelectAll: {
      type: Boolean,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    apiPostObject: {
      type: Object,
      required: true,
    },
    designs: {
      type: Array,
      required: true,
    },
    designTypeOptions: {
      type: Array,
      required: true,
    },
    briefOptions: {
      type: Array,
      required: true,
    },
    selectedDesignIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterDesignTypeDisplayName: null,
      filterBriefId: null,
      paginating: false
    };
  },
  computed: {
    isLoading() {
      return this.paginating || !this.designs || !this.designs.length;
    },
  },
  watch: {
    designs() {
      this.paginating = false;
    },
    filterDesignTypeDisplayName(newValue) {
      this.$emit('update-filter', {
        briefId: this.filterBriefId,
        designTypeDisplayName: newValue,
      });
    },
    filterBriefId(newValue) {
      this.$emit('update-filter', {
        briefId: parseInt(newValue, 10),
        designTypeDisplayName: this.filterDesignTypeDisplayName,
      });
    },
  },
  methods: {
    onPaginationClick(e) {
      this.$emit('on-pagination-click', e);
    },
    onPaginationClickPre() {
      this.paginating = true;
    },
    selectDesign(designId) {
      this.$emit('select-design', designId);
    },
    deSelectDesign(designId) {
      this.$emit('de-select-design', designId);
    },
    selectAll() {
      this.$emit('select-this-page');
    },
    deSelectAll() {
      this.$emit('de-select-all');
    },
    close() {
      this.$emit('closed');
    },
  },
};
</script>
