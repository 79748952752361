<template>
  <form action="" method="post" class="account-settings" @submit.prevent>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Display name</strong>
          <input
            v-model="displayNameValue"
            class="mts"
            type="text"
            name="displayName"
            maxlength="50"
            :disabled="form.disabled"
          />
        </label>
        <div v-if="form.errors.profileDisplayName" class="small-12">
          <ul
            v-for="(error, index) in form.errors.profileDisplayName"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Website</strong>
          <input
            v-model="websiteValue"
            class="mts"
            type="text"
            name="website"
            maxlength="200"
            :disabled="form.disabled"
          />
        </label>
        <div v-if="form.errors.website" class="small-12">
          <ul
            v-for="(error, index) in form.errors.website"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="column small-12 medium-12">
        <label>
          <strong class="text-black"
            >About You / Your Design/Creative Style</strong
          >
          <textarea
            v-model="aboutValue"
            class="full-width mts"
            name="about"
            rows="3"
            :disabled="form.disabled"
          />
        </label>
      </div>
      <div class="column small-12 medium-12 mbm">
        <label>
          <strong class="text-black">Availability for Work</strong>
          <p>
            {{ unPrettierNuggets.availabilityCopy }}
          </p>
        </label>
        <div class="flex-container">
          <label class="mrl">
            <input
              v-model="availability"
              type="radio"
              class="input-type--radio"
              name="availability"
              checked="checked"
              value="Yes"
              :disabled="form.disabled"
            />
            <span class="text-medium">Yes, offer me work</span>
          </label>
          <label class="mrl">
            <input
              v-model="availability"
              type="radio"
              class="input-type--radio"
              name="availability"
              checked="checked"
              value="No"
              :disabled="form.disabled"
            />
            <span class="text-medium">No, I'm not available</span>
          </label>
        </div>
      </div>
      <div class="column small-12 medium-12 mbm">
        <label>
          <strong class="text-black">Default Design Visibility</strong>
          <p>
            {{ unPrettierNuggets.defaultDesignVisibilityCopy }}
          </p>
        </label>
        <div class="flex-container">
          <label class="mrl">
            <input
              v-model="defaultDesignVisibility"
              type="radio"
              class="input-type--radio"
              name="defaultDesignVisibility"
              checked="checked"
              value="public"
              :disabled="form.disabled"
            />
            <span class="text-medium">Show new designs</span>
          </label>
          <label class="mrl">
            <input
              v-model="defaultDesignVisibility"
              type="radio"
              class="input-type--radio"
              name="defaultDesignVisibility"
              checked="checked"
              value="private"
              :disabled="form.disabled"
            />
            <span class="text-medium">Hide new designs</span>
          </label>
        </div>
      </div>
      <div class="column small-12 medium-12 mbm">
        <label>
          <strong class="text-black">
            {{ unPrettierNuggets.languageCopy }}
          </strong>
        </label>
        <div class="row-300 mts">
          <label
            v-for="(language, index) in publicProfile.supportedLanguages"
            :key="index"
            class="mrl mbs"
          >
            <input
              v-model="languages"
              type="checkbox"
              class="input-type--checkbox"
              :checked="language.value"
              :value="language.key"
            />
            <span class="text-medium">{{ language.key }}</span>
          </label>
        </div>
      </div>
      <div class="column small-12 medium-12 mbm">
        <label>
          <strong class="text-black">
            Select a few (max 12) tags to describe your skill set
          </strong>
        </label>
        <div class="grid-3-columns">
          <label
            v-for="(skill, index) in publicProfile.skills"
            :key="index"
            class="mrl"
          >
            <input
              v-model="skillSet"
              type="checkbox"
              class="input-type--checkbox"
              :checked="skill.selected"
              :value="skill.value"
            />
            <span class="text-medium">
              {{ skill.displayName }}
            </span>
          </label>
          <div v-if="form.errors.skills" class="small-12">
            <ul
              v-for="(error, index) in form.errors.skills"
              :key="index"
              class="text-color-red text-medium"
            >
              <li>{{ error }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="column small-12 medium-3 mbl">
        <label>
          <strong class="text-black">Profile Picture</strong>
        </label>
        <img
          width="100%"
          :src="profileThumbnailUrl"
          alt="Profile picture"
          class="mtm"
        />
      </div>
      <div class="column small-12 medium-9 mbm">
        <label>
          <strong class="text-black">Change Picture</strong>
          <p>{{ unPrettierNuggets.changePicturePixelRange }}</p>
          <FileUploader
            :options="profileImageUploader"
            :icons="icons"
          ></FileUploader>
        </label>
        <div v-if="form.errors.profileImage" class="small-12">
          <ul
            v-for="(error, index) in form.errors.profileImage"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="column text-center">
        <button class="button mbn" :disabled="form.disabled" @click="save">
          Update details
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import FileUploader from '../../../shared/FileUploader/FileUploader.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: { FileUploader },
  props: {
    publicProfile: {
      type: Object,
      required: true,
    },
    profileImageUploader: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    saveEndpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    const errorDefault = {
      skills: [],
      profile: [],
    };

    const original = {
      languages: this.publicProfile.supportedLanguages
        .filter((x) => x.value)
        .map((x) => x.key),
      skillSet: this.publicProfile.skills
        .filter((x) => x.selected)
        .map((x) => x.value),
    };
    return {
      displayNameValue: this.publicProfile.profileDisplayName,
      aboutValue: this.publicProfile.about,
      websiteValue: this.publicProfile.websiteUrl,
      availability: this.publicProfile.isAvailable ? 'Yes' : 'No',
      defaultDesignVisibility: this.publicProfile.defaultDesignVisibility,
      skillSet: original.skillSet,
      languages: original.languages,
      files: {
        profileImage: [],
      },
      form: {
        disabled: false,
        errorDefault,
        errors: errorDefault,
      },
      unPrettierNuggets: {
        changePicturePixelRange: `Click below to change your profile picture (${this.profileImageUploader.PixelLimits.MaxWidth}px by ${this.profileImageUploader.PixelLimits.MaxHeight}px)`,
        defaultDesignVisibilityCopy: `Shows or hides new designs on your portfolio as you submit them to contests (must have min of 20 designs shown)`,
        languageCopy: `Help us find you more jobs by telling us what languages you speak`,
        availabilityCopy: `When turned on, customers can offer design / creative work to you or invite you to their projects.`,
      },
      original,
      profileThumbnailUrl: this.publicProfile.profileThumbnailUrl,
    };
  },
  watch: {},
  methods: {
    async save() {
      this.form.disabled = true;

      const profileImageJson = document.querySelector(
        `[data-upload-id='${this.profileImageUploader.Id}'] input[name='filesJson']`,
      ).value;
      const profileImages = JSON.parse(profileImageJson);
      let profileImage = null;
      if (profileImages.length) [profileImage] = profileImages;

      const data = {
        ProfileImgUpload: profileImage,
        ProfileDisplayName:
          this.publicProfile.profileDisplayName === this.displayNameValue
            ? null
            : this.displayNameValue,
        About:
          this.publicProfile.about === this.aboutValue
            ? null
            : this.aboutValue,
        WebsiteUrl:
          this.publicProfile.websiteUrl === this.websiteValue
            ? null
            : this.websiteValue,
        IsAvailable:
          this.publicProfile.isAvailable === (this.availability === 'Yes')
            ? null
            : this.availability === 'Yes',
        DefaultDesignVisibility:
          this.publicProfile.defaultDesignVisibility === this.defaultDesignVisibility
            ? null
            : this.defaultDesignVisibility,
        SupportedLanguages:
          this.original.languages.toString() === this.languages.toString()
            ? null
            : this.languages,
        Skills:
          this.original.skillSet.toString() === this.skillSet.toString()
            ? null
            : this.skillSet,
      };

      const res = await axios.post(this.saveEndpoint, data, axiosConfig);
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };

      if (res.data.errors) {
        this.$emit('show-api-success-message', null);
      } else if (res.data.errorMessage) {
        this.$emit('show-api-success-message', null);
        this.$emit('show-api-error-messages', [res.data.errorMessage]);
      } else {
        this.$emit('show-api-success-message', res.data.successMessage);
        this.$emit('update-profile-details', data);
        if ((res.data.data||{}).profileThumbnailUrl) {
          this.profileThumbnailUrl = res.data.data.profileThumbnailUrl;
        }
      }
      this.form.disabled = false;
    },
  },
};
</script>
