<template>
  <!-- Desktop view -->
  <tbody>
    <tr class="show-for-medium">
      <td class="u-vertically-align-top phm">{{ briefId }}</td>
      <td class="u-vertically-align-top prm">
        <PrintOrderTableRowItems
          :name="items[0].name"
          :details="items[0].details"
          :arrow-down-icon-url="arrowDownIconUrl"
          :arrow-up-icon-url="arrowUpIconUrl"
        />
      </td>
      <td class="u-vertically-align-top prm">
        {{ price }}
      </td>
      <td class="u-vertically-align-top prm">
        {{ shipping }}
      </td>
      <td class="u-vertically-align-top prm">
        <span v-if="status" :class="{ [`label ${statusClass}`]: true }">
          {{ status }}
        </span>
      </td>
      <td class="u-vertically-align-top prm">
        <a
          v-if="items[0].reorderUrl"
          class="button primary tiny mbn"
          :href="items[0].reorderUrl"
        >
          Re-order
        </a>
      </td>
    </tr>
    <tr
      v-for="(item, index) in orderLineItems"
      :key="index"
      class="show-for-medium"
    >
      <td class="phm"></td>
      <td class="prm">
        <PrintOrderTableRowItems
          :name="item.name"
          :details="item.details"
          :arrow-down-icon-url="arrowDownIconUrl"
          :arrow-up-icon-url="arrowUpIconUrl"
        />
      </td>
      <td class="prm" colspan="4"></td>
    </tr>
    <!-- Mobile view -->
    <tr class="hide-for-medium">
      <td><strong>Brief ID</strong></td>
      <td>{{ briefId }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td class="u-vertically-align-top"><strong>Items</strong></td>
      <td>
        <PrintOrderTableRowItems
          v-for="(item, index) in orderLineItems"
          :key="index"
          :name="item.name"
          :details="item.details"
          :arrow-down-icon-url="arrowDownIconUrl"
          :arrow-up-icon-url="arrowUpIconUrl"
        />
      </td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong>Order Total</strong></td>
      <td>{{ price }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong>Shipping</strong></td>
      <td>{{ shipping }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong>Status</strong></td>
      <td>{{ status }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong>Action</strong></td>
      <td>
        <a
          v-if="items[0].reorderUrl"
          class="button primary tiny mbn"
          :href="items[0].reorderUrl"
        >
          Re-order
        </a>
      </td>
    </tr>
  </tbody>
</template>

<script>
import PrintOrderTableRowItems from '../PrintOrderTableRowItems/PrintOrderTableRowItems.vue';

export default {
  components: {
    PrintOrderTableRowItems,
  },
  props: {
    briefId: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    price: {
      type: String,
      required: true,
    },
    shipping: {
      type: String,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isInProgress: {
      type: Boolean,
      required: true,
    },
    isRefunded: {
      type: Boolean,
      required: true,
    },
    isAwaitingDesignApproval: {
      type: Boolean,
      required: true,
    },
    arrowDownIconUrl: {
      type: String,
      required: true,
    },
    arrowUpIconUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      status: '',
      statusClass: '',
      isDetailsVisible: false,
    };
  },
  computed: {
    orderLineItems() {
      return this.items.slice(1);
    },
  },
  mounted() {
    this.setStatus();
  },
  methods: {
    setStatus() {
      if (this.isCompleted) {
        this.status = 'COMPLETED';
        this.statusClass = 'success';
      } else if (this.isInProgress) {
        this.status = 'IN PROGRESS';
        this.statusClass = 'primary';
      } else if (this.isRefunded) {
        this.status = 'REFUNDED';
        this.statusClass = 'warning';
      } else if (this.isAwaitingDesignApproval) {
        this.status = 'AWAITING DESIGN APPROVAL';
        this.statusClass = 'warning';
      } else {
        this.status = '';
      }
    },
  },
};
</script>
