import * as types from './designs-mutation-types';

export default {
  [types.SET_DESIGNS]: (state, designs) => {
    state.designs = designs;
  },
  [types.SET_SHOW_FILTERS]: (state, showFilters) => {
    state.showFilters = showFilters;
  },
  [types.RESET_FILTERS]: (state) => {
    state.filteredDesigns = [];
    state.filteredDesigns = state.designs;
    state.ratingsFilter = [];
    state.designersFilter = [];
    state.feedbackFilter = null;
    state.orderFilter = null;
    state.eliminatedFilter = null;
  },
  [types.RESET_FILTERED_DESIGNS]: (state) => {
    state.filteredDesigns = [];
    state.filteredDesigns = state.designs;
  },
  [types.ADD_TO_RATINGS_FILTER]: (state, rating) => {
    state.ratingsFilter.push(rating);
  },
  [types.REMOVE_FROM_RATINGS_FILTER]: (state, rating) => {
    const indexOfRating = state.ratingsFilter.indexOf(rating);
    state.ratingsFilter.splice(indexOfRating, 1);
  },
  [types.FILTER_BY_RATINGS]: (state, designs) => {
    const designsToBeFiltered = designs || state.designs;
    state.filteredDesigns = designsToBeFiltered.filter(
      (design) => state.ratingsFilter.indexOf(design.StarRating) > -1,
    );
  },
  [types.ADD_TO_DESIGNERS_FILTER]: (state, designer) => {
    state.designersFilter.push(designer);
  },
  [types.REMOVE_FROM_DESIGNERS_FILTER]: (state, designer) => {
    const indexOfDesigner = state.designersFilter.indexOf(designer);
    state.designersFilter.splice(indexOfDesigner, 1);
  },
  [types.FILTER_BY_DESIGNERS]: (state, designs) => {
    const designsToBeFiltered = designs || state.designs;
    state.filteredDesigns = designsToBeFiltered.filter(
      (design) =>
        state.designersFilter.indexOf(design.DesignerProfileDisplayName) > -1,
    );
  },
  [types.SET_FEEDBACK_FILTER]: (state, feedback) => {
    state.feedbackFilter = feedback;
  },
  [types.SET_ORDER_FILTER]: (state, order) => {
    state.orderFilter = order;
  },
  [types.SET_ELIMINATED_FILTER]: (state, eliminated) => {
    state.eliminatedFilter = eliminated;
  },
  [types.FILTER_BY_FEEDBACK]: (state, designs) => {
    const designsToBeFiltered = designs || state.designs;
    switch (state.feedbackFilter) {
      case 0:
        state.filteredDesigns = designsToBeFiltered.filter(
          (design) => design.FeedbackCount === 0,
        );
        break;
      case 1:
        state.filteredDesigns = designsToBeFiltered.filter(
          (design) => design.FeedbackCount > 0,
        );
        break;
      case null:
      default:
        break;
    }
  },
  [types.FILTER_BY_ORDER]: (state, designs) => {
    switch (state.orderFilter) {
      case 'rating-desc':
        designs.sort((designA, designB) => {
          if (designA.StarRating > designB.StarRating) {
            return -1;
          }
          if (designA.StarRating < designB.StarRating) {
            return 1;
          }
          if (designA.SubmissionTime > designB.SubmissionTime) {
            return -1;
          }
          if (designA.SubmissionTime < designB.SubmissionTime) {
            return 1;
          }

          return 0;
        });
        break;
      case 'designer-name-asc':
        designs.sort((designA, designB) => {
          if (
            designA.DesignerProfileDisplayName <
            designB.DesignerProfileDisplayName
          ) {
            return -1;
          }
          if (
            designA.DesignerProfileDisplayName >
            designB.DesignerProfileDisplayName
          ) {
            return 1;
          }
          if (designA.SubmissionTime > designB.SubmissionTime) {
            return -1;
          }
          if (designA.SubmissionTime < designB.SubmissionTime) {
            return 1;
          }
          return 0;
        });
        break;
      case null:
      default:
        designs.sort((designA, designB) => {
          if (designA.SubmissionTime > designB.SubmissionTime) {
            return -1;
          }
          if (designA.SubmissionTime < designB.SubmissionTime) {
            return 1;
          }
          return 0;
        });
        break;
    }
    state.filteredDesigns = designs;
  },
  [types.FILTER_BY_ELIMINATED]: (state, designs) => {
    const designsToBeFiltered = designs || state.designs;
    switch (state.eliminatedFilter) {
      case 'show-all':
        state.filteredDesigns = designsToBeFiltered;
        break;
      case 'only-eliminated':
        state.filteredDesigns = designsToBeFiltered.filter(
          (design) => design.IsEliminated || design.IsWithdrawn,
        );
        break;
      case 'hide-eliminated':
        state.filteredDesigns = designsToBeFiltered.filter(
          (design) => !design.IsEliminated && !design.IsWithdrawn,
        );
        break;
      default:
        state.filteredDesigns = designsToBeFiltered.filter(
          (design) => !design.IsEliminated,
        );
        break;
    }
  },
  [types.ADD_TO_SHARED_DESIGNS]: (state, design) => {
    const designToBeAdded = state.designs.find(
      (d) => d.DesignId === design.DesignId,
    );
    state.sharedDesigns.push(designToBeAdded);
  },
  [types.REMOVE_FROM_SHARED_DESIGNS]: (state, design) => {
    state.sharedDesigns = state.sharedDesigns.filter(
      (d) => d.DesignId !== design.DesignId,
    );
  },
  [types.REMOVE_ALL_SHARED_DESIGNS]: (state) => {
    state.sharedDesigns = [];
  },
};
