<template>
  <!-- This component was copied from BrandCrowd.Net project then modified -->
  <div class="notice notice--flex">
    <div
      v-show="!isClosed"
      class="notice__overlay"
      @click="closeNoticeTooltip"
    />
    <div
      :ref="noticeTooltipRef"
      :class="{
        ['notice__tooltip']: true,
        ['notice__tooltip--hollow']: true,
        ['notice__tooltip--float-arrow']: floatArrow,
        [`notice__tooltip--arrow-${arrowSize}`]: arrowSize,
        [`notice__tooltip--${size}`]: size && !isSmall,
        [`align-${alignment}`]: true,
        [color]: true,
        [direction]: true,
        ['hide']: isClosed,
        ['eliminate-feedback']: eliminate,
        ['feedback-modal']: feedback,
      }"
      @click="onClickNoticeToolTip"
    >
      <div class="row">
        <div class="column">
          <slot name="tooltip" />
        </div>
      </div>
    </div>
    <slot name="target" />
  </div>
</template>

<script>
import MediaQuery from '../../../helpers/mediaquery';

export default {
  props: {
    // Color can be primary (blue), secondary (dark gray), warning (yellow), alert (red)
    // or success (green)
    color: {
      type: String,
      default: 'primary',
    },
    // Determines the size of the tooltip arrow. Can be regular or small.
    // Defaults to regular if left undefined
    arrowSize: {
      type: String,
      default: undefined,
    },
    floatArrow: {
      type: Boolean,
      default: true,
    },
    // The placement of the tooltip. Can be top, right, bottom or left
    direction: {
      type: String,
      default: 'top',
    },
    // The alignment of the arrow. Can be top, right, bottom, left or center
    alignment: {
      type: String,
      default: 'center',
    },
    size: {
      type: String,
      default: undefined,
    },
    // Whether the notice can be dismissed or not
    closable: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: false,
    },
    eliminate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isClosed: !this.isVisible,
      isSmall: false,
      MediaQuery,
    };
  },
  computed: {
    noticeTooltipRef() {
      // eslint-disable-next-line no-underscore-dangle
      return `js-slot-target-${this._uid}`;
    },
  },
  watch: {
    isVisible(visible) {
      this.isClosed = !visible;
    },
    isClosed() {
      this.isSmall = this.MediaQuery.isSmall();
    },
  },
  methods: {
    onClickNoticeToolTip() {
      if (this.closable && this.isSmall) {
        this.isClosed = true;
      }
    },
    closeNoticeTooltip() {
      this.isClosed = true;
      this.$emit('closed');
    },
  },
};
</script>
