<template>
  <div>
    <MasonryGrid
      v-if="isGridView"
      v-slot="{ item }"
      classes="row-300"
      :items="gridItems"
      image-to-load-data-id="data-design-image-thumbnail"
      :img-placeholder="imgPlaceholderUrl"
      wait-for-images-to-load
      @on-loading-complete="loadingComplete"
    >
      <slot :item="item" />
    </MasonryGrid>
    <div v-for="(items, index) in listItems" v-else :key="index">
      <div class="flex-container">
        <strong class="text-black">{{ index }}</strong>
        <DesignerOption
          v-if="isDesigners"
          :ellipsis-icon-url="ellipsisIconUrl"
          @eliminate-designer="onEliminateDesigner(items[0].DesignerId)"
        />
      </div>
      <span class="horizontal-rule horizontal-rule--full-width">
        <hr />
      </span>
      <MasonryGrid
        v-if="!isGridView"
        v-slot="{ item }"
        classes="row-300"
        :items="items"
        image-to-load-data-id="data-design-image-thumbnail"
        :img-placeholder="imgPlaceholderUrl"
        wait-for-images-to-load
        @on-loading-complete="loadingComplete"
      >
        <slot :item="item" />
      </MasonryGrid>
    </div>
  </div>
</template>

<script>
import MasonryGrid from '../../../shared/MasonryGrid/MasonryGrid.vue';
import DesignerOption from '../../../shared/DesignerOption/DesignerOption.vue';

export default {
  components: {
    MasonryGrid,
    DesignerOption,
  },
  props: {
    isGridView: {
      type: Boolean,
      default: true,
    },
    isDesigners: {
      type: Boolean,
      default: false,
    },
    gridItems: {
      type: Array,
      default: () => [],
    },
    listItems: {
      type: Object,
      default: () => {},
    },
    imgPlaceholder: {
      type: String,
      default: undefined,
    },
    imgPlaceholderUrl: {
      type: String,
      default: undefined,
    },
    ellipsisIconUrl: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    loadingComplete() {
      this.$emit('loading-complete');
    },
    onEliminateDesigner(designerId) {
      this.$emit('eliminate-designer', designerId);
    },
  },
};
</script>
