<template>
  <div>
    <ModalV2
      title="Pause your subscription"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="isPauseVisible"
      :hide-close-button="true"
    >
      <template #body>
        <div class="text-center">
          <img alt="An image" :src="icons.commonLargePause" />
          <p class="text-large">
            {{ unPrettierNuggets.pausePeriodSwitchCopy }}
          </p>
          <div class="btn-container mbm">
            <label class="switch btn-color-mode-switch">
              <input
                id="color_mode"
                v-model="switchOn"
                type="checkbox"
                name="color_mode"
                :disabled="true"
              />
              <label
                for="color_mode"
                data-on="1 Month"
                data-off="3 Months "
                class="btn-color-mode-switch--inner"
                @click="if (processing) return; switchOn = !switchOn;"
              ></label>
            </label>
          </div>
          <div
            v-for="(pointCopy, index) in unPrettierNuggets.getPoints(
              subscription,
              parseInt(period),
            )"
            :key="index"
            class="text-left text-large pbs"
          >
            <p>
              <img
                alt="An image"
                :src="icons.commonSmallCheckBlue"
                class="prm"
              />
              <span v-html="pointCopy"></span>
            </p>
          </div>
          <p class="text-medium pts">
            {{ unPrettierNuggets.ifYouCancelCopy(subscription) }}
          </p>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div v-if="!standalone" class="column shrink">
              <button class="mbn button tiny secondary hollow" @click="next">
                Continue Cancelling
              </button>
            </div>
            <div v-else class="column shrink">
              <button
                class="mbs button tiny secondary hollow"
                :disabled="processing"
                @click="close"
              >
                Cancel
              </button>
            </div>
            <div class="column shrink">
              <button
                type="button"
                class="button tiny prm plm mbn"
                :disabled="processing"
                @click="pause"
                v-html="unPrettierNuggets.getPauseButtonCopy(period)"
              ></button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
    <ModalV2
      title="Subscription Paused"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="isPausedVisible"
      :hide-close-button="true"
    >
      <template #body>
        <div>
          <div class="text-center text-medium">
            <img alt="An image" :src="icons.commonLargeCancel" />
            <p
              v-html="
                unPrettierNuggets.subscriptionPaused(subscription, period)
              "
            ></p>
            <p v-html="unPrettierNuggets.unPauseCopy"></p>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div class="column shrink">
              <button
                :disabled="processing"
                class="mbs button tiny secondary hollow prm plm"
                @click="reload"
              >
                No thanks, maybe later
              </button>
            </div>
            <div class="column shrink">
              <button
                :disabled="processing"
                type="button"
                class="button tiny prm plm mbs"
                @click="unPauseSubscription"
              >
                Un-pause
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
    <ModalV2
      title="Un-Paused"
      :close-icon="null"
      :close-on-click-outside="false"
      :visible="isUnPausedVisible"
      :hide-close-button="true"
    >
      <template #body>
        <div>
          <div class="text-center text-medium">
            <img alt="An image" :src="icons.commonLargeAnnualPremium" />
            <p
              v-html="unPrettierNuggets.subscriptionUnPaused(subscription)"
            ></p>
          </div>
        </div>
      </template>
      <template #footer>
        <div>
          <div class="row align-center">
            <div class="column shrink">
              <button
                class="mbs button tiny secondary hollow prm plm"
                type="button"
                @click="reload"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </template>
    </ModalV2>
  </div>
</template>

<script>
import axios from 'axios';
import ModalV2 from '../../../../shared/Modal/ModalV2.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    ModalV2,
  },
  props: {
    cancelSubscriptionFlow: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    standalone: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isPauseVisible: this.visible,
      isPausedVisible: false,
      isUnPausedVisible: false,
      processing: false,
      switchOn: true,
      unPrettierNuggets: {
        subscriptionPaused: (subscription, period) => {
          return `Your subscription will be paused on <strong>${subscription.renewalDateCopy}</strong> and restart on <strong>${subscription.resumeDateCopy[period]}</strong>`;
        },
        getPoints: (subscription, period) => {
          return [
            this.unPrettierNuggets.subscriptionPaused(subscription, period),
            `You will not be charged for ${period} months`,
            `You can resubscribe or cancel at any time`,
          ];
        },
        ifYouCancelCopy: (subscription) => {
          return `If you cancel your subscription, after ${subscription.renewalDateCopy} you will lose all your premium benefits`;
        },
        subscriptionUnPaused: (subscription) => {
          return `Your <strong>${subscription.subscriptionFor}</strong> subscription has been un-paused. ${subscription.autoRenewalCopy}`;
        },
        unPauseCopy: `If you didn't mean to pause, or would like to un-pause, you can quickly and easily do so by clicking below.`,
        getPauseButtonCopy: (period) => {
          return `Pause for <strong>${period}</strong> months`;
        },
        subscriptionPeriodSwitchCopy: `Don't need access to your subscription right now? You can pause instead of cancelling.`,
      },
      reloadUrl: null,
    };
  },
  computed: {
    period() {
      return this.switchOn ? 3 : 1;
    },
  },
  watch: {
    visible(newValue) {
      this.isPauseVisible = newValue;
    },
  },
  methods: {
    async pause(e) {
      e.preventDefault();

      this.processing = true;

      const data = {
        subscriptionId: this.subscription.subscriptionId,
        months: this.period,
      };
      const res = await axios
        .post(
          this.cancelSubscriptionFlow.pauseSubscriptionApi,
          data,
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        this.close();
        this.isPauseVisible = false;
        this.isPausedVisible = true;
        this.processing = false;
        this.reloadUrl = res.data.redirectUrl;
      }
    },
    async unPauseSubscription() {
      this.processing = true;

      const res = await axios
        .post(
          this.cancelSubscriptionFlow.unPauseSubscriptionApi,
          {
            subscriptionId: this.subscription.subscriptionId,
          },
          axiosConfig,
        )
        .catch(function (error) {
          if (error.response.data && error.response.data.redirectUrl) {
            window.location.href = error.response.data.redirectUrl;
          }
        });

      if (res.status === 200) {
        this.close();
        this.isPausedVisible = false;
        this.isUnPausedVisible = true;
        this.processing = false;
        this.reloadUrl = res.data.redirectUrl;
      }
    },
    async reload() {
      this.processing = true;
      window.location.href = this.reloadUrl;
    },
    close() {
      this.$emit('close');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
