import mutations from './designs-mutations';
import actions from './designs-actions';
import getters from './designs-getters';

export default {
  namespaced: true,
  state: () => {
    return {
      designs: [],
      filteredDesigns: [],
      ratingsFilter: [],
      designersFilter: [],
      feedbackFilter: null,
      showFilters: undefined,
      sharedDesigns: [],
      eliminatedFilter: null,
    };
  },
  mutations,
  actions,
  getters,
};
