<template>
  <div class="row">
    <div class="column small-12">
      <a :href="backToMessagesLinkUrl" class="back-link">
        Back to all messages
      </a>
    </div>
    <div class="column small-12 large-3">
      <ThreadDetails
        :project-name="overview.projectName"
        :project-name-url="overview.jobUrl"
        :subject="overview.subject"
        :customer-profile-pic="overview.customerProfilePic"
        :customer-display-name="overview.customerDisplayName"
      />
    </div>
    <div class="column small-12 large-9">
      <div class="row">
        <div class="column small-12">
          <textarea
            v-model="message"
            class="full-width"
            name="message"
            rows="5"
            placeholder="Type a message..."
            autocomplete="off"
            required=""
          >
          </textarea>
          <Callout
            :type="'warning'"
            :title="'Warning: Soliciting work off DesignCrowd is against our terms and conditions'"
            :icon-url="icons[`warningIcon`]"
            closable
          >
            <template #message>
              {{ unPrettierNuggets.solicitationWarningTitle }}
              <ul>
                <li>
                  Exposes you to risk of not getting paid for work you do
                </li>
                <li>No secure file transfer mechanism</li>
                <li>No dispute resolution</li>
                <li>Risk of getting banned from DesignCrowd</li>
              </ul>
            </template>
          </Callout>
        </div>
        <div class="column text-right">
          <button
            :class="{
              'button secondary hollow small mbn': true,
              'button-processing': false,
              disabled: !message,
            }"
            @click="onSubmitReply"
          >
            Post Message
            <img :src="icons.processing" alt="Processing icon" />
          </button>
        </div>
      </div>
      <Thread
        v-for="(msg, index) in currentPage"
        :key="index"
        :content="msg.content"
        :message-id="msg.messageId"
        :from-display-name="msg.fromDisplayName"
        :from-profile-pic-thumbnail-location="
          msg.fromProfilePicThumbnailLocation
        "
        :message-date-text="msg.messageDateText"
        :porfolio-link="getPortfolioLinkUrl(msg)"
      />
      <Pagination
        class="mtm"
        :max-page="pagination.maxPage"
        :current="pagination.current"
        :start="pagination.start"
        :end="pagination.end"
        :start-of-page="pagination.startOfPage"
        :number-of-results="pagination.numberOfResults"
        :total-results-count="pagination.totalResultsCount"
        :description="pagination.description"
        :base-url="pagination.baseUrl"
        :api-base-url="pagination.apiBaseUrl"
        :left-arrow-icon-url="icons.paginationLeftArrow"
        :right-arrow-icon-url="icons.paginationRightArrow"
        @page-link-click="onPaginationClick"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ThreadDetails from '../../components/my-account/designer/view-message/ThreadDetails/ThreadDetails.vue';
import Thread from '../../components/my-account/designer/view-message/Thread/Thread.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';
import LOCAL_STORAGE_MESSAGES_PAGE_NO from '../../constants/constants';
import Callout from '../../components/shared/Callout/Callout.vue';

export default {
  components: {
    ThreadDetails,
    Thread,
    Pagination,
    Callout,
  },
  props: {
    icons: {
      type: Object,
      default: undefined,
    },
    overview: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    backToMessagesUrl: {
      type: String,
      default: undefined,
    },
    replyEndpoint: {
      type: String,
      default: undefined,
    },
    customerId: {
      type: Number,
      required: true,
    },
    designId: {
      type: Number,
      default: undefined,
    },
    threadId: {
      type: Number,
      required: true,
    },
    replyToMessageId: {
      type: Number,
      required: true,
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.messages,
      pagination: this.paginationObject,
      message: '',
      unPrettierNuggets: {
        solicitationWarningTitle: `Just a friendly reminder, that soliciting work off DesignCrowd comes with risks. Do not send offsite contact details to clients. Why?`,
      },
    };
  },
  computed: {
    backToMessagesLinkUrl() {
      const pageNo = localStorage.getItem(LOCAL_STORAGE_MESSAGES_PAGE_NO);
      return `${this.backToMessagesUrl}?page=${pageNo}`;
    },
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
    getPortfolioLinkUrl(thread) {
      return thread?.portfolioLink?.linkUrl;
    },
    async onSubmitReply() {
      if (this.message) {
        const res = await axios.post(this.replyEndpoint, {
          customerId: this.customerId,
          designId: this.designId,
          threadId: this.threadId,
          messageId: this.replyToMessageId,
          message: this.message,
        });

        if (res) {
          const messages = await axios.get(this.pagination.apiBaseUrl, {
            params: {
              page: this.pagination.current,
            },
          });

          if (messages) {
            this.currentPage = messages.data.items;
            this.message = '';
          }
        }
      }
    },
  },
};
</script>
