<template>
  <div class="row column">
    <div
      class="user-profile user-profile--designer-portfolio user-profile--large ptm pbm"
    >
      <div class="user-profile__status mbn">
        <div class="row align-center">
          <div class="column shrink">
            <div class="user-profile__image">
              <img :src="model.thumb" alt="profile picture" />
            </div>
          </div>
          <div
            class="column small-12 large-expand align-self-middle text-left large-text-left"
          >
            <div class="row">
              <div
                class="column small-12 large-4 flex-container align-middle align-left user-profile__designer-name"
              >
                <h3 style="overflow-wrap: anywhere">{{ model.name }}</h3>
              </div>
              <div class="column">
                <div class="user-profile__stats">
                  <div class="row flex-container align-spaced text-left">
                    <div
                      class="column small-12 medium-shrink user-profile__overview-stats"
                    >
                      <div class="row align-left">
                        <div class="column">
                          <p class="p-fine-print">
                            <strong>RANK</strong>
                          </p>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column medium-shrink">
                          <h3 class="mbn">
                            {{ model.rank }}
                          </h3>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column">
                          <p class="p-fine-print" v-html="rankMessage"></p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column small-12 medium-shrink user-profile__overview-stats"
                    >
                      <div class="row align-left">
                        <div class="column">
                          <p class="p-fine-print">
                            <strong>POSITIVE</strong>
                          </p>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column medium-shrink">
                          <h3 class="mbn">{{ model.percentage }}%</h3>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column">
                          <StarRatingDisplay
                            :rating="parseInt(rating)"
                            size="small"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="column small-12 medium-shrink user-profile__overview-stats"
                    >
                      <div class="row align-left">
                        <div class="column">
                          <p class="p-fine-print">
                            <strong>SUBMISSIONS</strong>
                          </p>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column medium-shrink">
                          <h3 class="mbn">
                            {{ model.submissions }}
                          </h3>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column">
                          <p
                            class="p-fine-print"
                            v-html="siteAvgMessage(model.avgSubmissions)"
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column small-12 medium-shrink user-profile__overview-stats"
                    >
                      <div class="row align-left">
                        <div class="column">
                          <p class="p-fine-print">
                            <strong>EARNINGS</strong>
                          </p>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column medium-shrink">
                          <h3 class="mbn">
                            {{ model.earnings }}
                          </h3>
                        </div>
                      </div>
                      <div class="row align-left">
                        <div class="column">
                          <p
                            class="p-fine-print"
                            v-html="siteAvgMessage(model.avgDesignerEarnings)"
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRatingDisplay from '../../../../shared/StarRatingDisplay/StarRatingDisplay.vue';

export default {
  components: {
    StarRatingDisplay,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rating:
        this.model.percentage === 0 ? 0 : (this.model.percentage / 100) * 5,
    };
  },
  computed: {
    rankMessage() {
      return `of ${this.model.designerCount} designers`;
    },
  },
  methods: {
    siteAvgMessage(value) {
      return `Site average: ${value}`;
    },
  },
};
</script>
