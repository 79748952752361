<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="design-page-container">
    <div :ref="topOfResultsRef" class="row-300 small-collapse">
      <div v-if="showFilters" class="column small-12">
        <DesignsFilters
          ref="DesignsFilter"
          design-rating-key="StarRating"
          designer-name-key="DesignerProfileDisplayName"
          design-feedback-count-key="FeedbackCount"
          :star-filled-icon-url="icons.starFilled"
          :star-hollow-icon-url="icons.starHollow"
          :grid-icon-url="icons.grid"
          :grid-white-icon-url="icons.gridWhite"
          :list-icon-url="icons.list"
          :list-white-icon-url="icons.listWhite"
          @rated-designs-selected="onFilterDesignsByRatingCategory"
          @rating-selected="onFilterDesignsByRatings"
          @designer-selected="onFilterDesignsByDesignerNames"
          @feedback-filter-option-selected="onFilterByFeedback"
          @order-filter-option-selected="onFilterByOrder"
          @eliminated-filter-option-selected="onFilterDesignsByEliminated"
          @reset-filters="initialiseDesignResults(pageNo)"
          @list-view-selected="displayListView"
          @grid-view-selected="isGridView = true"
        />
      </div>
      <div
        v-if="
          currentPageOfDesigns.length > 0 && currentPage <= pagination.maxPage
        "
        class="column small-12"
      >
        <div class="maker-spinner-container">
          <div v-if="isLoadingDesigns" class="maker-spinner">
            <div class="maker-spinner__icon" />
          </div>
          <DesignResults
            v-slot="{ item }"
            :is-grid-view="isGridView"
            :is-designers="isDesigners"
            :grid-items="currentPageOfDesigns"
            :list-items="listViewItems"
            :img-placeholder-url="icons.emptyDesign"
            :ellipsis-icon-url="icons.ellipsisIcon"
            @eliminate-designer="onEliminateDesigner"
            @loading-complete="isLoadingDesigns = false"
          >
            <DesignSubmission
              v-if="
                item.DesignThumbnailImageLocation &&
                item.DesignerProfileDisplayName
              "
              :submission-id="item.SubmissionId"
              :design-id="item.DesignId"
              :design-thumbnail-image-path="item.DesignThumbnailImageLocation"
              :design-image-path="item.DesignImageLocation"
              :designer-profile-thumbnail-path="
                item.DesignerProfilePicThumbnailLocation
              "
              :designer-page-url="item.DesignerPageUrl"
              :designer-display-name="item.DesignerProfileDisplayName"
              :designer-country="item.DesignerCountryEnglishName"
              :select-as-winner-action="item.SelectAsWinnerUrl"
              :access-design-files-action="item.AccessDesignFilesUrl"
              :tip-designer-action="item.TipDesignerUrl"
              :placement="item.Placement"
              :placement-label="item.PlacementLabel"
              :rating="item.StarRating"
              :feedback-count="item.FeedbackCount"
              :is-eliminated="item.IsEliminated"
              :is-recent="item.IsRecent"
              :is-withdrawn="item.IsWithdrawn"
              :is-selected="item.isSelected"
              :is-feedbackmodal-visible="isFeedbackModalVisible"
              :base-payment="!!item.BasePaymentCount"
              :buy-this-design-url="item.BuyThisDesignUrl"
              :currency-abbreviation="currencyAbbreviation.text"
              :is-shared="!!isDesignShared(item.DesignId)"
              :designer-id="item.DesignerId"
              :trigger-modal-on-mount="item.InitiallyVisible"
              :thumbnail-width="item.ThumbnailWidth"
              :thumbnail-height="item.ThumbnailHeight"
              :is-winner="!showFilters"
              :has-variations="item.HasLinkedSubmissions"
              :processing-icon-url="icons.processing"
              :close-icon-url="icons.closeIcon"
              :comment-square-filled-icon-url="icons.commentSquareFilled"
              :comment-square-hollow-icon-url="icons.commentSquareHollow"
              :delete-icon-url="icons.deleteIcon"
              :variation-icon-url="icons.variationIcon"
              :ellipsis-icon-url="icons.ellipsisIcon"
              :undo-icon-url="icons.undoIcon"
              :brief-status="briefStatus.status"
              :eliminate-design-action="endpoints.eliminate"
              :elimination-feedback-action="endpoints.eliminationFeedback"
              :is-menu-buy-design-visible="showBuyDesign"
              :is-menu-buy-design-url="buyDesignUrl"
              :is-menu-buy-design-image="imageThumbnail"
              @modal-not-visible="showBuyDesign = false"
              @rating-changed="onRatingChanged"
              @feedback-added="onFeedbackAdded"
              @share="onShareDesign(item)"
              @unshare="onUnshareDesign(item)"
              @selected="onSelected($event.designId, $event.submissionId)"
              @icon-eliminate="setDesignEliminateEliminatedStatus($event, true)"
              @icon-uneliminate="setDesignEliminateEliminatedStatus($event, false), refreshPageOfResults()"
              @close-eliminate-popup="refreshPageOfResults"
            />
          </DesignResults>
        </div>
        <div
          v-if="
            (showFilters || filteredDesigns.length > pageSize) &&
            enablePagination
          "
          class="row column"
        >
          <Pagination
            :pagination="pagination"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            @page-link-click="
              getPageOfResults($event);
              isReadyToScrollToTop = true;
            "
          />
        </div>
      </div>
      <div
        v-else-if="
          currentPageOfDesigns.length === 0 && currentPage <= pagination.maxPage
        "
        class="column small-12 text-center"
      >
        <img :src="icons.emptyDesign" alt="No designs" />
        <h3>No Designs match your current filter criteria.</h3>
        <p>Reset your filters and try again</p>
      </div>
      <div v-else class="column small-12 text-center">
        <img :src="icons.emptyDesign" alt="No designs" />
        <h3>No design found.</h3>
        <p>
          There are no designs matching your search.
          <a @click="resetAndInitialiseFilters">Reset</a> your search.
        </p>
      </div>
      <PopupBar :is-visible="isShareBarVisible">
        <form :ref="shareDesignsFormRef" method="POST">
          <div class="row align-justify align-middle plm prm">
            <div class="column small-6 pbm" :class="generatePDFUrl || shareViaPortalUrl ? 'medium-6 large-6' : 'medium-shrink large-shrink'">
              <strong class="text-black">
                {{ selectedList.length }} designs selected
              </strong>
            </div>
            <div v-if="!generatePDFUrl || !shareViaPortalUrl" class="column small-6 show-for-small hide-for-medium">
              <a class="button small alert clear deselect pts" @click="onDeselectAll">
                Deselect all
              </a>
            </div>
            <div class="column small-12 text-center align-center" :class="generatePDFUrl || shareViaPortalUrl ? 'medium-12 large-12' : 'medium-shrink large-shrink'">
              <input type="hidden" name="designs[]" :value="selectedList" />
              <input
                type="hidden"
                name="submissionIds"
                :value="submissionList"
              />
              <input type="hidden" name="setupPollOnly" :value="true" />
              <div class="row align-center">
                <div v-if="winnerUrl && selectedList.length === 1" class="column small-6 large-shrink">
                  <a
                    class="button small full-width mrm mbm"
                    :href="winnerUrl"
                  >
                    Select as Winner
                  </a>
                </div>
                <div v-if="buyDesignUrl && selectedList.length === 1" class="column small-6 large-shrink">
                  <button
                    class="button small full-width mrm mbm"
                    @click="onClickBuyDesign"
                  >
                    Buy this Design
                </button>
                </div>
                <div v-if="generatePDFUrl" class="column small-6 large-shrink">
                  <button
                    class="button small full-width mrm mbm"
                    @click="onClickShareAsPDF"
                  >
                    Share as PDF
                  </button>
                </div>
                <div class="column small-6 large-shrink">
                  <button
                    class="button secondary hollow small full-width mrm mbm"
                    @click="onClickShareViaEmail"
                  >
                    Share via Email
                  </button>
                </div>
                <div v-if="shareViaPortalUrl" class="column small-6 large-shrink">
                  <button
                    class="button secondary hollow small full-width mrm mbm"
                    @click="onClickShareViaPortal"
                  >
                    Share via Portal
                  </button>
                </div>
                <div class="column small-6 large-shrink">
                  <button
                    class="button secondary hollow small full-width mbm mrm"
                    @click="onClickAddToPoll"
                  >
                    Add to Poll
                  </button>
                </div>
                <div class="column small-6 large-shrink">
                  <a
                    class="button small alert full-width mbm"
                    @click="onEliminateDesigns"
                  >
                    Eliminate
                  </a>
                </div>
              </div>
            </div>
            <div v-if="!generatePDFUrl || !shareViaPortalUrl" class="column small-6 pbm medium-shrink large-shrink hide-for-small show-for-medium">
              <a class="button small alert clear mbn deselect" @click="onDeselectAll">
                Deselect all
              </a>
            </div>
          </div>
        </form>
      </PopupBar>
    </div>
  </fragment>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import { capitalize } from '../helpers/utilities';
import DesignsFilters from '../components/manage-project/designs/DesignsFilters/DesignsFilters.vue';
import DesignSubmission from '../components/manage-project/designs/DesignSubmission/DesignSubmission.vue';
import Pagination from '../components/shared/Pagination/Pagination.vue';
import PopupBar from '../components/shared/PopupBar/PopupBar.vue';
import designSubmissionApi from '../clients/design-submission-api';
import DesignResults from '../components/manage-project/designs/DesignResults/DesignResults.vue';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(calendar);
export default {
  components: {
    DesignsFilters,
    DesignSubmission,
    Pagination,
    PopupBar,
    DesignResults,
  },
  props: {
    designs: {
      type: Array,
      default: undefined,
    },
    icons: {
      type: Object,
      default: undefined,
    },
    currencyAbbreviation: {
      type: Object,
      required: true,
    },
    endpoints: {
      type: Object,
      default: undefined,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    enablePagination: {
      type: Boolean,
      default: true,
    },
    hasWinningDesigns: {
      type: Boolean,
      default: false,
    },
    briefStatus: {
      type: Object,
      required: true,
    },
    isWinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSize: 20,
      paginationSize: 5,
      pagination: {},
      currentPageOfDesigns: [],
      currentPage: 1,
      pageStart: 0,
      isShareBarVisible: false,
      isLoadingDesigns: false,
      shareDesignsFormRef: 'js-share-designs-form',
      topOfResultsRef: 'js-top-of-results',
      isReadyToScrollToTop: false,
      DesignSubmission,
      orderFilterType: '',
      isGridView: true,
      listViewItems: {},
      selectedList: [],
      submissionList: [],
      isFeedbackModalVisible: false,
      isBulkEliminatedSuccess: false,
      isDesigners: false,
      winnerUrl: null,
      buyDesignUrl: null,
      imageThumbnail: null,
      showBuyDesign: false,
    };
  },
  computed: {
    ...mapState('designs', [
      'ratingsFilter',
      'designersFilter',
      'feedbackFilter',
      'orderFilter',
      'filteredDesigns',
      'sharedDesigns',
      'eliminatedFilter',
    ]),
    ...mapGetters('designs', ['isDesignShared']),
    halfPaginationSize() {
      return Math.floor(this.paginationSize / 2);
    },
    pageNo() {
      return this.$route.query && this.$route.query.pageNo
        ? parseInt(this.$route.query.pageNo, 10)
        : 0;
    },
    generatePDFUrl() {
      return this.endpoints && this.endpoints.pdf;
    },
    shareViaPortalUrl() {
      return this.endpoints && this.endpoints.portal;
    },
    tipDesignerEndpoint() {
      return this.endpoints && this.endpoints.tipDesigner;
    },
    briefId() {
      const urlMatch = this.$route
        ? this.$route.fullPath.match(/([^/]+$)/)
        : null;
      if (urlMatch && urlMatch.length > 1) {
        return parseInt(urlMatch[1], 10);
      }
      return null;
    },
  },
  watch: {
    sharedDesigns(designs) {
      if (designs.length > 0) {
        this.isShareBarVisible = true;
      } else {
        this.isShareBarVisible = false;
      }
    },
    isLoadingDesigns(isLoading) {
      if (!isLoading && this.isReadyToScrollToTop && this.hasWinningDesigns) {
        this.scrollToTopOfPage();
        this.isReadyToScrollToTop = false;
      }
    },
  },
  mounted() {
    this.setEliminatedFilter(this.isWinner ? 'show-all' : 'hide-eliminated');
    this.initialiseDesignResults(this.pageNo);
  },
  methods: {
    ...mapActions('designs', [
      'setDesigns',
      'setShowFilters',
      'resetFilters',
      'addToRatingsFilter',
      'removeFromRatingsFilter',
      'addToDesignersFilter',
      'removeFromDesignersFilter',
      'setFeedbackFilter',
      'setOrderFilter',
      'setEliminatedFilter',
      'filterDesignsbyEliminated',
      'applyAllFilters',
      'addToSharedDesigns',
      'removeFromSharedDesigns',
      'removeAllSharedDesigns',
    ]),
    resetAndInitialiseFilters() {
      this.resetFilters();
      this.$refs.DesignsFilter.initialiseFilters();
      this.initialiseDesignResults(1);
    },
    initialiseDesignResults(pageNumber) {
      const mq = window.matchMedia(
        'screen and (max-device-width: 640px) and (orientation: portrait)',
      );
      mq.addListener(() => {
        if (mq.matches) {
          this.pageSize = 10;
        }
        this.applyFilters(this.currentPage);
      });
      if (mq.matches) {
        this.pageSize = 10;
      }
      if (!this.showFilters) {
        this.pageSize = 4;
      }
      if (
        this.designs.filter((d) => d !== d.IsEliminated && d.InitiallyVisible)
          .length
      ) {
        this.setEliminatedFilter(true);
      }
      this.setShowFilters(this.showFilters);
      this.initialiseDesignsStore();
      this.getPageOfResults(pageNumber);
    },
    applyFilters(pageNo) {
      this.applyAllFilters();
      this.getPageOfResults(pageNo || 1);
    },
    initialiseDesignsStore() {
      this.setDesigns(this.designs);
      this.filterDesignsbyEliminated(false);
    },
    onFilterDesignsByRatingCategory(ratingsCategory) {
      if (ratingsCategory) {
        ratingsCategory.forEach((rating) => this.addToRatingsFilter(rating));
        this.applyFilters();
      }
    },
    onFilterDesignsByRatings({ selected, option }) {
      if (selected) {
        this.addToRatingsFilter(option.rating);
      } else {
        this.removeFromRatingsFilter(option.rating);
      }
      this.applyFilters();
    },
    onFilterDesignsByDesignerNames({ selected, option }) {
      if (selected) {
        this.addToDesignersFilter(option.name);
      } else {
        this.removeFromDesignersFilter(option.name);
      }
      this.applyFilters();
    },
    onFilterByFeedback(option) {
      this.setFeedbackFilter(option);
      this.applyFilters();
    },
    onFilterByOrder(option) {
      if (option === 'designer-name-asc') {
        this.isDesigners = true;
      } else {
        this.isDesigners = false;
      }
      this.orderFilterType = option;
      this.setOrderFilter(option);
      this.applyFilters();
    },
    onFilterDesignsByEliminated(showEliminatedDesigns) {
      this.setEliminatedFilter(showEliminatedDesigns);
      this.applyFilters();
    },
    getPageStart(currentPage, maxPage) {
      let pageStart = 0;
      if (currentPage - this.halfPaginationSize <= 1) {
        pageStart = 1;
      } else if (currentPage + this.halfPaginationSize >= maxPage) {
        pageStart = Math.max(1, maxPage - this.paginationSize + 1);
      } else {
        pageStart = currentPage - this.halfPaginationSize;
      }
      return pageStart;
    },
    getPageEnd(currentPage, maxPage) {
      let pageEnd = 0;
      if (currentPage - this.halfPaginationSize <= 1) {
        pageEnd = Math.min(maxPage, this.paginationSize);
      } else if (currentPage + this.halfPaginationSize >= maxPage) {
        pageEnd = maxPage;
      } else {
        pageEnd = currentPage + this.halfPaginationSize;
      }
      return pageEnd;
    },
    updatePagination() {
      this.$set(
        this.pagination,
        'maxPage',
        Math.ceil(this.filteredDesigns.length / this.pageSize),
      );
      this.$set(this.pagination, 'current', this.currentPage);
      this.$set(
        this.pagination,
        'start',
        this.getPageStart(this.currentPage, this.pagination.maxPage),
      );
      this.$set(
        this.pagination,
        'end',
        this.getPageEnd(this.currentPage, this.pagination.maxPage),
      );
      const startOfPage = (this.pagination.current - 1) * this.pageSize + 1;
      this.$set(this.pagination, 'startOfPage', startOfPage);
      const numberOfResults = Math.min(
        this.currentPage * this.pageSize,
        this.filteredDesigns.length,
      );
      this.$set(this.pagination, 'numberOfResults', numberOfResults);
      this.$set(
        this.pagination,
        'totalResultsCount',
        this.filteredDesigns.length,
      );
    },
    getPageOfResults(pageNo) {
      if (this.enablePagination) {
        this.currentPage = pageNo || 1;
        this.pageStart = this.pageSize * (this.currentPage - 1);
        const pageEnd = this.pageStart + this.pageSize;
        this.updatePagination();
        this.currentPageOfDesigns = [];
        this.$nextTick(() => {
          this.currentPageOfDesigns = this.filteredDesigns
            .slice(this.pageStart, pageEnd)
            .map((d) => ({
              ...d,
              isSelected: this.selectedList.some((id) => id === d.DesignId),
            }));
          if (!this.isGridView) {
            this.displayListView();
          }
        });
      } else {
        this.updatePagination();
        this.currentPageOfDesigns = this.designs;
      }
      if (pageNo) {
        this.isReadyToScrollToTop = true;
      }
    },
    refreshPageOfResults() {
      this.isLoadingDesigns = true;
      this.setDesigns(this.designs);
      this.applyFilters(this.currentPage);
      this.isLoadingDesigns = false;
    },
    scrollToTopOfPage() {
      const offsetPadding = 150;
      const topOfPageElementOffsetTop =
        this.$refs[this.topOfResultsRef].offsetTop;
      window.scrollTo(0, topOfPageElementOffsetTop - offsetPadding);
    },
    onShareDesign(design) {
      this.addToSharedDesigns(design);
    },
    onUnshareDesign(design) {
      this.removeFromSharedDesigns(design);
    },
    onSelected(designId, submissionId) {
      const design = this.currentPageOfDesigns.find(
        (d) => d.DesignId === designId,
      );

      if (this.selectedList.some((id) => id === designId)) {
        this.selectedList = this.selectedList.filter((id) => id !== designId);
        if (design) {
          design.isSelected = false;
        }
      } else {
        this.selectedList.push(designId);
        if (design) {
          design.isSelected = true;
        }
      }
      if (this.submissionList.some((id) => id === submissionId)) {
        this.submissionList = this.submissionList.filter(
          (id) => id !== submissionId,
        );
        if (this.submissionList.indexOf(design.SubmissionId) > -1) {
          return design;
        }
      } else {
        this.submissionList.push(submissionId);
      }

      if (!this.selectedList || this.selectedList.length === 0) {
        this.isShareBarVisible = false;
      } else {
        this.isShareBarVisible = true;
        const firstSelectedDesignId = this.selectedList[0];
        const url = this.currentPageOfDesigns.find(
          (d) => d.DesignId === firstSelectedDesignId,
        );
        this.winnerUrl = url.SelectAsWinnerUrl;
        this.buyDesignUrl = url.BuyThisDesignUrl;
        this.imageThumbnail = url.DesignThumbnailImageLocation;
      }
      return design;
    },
    updateEliminatedDesignsState(designs, eliminatedStatus) {
      const designIds = designs.map((design) => design.DesignId);
      this.currentPageOfDesigns.forEach((design) => {
        if (designIds.indexOf(design.DesignId) > -1) {
          // eslint-disable-next-line no-param-reassign
          design.IsEliminated = eliminatedStatus;
        }
      });
      const updatedDesigns = this.designs.map((d) => {
        const design = d;
        if (designIds.indexOf(design.DesignId) > -1) {
          design.IsEliminated = eliminatedStatus;
        }
        return design;
      });
      this.setDesigns(updatedDesigns);
    },
    async onEliminateDesigns() {
      await designSubmissionApi.eliminateBulkDesignsAsync(
        this.briefId,
        this.selectedList,
      );
      const designs = this.designs.map((design) => {
        if (this.selectedList.indexOf(design.DesignId) > -1) {
          return design;
        }
        return false;
      });
      this.updateEliminatedDesignsState(designs, true);
      this.isShareBarVisible = false;
      this.selectedList = [];
      this.currentPageOfDesigns.forEach((d) => {
        // eslint-disable-next-line no-param-reassign
        d.isSelected = false;
      });

      this.refreshPageOfResults();
    },
    async setDesignEliminateEliminatedStatus(designId, eliminatedStatus) {
      const design = this.currentPageOfDesigns.find(
        (d) => d.DesignId === designId,
      );

      this.updateEliminatedDesignsState([design], eliminatedStatus);
      this.isShareBarVisible = false;
      this.selectedList = [];
    },
    async onEliminateDesigner(designerId) {
      await designSubmissionApi.eliminateDesignerAsync(
        this.briefId,
        designerId,
      );
      const designs = this.designs.map((design) => {
        if (design.DesignerId === designerId) {
          return design;
        }
        return false;
      });

      this.updateEliminatedDesignsState(designs, true);
    },
    onDeselectAll() {
      this.currentPageOfDesigns.forEach((design) => {
        // eslint-disable-next-line no-param-reassign
        design.isSelected = false;
      });
      this.selectedList = [];
      this.submissionList = [];
      this.isShareBarVisible = false;
    },
    updateDesignProperty(design, prop) {
      const uppercaseProp = capitalize(prop);
      const designIndex = this.designs.findIndex(
        (d) => d.DesignId === design.designId,
      );
      const currentPageOfDesignsIndex = this.currentPageOfDesigns.findIndex(
        (d) => d.DesignId === design.designId,
      );
      this.$set(this.designs[designIndex], uppercaseProp, design[prop]);
      this.$set(
        this.currentPageOfDesigns[currentPageOfDesignsIndex],
        uppercaseProp,
        design[prop],
      );
      this.setDesigns(this.designs);
    },
    onFeedbackAdded(design) {
      this.updateDesignProperty(design, 'feedbackCount');
    },
    onRatingChanged(design) {
      this.updateDesignProperty(design, 'starRating');
      this.refreshPageOfResults();
      if (this.ratingsFilter.length > 0 || this.feedbackFilter !== null) {
        this.applyFilters(this.currentPage);
        this.getPageOfResults(this.currentPage);
        this.isReadyToScrollToTop = true;
      }
    },
    onClickShareAsPDF(event) {
      event.preventDefault();
      this.$refs[this.shareDesignsFormRef].action = this.generatePDFUrl;
      this.$refs[this.shareDesignsFormRef].submit();
    },
    onClickBuyDesign(event) {
      event.preventDefault();
      this.showBuyDesign = true;
    },
    onClickShareViaEmail(event) {
      event.preventDefault();
      this.$refs[
        this.shareDesignsFormRef
      ].action = `/share-designs.aspx?briefId=${this.briefId}`;
      this.$refs[this.shareDesignsFormRef].submit();
    },
    onClickShareViaPortal(event) {
      event.preventDefault();
      this.$refs[this.shareDesignsFormRef].action = this.shareViaPortalUrl;
      this.$refs[this.shareDesignsFormRef].submit();
    },
    onClickAddToPoll(event) {
      event.preventDefault();
      this.$refs[
        this.shareDesignsFormRef
      ].action = `/manage-poll/create/${this.briefId}`;
      this.submissionList = JSON.stringify(this.submissionList);
      this.$nextTick(() => this.$refs[this.shareDesignsFormRef].submit());
    },
    displayListView() {
      this.isGridView = false;
      this.listViewItems = [];
      switch (this.orderFilterType) {
        case 'designer-name-asc':
          this.generateListByDesignerNameAsc();
          break;
        case 'rating-desc':
          this.generateListByStarRatingDesc();
          break;
        default:
          this.generateListBySubmissionTime();
          break;
      }
    },
    generateListByDesignerNameAsc() {
      const designsGroupedByName = {};
      this.currentPageOfDesigns.forEach((design) => {
        if (!designsGroupedByName[design.DesignerProfileDisplayName]) {
          designsGroupedByName[design.DesignerProfileDisplayName] = [];
        }
        designsGroupedByName[design.DesignerProfileDisplayName].push(design);
      });
      this.listViewItems = designsGroupedByName;
    },
    generateListByStarRatingDesc() {
      const designsGroupedByRating = {};
      const getRatingLabel = (rating) => {
        if (rating > 1) {
          return `${rating} stars`;
        }
        if (rating === 1) {
          return `${rating} star`;
        }
        return 'Unrated';
      };
      this.currentPageOfDesigns.forEach((design) => {
        const label = getRatingLabel(design.StarRating);
        if (!designsGroupedByRating[label]) {
          designsGroupedByRating[label] = [];
        }
        designsGroupedByRating[label].push(design);
      });
      this.listViewItems = designsGroupedByRating;
    },
    generateListBySubmissionTime() {
      const designsGroupedBySubmissionTime = {};
      const sameDay = 'Today';
      const lastDay = '1 day ago';
      const lastWeek = '1 week ago';
      const lastMonth = '1 month ago';
      const isLastMonth = (date) => {
        const thisYear = dayjs().tz('UTC').format('YYYY');
        const thisMonth = parseInt(dayjs().tz('UTC').format('M'), 10);
        const submissionYear = dayjs(date).format('YYYY');
        const submissionMonth = parseInt(dayjs(date).format('M'), 10);
        if (submissionYear === thisYear && submissionMonth === thisMonth - 1) {
          return true;
        }
        return false;
      };
      this.currentPageOfDesigns.forEach((design) => {
        let submissionPeriod = dayjs(design.SubmissionTime).calendar(null, {
          sameDay: `[${sameDay}]`,
          lastDay: `[${lastDay}]`,
          lastWeek: `[${lastWeek}]`,
          sameElse: 'MMMM YYYY',
        });
        if (isLastMonth(design.SubmissionTime)) {
          submissionPeriod = lastMonth;
        }
        switch (submissionPeriod) {
          case sameDay:
            if (!designsGroupedBySubmissionTime[sameDay]) {
              designsGroupedBySubmissionTime[sameDay] = [];
            }
            designsGroupedBySubmissionTime[sameDay].push(design);
            break;
          case lastDay:
            if (!designsGroupedBySubmissionTime[lastDay]) {
              designsGroupedBySubmissionTime[lastDay] = [];
            }
            designsGroupedBySubmissionTime[lastDay].push(design);
            break;
          case lastWeek:
            if (!designsGroupedBySubmissionTime[lastWeek]) {
              designsGroupedBySubmissionTime[lastWeek] = [];
            }
            designsGroupedBySubmissionTime[lastWeek].push(design);
            break;
          case lastMonth:
            if (!designsGroupedBySubmissionTime[lastMonth]) {
              designsGroupedBySubmissionTime[lastMonth] = [];
            }
            designsGroupedBySubmissionTime[lastMonth].push(design);
            break;
          default:
            if (!designsGroupedBySubmissionTime[submissionPeriod]) {
              designsGroupedBySubmissionTime[submissionPeriod] = [];
            }
            designsGroupedBySubmissionTime[submissionPeriod].push(design);
            break;
        }
      });
      this.listViewItems = designsGroupedBySubmissionTime;
    },
  },
};
</script>
