<template>
  <table class="table table--striped text-large">
    <thead>
      <tr>
        <td class="text-large">Credit card</td>
        <td class="text-large">Expiry date</td>
        <td class="text-large">Action</td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(card, index) in storedCards" :key="index">
        <td class="text-large">
          <img
            class="mrs"
            :src="card.paymentMethodIconUrl"
            :alt="card.paymentMethodIconAlt"
          />
          {{ card.creditCardName }}
        </td>
        <td class="text-large">{{ card.expiryDate }}</td>
        <td>
          <a
            :class="{
              ['button clear tiny pan man']: true,
              disabled: isDisabled,
            }"
            @click="onClickRemoveCard(card, index)"
          >
            <strong class="text-medium">REMOVE</strong>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    storedCards: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onClickRemoveCard(card, index) {
      if (!this.isDisabled) {
        this.$emit('remove-card', { card, index });
      }
    },
  },
};
</script>
