export const SET_DESIGNS = 'SET_DESIGNS';
export const SET_SHOW_FILTERS = 'SET_SHOW_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_FILTERED_DESIGNS = 'RESET_FILTERED_DESIGNS';
export const ADD_TO_RATINGS_FILTER = 'ADD_TO_RATINGS_FILTER';
export const REMOVE_FROM_RATINGS_FILTER = 'REMOVE_FROM_RATINGS_FILTER';
export const FILTER_BY_RATINGS = 'FILTER_BY_RATINGS';
export const FILTER_BY_DESIGNERS = 'FILTER_BY_DESIGNERS';
export const ADD_TO_DESIGNERS_FILTER = 'ADD_TO_DESIGNERS_FILTER';
export const REMOVE_FROM_DESIGNERS_FILTER = 'REMOVE_FROM_DESIGNERS_FILTER';
export const SET_FEEDBACK_FILTER = 'SET_FEEDBACK_FILTER';
export const SET_ORDER_FILTER = 'SET_ORDER_FILTER';
export const FILTER_BY_FEEDBACK = 'FILTER_BY_FEEDBACK';
export const FILTER_BY_ORDER = 'FILTER_BY_ORDER';
export const FILTER_BY_ELIMINATED = 'FILTER_BY_ELIMINATED';
export const SET_ELIMINATED_FILTER = 'SET_ELIMINATED_FILTER';
export const ADD_TO_SHARED_DESIGNS = 'ADD_TO_SHARED_DESIGNS';
export const REMOVE_FROM_SHARED_DESIGNS = 'REMOVE_FROM_SHARED_DESIGNS';
export const REMOVE_ALL_SHARED_DESIGNS = 'REMOVE_ALL_SHARED_DESIGNS';
