<template>
  <ModalV2
    title="Here's what you'll be missing"
    :close-icon="icons.close"
    :visible="visible"
    @close="close"
  >
    <template #body>
      <div class="flex-container w-100 align-center text-center">
        <img
          alt="An image"
          class="mbs flex-container align-center row"
          :src="icons.commonLargeCancel"
        />
      </div>
      <div class="flex-container align-center">
        <div class="flex-dir-column">
          <div
            v-for="(pointCopy, index) in unPrettierNuggets.getPoints(
              subscription,
            )"
            :key="index"
            class="mts mbm column pll"
          >
            <img alt="An image" class="mrs" :src="icons.commonSmallCheckBlue" />
            <span class="text-medium text-black" v-html="pointCopy"></span>
          </div>
          <p class="text-small text-center">
            {{ unPrettierNuggets.ifYouCancelCopy(subscription) }}
          </p>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="row align-center">
        <div class="column shrink">
          <button class="mbn button tiny secondary hollow" @click="next">
            Continue Cancelling
          </button>
        </div>
        <div class="column shrink">
          <button
            type="button"
            class="button tiny mbn prm plm text-small"
            @click="close"
          >
            Keep Premium
          </button>
        </div>
      </div>
    </template>
  </ModalV2>
</template>

<script>
import ModalV2 from '../../../../shared/Modal/ModalV2.vue';

export default {
  components: {
    ModalV2,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      unPrettierNuggets: {
        getPoints: (subscription) => {
          const socialMediaBlock = [
            `<strong>150,000+</strong> Social media templates and design tools`,
            `<strong>10,000+</strong> Business card templates and design tools`,
            `<strong>40,000+</strong> Poster, Flyer, Letterhead templates and more`,
          ];
          const perpStorAndPremium = [
            `Perpetual storage of your files`,
            `Premium templates`,
          ];
          const website = [
            `<strong>Mobile-friendly Website</strong> featuring your logo`,
          ];
          switch (subscription.subscriptionType) {
            case 'WebHosting':
              return [
                `Professionally designed website`,
                `Editable site via WordPress CMS`,
                `Domain name`,
                `Hosting`,
                `Website customised with your logo`,
              ];
            case 'LogoEditor':
              break;
            case 'SocialMediaEditor':
              break;
            case 'VipCustomerService':
              return [
                `Dedicated 24/7 VIP phone and screen sharing calls`,
                `All your emails will be at the top of a dedicated queue`,
                `Get premium support for all your questions`,
                `Our team can help you communicate with the designs to get you the perfect design`,
              ];
            case 'PerpetualStorage':
              return [
                `Perpetual storage of your design files on DesignCrowd`,
              ];
            case 'ProDesignerSupport':
              break;
            case 'SocialMediaDesign':
              return [
                `Social media designs created monthly by our designers and made for you`,
                `Custom Facebook and Instagram designs`,
                `Industry standard files`,
                `24/7 email support`,
                `2 day turnaround time`,
              ];
            case 'PremiumDesignPack':
              return [
                `<strong>Logo editor</strong> with unlimited edits and downloads`,
                ...socialMediaBlock,
                ...perpStorAndPremium,
              ];
            case 'LogoAndMorePackage1':
              return [...socialMediaBlock, ...perpStorAndPremium];
            case 'LogoAndMorePackage2':
              return [...website, ...socialMediaBlock, ...perpStorAndPremium];
            case 'LogoAndMorePackage3':
              return [
                `<strong>Social media designs created by our designers and made for you</strong>`,
                ...website,
                ...socialMediaBlock,
                ...perpStorAndPremium,
              ];
            default:
              break;
          }

          return [];
        },
        ifYouCancelCopy: (subscription) => {
          return `If you cancel your subscription, after ${subscription.renewalDateCopy} you will lose all your premium benefits`;
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
