/* eslint-disable */

<template>
  <select :id="id" class="mbn" :name="name || id" @change="onChange($event)">
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="index"
      :selected="option[isSelectedKey]"
    >
      <slot :option="option">{{ option[labelKey || valueKey] }}</slot>
    </option>
  </select>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
    labelKey: {
      type: String,
      default: undefined,
    },
    valueKey: {
      type: String,
      required: true,
    },
    isSelectedKey: {
      type: String,
      required: true,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('input', {
        event,
        option: this.options[event.target.value][this.valueKey],
      });
    },
  },
};
</script>
