var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.alerts && _vm.alerts.length > 0)?_c('div',{staticClass:"column small-12"},_vm._l((_vm.alerts),function(alert,index){return _c('Callout',{key:index,attrs:{"type":alert.type,"title":alert.title,"icon-url":_vm.icons[`${alert.type}Icon`],"closable":""},on:{"close":function($event){_vm.alerts = []}},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(_vm._s(alert.message))]},proxy:true}],null,true)})}),1):_vm._e(),_c('div',{staticClass:"column small-12 large-3"},[_c('ManageAccountMenu',{attrs:{"menu":_vm.menu}})],1),_c('div',{staticClass:"column small-12 large-9"},[_c('div',{staticClass:"frame frame--padding-small ptn"},[_c('div',{staticClass:"row column"},[_c('ul',{staticClass:"menu menu--tabs menu--tabs-white u-block full-width"},_vm._l((Object.keys(_vm.TABS)),function(tab,index){return _c('li',{key:index,class:{
              'menu__active-tab': _vm.isTabVisible(_vm.TABS[tab]),
            },on:{"click":function($event){return _vm.openTab(_vm.TABS[tab])}}},[_c('a',[_vm._v(_vm._s(_vm.TABS[tab]))])])}),0),_c('hr',{staticClass:"horizontal-rule horizontal-rule--form-frame-small mbm"})]),(_vm.isTabVisible(_vm.TABS.ACCOUNT_DETAILS))?_c('AccountDetailsForm',{attrs:{"address":_vm.userAccountDetails.address,"city":_vm.userAccountDetails.city,"company-name":_vm.userAccountDetails.companyName,"country":_vm.userAccountDetails.country,"email":_vm.accountDetails.email,"first-name":_vm.userAccountDetails.firstName,"last-name":_vm.userAccountDetails.lastName,"phone":_vm.userAccountDetails.phone,"postcode":_vm.userAccountDetails.postcode,"state":_vm.userAccountDetails.state,"password-system-generated":_vm.userAccountDetails.passwordSystemGenerated,"countries":_vm.countries,"reset-change-password-form":_vm.resetChangePasswordForm,"processing":_vm.processing},on:{"update-password":function($event){return _vm.updatePassword(
            $event.password,
            $event.newPassword,
            $event.confirmPassword,
          )},"update-details":_vm.updateDetails}}):_vm._e(),(_vm.isTabVisible(_vm.TABS.EMAIL_SUBSCRIPTION))?_c('EmailSubscriptionsForm',{attrs:{"email":_vm.accountDetails.email,"product-offer-and-promotion-enabled":_vm.userEmailSubscriptions.productOfferAndPromotionEnabled,"newsletter-enabled":_vm.userEmailSubscriptions.newsletterEnabled,"general-project-alert-enabled":_vm.userEmailSubscriptions.generalProjectAlertEnabled,"new-design-notification-enabled":_vm.userEmailSubscriptions.newDesignNotificationEnabled,"daily-summary-enabled":_vm.userEmailSubscriptions.dailySummaryEnabled,"invite-notification-enabled":_vm.userEmailSubscriptions.inviteNotificationEnabled},on:{"update-subscription":_vm.updateSubscriptions}}):_vm._e(),(_vm.isTabVisible(_vm.TABS.STORED_CARDS))?_c('StoredCards',{attrs:{"stored-cards":_vm.userStoredCards,"is-disabled":!_vm.canRemoveCards},on:{"remove-card":function($event){return _vm.removeCard($event.card, $event.index)}}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }