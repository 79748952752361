<template>
  <form :ref="customizeWhitelabelFormRef" method="POST">
    <div class="row">
      <div class="column small-12">
        <p class="mbm">
          {{ unPrettierNuggets.blurb }}
        </p>
        <label for="whitelabel-text">
          <strong class="text-black">Whitelabel Text</strong>
        </label>
        <span>Text to be white-labelled over images</span>
        <img
          :ref="whitelabelTextTooltipRef"
          class="has-tip top mls"
          data-tooltip
          aria-haspopup="true"
          data-allow-html="true"
          data-tooltip-class="tooltip"
          :src="questionIconUrl"
          alt="question mark icon"
          title="<strong class='text-black'>Whitelabel Text</strong>
            <br />
            This is the text that is rendered as the watermark over images as well as the branding for PDFs and Client pages. For best results, we recommend keeping the text to 15 to 25 characters in length.
            <br />
            Example: <em>My Company Example</em>"
        />
        <input
          id="whitelabel-text"
          v-model="whiteLabelTextValue"
          type="text"
          name="Text"
        />

        <label for="whitelabel-logo-url">
          <strong class="text-black">Your Logo (Image URL)</strong>
        </label>
        <span>
          {{ unPrettierNuggets.logoBlurb }}
        </span>
        <img
          :ref="whitelabelImageTooltipRef"
          class="has-tip right"
          data-tooltip
          aria-haspopup="true"
          data-allow-html="true"
          data-tooltip-class="tooltip"
          :src="questionIconUrl"
          alt="question mark icon"
          title="<strong class='text-black'>Whitelabel Image</strong>
            <br />
            This is the link to your image location for your logo. For best results, we recommend a logo of 250 pixels wide by 150 pixels in height.
            <br />
            If you have a logo on your website that you want to use, just right click on the image, then click properties. You will then see the URL which you can copy and paste into here.
            <br />Example: <em>http://www.example.com/mylogo.jpg</em>"
        />
        <input
          id="whitelabel-logo-url"
          v-model="whiteLabelImageUrlValue"
          type="text"
          name="ImageUrl"
        />
        <img
          v-if="whitelabelImageUrl"
          class="mbm"
          :src="whitelabelImageUrl"
          alt="whitelabel logo preview"
        />
      </div>
    </div>
    <div class="row align-center form-stacking form-stacking--medium">
      <div class="column small-12">
        <hr class="horizontal-rule horizontal-rule--form-frame-small mbm" />
      </div>
      <div class="column small-12 large-shrink">
        <button
          class="form-stacking__item button primary full-width"
          @click="onClickSaveDetails"
        >
          Save Details
        </button>
      </div>
      <div class="column small-12 large-shrink">
        <button
          class="form-stacking__item button secondary hollow full-width"
          @click="onClickSaveDetailsAndPreviewPdf"
        >
          Save & Preview PDF
        </button>
      </div>
      <div class="column medium-12 large-shrink">
        <button
          class="button secondary hollow full-width mbn"
          @click="onClickSaveDetailsAndPreviewClientPortal"
        >
          Save & Preview Client Portal
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    questionIconUrl: {
      type: String,
      default: '',
    },
    whitelabelText: {
      type: String,
      default: undefined,
    },
    whitelabelImageUrl: {
      type: String,
      default: undefined,
    },
    saveDetailsApi: {
      type: String,
      default: undefined,
    },
    saveDetailsPreviewPdfApi: {
      type: String,
      default: undefined,
    },
    saveDetailsPreviewClientPortalApi: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      customizeWhitelabelFormRef: 'js-customize-whitelabel-form',
      whiteLabelTextValue: this.whitelabelText,
      whiteLabelImageUrlValue: this.whitelabelImageUrl,
      whitelabelTextTooltip: null,
      whitelabelImageTooltip: null,
      whitelabelTextTooltipRef: 'js-whitelabel-text-tooltip',
      whitelabelImageTooltipRef: 'js-whitelabel-image-tooltip',
      unPrettierNuggets: {
        blurb: `This page give you the ability to customize the branding of the Client Portal and PDFs that are generated when you share with your clients. By doing this you are able to make the generated output look like your own.`,
        logoBlurb: `Image that is displayed as your logo on the Portal and PDF (Recommended size: width 300px and height 100px)`,
      }
    };
  },
  mounted() {
    if (window.Foundation && window.$) {
      this.whitelabelTextTooltip = new window.Foundation.Tooltip(
        // eslint-disable-next-line no-undef
        $(this.$refs[this.whitelabelTextTooltipRef]),
      );

      this.whitelabelImageTooltip = new window.Foundation.Tooltip(
        // eslint-disable-next-line no-undef
        $(this.$refs[this.whitelabelImageTooltipRef]),
      );
    }
  },
  methods: {
    onClickSaveDetails(event) {
      event.preventDefault();
      this.$refs[this.customizeWhitelabelFormRef].action = this.saveDetailsApi;
      this.$refs[this.customizeWhitelabelFormRef].submit();
    },
    onClickSaveDetailsAndPreviewPdf(event) {
      event.preventDefault();
      this.$refs[this.customizeWhitelabelFormRef].action =
        this.saveDetailsPreviewPdfApi;
      this.$refs[this.customizeWhitelabelFormRef].submit();
    },
    onClickSaveDetailsAndPreviewClientPortal(event) {
      event.preventDefault();
      this.$refs[this.customizeWhitelabelFormRef].action =
        this.saveDetailsPreviewClientPortalApi;
      this.$refs[this.customizeWhitelabelFormRef].submit();
    },
  },
};
</script>
