<template>
  <tbody>
    <tr class="show-for-medium">
      <td>{{ briefId }}</td>
      <td>{{ item }}</td>
      <td>{{ pages }}</td>
      <td>{{ domainName }}</td>
      <td>{{ cost }}</td>
      <td>
        <span v-if="status" :class="{ [`label ${statusClass}`]: true }">
          {{ status }}
        </span>
      </td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Brief ID</strong></td>
      <td>{{ briefId }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Item</strong></td>
      <td>{{ item }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Pages</strong></td>
      <td>{{ pages }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Domain Name</strong></td>
      <td>{{ domainName }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Cost</strong></td>
      <td>{{ cost }}</td>
    </tr>
    <tr class="hide-for-medium">
      <td><strong class="text-black">Status</strong></td>
      <td>
        <span v-if="status" :class="{ [`label ${statusClass}`]: true }">
          {{ status }}
        </span>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    briefId: {
      type: Number,
      required: true,
    },
    item: {
      type: String,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    domainName: {
      type: String,
      required: true,
    },
    cost: {
      type: String,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isInProgress: {
      type: Boolean,
      required: true,
    },
    isRefunded: {
      type: Boolean,
      required: true,
    },
    isAwaitingDesignApproval: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      status: '',
      statusClass: '',
    };
  },
  methods: {
    setStatus() {
      if (this.isCompleted) {
        this.status = 'COMPLETED';
        this.statusClass = 'success';
      } else if (this.isInProgress) {
        this.status = 'IN PROGRESS';
        this.statusClass = 'primary';
      } else if (this.isRefunded) {
        this.status = 'REFUNDED';
        this.statusClass = 'warning';
      } else if (this.isAwaitingDesignApproval) {
        this.status = 'AWAITING DESIGN APPROVAL';
        this.statusClass = 'warning';
      } else {
        this.status = '';
      }
    },
  },
};
</script>
