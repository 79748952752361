<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="feedback-page-container">
    <div class="row">
      <div class="column small-12">
        <FeedbackForm
          :feedback="models.feedback"
          :icons="icons"
          :urls="models.urls"
        />
      </div>
    </div>
  </fragment>
</template>

<script>
import FeedbackForm from '../../components/my-account/designer/design-feedback/FeedbackForm.vue';

export default {
  components: { FeedbackForm },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods: {},
};
</script>
