<!-- eslint-disable prettier/prettier -->
<template>
    <fragment name="payout-logs-page-container">
        <div class="row">
            <div class="column small-12 medium-3">
              <profile-strength
                :percentage="models.profileStrength.percentage"
                class="mbm"
              />
              <manage-account-menu :menu="models.menu" />
            </div>
            <div class="column small-12 medium-9">
                <PayoutLogsTable
                  :earnings="earnings"
                  :payout-logs="currentPage"
                  :api-base-url="pagination.apiBaseUrl"
                  :api-post-object="apiPostObject"
                  @table-update="onTableUpdate"
                />
                <Pagination
                  class="mtm"
                  :max-page="pagination.maxPage"
                  :current="pagination.current"
                  :start="pagination.start"
                  :end="pagination.end"
                  :start-of-page="pagination.startOfPage"
                  :number-of-results="pagination.numberOfResults"
                  :total-results-count="pagination.totalResultsCount"
                  :description="pagination.description"
                  :base-url="pagination.baseUrl"
                  :api-base-url="pagination.apiBaseUrl"
                  :left-arrow-icon-url="icons.paginationLeftArrow"
                  :right-arrow-icon-url="icons.paginationRightArrow"
                  :api-post-object="apiPostObject"
                  @page-link-click="onPaginationClick"
                />
            </div>
        </div>
    </fragment>
  </template>

<script>
import PayoutLogsTable from '../../components/my-account/designer/payout-logs/PayoutLogsTable.vue';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import ProfileStrength from '../../components/my-account/designer/overview/ProfileStrength/ProfileStrength.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    PayoutLogsTable,
    ManageAccountMenu,
    ProfileStrength,
    Pagination,
  },
  props: {
    earnings: {
      type: String,
      required: true,
    },
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiPostObject: {
        page: 1,
        status: null,
        sortBy: null,
      },
      currentPage: this.models.payoutLogs,
      pagination: this.models.pagination,
    };
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
    onTableUpdate(e) {
      this.apiPostObject = e.apiPostObject;
      this.currentPage = e.resData.items;
      this.pagination = e.resData.pagination;
    },
  },
};
</script>
