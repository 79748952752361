<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="submissions-card">
    <SubmissionsFilter
      :count="count"
      :disabled="submissionsFilterDisabled"
      :briefs="briefs"
      @apo-updated="apoUpdated"
    />
    <ManageAccountEmptyCard v-if="nothingToShow" :message="emptyCardMessage" />
    <div class="maker-spinner-container">
      <div v-if="isLoading" class="maker-spinner">
        <span class="maker-spinner__icon" />
      </div>
      <div v-if="somethingToShow">
        <div
          v-for="(item, index) in allSubmissions"
          :key="index"
          class="frame pam frame--border-alt frame--hover-box-shadow mbn mbm"
        >
          <div class="row mrn mln pbs">
            <div class="column pan flex-container small-12 medium-8">
              <a target="_blank" :href="item.jobPageUrl">
                "{{ item.briefName }}"&nbsp;
              </a>
              <p class="text-blue show-for-medium">
                Contest(#{{ item.briefId }})
              </p>
            </div>
            <p class="text-blue small-12 show-for-small-only">
              Contest(#{{ item.briefId }})
            </p>
            <div class="column pan text-right small-12 medium-4">
              {{ item.submissionTime }}
            </div>
          </div>
          <div class="row pts pbs">
            <div
              class="column small-12 medium-3"
              :class="{
                shrink: !item.isSubmission,
              }"
            >
              <div
                class="submissions__image_container text-center"
                :class="{
                  'submissions__image_container--open': !item.isCollapsed,
                  ptn: !item.isSubmission,
                }"
              >
                <div
                  v-if="item.isSubmission"
                  class="submissions__image_container--overlay"
                  @click="
                    openImage(
                      item.designImageLocation,
                      item.briefId,
                      item.designId,
                    )
                  "
                />
                <img
                  :src="item.designThumbnailImageLocation"
                  alt="Service Image"
                  :class="{
                    'card-image': item.isSubmission,
                    'card-image--hold': !item.isSubmission,
                  }"
                />
                <div
                  v-if="item.designId"
                  class="submissions-card--actions"
                  :class="{
                    'submissions-card--actions--open': !item.isCollapsed,
                  }"
                >
                  <div
                    class="row mrn mln align-center"
                    @click="onCollapse(index)"
                  >
                    <p>Actions</p>
                    <img
                      :src="!item.isCollapsed ? icons.downIcon : icons.upIcon"
                      alt="action icon"
                    />
                  </div>
                  <div class="align-center submissions-card--actions_menu">
                    <a
                      v-if="item.actionSubmit"
                      :disabled="item.disabled"
                      :href="item.actionSubmit"
                      >Submit a Design</a
                    >
                    <a
                      v-if="item.showActionWithdraw"
                      :disabled="item.disabled"
                      @click="onWithdraw(index)"
                      >Withdraw Design</a
                    >
                    <a
                      v-if="item.showActionHide"
                      :disabled="item.disabled"
                      @click="onHide(index)"
                      >Hide in Portfolio</a
                    >
                    <a
                      v-if="!item.showActionHide"
                      :disabled="item.disabled"
                      @click="onUnHide(index)"
                      >Show in Portfolio</a
                    >
                    <a
                      v-if="item.showActionFavourite"
                      :disabled="item.disabled"
                      @click="onFavourite(index)"
                      >Pick as Favorite</a
                    >
                    <a
                      v-if="item.showActionUnFavourite"
                      :disabled="item.disabled"
                      @click="onUnFavourite(index)"
                      >Un-Favorite</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <template v-if="item.isSubmission">
              <div class="column text-center">
                <h6>Project Status</h6>
                <div :class="item.tooltip ? 'inline-tooltip' : null">
                  <div
                    v-if="item.tooltip"
                    role="tooltip"
                    class="tooltip top align-center"
                    v-html="item.tooltip"
                  ></div>
                  <img :src="item.iconUrl" alt="icon" />
                  <a
                    v-if="item.actionDesignHandover"
                    class="mbn u-block"
                    :href="item.actionDesignHandover"
                    >{{ item.submissionStatus }}</a
                  >
                  <p v-else class="mbn">{{ item.submissionStatus }}</p>
                </div>
                <p v-if="item.isWinner" class="card__discount text-small mbn">
                  {{ item.submissionSubStatus }}
                </p>
                <p v-else-if="item.submissionSubStatus" class="mbn">
                  {{ item.submissionSubStatus }}
                </p>
                <p v-else-if="item.deadline" class="mbn text-med">
                  Deadline:<br />
                  {{ item.deadline }}
                </p>
              </div>
              <div class="column text-center">
                <h6>Feedback</h6>
                <img :src="icons.messageIcon" alt="msg icon" />
                <p>{{ item.feedbackCount }} messages</p>
                <a
                  v-if="item.viewFeedbackUrl"
                  :href="item.viewFeedbackUrl"
                  class="text-medium"
                  >View feedback</a
                >
              </div>
              <div class="column text-center">
                <h6>Revisions</h6>
                <img :src="icons.penIcon" alt="pen icon" />
                <p class="mbn">{{ item.revisionCount }} revisions</p>
                <a
                  v-if="item.actionSubmit"
                  :href="item.actionSubmit"
                  class="text-medium"
                  >Submit a revision</a
                >
              </div>
              <div class="column text-center">
                <h6>Source Files</h6>
                <img :src="icons.srcIcon" alt="src icon" />
                <p class="mbn">{{ item.sourceFileCount }} of {{ item.sourceFilesPossible }}</p>
                <a
                  v-if="item.actionSubmitSourceFile"
                  :href="item.actionSubmitSourceFile"
                  class="text-medium"
                  >Upload Files</a
                >
              </div>
            </template>
            <template v-else>
              <div class="column text-center small-2">
                <h6>Design Status</h6>
                <p>{{ item.designPendingStatusDisplay }}</p>
              </div>
              <div class="column text-left">
                <p class="text-gray-ultra-dark">
                  <template
                    v-if="
                      item.designPendingStatus === 'Pending' &&
                      approvalStatus === 'Unassessed'
                    "
                  >
                    <span class="text-bold">
                      Why is my design under review?
                    </span>
                    <br />
                    <span
                      v-html="
                        unPrettierNuggets.submissionStatusCopy.designUnderReview
                      "
                    ></span>
                  </template>
                  <template
                    v-else-if="
                      item.designPendingStatus === 'Pending' &&
                      approvalStatus === 'ApprovalRevoked'
                    "
                  >
                    <span class="text-bold">
                      Why is my design / designer account under review?
                    </span>
                    <br />
                    <span
                      v-html="
                        unPrettierNuggets.submissionStatusCopy
                          .accountUnderReview
                      "
                    ></span>
                  </template>
                  <template
                    v-else-if="
                      item.designPendingStatus === 'PendingAutoApproval' &&
                      approvalStatus === 'Approved' &&
                      item.starRatingAvg
                    "
                  >
                    <span class="text-bold">
                      Why has my submission been delayed?
                    </span>
                    <br />
                    <span
                      v-html="
                        unPrettierNuggets.submissionStatusCopy.getDesignDelayed(
                          item,
                        )
                      "
                    ></span>
                  </template>
                  <template
                    v-else-if="
                      item.designPendingStatus === 'PendingAutoApproval' &&
                      approvalStatus === 'Approved' &&
                      !item.starRatingAvg
                    "
                  >
                    <span class="text-bold">
                      Why has my submission been delayed?
                    </span>
                    <br />
                    <span
                      v-html="
                        unPrettierNuggets.submissionStatusCopy
                          .designDelayedNoRating
                      "
                    ></span>
                  </template>
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <ImageModal
      :is-visible="showImageModal"
      :title="modalTitle"
      :image-url="imageUrl"
      @close="closeImageModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import SubmissionsFilter from './SubmissionsFilter.vue';
import ImageModal from './ImageModal.vue';
import ManageAccountEmptyCard from '../../ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};
export default {
  components: {
    SubmissionsFilter,
    ImageModal,
    ManageAccountEmptyCard,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
    submissions: {
      type: Array,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
    briefFilterData: {
      type: Array,
      required: true,
    },
    apiBaseUrl: {
      type: String,
      required: true,
    },
    minQaStarRating: {
      type: Number,
      required: true,
    },
    helpArticleStarRatingsUrl: {
      type: String,
      required: true,
    },
    approvalStatus: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      allSubmissions: [],
      submissionsFilterDisabled: false,
      briefs: this.briefFilterData,
      canHide: true,
      imageUrl: '',
      modalTitle: '',
      briefId: 0,
      showImageModal: false,
      emptyCardMessage: null,
      unPrettierNuggets: {
        submissionStatusCopy: {
          designUnderReview: `Your design has been flagged for review because you are a new designer. All new designers submissions are reviewed and approved before being processed (for quality verification).`,
          accountUnderReview: `Your designer account has been flagged as dropping below our quality standards. As such, your new submissions will be reviewed and approved before being sent to the customer (for quality verification). Once our internal quality team reviews some of your submissions, you will be fully approved.`,
          getDesignDelayed(item) {
            return `Don't worry, your design will be submitted shortly. We are rewarding ${this.minQaStarRating}+ Star Rated designers with a head start. Your current Star Rating for ${item.briefName} is ${item.starRatingAvg}. <a href='${this.helpArticleStarRatingsUrl}'>Learn how to improve your Star Ratings to get ahead!</a>`;
          },
          designDelayedNoRating: `Don't worry, your design will be submitted shortly. We are rewarding ${this.minQaStarRating}+ Star Rated designers with a head start. <a href='${this.helpArticleStarRatingsUrl}'>Learn how to improve your Star Ratings to get ahead!</a>`,
        },
      },
    };
  },
  computed: {
    nothingToShow() {
      return this.allSubmissions.length === 0;
    },
    somethingToShow() {
      return this.allSubmissions.length > 0;
    },
  },
  watch: {
    submissions() {
      this.initialiseItems();
      this.submissionsFilterDisabled = false;
      if (this.allSubmissions.length === 0) {
        this.emptyCardMessage = 'No items matched your filter.';
      }
    },
  },
  mounted() {
    this.initialiseItems();
  },
  methods: {
    initialiseItems() {
      this.allSubmissions = this.submissions.map((submission) => ({
        ...submission,
        isCollapsed: submission.isCollapsed || true,
      }));
    },
    openImage(url, id, desId) {
      // window.open(url);
      this.imageUrl = url;
      this.modalTitle = `Contest Brief #${id} (Design Id #${desId})`;
      this.showImageModal = true;
    },
    closeImageModal() {
      this.imageUrl = '';
      this.briefId = 0;
      this.showImageModal = false;
    },
    onCollapse(index) {
      this.allSubmissions[index].isCollapsed =
        !this.allSubmissions[index].isCollapsed;
    },
    async onWithdraw(index) {
      await this.api(index, { withdraw: true });
      this.allSubmissions[index].showActionWithdraw = false;
    },
    async onHide(index) {
      const res = await this.api(index, { hide: true });
      this.canHide = res.AllowFutureHides;
      this.allSubmissions[index].showActionHide = false;
      this.allSubmissions[index].showActionUnHide = true;
    },
    async onUnHide(index) {
      const res = await this.api(index, { show: true });
      this.canHide = res.AllowFutureHides;
      this.allSubmissions[index].showActionHide = true;
      this.allSubmissions[index].showActionUnHide = false;
    },
    async onFavourite(index) {
      await this.api(index, { favourite: true });
      this.allSubmissions[index].showActionFavourite = false;
      this.allSubmissions[index].showActionUnFavourite = true;
    },
    async onUnFavourite(index) {
      await this.api(index, { unfavourite: true });
      this.allSubmissions[index].showActionFavourite = true;
      this.allSubmissions[index].showActionUnFavourite = false;
    },
    apoUpdated(apo) {
      this.submissionsFilterDisabled = true;
      this.$emit('apo-updated', apo);
    },
    async api(index, apo) {
      this.allSubmissions[index].disabled = true;
      const res = await axios.post(
        this.apiBaseUrl,
        {
          submissionAction: {
            designId: this.allSubmissions[index].designId,
            submissionId: this.allSubmissions[index].submissionId,
            ...apo,
          },
        },
        axiosConfig,
      );
      this.allSubmissions[index].disabled = false;
      return res;
    },
  },
};
</script>
