<template>
  <form action="" method="post">
    <h4 class="mbs">News and announcement emails</h4>
    <span>{{ email }}</span>
    <div class="row mtm">
      <div class="column small-12">
        <label>
          <input
            v-model="productOfferAndPromotion"
            type="checkbox"
            name="offersAndPromotions"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black"
              >Product offers and promotions</strong
            >
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          Send me awesome, tailored DesignCrowd offers every month!
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mtm">
          <input
            v-model="newsletter"
            type="checkbox"
            name="newsletter"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black">Newsletter</strong>
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          {{ unPrettierNuggets.newsletterBlurb }}
        </p>
      </div>
    </div>
    <hr />
    <h4 class="mbm">Project specific emails</h4>
    <div class="row">
      <div class="column small-12">
        <label>
          <input
            v-model="generalProjectAlert"
            type="checkbox"
            name="newsletter"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black">
              General project alerts/notifications
            </strong>
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          {{ unPrettierNuggets.generalProjectAlertBlurb }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mtm">
          <input
            v-model="newDesignNotification"
            type="checkbox"
            name="newsletter"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black">New design notification</strong>
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          {{ unPrettierNuggets.newDesignNotificationBlurb }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mtm">
          <input
            v-model="dailySummary"
            type="checkbox"
            name="newsletter"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black">Daily summary email</strong>
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          {{ unPrettierNuggets.dailySummaryBlurb }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mtm">
          <input
            v-model="inviteNotification"
            type="checkbox"
            name="newsletter"
            class="input-type--checkbox"
          />
          <span>
            <strong class="text-black">Invite notification</strong>
          </span>
        </label>
      </div>
      <div class="column small-12">
        <p class="text-medium mll">
          {{ unPrettierNuggets.inviteNotificationBlurb }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column text-center">
        <hr class="horizontal-rule horizontal-rule--form-frame-small mvm" />
        <button class="button mbn" @click="onSubmitUpdateSubscriptionsForm">
          Update Preferences
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      default: undefined,
    },
    productOfferAndPromotionEnabled: {
      type: Boolean,
      default: false,
    },
    newsletterEnabled: {
      type: Boolean,
      default: false,
    },
    generalProjectAlertEnabled: {
      type: Boolean,
      default: false,
    },
    newDesignNotificationEnabled: {
      type: Boolean,
      default: false,
    },
    dailySummaryEnabled: {
      type: Boolean,
      default: false,
    },
    inviteNotificationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productOfferAndPromotion: this.productOfferAndPromotionEnabled,
      newsletter: this.newsletterEnabled,
      generalProjectAlert: this.generalProjectAlertEnabled,
      newDesignNotification: this.newDesignNotificationEnabled,
      dailySummary: this.dailySummaryEnabled,
      inviteNotification: this.inviteNotificationEnabled,
      unPrettierNuggets: {
        newsletterBlurb: `Send me the hand curated monthly (or thereabouts) newsletter chock-full of tech, business and design articles, product updates, tutorials, profiles and community news`,
        inviteNotificationBlurb: `Send me a notification when designers accept / reject invitations`,
        generalProjectAlertBlurb: `Send me emails related to my projects and contests (such as payments and project status emails)`,
        newDesignNotificationBlurb: `Send me a notification every time a new design is submitted to my project`,
        dailySummaryBlurb: `Send me a daily summary of new designs and activity associated to my live projects`,
      }
    };
  },
  methods: {
    onSubmitUpdateSubscriptionsForm(e) {
      e.preventDefault();
      this.$emit('update-subscription', {
        productOfferAndPromotionEnabled: this.productOfferAndPromotion,
        newsletterEnabled: this.newsletter,
        generalProjectAlertEnabled: this.generalProjectAlert,
        newDesignNotificationEnabled: this.newDesignNotification,
        dailySummaryEnabled: this.dailySummary,
        inviteNotificationEnabled: this.inviteNotification,
      });
    },
  },
};
</script>
