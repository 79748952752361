<template>
  <fragment name="pagination">
    <ul
      id="paging"
      class="pagination text-center align-center align-middle pager"
      role="navigation"
      aria-label="Pagination"
    >
      <li
        :class="{
          ['pagination-previous']: true,
          disabled: pagination.current === 1,
        }"
      >
        <span v-if="pagination.current === 1">
          <img :src="leftArrowIconUrl" alt="Pagination left arrow" />
          <span class="show-for-sr">Go to previous page</span>
        </span>
        <a
          v-else
          title="Go to previous page"
          @click="onClickPageLink(pagination.current - 1)"
        >
          <img :src="leftArrowIconUrl" alt="Pagination left arrow" />
          <span class="show-for-sr">Go to previous page</span>
        </a>
      </li>
      <li v-if="shouldRenderMinPageLink">
        <a @click="onClickPageLink(1)">1</a>
      </li>
      <li v-if="shouldRenderMinPageLink" class="ellipsis"></li>
      <li
        v-for="page in pages"
        :key="page"
        :class="{ current: pagination.current === page }"
      >
        <span v-if="pagination.current === page" name="current-page-link">
          <span class="show-for-sr">You're on page</span>
          <span>{{ page }}</span>
        </span>
        <a v-else @click="onClickPageLink(page)">
          <span class="show-for-sr">Go to page</span>
          {{ page }}
        </a>
      </li>
      <li v-if="shouldRenderMaxPageLink" class="ellipsis"></li>
      <li v-if="shouldRenderMaxPageLink">
        <a @click="onClickPageLink(pagination.maxPage)">
          {{ pagination.maxPage }}
        </a>
      </li>
      <li class="pagination__page-no">
        Page {{ pagination.current }} of {{
          pagination.maxPage
        }}
      </li>
      <li
        :class="{
          ['pagination-next']: true,
          disabled: pagination.current === pagination.maxPage,
        }"
      >
        <span v-if="pagination.current === pagination.maxPage">
          <img :src="rightArrowIconUrl" alt="pagination right arrow" />
          <span class="show-for-sr">Go to next page</span>
        </span>
        <a
          v-else
          title="Go to next page"
          @click="onClickPageLink(pagination.current + 1)"
        >
          <img :src="rightArrowIconUrl" alt="pagination right arrow" />
          <span class="show-for-sr">Go to next page</span>
        </a>
      </li>
    </ul>
    <p class="p-small text-center mbn">
      {{ pagination.startOfPage }} - {{ pagination.numberOfResults }}
      of
      {{ pagination.totalResultsCount }} {{ pagination.description }}
    </p>
  </fragment>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    leftArrowIconUrl: {
      type: String,
      required: true,
    },
    rightArrowIconUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pages: [],
      minPageLinkThreshold: 4,
    };
  },
  computed: {
    previousPageUrl() {
      const prevPage = this.pagination.current - 1;
      return this.getPageUrl(prevPage);
    },
    nextPageUrl() {
      let nextPage = this.pagination.current + 1;

      if (nextPage > this.pagination.maxPage) {
        nextPage = this.pagination.maxPage;
      }

      return this.getPageUrl(nextPage);
    },
    shouldRenderMaxPageLink() {
      return this.pagination.end < this.pagination.maxPage;
    },
    shouldRenderMinPageLink() {
      if (this.pagination.start === 1) {
        return false;
      }

      return this.pagination.current >= this.minPageLinkThreshold;
    },
  },
  watch: {
    pagination: {
      handler(newPagination) {
        this.pages = this.getPages(newPagination.start, newPagination.end);
      },
      deep: true,
    },
  },
  mounted() {
    this.pages = this.getPages(this.pagination.start, this.pagination.end);
  },
  methods: {
    getPages(x, y) {
      const numbers = [];

      for (let i = x; i <= y; i += 1) {
        numbers.push(i);
      }

      return numbers;
    },
    getPageUrl(pageNo) {
      let paginationUrl = '';
      let queryUrl = '';

      if (this.pagination.query) {
        queryUrl = `${this.pagination.query}`;
      } else {
        queryUrl = '';
      }

      if (pageNo && pageNo > 1) {
        queryUrl = `${queryUrl}${queryUrl ? '&' : ''}page=${pageNo}`;
      }
      if (queryUrl) {
        paginationUrl = `${this.pagination.baseUrl}?${queryUrl}`;
      } else {
        paginationUrl = `${this.pagination.baseUrl}`;
      }

      return paginationUrl;
    },
    onClickPageLink(pageNo) {
      this.$emit('page-link-click', pageNo);
    },
  },
};
</script>
