<template>
  <Modal
    title="Rate your experience"
    :close-icon="closeIconUrl"
    :is-visible="isModalVisible"
    @closed="onCloseTipDesignerModal"
  >
    <template #body>
      <form
        :ref="tipDesignerFormRef"
        :action="tipDesignerAction"
        method="post"
        data-abide
      >
        <div class="text-center">
          <div class="row align-center">
            <div class="column shrink">
              <div class="user-profile user-profile--large mbm">
                <div
                  v-if="designerProfileThumbnailPath"
                  class="user-profile__image"
                >
                  <div class="responsive-embed square">
                    <img
                      class="responsive-embed__image"
                      :src="designerProfileThumbnailPath"
                      alt="Profile picture"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Rate {{ designerDisplayName }}</p>
          <StarRatingSelectable
            size="medium"
            :rating="starRating"
            @click="onClickStarRating($event)"
          />
          <p>Leave a tip? (optional)</p>
          <button
            class="button secondary tiny hollow"
            data-close
            @click="onCloseTipDesignerModal"
          >
            No Tip
          </button>
          <button
            class="button secondary tiny hollow"
            @click="onClickTipDesigner($event, 10)"
          >
            {{ currencyAbbreviation }}10
          </button>
          <button
            class="button secondary tiny hollow"
            @click="onClickTipDesigner($event, 25)"
          >
            {{ currencyAbbreviation }}25
          </button>
          <button
            class="button secondary tiny hollow"
            @click="onClickTipDesigner($event, 50)"
          >
            {{ currencyAbbreviation }}50
          </button>
          <button
            class="button secondary tiny hollow"
            @click="onClickTipDesignerFormOther"
          >
            Other
          </button>
          <div
            :class="{
              ['row align-center']: true,
              hide: isTipDesignerFormHidden,
            }"
          >
            <div class="column small-12 medium-5">
              <div class="input-group">
                <span class="input-group-label">
                  {{ currencyAbbreviation }}
                </span>
                <input type="hidden" name="briefId" :value="briefId" />
                <input type="hidden" name="designerId" :value="designerId" />
                <input
                  :ref="tipDesignerAmountInputRef"
                  v-model="tipDesignerAmount"
                  class="input-group-field"
                  type="number"
                  name="purchaseAmount"
                  :min="tipDesignerAmountMinimum"
                  required
                />
                <span class="input-group-label">.00</span>
              </div>
              <span v-if="isTipDesignerFormDisabled" class="u-block form-error">
                Please enter an amount to tip
              </span>
            </div>
            <div class="column shrink">
              <button
                class="button mbn"
                :disabled="isTipDesignerFormDisabled"
                @click="onClickSubmitTipDesignerForm"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../../../shared/Modal/Modal.vue';
import StarRatingSelectable from '../../../shared/StarRatingSelectable/StarRatingSelectable.vue';

export default {
  components: {
    Modal,
    StarRatingSelectable,
  },
  props: {
    designerProfileThumbnailPath: {
      type: String,
      default: undefined,
    },
    designerDisplayName: {
      type: String,
      required: true,
    },
    designerPageUrl: {
      type: String,
      required: true,
    },
    tipDesignerAction: {
      type: String,
      default: undefined,
    },
    isWinner: {
      type: Boolean,
      default: undefined,
    },
    isDesignOptions: {
      type: Boolean,
      default: undefined,
    },
    isModalVisible: {
      type: Boolean,
      default: false,
    },
    currencyAbbreviation: {
      type: String,
      required: true,
    },
    designerId: {
      type: Number,
      required: true,
    },
    designId: {
      type: Number,
      default: undefined,
    },
    closeIconUrl: {
      type: String,
      default: undefined,
    },
    starRating: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      tipDesignerAmount: 10,
      tipDesignerAmountMinimum: 10,
      tipDesignerAmountInputRef: 'js-tip-designer-amount-input',
      tipDesignerFormRef: 'js-tip-designer-form',
      isTipDesignerFormHidden: true,
    };
  },
  computed: {
    tipDesignerAmountInt() {
      return parseInt(this.tipDesignerAmount, 10);
    },
    isTipDesignerFormDisabled() {
      return (
        !this.tipDesignerAmountInt ||
        this.tipDesignerAmountInt < this.tipDesignerAmountMinimum
      );
    },
    briefId() {
      const urlMatch = this.$route
        ? this.$route.fullPath.match(/([^/]+$)/)
        : null;

      if (urlMatch && urlMatch.length > 1) {
        return parseInt(urlMatch[1], 10);
      }

      return null;
    },
  },
  methods: {
    async onClickTipDesigner(event, tipAmount) {
      event.preventDefault();
      this.tipDesignerAmount = tipAmount;
      this.$refs[this.tipDesignerAmountInputRef].value = tipAmount;
      this.$refs[this.tipDesignerFormRef].submit();
    },
    async onClickSubmitTipDesignerForm() {
      if (!this.isTipDesignerFormDisabled) {
        this.$refs[this.tipDesignerFormRef].submit();
      }
    },
    onClickTipDesignerFormOther(event) {
      event.preventDefault();
      this.isTipDesignerFormHidden = false;
    },
    onCloseTipDesignerModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.isTipDesignerFormHidden = true;
      this.$emit('modal-closed');
    },
    onClickStarRating(rating) {
      this.$emit('click-star-rating', rating);
    },
  },
};
</script>
