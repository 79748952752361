<!-- eslint-disable prettier/prettier -->
<template>
  <fragment name="submit-source-files-page-container">
    <div class="row">
      <div class="column small-12">
        <SubmitDesign :models="models" :icons="icons" />
      </div>
    </div>
  </fragment>
</template>

<script>
import SubmitDesign from '../../components/my-account/designer/submit-design/SubmitDesign.vue';

export default {
  components: { SubmitDesign },
  props: {
    models: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
