<template>
  <Modal
    title="Invite Designers"
    class-name="large"
    :close-icon="closeIcon"
    :is-loading="isLoading"
    :is-visible="true"
  >
    <template v-slot:body>
      <div v-if="!invitesSendSuccess">
        <p class="text-black">
          <span v-html="designerCountMessage"></span>
          <span
            v-if="invite.BasePaymentCount > 0"
            v-html="basePaymentCountMessage"
          >
          </span>
        </p>
        <Callout
          v-if="isTooManyParticipationPayments"
          type="warning"
          :icon-url="warningIcon"
          title="Too many participation payments allocated"
          :closable="true"
          @close="isTooManyParticipationPayments = false"
        >
          <template v-slot:message>
            You only have {{ invite.BasePaymentCount }} participation payment(s) to allocate. Deselect another designer's participation payment before trying to allocate
          </template>
        </Callout>
        <div v-if="inviteData" class="table-scroll-modal">
          <table class="table stack">
            <thead class="hide-for-small-only hide-for-medium-only">
              <tr>
                <th>Designer</th>
                <th>Send Invite?</th>
                <th v-if="invite.BasePaymentCount > 0">
                  Send Participation Payment?
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(designer, index) in inviteData.Designers"
                :key="index"
              >
                <td>
                  <div class="user-profile user-profile--tiny mhn">
                    <div class="row collapse align-middle mhn">
                      <div class="column shrink">
                        <div
                          v-if="designer.ProfilePicUrl"
                          class="user-profile__image"
                        >
                          <div class="responsive-embed square">
                            <img
                              class="responsive-embed__image"
                              height="40"
                              width="40"
                              :src="designer.ProfilePicUrl"
                              alt="Profile picture"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="column">
                        <div class="row">
                          <div class="column small-12">
                            <span class="user-profile__small-text">
                              <a :href="designer.PortfolioUrl">
                                {{ designer.DisplayName }}
                              </a>
                            </span>
                          </div>
                          <div
                            v-if="designer.CountryName"
                            class="column small-12"
                          >
                            <span class="user-profile__small-text">
                              {{ designer.CountryName }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <Checkbox
                    :id="`invite-designer-${designer.DesignerId}`"
                    :checked="invitedDesigners[index].isInvited"
                    @input="onInviteDesignerChecked($event, index)"
                  >
                    Invite to project
                  </Checkbox>
                </td>
                <td v-if="invite.BasePaymentCount">
                  <Checkbox
                    :id="`send-participation-payment-${designer.DesignerId}`"
                    :checked="invitedDesigners[index].isPaidInvite"
                    @input="onParticipationPaymentChecked($event, index)"
                  >
                    Participation payment
                  </Checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <Checkbox id="reallocate-invites" @input="reallocateInvites = $event">
            Reallocate invites if rejected
          </Checkbox>
        </div>
      </div>
      <div v-else>
        <Callout
          type="success"
          :icon-url="successIcon"
          title="Invite Sent Successfully"
          @close="isTooManyParticipationPayments = false"
        >
          <template v-slot:message>
            We have sent {{ invitedDesignersCount }} invite(s) on your behalf.
          </template>
        </Callout>
        <p>
          You can also hand-pick and send invites to designers in our designer directories. To do this simply:
        </p>
        <ol>
          <li>
            <span v-html="designerPortfolioMessage"></span>
          </li>
          <li>
            Find a designer you want to personally invite to your project
          </li>
          <li>
            <span v-html="getADesignFromMeMessage"></span>
            <img
              :src="inviteDesignerPortfolioBanner"
              alt="Invite Designer Portfolio Banner"
            />
          </li>
          <li>
            <span v-html="forMoreInfoMessage"></span>
          </li>
        </ol>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="!invitesSendSuccess">
        <input
          type="submit"
          :class="{
            ['button small mrm']: true,
            disabled: invitedDesignersCount === 0,
          }"
          value="Send Invites"
          @click="onClickSubmitInvites"
        />
        <button
          class="button secondary hollow small"
          data-close
          data-role="cancel-invite-wizard"
        >
          Cancel
        </button>
      </div>
      <div v-else>
        <button class="button small" data-close>Okay, Got It</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../shared/Modal/Modal.vue';
import Checkbox from '../../../shared/Checkbox/Checkbox.vue';
import Callout from '../../../shared/Callout/Callout.vue';
import submitInvitesAsync from '../../../../clients/invites-api';

export default {
  components: {
    Modal,
    Checkbox,
    Callout,
  },
  props: {
    warningIcon: {
      type: String,
      default: undefined,
    },
    successIcon: {
      type: String,
      default: undefined,
    },
    invite: {
      type: Object,
      required: true,
    },
    closeIcon: {
      type: String,
      default: undefined,
    },
    inviteDesignerPortfolioBanner: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      invitedDesigners: [],
      inviteData: null,
      isTooManyParticipationPayments: false,
      reallocateInvites: false,
      invitesSendSuccess: false,
      isLoading: false,
    };
  },
  computed: {
    designerCount() {
      return this.invite.Designers.length;
    },
    invitedDesignersCount() {
      return this.invitedDesigners.filter((payment) => payment.isInvited)
        .length;
    },
    briefId() {
      const urlMatch = this.$route
        ? this.$route.fullPath.match(/([^/]+$)/)
        : null;

      if (urlMatch && urlMatch.length > 1) {
        return parseInt(urlMatch[1], 10);
      }

      return null;
    },
    designerCountMessage() {
      return `We recommend inviting the following <strong>${this.designerCount} designers</strong> to your project.`;
    },
    basePaymentCountMessage() {
      return `You can allocate <strong>${this.invite.BasePaymentCount} participation payments (paid invites) to entice top designers to work to your project.</strong>`;
    },
    getADesignFromMeMessage() {
      return `Click on the <strong>Get a design from me</strong> on a designer portfolio and follow the prompts`;
    },
    forMoreInfoMessage() {
      return `For more info see <a href='${this.invite.ForMoreInfoUrl}'>Can I invite designers to my project?</a>`;
    },
    designerPortfolioMessage() {
      return `Go to the designer portfolio - click on the <a href='${this.invite.DesignersListUrl}'>Designers</a> link in the DesignCrowd navigation bar`;
    },
  },
  mounted() {
    this.initialiseInvitedDesigners();
    this.inviteData = this.invite;
  },
  methods: {
    initialiseInvitedDesigners() {
      this.invitedDesigners = this.invite.Designers.map((designer) => ({
        designerId: designer.DesignerId,
        isPaidInvite: designer.RecommendedForPaidInvite,
        isInvited: true,
      }));
    },
    onInviteDesignerChecked(checked, index) {
      this.$set(this.invitedDesigners[index], 'isInvited', checked);
    },
    onParticipationPaymentChecked(checked, index) {
      this.$set(this.invitedDesigners[index], 'isPaidInvite', checked);
      const checkedPayments = this.invitedDesigners.filter(
        (x) => x.isPaidInvite,
      );

      if (
        checkedPayments &&
        checkedPayments.length > this.inviteData.BasePaymentCount
      ) {
        this.$nextTick(() => {
          this.$set(this.invitedDesigners[index], 'isPaidInvite', false);
          this.isTooManyParticipationPayments = true;
        });
      } else {
        this.isTooManyParticipationPayments = false;
      }
    },
    async onClickSubmitInvites() {
      if (this.invitedDesignersCount > 0) {
        const invites = {
          autoReallocate: this.reallocateInvites,
          designers: this.invitedDesigners.filter(
            (designer) => designer.isInvited,
          ),
        };

        this.isLoading = true;
        const res = await submitInvitesAsync(this.briefId, invites);
        this.invitesSendSuccess = res;
        this.isLoading = false;
      }
    },
  },
};
</script>
