<template>
  <a :href="tileLinkUrl" @click="onClick">
    <div
      :class="{
        tile: true,
        'tile--selected': selected,
        'tile--thumbnail': isThumbnail,
      }"
    >
      <slot />
    </div>
  </a>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: undefined,
    },
    isThumbnail: {
      type: Boolean,
      default: undefined,
    },
    tileLinkUrl: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      selected: this.isSelected,
    };
  },
  watch: {
    isSelected(selected) {
      this.selected = selected;
    },
  },
  methods: {
    onClick(e) {
      if (!this.tileLinkUrl) {
        e.preventDefault();
      }

      this.selected = !this.selected;
      this.$emit('selected', this.selected);
    },
  },
};
</script>
