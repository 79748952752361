<template>
  <div
    :ref="masonryGridRef"
    :class="{
      row: true,
      [classes]: classes,
    }"
  >
    <div
      v-for="(column, index) in columns"
      :key="index"
      class="column small-12 medium-6 large-3"
    >
      <div
        v-for="(item, i) in column"
        :key="i"
        :class="{ ['opacity-0']: isLoading }"
      >
        <slot :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import allImagesLoaded from '../../../helpers/image-loader';
import mediaQuery from '../../../helpers/mediaquery';

export default {
  props: {
    classes: {
      type: [String, Object],
      default: undefined,
    },
    items: {
      type: Array,
      required: true,
    },
    waitForImagesToLoad: {
      type: Boolean,
      default: false,
    },
    imageToLoadDataId: {
      type: String,
      default: undefined,
    },
    imgPlaceholder: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      masonryGridRef: 'js-masonry-grid',
      isLoading: true,
      columns: [],
      numColumns: 4,
    };
  },
  async mounted() {
    this.updateColumns();
    this.updateColumnsOnPageResize();
    this.populateColumns();
    await this.loadAllImages();
  },
  methods: {
    updateColumns() {
      if (mediaQuery.isSmall()) {
        this.numColumns = 1;
      } else if (mediaQuery.isMedium()) {
        this.numColumns = 2;
      } else {
        this.numColumns = 4;
      }

      this.populateColumns();
    },
    updateColumnsOnPageResize() {
      window.addEventListener('resize', debounce(this.updateColumns, 150));
    },
    populateColumns() {
      this.columns = [];

      for (let i = 0; i < this.numColumns; i += 1) {
        this.columns.push([]);
      }

      let columnToBePopulated = 0;

      for (let i = 0; i < this.items.length; i += 1) {
        if (columnToBePopulated > this.numColumns - 1) {
          columnToBePopulated = 0;
        }

        this.columns[columnToBePopulated].push(this.items[i]);

        columnToBePopulated += 1;
      }
    },
    async loadAllImages() {
      if (this.waitForImagesToLoad) {
        await allImagesLoaded(
          this.$refs[this.masonryGridRef],
          this.imageToLoadDataId,
          {
            url: this.imgPlaceholder,
            class: 'pal mal',
          },
        );
      }
      this.isLoading = false;
      this.onLoadingComplete();
    },
    onLoadingComplete() {
      this.$emit('on-loading-complete');
    },
  },
};
</script>
