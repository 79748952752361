<template>
  <div class="row">
    <div class="column small-12">
      <div class="row align-justify">
        <div class="column shrink">{{ name }}</div>
        <div class="column shrink">
          <a v-if="details">
            <img
              v-if="!isDetailsVisible"
              :src="arrowDownIconUrl"
              alt="arrow down"
              @click="isDetailsVisible = true"
            />
            <img
              v-else
              :src="arrowUpIconUrl"
              alt="arrow up"
              @click="isDetailsVisible = false"
            />
          </a>
        </div>
      </div>
      <div class="row">
        <div v-if="isDetailsVisible" class="column">
          <hr class="show-for-medium mvs" />
          <ul class="no-bullet text-left mbn">
            <li v-for="(detail, index) in details" :key="index">
              {{ detail }}
            </li>
          </ul>
          <hr class="hide-for-medium mvs" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    details: {
      type: Array,
      default: () => [],
    },
    arrowDownIconUrl: {
      type: String,
      required: true,
    },
    arrowUpIconUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDetailsVisible: false,
    };
  },
};
</script>
