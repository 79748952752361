<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <div v-if="currentPage && currentPage.length > 0">
        <SubscriptionCard
          v-for="subscription in currentPage"
          :key="subscription.subscriptionId"
          class="mbm"
          :icons="icons"
          :subscription="subscription"
          :cancel-subscription-flow="cancelSubscriptionFlow"
        />
        <Pagination
          v-if="pagination"
          :max-page="pagination.maxPage"
          :current="pagination.current"
          :start="pagination.start"
          :end="pagination.end"
          :start-of-page="pagination.startOfPage"
          :number-of-results="pagination.numberOfResults"
          :total-results-count="pagination.totalResultsCount"
          :description="pagination.description"
          :base-url="pagination.baseUrl"
          :api-base-url="pagination.apiBaseUrl"
          :left-arrow-icon-url="icons.paginationLeftArrow"
          :right-arrow-icon-url="icons.paginationRightArrow"
          @page-link-click="onPaginationClick($event)"
        />
      </div>
      <div v-else class="frame frame--padding-small text-center ptn">
        <img :src="icons.emptySubscriptions" alt="Empty subscriptions icon" />
        <p>
          <strong class="text-black">No Subscriptions</strong>
          <br />
          <span>Looks like you don't have any subscriptions yet</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import SubscriptionCard from '../../components/my-account/customer/manage-subscriptions/SubscriptionCard/SubscriptionCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';

export default {
  components: {
    ManageAccountMenu,
    SubscriptionCard,
    Pagination,
  },
  props: {
    icons: {
      type: Object,
      default: () => {},
    },
    cancelSubscriptionFlow: {
      type: Object,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    },
    subscriptions: {
      type: Array,
      default: () => [],
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.subscriptions,
      pagination: this.paginationObject,
    };
  },
  methods: {
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
  },
};
</script>
