<template>
  <div
    data-test-upload-box
    :data-upload-id="options.Id"
    data-upload-target
    style="width: 100%"
  >
    <div data-uploads class="is-hidden"></div>
    <input type="hidden" name="filesJson" value="[]" />
    <input
      :id="options.HasFileCheckboxId"
      class="is-hidden"
      type="checkbox"
      name="hasFile"
      :required="options.Required"
    />
    <div
      class="dropzone__header dropzone__header--large"
      data-js-dropzone-clickable
      data-test-dropzone="header"
    >
      <img
        class="dropzone__file-upload-image"
        :src="icons.uploader.upload"
        alt="File upload"
      />
      <p class="dropzone__message dz-message" style="margin: auto">
        Drop your files here&nbsp;<span class="dropzone__message-link"
          >or <a>click to upload</a></span
        >
        <br />
        <span class="text-center">
          {{ GetUploaderInstructionalCopy() }}
        </span>
      </p>
    </div>
    <div style="display: none" data-preview-template>
      <div class="dropzone__upload-preview">
        <div class="dropzone__preview-left small-6 medium-4">
          <div class="dropzone__image">
            <img data-dz-thumbnail="" alt="dz-thumbnail" />
          </div>
          <span class="dropzone__filename" data-dz-name=""></span>
        </div>
        <div
          class="dropzone__preview-right small-6 medium-8"
          style="justify-content: space-between"
        >
          <div class="dropzone__progress progress" data-js-dropzone-progress>
            <span
              class="dz-upload progress-meter"
              data-dz-uploadprogress=""
            ></span>
          </div>
          <div class="small-8 medium-10">
            <div class="dropzone__error text-left">
              <div class="dz-error-mark">
                <span>Upload failed!</span>
              </div>
              <span data-dz-errormessage></span>
            </div>
          </div>
          <div
            data-js-dropzone-preview-right-ui
            class="hide flex-container align-right shrink small-2"
          >
            <span class="dropzone__size" data-dz-size=""></span>
            <img
              class="dropzone__remove"
              height="16"
              width="16"
              :src="icons.uploader.delete"
              alt="Delete icon"
              data-dz-remove
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    icons: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    (function (options) {
      window.require(['www'], function () {
        window.require(['app/fileUploader'], function (fileUploader) {
          fileUploader.inititalise(options);
        });
      });
    })(this.options);
  },
  methods: {
    GetUploaderInstructionalCopy() {
      if (this.options.PixelLimits)
        if (this.options.AcceptedFileExtensionsDisplay === '*.*')
          return `We accept ${this.options.MaxFiles} file(s) up to ${this.options.MaxFileSize}MB and ${this.options.PixelLimits.MaxWidth}x${this.options.PixelLimits.MaxHeight} pixels of any type`;
        else
          return `We accept ${this.options.MaxFiles} file(s) up to ${this.options.MaxFileSize}MB and ${this.options.PixelLimits.MaxWidth}x${this.options.PixelLimits.MaxHeight} pixels of type ${this.options.AcceptedFileExtensionsDisplay}`;
      return `We accept ${this.options.MaxFiles} files up to ${this.options.MaxFileSize}MB of type ${this.options.AcceptedFileExtensionsDisplay}px`;
    },
  },
};
</script>
