<template>
  <div class="addon form-stacking text-center medium-text-left mbl">
    <h3>Getting Started - Complete Your Profile</h3>
    <div
      class="progress mtm mbm"
      role="progressbar"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuetext="25 percent"
      aria-valuemax="100"
    >
      <span class="progress-meter" :style="widthStyle">
        <span class="progress-meter-text">{{ getPercentage }}</span>
      </span>
    </div>
    <h6>Things left to do:</h6>
    <ul class="user-profile__completeness mln flex-container column pln">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ completed: item.complete }"
        class="text-left"
      >
        <div class="row align-middle">
          <div class="column shrink">
            <div v-if="item.complete" class="badge badge--small">
              <img :src="tickIcon" alt="tick icon" />
            </div>
            <div
              v-else
              class="badge badge--small badge--hollow badge--thin secondary"
            ></div>
          </div>
          <div class="column">
            <h6>{{ item.name }}</h6>
          </div>
          <div class="column shrink">
            <a v-if="!item.complete" :href="item.actionUrl" class="button secondary hollow tiny mbn">
              Resolve
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    percentage: {
      type: Number,
      default: 0,
    },
    tickIcon: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      getPercentage: `${this.percentage}%`,
      widthStyle: {
        width: `${this.percentage}%`,
      },
    };
  },
};
</script>
