<template>
  <div class="my-project-card">
    <div class="my-project-card__header">
      <div class="row">
        <div class="column small-12 medium-shrink">
          <strong class="text-black" v-html="name"></strong>
        </div>
        <div v-if="isUnderReview" class="column small-12 medium-shrink">
          <span class="text-medium mrs">Under review</span>
          <div class="inline-tooltip">
            <div role="tooltip" class="tooltip top align-center">
              Your brief is currently being reviewed by our Quality Team to ensure the best possible results in your project. This process take up to 24 hours. Your project will be launched or you will be contacted by our team for more information.
            </div>
            <img :src="questionIconUrl" alt="Under review" />
          </div>
        </div>
        <div
          :class="{
            'column small-12 mla': true,
            'large-5': whitelabelling,
            'medium-shrink': !whitelabelling,
          }"
        >
          <div class="row">
            <div class="column small-12 medium-shrink">
              <fragment v-if="showGoPrivate" name="go-private">
                <span class="text-medium mrs">Public project</span>
                <div class="inline-tooltip mrm">
                  <div role="tooltip" class="tooltip top align-center">
                    Your project is currently public and can be listed on Google and seen by your competitors. Go private to increase your privacy.
                  </div>
                  <img :src="questionIconUrl" alt="Public project" />
                </div>
                <a
                  :href="goPrivateUrl"
                  class="button secondary hollow tiny mbn"
                >
                  Go Private
                </a>
              </fragment>
              <fragment v-if="statusCopy" name="status">
                <span>{{ statusCopy }}</span>
              </fragment>
              <div v-if="whitelabelling" class="row large-text-right">
                <div class="column small-12">
                  Whitelabelling Feature Enabled
                </div>
                <div class="column small-12">
                  Designs Shared with Client:
                  {{ whitelabellingDesignsShared }}
                </div>
                <div class="column small-12">
                  Feedback Received via Client Portal:
                  {{ whitelabellingFeedback }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 large-2">
        <div
          :class="{
            'my-project-card__status': true,
            [`my-project-card__status--${contentMode}`]: true,
          }"
        >
          <div class="my-project-card__status-draft">
            <span>Draft</span>
          </div>
          <div class="my-project-card__status-open">
            <span>Open</span>
          </div>
          <div class="my-project-card__status-complete">
            <span>Complete</span>
          </div>
        </div>
      </div>
      <div class="column small-12 large-10">
        <div class="my-project-card__body">
          <DraftProject
            v-if="contentMode === 'draft'"
            :active-step-urls="activeStepUrls"
            :project-progress-step1-icon-url="projectProgressStep1IconUrl"
            :project-progress-step2-icon-url="projectProgressStep2IconUrl"
            :project-progress-step3-icon-url="projectProgressStep3IconUrl"
            :project-progress-step4-icon-url="projectProgressStep4IconUrl"
          />
          <OpenProject
            v-if="contentMode === 'open'"
            :submission-count="submissionCount"
            :package-cost="packageCost"
            :comment-count="commentCount"
            :days-left="daysLeft"
            :manage-project-url="manageProjectUrl"
            :view-comments-url="viewCommentsUrl"
          />
          <ClosedProject
            v-if="contentMode === 'complete'"
            :design-thumbnail-url="designThumbnailUrl"
            :designer-display-name="designerDisplayName"
            :submission-count="submissionCount"
            :print-upsell-button-copy="printUpsellButtonCopy"
            :print-upsell-url="printUpsellUrl"
            :manage-project-url="manageProjectUrl"
            :new-project-with-designer-url="newProjectWithDesignerUrl"
          />
        </div>
        <div class="my-project-card__footer">
          <div class="row align-justify align-middle">
            <div class="column small-12 large-9">
              <a
                v-if="resumeOrderUrl"
                :href="resumeOrderUrl"
                class="button small"
              >
                Resume Order
              </a>
              <a
                v-if="manageProjectUrl"
                :href="manageProjectUrl"
                class="button small"
                data-test-cta="manage-project"
              >
                Manage Project
              </a>
              <a v-if="viewBriefUrl" :href="viewBriefUrl" class="button small">
                View brief
              </a>
              <a
                v-if="deleteDraftUrl"
                :href="deleteDraftUrl"
                class="button small alert hollow"
              >
                Delete Draft
              </a>
              <a
                v-if="accessFilesUrl"
                :href="accessFilesUrl"
                class="button secondary hollow small"
              >
                Access Files
              </a>
              <a
                v-if="viewInvoiceUrl"
                :href="viewInvoiceUrl"
                class="button secondary hollow small"
              >
                View Invoice
              </a>
              <a
                v-if="postAnUpdateUrl"
                :href="postAnUpdateUrl"
                class="button secondary hollow small"
              >
                Post An Update
              </a>
              <a
                v-if="getMoreDesignsUrl"
                :href="getMoreDesignsUrl"
                class="button secondary hollow small"
              >
                Get More Designs
              </a>
              <a
                v-if="upgradeProjectUrl"
                :href="upgradeProjectUrl"
                class="button secondary hollow small"
              >
                Upgrade Your Project
              </a>
            </div>
            <div
              class="column small-12 large-3 align-self-top text-center large-text-right"
            >
              <span class="my-project-card__date">
                Date created: {{ dateAdded }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DraftProject from '../DraftProject/DraftProject.vue';
import OpenProject from '../OpenProject/OpenProject.vue';
import ClosedProject from '../ClosedProject/ClosedProject.vue';

export default {
  components: {
    DraftProject,
    OpenProject,
    ClosedProject,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    activeStep: {
      type: Number,
      default: undefined,
    },
    daysLeft: {
      type: Number,
      default: undefined,
    },
    dateAdded: {
      type: String,
      required: true,
    },
    commentCount: {
      type: Number,
      default: undefined,
    },
    status: {
      type: String,
      required: true,
    },
    contentMode: {
      type: String,
      required: true,
    },
    packageCost: {
      type: String,
      default: '',
    },
    designThumbnailUrl: {
      type: String,
      default: '',
    },
    designerDisplayName: {
      type: String,
      default: '',
    },
    submissionCount: {
      type: Number,
      default: undefined,
    },
    showGoPrivate: {
      type: Boolean,
      default: false,
    },
    goPrivateUrl: {
      type: String,
      default: undefined,
    },
    statusCopy: {
      type: String,
      default: undefined,
    },
    whitelabelling: {
      type: Boolean,
      default: false,
    },
    whitelabellingDesignsSharedLabel: {
      type: String,
      default: undefined,
    },
    whitelabellingDesignsShared: {
      type: Number,
      default: undefined,
    },
    whitelabellingFeedbackLabel: {
      type: String,
      default: undefined,
    },
    whitelabellingFeedback: {
      type: Number,
      default: undefined,
    },
    printUpsellButtonCopy: {
      type: String,
      default: undefined,
    },
    printUpsellUrl: {
      type: String,
      default: undefined,
    },
    accessFilesUrl: {
      type: String,
      default: undefined,
    },
    deleteDraftUrl: {
      type: String,
      default: undefined,
    },
    getMoreDesignsUrl: {
      type: String,
      default: undefined,
    },
    viewInvoiceUrl: {
      type: String,
      default: undefined,
    },
    manageProjectUrl: {
      type: String,
      default: undefined,
    },
    postAnUpdateUrl: {
      type: String,
      default: undefined,
    },
    resumeOrderUrl: {
      type: String,
      default: undefined,
    },
    upgradeProjectUrl: {
      type: String,
      default: undefined,
    },
    viewBriefUrl: {
      type: String,
      default: undefined,
    },
    newProjectWithDesignerUrl: {
      type: String,
      default: undefined,
    },
    viewCommentsUrl: {
      type: String,
      default: undefined,
    },
    activeStepUrls: {
      type: Object,
      default: undefined,
    },
    projectProgressStep1IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep2IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep3IconUrl: {
      type: String,
      default: '',
    },
    projectProgressStep4IconUrl: {
      type: String,
      default: '',
    },
    questionIconUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    isUnderReview() {
      return (
        this.statusCopy && this.statusCopy.toLowerCase() === 'under review'
      );
    },
  },
};
</script>
