<template>
  <div
    :class="{
      thread__reply: isThreadReply,
      thread: !isThreadReply,
    }"
    :id="messageId"
  >
    <div class="row align-top collapse">
      <div class="column shrink">
        <div class="thread__user-profile-image">
          <div
            class="user-profile user-profile--designer-portfolio user-profile--tiny"
          >
            <div class="user-profile__image">
              <img
                :src="fromProfilePicThumbnailLocation"
                :alt="displayPictureAlt"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="row collapse">
          <div class="column">
            <strong>
              <a class="text-black mrs disable-link" :href="portfolioLink">
                {{ fromDisplayName }}
              </a>
            </strong>
            <span class="text-small">{{ messageDateText }}</span>
          </div>
        </div>
        <div class="row collapse">
          <div class="column small-12">
            <p class="mbm" v-html="content"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isThreadReply: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      required: true,
    },
    fromDisplayName: {
      type: String,
      required: true,
    },
    fromProfilePicThumbnailLocation: {
      type: String,
      required: true,
    },
    messageDateText: {
      type: String,
      required: true,
    },
    portfolioLink: {
      type: String,
      default: undefined,
    },
    messageId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    displayPictureAlt() {
      return `${this.fromDisplayName}'s profile picture`;
    },
  },
};
</script>
