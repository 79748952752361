<template>
  <div class="designer-services">
    <div v-if="designerServices.length" class="flex-container align-right mbm">
      <a :href="urls.newServiceUrl" class="button small">Add New Service</a>
    </div>
    <table v-if="services.length" class="table designer-services">
      <thead>
        <tr>
          <th width="220px">Design Type</th>
          <th>
            <table class="table designer-services__table no-border mbn ptm pbm">
              <tr>
                <th width="45%" class="pln">Title</th>
                <th width="15%">Price</th>
                <th width="20%">Active</th>
                <th width="20%"></th>
              </tr>
            </table>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in services" :key="index">
          <td class="prm ptm pbm u-vertically-align-top">
            <div class="designer-services__image_container text-center">
              <img :src="item.service.image" alt="Service Image" />
              <p>{{ item.service.designTypeDisplayName }}</p>
            </div>
          </td>
          <td colspan="4" class="prm">
            <table class="table designer-services__table no-border mbn ptm pbm">
              <tr>
                <td width="45%" class="pln prs">
                    <a :href="item.service.designerServiceUrl">{{ item.service.title }}</a>
                </td>
                <td width="15%" class="prs">{{ item.service.price }}</td>
                <td width="20%" class="prs">
                  <span
                    :class="{
                      'label success': item.service.active === true,
                      'label alert': item.service.active === false,
                    }"
                  >
                    {{ item.service.active ? 'Active' : 'Inactive' }}
                  </span>
                </td>
                <td width="20%" class="prs">
                  <a :href="item.service.editUrl">Edit</a>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="prs pls ptm">
                  <p class="mbn" v-html="descriptionText(item)" />
                  <span class="readMore" @click="onToggle(index)">
                    {{ item.readMore ? 'Read More' : 'Read Less' }}
                  </span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="frame frame--padding-tight mbl">
      <h2>Welcome to Designer Services</h2>
      <p>
        DesignCrowd now gives you the opportunity to offer design services
        directly to customers and work one-on-one with them. You define the
        design service and the price point, we put the service on your designer
        portfolio and get you customers. Once a customer purchases the service,
        you are notified and you work one-on-one with the customer.
        <br />
        Simply click to add a design service below to get started. It's that
        simple!
      </p>
      <div class="align-right flex-container">
        <a :href="urls.newServiceUrl" class="button">Add Design Service</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    designerServices: {
      type: Array,
      default: () => [],
    },
    apiBaseUrl: {
      type: String,
      default: undefined,
    },
    urls: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      services: [],
    };
  },
  mounted() {
    this.initialiseItems();
  },
  watch: {
    designerServices() {
      this.initialiseItems();
    },
  },
  methods: {
    initialiseItems() {
      this.services = this.designerServices.map((service) => ({
        service,
        readMore: service.description.length > 150,
      }));
    },
    descriptionText(item) {
      return item.readMore
        ? item.service.description.substring(0, 150)
        : item.service.description;
    },
    onToggle(index) {
      this.services[index].readMore = !this.services[index].readMore;
    },
  },
};
</script>
