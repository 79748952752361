<template>
  <div
    class="frame frame--padding-tight flex-container flex-dir-column align-middle text-center"
  >
    <img
      v-if="iconUrl"
      height="100"
      width="100"
      class="mbs"
      :src="iconUrl"
      :alt="iconAlt"
    />
    <strong v-if="title" class="text-black">{{ title }}</strong>
    <slot>
      <span v-if="message">{{ message }}</span>
      <span v-else>Looks like you don't have any items yet.</span>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    iconUrl: {
      type: String,
      default: undefined,
    },
    iconAlt: {
      type: String,
      default: 'No Items',
    },
    title: {
      type: String,
      default: undefined,
    },
    message: {
      type: String,
      default: undefined,
    },
  },
};
</script>
