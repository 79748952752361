<template v-if="errors.length">
  <div :class="combinedClass">
    <ul
      v-for="(error, index) in errors"
      :key="index"
      class="text-color-red text-medium"
    >
      <li>{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      combinedClass: ['small-12', ...this.classes],
    };
  },
};
</script>
