<template>
  <div>
    <div @click="open">
      <slot name="open" />
    </div>
    <div
      v-show="visible"
      class="modal--overlay"
      @keydown.esc="close()"
      @click.self="close"
    >
      <div class="modal--popup">
        <div class="modal--header">
          {{ title }}
          <button
            v-if="!hideCloseButton"
            class="close-button"
            aria-label="Close modal"
            type="button"
            @click="close"
          >
            <span v-if="closeIcon" aria-hidden="true">
              <img :src="closeIcon" alt="close icon" />
            </span>
            <span v-else aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="maker-spinner-container">
          <div v-if="isLoading" class="maker-spinner">
            <span class="maker-spinner__icon" />
          </div>
          <div class="modal--body text-small">
            <slot name="body" />
          </div>
          <footer
            v-if="$slots.footer"
            class="modal--footer modal--footer_border-top"
          >
            <slot name="footer" />
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(visible) {
      if (visible && this.closeOnEsc) {
        document.addEventListener('keydown', this.closeModalOnKeyDown);
      } else {
        document.removeEventListener('keydown', this.closeModalOnKeyDown);
      }
    },
  },
  mounted() {
    if (this.closeOnEsc) {
      document.addEventListener('keydown', this.closeModalOnKeyDown);
    }
  },
  unmounted() {
    if (this.closeOnEsc) {
      document.removeEventListener('keydown', this.closeModalOnKeyDown);
    }
  },
  methods: {
    closeModalOnKeyDown(event) {
      const evt = event || window.event;
      let isEscape = false;
      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc';
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        document.removeEventListener('keydown', this.closeModalOnKeyDown);
        this.close();
      }
    },
    close($event) {
      this.$emit('close', $event);
    },
    open() {
      this.$emit('open', true);
    },
  },
};
</script>
