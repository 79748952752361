<!-- eslint-disable prettier/prettier -->
<template>
  <div
    :class="{
      'design-submission': true,
      'design-submission--selected': isSelectedStatus,
    }"
  >
    <div
      v-if="showSelector"
      class="design-submission__top--no-selector"
    >
      <a :href="designerPageUrl">
        {{ designerDisplayName }}
      </a>
    </div>
    <div
      v-else
      class="design-submission__top"
      @click="onSelect(designId, submissionId)"
    >
      <a @click.stop="goToDesignerProfileUrl">
        {{ designerDisplayName }}
      </a>
    </div>
    <div class="design-submission__image">
      <div class="design-submission__status-bar">
        <div class="design-submission__status-labels">
          <div
            v-for="(s, index) in status"
            :key="index"
            :class="{
              'design-submission__status': true,
              'design-submission__status--secondary': s.isSecondary,
            }"
          >
            <h4 v-if="s.isSecondary" class="mbn heading--white">
              {{ s.label }}
            </h4>
            <span v-if="!s.isSecondary">{{ s.label }}</span>
          </div>
        </div>
        <div class="design-submission__icons">
          <img
            v-if="hasVariations"
            :ref='variationsTooltipRef'
            :src="variationIconUrl"
            class="has-tip bottom"
            alt="variation icon"
            data-tooltip
            aria-haspopup="true"
            data-allow-html="true"
            data-tooltip-class="tooltip"
            title="This design has variations"
          />
        </div>
      </div>
      <div class="row collapse">
        <div
          v-if="designThumbnailImagePath"
          class="column small-12 text-center"
        >
          <a @click="loadDesignDetail(designId)">
            <img
              :src="designImagePath"
              data-test="design-image"
              alt="Design image preview"
              :height="getMaxImageHeight(thumbnailWidth, thumbnailHeight)"
              :width="maxImageWidth"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="row collapse">
      <div class="column small-12">
        <div class="design-submission__middle">
          <div class="row align-justify align-middle">
            <div class="column" data-test-role="design-card-star-rating">
              <!-- Feedback Modal -->
              <Notice
                size="medium"
                arrow-size="none"
                direction="bottom left"
                :close-icon="closeIconUrl"
                :is-visible="isFeedbackPopupVisible"
                :feedback="isFeedbackPopupVisible"
                @closed="isFeedbackPopupVisible = false"
              >
                <template #target>
                  <StarRatingSelectable
                    size="medium"
                    :rating="starRating"
                    @click="onClickStarRating($event, true)"
                  />
                </template>

                <template #tooltip>
                  <h6>Feedback</h6>
                  <label for="notice-feedback">
                    <textarea
                      id="notice-feedback"
                      v-model="feedbackText"
                      class="full-width"
                      rows="5"
                      :placeholder="feedbackPlaceholder"
                      tabindex="0"
                    >
                    </textarea>
                  </label>
                  <span v-if="feedbackError" class="is-deleted">
                    {{ feedbackErrorMessage }}
                  </span>
                  <Checkbox
                    v-if="!isMessageDesigner"
                    :id="`auto-reject-future-submissions${cardId}`"
                    @input="autoReject = $event"
                  >
                    Auto-reject any future submissions from this designer
                  </Checkbox>
                  <div class="row mtm">
                    <div class="column shrink">
                      <button
                        :class="{
                          ['button tiny mbn']: true,
                          ['button-processing']: isFeedbackSubmitting,
                        }"
                        data-test-role="design-card-submit-feedback"
                        @click="onSubmitFeedback"
                      >
                        <img
                          v-if="isFeedbackSubmitting && processingIconUrl"
                          :src="processingIconUrl"
                          alt="Processing Icon"
                        />
                        Send Feedback
                      </button>
                    </div>
                    <div class="column shrink">
                      <button
                        class="button secondary hollow tiny mbn"
                        data-test-role="design-card-cancel-feedback"
                        @click="isFeedbackPopupVisible = false"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </template>
              </Notice>
            </div>
            <div class="column flex-container small-4 align-right">
              <a
                v-if="!isWinner && !placement && briefStatus === 'Closed'"
                @click="onClickOptions"
              >
                <img
                  class="mrn small-2 shrink"
                  :src="ellipsisIconUrl"
                  alt="ellipsis icon"
                  data-test-role="ellipsis-icon"
                />
              </a>
              <ul
                v-if="isDesignOptions && !isWinner"
                v-click-outside="onClickOptions"
                class="design-submission__menu"
              >
                <li v-if="buyThisDesignUrl">
                  <a @click="isBuyDesignerVisible = true">
                    Buy This Design
                  </a>
                </li>
                <li v-if="tipDesignerAction !== null">
                  <a @click="isTipDesignerModalVisible = true">
                    Tip Designer
                  </a>
                </li>
                <li>
                  <a @click="onClickMessageDesigner">Message Designer</a>
                </li>
                <li>
                  <a v-if="!isDesignShared(designId)" @click="onClickShareButton(designId, submissionId)">
                    Share Design
                  </a>
                  <a v-else @click="onClickUnshareButton(designId, submissionId)">
                    Unshare Design
                  </a>
                </li>
              </ul>
              <a
                v-if="isWinner || briefStatus === 'Open'"
                @click="onClickMessageDesigner"
              >
                <img
                  v-if="feedbackThreadCount"
                  class="mrs"
                  :src="commentSquareFilledIconUrl"
                  alt="comment square icon"
                  data-test-role="feedback-sent-icon"
                />
                <img
                  v-else
                  class="mrs"
                  :src="commentSquareHollowIconUrl"
                  alt="comment square icon"
                  data-test-role="feedback-none-icon"
                />
              </a>
              <!-- eliminate feedback Modal -->
              <Notice
                size="medium"
                arrow-size="none"
                direction="bottom right"
                :eliminate="isEliminatePopupVisible"
                :is-visible="isEliminatePopupVisible"
                @closed="closeEliminateNotice"
              >
                <template v-if="!isWinner && briefStatus === 'Open'" #target>
                  <a v-if="!isEliminatedStatus">
                    <img
                      height="22"
                      width="22"
                      class="mls"
                      :src="deleteIconUrl"
                      alt="eliminate icon"
                      data-test-role="eliminate-icon"
                      @click="onEliminateDesign(designId)"
                    />
                  </a>
                </template>

                <template #tooltip>
                  <p>
                    Once you eliminate a design you can no longer purchase that design.
                  </p>
                  <form :ref="eliminationFeedbackFormRef" method="POST">
                    <input type="hidden" name="designIdsCsv" value="designId" />
                    <div class="row">
                      <div class="column small-12">
                        <strong class="text-black">Thanks but...</strong>
                        <ul class="list list--tight mts flex-container">
                          <div class="small-6">
                            <li>
                              <label>
                                <input
                                  v-model="reason"
                                  type="radio"
                                  class="input-type--radio"
                                  name="reason"
                                  checked="checked"
                                  value="NotWhatImAfter"
                                />
                                <span class="text-medium">Not what I'm after</span
                                >
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  v-model="reason"
                                  type="radio"
                                  class="input-type--radio"
                                  name="reason"
                                  value="NeedsSomeChanges"
                                />
                                <span class="text-medium">Please read the brief again</span>
                              </label>
                            </li>
                          </div>
                          <div class="small-6">
                            <li>
                              <label>
                                <input
                                  v-model="reason"
                                  type="radio"
                                  class="input-type--radio"
                                  name="reason"
                                  value="ReadTheBriefAgain"
                                />
                                <span class="text-medium">Needs some changes</span>
                              </label>
                            </li>
                            <li>
                              <label>
                                <input
                                  v-model="reason"
                                  type="radio"
                                  class="input-type--radio"
                                  name="reason"
                                  value="DidntFollowTheBrief"
                                />
                                <span class="text-medium">Didn't follow the creative brief</span>
                              </label>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </div>
                    <div class="mtn">
                      <strong class="text-black">Please...</strong>
                      <ul class="list list--tight mts flex-container">
                        <div class="small-6">
                          <li>
                            <label>
                              <input
                                v-model="action"
                                type="radio"
                                class="input-type--radio"
                                name="action"
                                checked="checked"
                                value="TryAgain"
                              />
                              <span class="text-medium">Try again</span>
                            </label>
                          </li>
                        </div>
                        <div class="small-6">
                          <li>
                            <label>
                              <input
                                v-model="action"
                                type="radio"
                                class="input-type--radio"
                                name="action"
                                value="SubmitNoMore"
                              />
                              <span class="text-medium">Don't submit anymore designs</span>
                            </label>
                          </li>
                        </div>
                      </ul>
                    </div>
                    <div class="mtn">
                      <span>
                        <strong class="text-black">Feedback</strong>
                        (optional)
                      </span>
                      <label for="notice-feedback">
                        <textarea
                          id="notice-feedback"
                          v-model="eliminationFeedbackText"
                          class="full-width"
                          rows="2"
                          :placeholder="feedbackPlaceholder"
                        >
                        </textarea>
                      </label>
                      <span v-if="feedbackError" class="is-deleted">
                        {{ feedbackErrorMessage }}
                      </span>
                    </div>
                    <div data-test-role="modal-star-rating">
                      <div class="row align-left">
                        <div class="column shrink">
                          <a
                            :class="{
                              ['button tiny mbn']: true,
                              ['button-processing']: isFeedbackSubmitting,
                            }"
                            @click="onClickEliminationFeedback()"
                          >
                            <img
                              v-if="isFeedbackSubmitting && processingIconUrl"
                              :src="processingIconUrl"
                              alt="Processing Icon"
                            />
                            Send Feedback
                          </a>
                        </div>
                        <div class="column shrink">
                          <a
                            class="button secondary hollow mbn tiny"
                            @click="closeEliminateNotice"
                          >
                            Skip
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </template>
              </Notice>
              <a v-if="isEliminatedStatus">
                <img
                  height="22"
                  width="22"
                  class="mls"
                  :src="undoIconUrl"
                  alt="undo icon"
                  data-test-role="undo-icon"
                  @click="onUneliminateDesign(designId)"
                />
              </a>
            </div>
          </div>
          <div v-if="!showSelector">
            <hr />
            <button class="button small expanded mbn"
            :class="isSelectedStatus ? '' : 'hollow secondary'"
            @click="onSelect(designId, submissionId)">
              {{ isSelectedStatus ? 'Selected' : 'Select'}}
            </button>
          </div>
        </div>
      </div>
      <div v-if="isWinner && hasCTAs" class="column small-12">
        <div
          class="design-submission__bottom flex-container align-center align-spaced mrn mln"
        >
          <a
            v-if="accessDesignFilesAction"
            :href="accessDesignFilesAction"
            class="button small success expanded small-9 mbn"
          >
            Access Design Files
          </a>
          <a
            v-if="briefStatus === 'Closed'"
            @click="onClickOptions"
          >
            <img
              class="mrn small-2 shrink"
              :src="ellipsisIconUrl"
              alt="ellipsis icon"
              data-test-role="ellipsis-icon"
            />
          </a>
          <ul
            v-if="isDesignOptions"
            v-click-outside="onClickOptions"
            class="design-submission__menu"
          >
            <li v-if="buyThisDesignUrl">
              <a @click="isBuyDesignerVisible = true">
                Buy This Design
              </a>
            </li>
            <li v-if="placement === 1 && tipDesignerAction !== null">
              <a @click="isTipDesignerModalVisible = true">
                Tip Designer
              </a>
            </li>
            <li v-if="
              accessDesignFilesAction && tipDesignerAction !== null && placement !== 1
            ">
              <a :href="accessDesignFilesAction">
                Access Design Files
              </a>
            </li>
            <li>
              <a @click="onClickMessageDesigner">Message Designer</a>
            </li>
            <li>
              <a v-if="!isDesignShared(designId)" @click="onClickShareButton(designId, submissionId)">
                Share Design
              </a>
              <a v-else @click="onClickUnshareButton(designId, submissionId)">
                Unshare Design
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <DesignPreviewModal
      v-if="designDetail"
      :brief-id="briefId"
      :submission-id="selectedDesignSubmissionId"
      :design-id="selectedDesignId"
      :is-loading="isDesignPreviewModalLoading"
      :is-visible="isDesignPreviewModalVisible"
      :design-preview-url="designPreviewUrl"
      :linked-designs="linkedSubmissions"
      :designer-profile-thumbnail-path="designerProfileThumbnailPath"
      :designer-page-url="designerPageUrl"
      :designer-display-name="designerDisplayName"
      :design-description="designDescription"
      :stock-items="stockItems"
      :rating="selectedDesignStarRating"
      :feedback-threads="threads"
      :de-select-design-url="deSelectDesignUrl"
      :select-design-url="selectDesignUrl"
      :buy-design-url="buyDesignUrl"
      :eliminate-design-url="eliminateDesignUrl"
      :un-eliminate-design-url="unEliminateDesignUrl"
      :is-winner="isWinner"
      :is-shared="isShared"
      :close-icon-url="closeIconUrl"
      :processing-icon-url="processingIconUrl"
      @feedback-added="loadDesignDetail(selectedDesignId)"
      @star-rating-updated="onClickStarRatingPreviewModal($event, selectedDesignSubmissionId)"
      @buy-design="isBuyDesignerVisible = true"
      @share="onClickShareButton(selectedDesignId, selectedDesignSubmissionId)"
      @unshare="onClickUnshareButton(selectedDesignId, selectedDesignSubmissionId)"
      @feedback-updated="onFeedbackUpdate"
      @close="isDesignPreviewModalVisible = false"
      @load-design-details="loadDesignDetail($event)"
    />
    <TipDesignerModal
      :tip-designer-action="tipDesignerAction"
      :currency-abbreviation="currencyAbbreviation"
      :close-icon="closeIconUrl"
      :designer-display-name="designerDisplayName"
      :designer-profile-thumbnail-path="designerProfileThumbnailPath"
      :designer-page-url="designerPageUrl"
      :designer-id="designerId"
      :is-winner="isWinner"
      :is-modal-visible="isTipDesignerModalVisible"
      :star-rating="starRating"
      @modal-closed="isTipDesignerModalVisible = false"
      @click-star-rating="onClickStarRating"
    />
    <BuyThisDesignModal
      :buy-this-design-url="isMenuBuyDesignUrl ? isMenuBuyDesignUrl : buyThisDesignUrl"
      :design-thumbnail-image-path="isMenuBuyDesignImage ? isMenuBuyDesignImage : designThumbnailImagePath"
      :currency-abbreviation="currencyAbbreviation"
      :close-icon="closeIconUrl"
      :is-modal-visible="isBuyDesignerVisible"
      @modal-closed="isBuyDesignerVisible = false"
      @modal-not-visible="hideBuyDesign"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Notice from '../../../shared/Notice/Notice.vue';
import Checkbox from '../../../shared/Checkbox/Checkbox.vue';
import StarRatingSelectable from '../../../shared/StarRatingSelectable/StarRatingSelectable.vue';
import BuyThisDesignModal from '../BuyThisDesignModal/BuyThisDesignModal.vue';
import designSubmissionApi from '../../../../clients/design-submission-api';
import designDetailApi from '../../../../clients/design-detail-api';
import TipDesignerModal from '../TipDesignerModal/TipDesignerModal.vue';
import DesignPreviewModal from '../DesignPreviewModal/DesignPreviewModal.vue';
import feedbackMixin from '../../../../mixins/feedbackMixin';

export default {
  components: {
    Notice,
    Checkbox,
    StarRatingSelectable,
    BuyThisDesignModal,
    TipDesignerModal,
    DesignPreviewModal,
  },
  mixins: [feedbackMixin],
  props: {
    submissionId: {
      type: Number,
      default: undefined,
    },
    designId: {
      type: Number,
      default: undefined,
    },
    designThumbnailImagePath: {
      type: String,
      default: undefined,
    },
    designImagePath: {
      type: String,
      default: undefined,
    },
    designerProfileThumbnailPath: {
      type: String,
      default: undefined,
    },
    designerDisplayName: {
      type: String,
      required: true,
    },
    designerPageUrl: {
      type: String,
      required: true,
    },
    designerId: {
      type: Number,
      required: true,
    },
    designerCountry: {
      type: String,
      default: undefined,
    },
    selectAsWinnerAction: {
      type: String,
      default: undefined,
    },
    accessDesignFilesAction: {
      type: String,
      default: undefined,
    },
    tipDesignerAction: {
      type: String,
      default: undefined,
    },
    eliminateDesignAction: {
      type: String,
      default: undefined,
    },
    eliminationFeedbackAction: {
      type: String,
      default: undefined,
    },
    unEliminateDesignAction: {
      type: String,
      default: undefined,
    },
    delectDesignAction: {
      type: String,
      default: undefined,
    },
    rating: {
      type: Number,
      default: 0,
    },
    feedbackCount: {
      type: Number,
      default: 0,
    },
    isEliminated: {
      type: Boolean,
      default: false,
    },
    isRecent: {
      type: Boolean,
      default: false,
    },
    isWithdrawn: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: Number,
      default: 0,
    },
    placementLabel: {
      type: String,
      default: undefined,
    },
    basePayment: {
      type: Boolean,
      default: false,
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    buyThisDesignUrl: {
      type: String,
      default: undefined,
    },
    currencyAbbreviation: {
      type: String,
      required: true,
    },
    triggerModalOnMount: {
      type: Boolean,
      default: false,
    },
    thumbnailWidth: {
      type: Number,
      default: null,
    },
    thumbnailHeight: {
      type: Number,
      default: null,
    },
    isWinner: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    hasVariations: {
      type: Boolean,
      default: false,
    },
    processingIconUrl: {
      type: String,
      default: undefined,
    },
    closeIconUrl: {
      type: String,
      default: undefined,
    },
    deleteIconUrl: {
      type: String,
      default: undefined,
    },
    undoIconUrl: {
      type: String,
      default: undefined,
    },
    commentSquareFilledIconUrl: {
      type: String,
      default: undefined,
    },
    commentSquareHollowIconUrl: {
      type: String,
      default: undefined,
    },
    ellipsisIconUrl: {
      type: String,
      default: undefined,
    },
    variationIconUrl: {
      type: String,
      default: undefined,
    },
    briefStatus: {
      type: String,
      required: true,
    },
    isMenuBuyDesignVisible: {
      type: Boolean,
      default: false,
    },
    isMenuBuyDesignUrl: {
      type: String,
      default: undefined,
    },
    isMenuBuyDesignImage: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      maxRating: 5,
      starRating: null,
      reason: 'NotWhatImAfter',
      action: 'TryAgain',
      eliminationFeedbackText: null,
      isEliminatedStatus: this.isEliminated,
      isSelectedStatus: this.isSelected,
      isShowUndo: false,
      isEliminatePopupVisible: false,
      designDetail: null,
      feedbackList: [],
      isDesignPreviewModalVisible: false,
      isDesignPreviewModalLoading: false,
      isFeedbackPopupVisible: false,
      tipDesignerAmount: 10,
      tipDesignerAmountMinimum: 10,
      tipDesignerAmountInputRef: 'js-tip-designer-amount-input',
      tipDesignerFormRef: 'js-tip-designer-form',
      eliminateDesignFormRef: 'js-eliminate-design-form',
      eliminationFeedbackFormRef: 'js-elimination-feedback-form',
      eli: 'js-eliminate-design-form',
      isTipDesignerFormHidden: true,
      markAsReadObserver: null,
      observerRootRef: 'js-observer-root-ref',
      maxImageWidth: 300,
      selected: false,
      isDesignOptions: false,
      isTipDesignerModalVisible: false,
      isBuyDesignerVisible: this.isMenuBuyDesignVisible,
      isMessageDesigner: false,
      variationsTooltip: null,
      variationsTooltipRef: 'js-variations-tooltip',
    };
  },
  computed: {
    ...mapGetters('designs', ['isDesignShared']),
    cardId() {
      // eslint-disable-next-line no-underscore-dangle
      return this._uid;
    },
    briefId() {
      const urlMatch = this.$route
        ? this.$route.fullPath.match(/([^/]+$)/)
        : null;
      if (urlMatch && urlMatch.length > 1) {
        return parseInt(urlMatch[1], 10);
      }
      return null;
    },
    tipDesignerAmountInt() {
      return parseInt(this.tipDesignerAmount, 10);
    },
    isTipDesignerFormDisabled() {
      return (
        !this.tipDesignerAmountInt ||
        this.tipDesignerAmountInt < this.tipDesignerAmountMinimum
      );
    },
    status() {
      let status = [];
      if (this.isRecent) {
        status.push({ label: 'New', isSecondary: false });
      }
      if (this.placementLabel) {
        status.push({ label: this.placementLabel, isSecondary: false });
      }
      if (this.basePayment) {
        status.push({
          label: 'Participation payment',
          isSecondary: false,
        });
      }
      if (this.isEliminatedStatus) {
        status.push({ label: 'Eliminated', isSecondary: true });
      }
      if (this.isWithdrawn && !this.isEliminated) {
        status.push({ label: 'Withdrawn', isSecondary: true });
      }
      if (this.isShowUndo) {
        status = [];
      }

      return status;
    },
    hasCTAs() {
      return (
        this.buyThisDesignUrl ||
        this.accessDesignFilesAction ||
        this.tipDesignerAction
      );
    },
    showSelector() {
      return this.isWinner || this.isWithdrawn || this.isEliminatedStatus;
    },
    selectedDesignId() {
      return this.designDetail?.designId;
    },
    selectedDesignSubmissionId() {
      return this.designDetail?.submissionId;
    },
    selectedDesignStarRating() {
      return this.designDetail?.starRating;
    },
    designPreviewUrl() {
      return this.designDetail
        ? this.designDetail?.designImageLocation
        : this.designImagePath;
    },
    linkedSubmissions() {
      return this.designDetail?.linkedSubmissions;
    },
    designDescription() {
      return this.designDetail?.designDescription;
    },
    stockItems() {
      return this.designDetail?.stockItems;
    },
    threads() {
      return this.designDetail?.threads;
    },
    deSelectDesignUrl() {
      return this.designDetail?.deSelectDesignUrl;
    },
    selectDesignUrl() {
      return this.designDetail?.selectDesignUrl;
    },
    buyDesignUrl() {
      return this.designDetail?.buyDesignUrl;
    },
    eliminateDesignUrl() {
      return this.designDetail?.eliminateDesignUrl;
    },
    unEliminateDesignUrl() {
      return this.designDetail?.unEliminateDesignUrl;
    },
  },
  watch: {
    isEliminated(eliminated) {
      this.isEliminatedStatus = eliminated;
    },
    isMenuBuyDesignVisible(visible) {
      this.isBuyDesignerVisible = visible;
    },
    isSelected(selected) {
      this.isSelectedStatus = selected;
    },
    rating(newStarRating) {
      this.starRating = newStarRating;
    },
    isFeedbackPopupVisible(newIsFeedbackPopupVisible) {
      if (!newIsFeedbackPopupVisible) {
        this.onCloseFeedbackPopup();
      }
    },
  },
  async mounted() {
    this.initializeFormData();
    this.feedbackThreadCount = this.feedbackCount;

    const variationsTooltip = this.$refs[this.variationsTooltipRef];
    if (variationsTooltip && window.Foundation && window.$) {
      this.variationsTooltip = new window.Foundation.Tooltip(
        window.$(variationsTooltip),
      );
    }

    if (this.triggerModalOnMount) await this.loadDesignDetail(this.designId);
  },
  methods: {
    initializeFormData() {
      this.starRating = this.rating;
    },
    async loadDesignDetail(designId) {
      this.isDesignPreviewModalVisible = true;
      this.isDesignPreviewModalLoading = true;
      this.designDetail = await designDetailApi.getDesignDetailAsync(designId);
      this.isDesignPreviewModalLoading = false;
    },
    onCloseFeedbackPopup() {
      this.$emit('rating-changed', {
        designId: this.designId,
        starRating: this.starRating,
      });
    },
    async onClickStarRating(rating, popup, submissionId) {
      const idOfSubmissionToBeRated = submissionId || this.submissionId;
      this.isFeedbackPopupVisible = rating > 0 && popup;
      this.starRating = rating;
      if (this.starRating !== this.rating) {
        await designSubmissionApi.saveStarRatingAsync({
          briefId: this.briefId,
          submissionId: idOfSubmissionToBeRated,
          starRating: this.starRating,
        });
        if (!this.isFeedbackPopupVisible) {
          this.onCloseFeedbackPopup();
        }
      }
    },
    async onClickStarRatingPreviewModal(rating, submissionId) {
      const idOfSubmissionToBeRated = submissionId || this.submissionId;
      this.starRating = rating;
      if (this.starRating !== this.rating) {
        await designSubmissionApi.saveStarRatingAsync({
          briefId: this.briefId,
          submissionId: idOfSubmissionToBeRated,
          starRating: this.starRating,
        });
      }
    },
    async onClickMessageDesigner() {
      this.isFeedbackPopupVisible = true;
      this.isMessageDesigner = true;
    },
    async onSubmitFeedback() {
      await this.submitFeedback(this.briefId, this.designId, this.starRating);
      this.isFeedbackPopupVisible = false;
    },
    async onSubmitFeedbackDesignDetail() {
      await this.onSubmitFeedback();
      await this.loadDesignDetail(this.designId);
    },
    onClickShareButton(designId, submissionId) {
      this.$emit('share', { DesignId: designId });
      this.onSelect(designId, submissionId);
    },
    onClickUnshareButton(designId, submissionId) {
      this.$emit('unshare', { DesignId: designId });
      this.onSelect(designId, submissionId);
    },
    async onClickTipDesigner(event, tipAmount) {
      event.preventDefault();
      this.tipDesignerAmount = tipAmount;
      this.$refs[this.tipDesignerAmountInputRef].value = tipAmount;
      this.$refs[this.tipDesignerFormRef].submit();
    },
    async onClickSubmitTipDesignerForm() {
      if (!this.isTipDesignerFormDisabled) {
        this.$refs[this.tipDesignerFormRef].submit();
      }
    },
    onClickTipDesignerFormOther(event) {
      event.preventDefault();
      this.isTipDesignerFormHidden = false;
    },
    onCloseTipDesignerModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.isTipDesignerFormHidden = true;
      this.markAsReadObserver.disconnect();
      this.markAsReadObserver = null;
    },
    getMaxImageHeight(width, height) {
      if (height && width) {
        const ratio = this.maxImageWidth / width;
        return height * ratio;
      }
      return null;
    },
    onSelect(designId, submissionId) {
      this.isDesignPreviewModalVisible = false;
      this.$emit('selected', { designId, submissionId });
    },
    async onEliminateDesign(designId) {
      this.isEliminatedStatus = true;
      this.isSelectedStatus = false;
      this.isEliminatePopupVisible = true;

      if (designId) {
        await designSubmissionApi.eliminateDesignAsync(
          this.briefId,
          this.designId,
        );
        this.$emit('icon-eliminate', designId);
      }
    },
    async onUneliminateDesign(designId) {
      this.isEliminatedStatus = false;

      if (designId) {
        await designSubmissionApi.unEliminateDesignAsync(
          this.briefId,
          this.designId,
        );
        this.$emit('icon-uneliminate', designId);
      }
    },
    onClickOptions(event) {
      event.preventDefault();
      this.isDesignOptions = !this.isDesignOptions;
    },
    goToDesignerProfileUrl() {
      window.location.href = this.designerPageUrl;
    },
    closeEliminateNotice() {
      this.isEliminatePopupVisible = false;
      this.$emit('close-eliminate-popup');
    },
    hideBuyDesign() {
      this.$emit('modal-not-visible');
    },
    async onClickEliminationFeedback() {
      this.closeEliminateNotice();
      const saveEliminationFeedbackRes =
        await designSubmissionApi.eliminationFeedback(
          this.briefId,
          this.designId,
          this.reason,
          this.action,
          this.eliminationFeedbackText,
        );
      if (saveEliminationFeedbackRes) {
        this.autoReject = false;
        this.feedbackText = '';
        this.isFeedbackSubmitting = false;
      }
    },
    async onFeedbackUpdate(feedbackCount) {
      this.feedbackThreadCount = feedbackCount;
    },
  },
};
</script>
