<template>
  <div
    :ref="calloutRef"
    :class="{
      callout: true,
      [calloutType]: true,
    }"
    data-closable
  >
    <div class="row">
      <div v-if="iconUrl" class="column shrink callout__icon">
        <img :src="iconUrl" :alt="`${type} icon`" />
      </div>
      <div class="column">
        <div class="row align-middle">
          <div class="column">
            <h6>{{ title }}</h6>
            <p>
              <slot name="message" />
            </p>
          </div>
          <div class="column shrink">
            <button
              v-if="closable"
              class="button clear small secondary phm mbn"
              data-close
              @click="onClose($event)"
            >
              Dismiss
            </button>
            <slot name="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    type: {
      type: String,
      default: undefined,
    },
    iconUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: undefined,
    },
    closable: {
      type: Boolean,
      default: undefined,
    },
    dismissUrl: {
      type: String,
      default: undefined,
    },
    autoClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: ['primary', 'secondary', 'success', 'warning', 'alert'],
    };
  },
  computed: {
    calloutRef() {
      // eslint-disable-next-line no-underscore-dangle
      return `ref-callout-${this._uid}`;
    },
    calloutType() {
      return this.types.indexOf(this.type) > -1 ? this.type : 'primary';
    },
  },
  mounted() {
    if (this.autoClose) {
      setTimeout(() => {
        window.$(this.$refs[this.calloutRef]).trigger('close');
      }, 5000);
    }
  },
  methods: {
    onClose() {
      this.$emit('close');

      if (typeof this.dismissUrl !== 'undefined') {
        axios.post(this.dismissUrl, {});
      }
    },
  },
};
</script>
