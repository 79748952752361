<template>
  <form action="" method="post" class="account-settings" @submit.prevent>
    <div class="row">
      <div class="column small-12">
        <label class="mbm">
          <strong class="text-black">My Portfolio Page</strong>
          <br />
          <a :href="portfolioUrl" class="overflow-break-text">{{
            portfolioUrl
          }}</a>
          <input type="hidden" name="portfolioUrl" :value="portfolioUrl" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mbm">
          <strong class="text-black">Email</strong>
          <br />
          <span>{{ email }}</span>
          <input type="hidden" name="Email" :value="email" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">First name</strong>
          <input
            v-model="firstNameValue"
            class="mts"
            type="text"
            name="FirstName"
            maxlength="50"
          />
        </label>
        <div v-if="form.errors.firstName" class="small-12">
          <ul
            v-for="(error, index) in form.errors.firstName"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Last name</strong>
          <input
            v-model="lastNameValue"
            class="mts"
            type="text"
            name="LastName"
            maxlength="50"
          />
        </label>
        <div v-if="form.errors.lastName" class="small-12">
          <ul
            v-for="(error, index) in form.errors.lastName"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="resendVerificationEmailEndpoint">
      <div class="column small-12">
        <strong class="text-black">Resend verification email</strong>
        <br />
        <button
          :disabled="verificationEmailReSent"
          class="button secondary tiny hollow mts"
          @click="resendVerificationEmail"
        >
          Resend
        </button>
        <div v-if="form.errors.resendVerficationEmail" class="small-12">
          <ul
            v-for="(error, index) in form.errors.resendVerficationEmail"
            :key="index"
            class="text-color-red text-medium"
          >
            <li>{{ error }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div v-if="!isChangePasswordFormVisible" class="column small-12">
        <strong class="text-black">Password</strong>
        <br />
        <button
          class="button secondary tiny hollow mts"
          @click="showChangePasswordForm"
        >
          Change Password
        </button>
      </div>
      <div v-if="isChangePasswordFormVisible" class="column small-12">
        <div class="row small-up-1 medium-up-2 large-up-3">
          <div class="column">
            <label>
              <strong class="text-black">Current password</strong>
              <input
                v-model="password"
                class="input-type input-type--password mts"
                type="password"
                name="currentPassword"
                placeholder="Current password"
              />
            </label>
          </div>
          <div class="column">
            <label>
              <strong class="text-black">New password</strong>
              <input
                v-model="newPassword"
                class="input-type input-type--password mts"
                type="password"
                name="newPassword"
                placeholder="New password"
              />
            </label>
          </div>
          <div class="column">
            <label>
              <strong class="text-black">Confirm new password</strong>
              <input
                v-model="confirmPassword"
                class="input-type input-type--password mts"
                type="password"
                name="confirmPassword"
                placeholder="Confirm new password"
              />
            </label>
          </div>
        </div>
        <div class="row align-center">
          <div class="column shrink">
            <span v-if="showPasswordWarning" class="text-color-red text-small">
              Your new password and confirmation password do not match
            </span>
          </div>
        </div>
        <div class="form-stacking mbm">
          <div class="row align-center">
            <div class="column small-12 medium-shrink">
              <button
                class="button secondary hollow form-stacking__item"
                @click="hideChangePasswordForm"
              >
                Cancel
              </button>
            </div>
            <div class="column small-12 medium-shrink">
              <button
                class="button form-stacking__item"
                @click="onSubmitUpdatePasswordForm"
              >
                Update Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Phone</strong>
          <input
            v-model="phoneValue"
            class="mts"
            type="text"
            name="Phone"
            maxlength="50"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Company name</strong>
          <input
            v-model="companyNameValue"
            class="mts"
            type="text"
            name="CompanyName"
            maxlength="50"
          />
        </label>
      </div>
    </div>
    <div class="row column">
      <label>
        <strong class="text-black">Address</strong>
        <textarea
          v-model="addressValue"
          class="full-width mts"
          name="Address"
          rows="3"
        >
        </textarea>
      </label>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">City</strong>
          <input
            v-model="cityValue"
            class="mts"
            type="text"
            name="City"
            maxlength="50"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">State</strong>
          <input
            v-model="stateValue"
            class="mts"
            type="text"
            name="State"
            maxlength="50"
          />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Postcode</strong>
          <input
            v-model="postcodeValue"
            class="mts"
            type="text"
            name="Postcode"
            maxlength="50"
          />
        </label>
      </div>
      <div class="column small-12 medium-6">
        <label>
          <strong class="text-black">Country</strong>
          <select v-model="countryValue" class="mts" name="Country">
            <option
              v-for="(countryName, index) in countries"
              :key="index"
              :value="countryName"
            >
              {{ countryName }}
            </option>
          </select>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column small-12">
        <label class="mbm">
          <strong class="text-black">Account Status</strong>
          <span>
            {{ status }}
            (<a :href="deactivateUrl">Deactivate my account</a>)
          </span>
          <input type="hidden" name="portfolioUrl" :value="portfolioUrl" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="column text-center">
        <button class="button mbn" :disabled="form.disabled" @click="save">
          Update details
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  props: {
    address: {
      type: String,
      default: undefined,
    },
    city: {
      type: String,
      default: undefined,
    },
    companyName: {
      type: String,
      default: undefined,
    },
    country: {
      type: String,
      default: undefined,
    },
    email: {
      type: String,
      default: undefined,
    },
    firstName: {
      type: String,
      default: undefined,
    },
    lastName: {
      type: String,
      default: undefined,
    },
    phone: {
      type: String,
      default: undefined,
    },
    postcode: {
      type: String,
      default: undefined,
    },
    state: {
      type: String,
      default: undefined,
    },
    portfolioUrl: {
      type: String,
      default: undefined,
    },
    deactivateUrl: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      default: undefined,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    resetChangePasswordForm: {
      type: Boolean,
      default: true,
    },
    updateDetailsEndpoint: {
      type: String,
      required: true,
    },
    resendVerificationEmailEndpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    const errorDefault = {
      profile: [],
      resendVerficationEmail: [],
    };

    return {
      showPasswordWarning: false,
      isChangePasswordFormVisible: false,
      firstNameValue: this.firstName,
      lastNameValue: this.lastName,
      password: '',
      newPassword: '',
      confirmPassword: '',
      phoneValue: this.phone,
      companyNameValue: this.companyName,
      addressValue: this.address,
      cityValue: this.city,
      stateValue: this.state,
      postcodeValue: this.postcode,
      countryValue: this.country,
      verificationEmailReSent: false,
      form: {
        disabled: false,
        errorDefault,
        errors: errorDefault,
      },
    };
  },
  watch: {
    resetChangePasswordForm(resetChangePasswordForm) {
      if (resetChangePasswordForm) {
        this.password = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.isChangePasswordFormVisible = false;
        this.showPasswordWarning = false;
      }
    },
  },
  methods: {
    showChangePasswordForm() {
      this.isChangePasswordFormVisible = true;
    },
    hideChangePasswordForm() {
      this.isChangePasswordFormVisible = false;
    },
    onSubmitUpdatePasswordForm(e) {
      e.preventDefault();

      if (this.newPassword !== this.confirmPassword) {
        this.showPasswordWarning = true;
        return;
      }

      this.$emit('update-password', {
        password: this.password,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      });
    },
    async save() {
      this.form.disabled = true;

      const data = {
        FirstName: this.firstNameValue,
        LastName: this.lastNameValue,
        Phone: this.phoneValue,
        CompanyName: this.companyNameValue,
        Address: this.addressValue,
        City: this.cityValue,
        State: this.stateValue,
        Postcode: this.postcodeValue,
        Country: this.countryValue,
      };

      const res = await axios.post(
        this.updateDetailsEndpoint,
        data,
        axiosConfig,
      );
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };

      if (res.data.errors) {
        this.$emit('show-api-success-message', null);
      } else if (res.data.errorMessage) {
        this.$emit('show-api-success-message', null);
        this.$emit('show-api-error-messages', [res.data.errorMessage]);
      } else {
        this.$emit('show-api-success-message', res.data.successMessage);
      }
      this.form.disabled = false;
    },
    async resendVerificationEmail() {
      this.verificationEmailReSent = true;
      const res = await axios.post(
        this.resendVerificationEmailEndpoint,
        null,
        axiosConfig,
      );
      this.form.errors = { ...this.form.errorDefault, ...res.data.errors };

      if (res.data.errors) {
        this.$emit('show-api-success-message', null);
        this.verificationEmailReSent = false;
      } else if (res.data.errorMessage) {
        this.$emit('show-api-success-message', null);
        this.$emit('show-api-error-messages', [res.data.errorMessage]);
        this.verificationEmailReSent = false;
      } else {
        this.$emit('show-api-success-message', res.data.successMessage);
      }
    },
  },
};
</script>
