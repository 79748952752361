<template>
  <div class="row">
    <div class="column small-12 large-3">
      <ManageAccountMenu :menu="menu" />
    </div>
    <div class="column small-12 large-9">
      <ManageAccountEmptyCard
        v-if="currentPage.length === 0"
        :icon-url="icons.noFavoriteDesigners"
        icon-alt="No favorite designers"
        title="No Favorite Designers"
      >
        <p v-html="unPrettierNuggets.noFavoritesContent">
        </p>
      </ManageAccountEmptyCard>
      <div v-else class="row column">
        <div class="row align-middle medium-text-left text-center">
          <div class="column small-12 medium-shrink">
            <button
              class="button primary small mbn"
              @click="onClickStartNewProject"
            >
              Start New Project
            </button>
          </div>
          <div class="column small-12 medium-shrink">
            <strong class="text-black mrm">or</strong>
            <button
              class="button secondary small hollow mbn"
              @click="onClickWork1on1"
            >
              Work One-On-One
            </button>
          </div>
          <div
            v-if="invitableBriefs.length > 0"
            class="column small-12 medium-shrink"
          >
            <strong class="text-black mrm">or</strong>
            <button
              class="button primary small mbn"
              @click="onClickInviteToProject"
            >
              Invite to project
            </button>
          </div>
          <div
            v-if="invitableBriefs.length > 0"
            class="column small-12 medium-expand"
          >
            <select v-model="selectedBrief" class="mbn">
              <option
                v-for="(brief, index) in invitableBriefs"
                :key="index"
                :value="brief.briefId"
              >
                {{ brief.briefName }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="row column">
          <Callout
            v-if="showWarning"
            :type="calloutType"
            :title="calloutTitle"
            :icon-url="icons.warningIcon"
            closable
            @close="showWarning = false"
          >
            <template #message>{{ calloutMessage }}</template>
          </Callout>
        </div>
        <div class="row small-up-1 medium-up-2 large-up-3">
          <div
            v-for="designer in currentPage"
            :key="designer.designerId"
            class="column"
          >
            <FavoriteDesignerCard
              :profile-pic-thumbnail-url="designer.profilePicUrl"
              :display-name="designer.profileDisplayName"
              :country="designer.country"
              :rank="designer.rank"
              :feedback-percentage="designer.feedbackPositivePercentage"
              :is-checked="isDesignerSelected(designer.designerId)"
              @input="onDesignerClicked(designer.designerId)"
              @remove-designer="onClickDesignerRemove(designer.designerId)"
            />
          </div>
        </div>
        <div class="row column">
          <Pagination
            v-if="pagination"
            :max-page="pagination.maxPage"
            :current="pagination.current"
            :start="pagination.start"
            :end="pagination.end"
            :start-of-page="pagination.startOfPage"
            :number-of-results="pagination.numberOfResults"
            :total-results-count="pagination.totalResultsCount"
            :description="pagination.description"
            :base-url="pagination.baseUrl"
            :api-base-url="pagination.apiBaseUrl"
            :left-arrow-icon-url="icons.paginationLeftArrow"
            :right-arrow-icon-url="icons.paginationRightArrow"
            @page-link-click="onPaginationClick($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ManageAccountMenu from '../../components/my-account/ManageAccountMenu/ManageAccountMenu.vue';
import FavoriteDesignerCard from '../../components/my-account/customer/favorite-designers/FavoriteDesignerCard/FavoriteDesignerCard.vue';
import Pagination from '../../components/shared/PaginationV2/PaginationV2.vue';
import Callout from '../../components/shared/Callout/Callout.vue';
import ManageAccountEmptyCard from '../../components/my-account/ManageAccountEmptyCard/ManageAccountEmptyCard.vue';

export default {
  components: {
    ManageAccountMenu,
    Pagination,
    FavoriteDesignerCard,
    Callout,
    ManageAccountEmptyCard,
  },
  props: {
    icons: {
      type: Object,
      default: undefined,
    },
    menu: {
      type: Object,
      required: true,
    },
    designers: {
      type: Array,
      default: () => [],
    },
    invitableBriefs: {
      type: Array,
      default: () => [],
    },
    startNewProjectBaseUrl: {
      type: String,
      required: true,
    },
    start1on1BaseUrl: {
      type: String,
      required: true,
    },
    inviteDesignerApi: {
      type: String,
      required: true,
    },
    unfavoriteDesignerApi: {
      type: String,
      required: true,
    },
    designerDirectoryUrl: {
      type: String,
      required: true,
    },
    paginationObject: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      currentPage: this.designers,
      pagination: this.paginationObject,
      selectedDesigners: [],
      showWarning: false,
      calloutIcon: this.icons.warningIcon,
      calloutTitle: 'Warning',
      calloutType: 'warning',
      calloutMessage: '',
      selectedBrief: undefined,
      unPrettierNuggets: {
        noFavoritesContent: `You can now favorite designers you want follow and invite to your project. Visit the <a href="${this.designerDirectoryUrl}">designers list</a> and add your favorite designers to this list`
      }
    };
  },
  computed: {
    hasSelectedAtLeastOneDesigner() {
      return this.selectedDesigners.length >= 1;
    },
    isAbleToWork1on1() {
      return this.selectedDesigners.length === 1;
    },
  },
  mounted() {
    if (this.invitableBriefs.length > 0) {
      this.selectedBrief = this.invitableBriefs[0].briefId;
    }
  },
  methods: {
    onDesignerClicked(designerId) {
      if (this.selectedDesigners.indexOf(designerId) < 0) {
        this.selectedDesigners.push(designerId);
      } else {
        this.selectedDesigners = this.selectedDesigners.filter(
          (id) => id !== designerId,
        );
      }
    },
    isDesignerSelected(designerId) {
      return this.selectedDesigners.indexOf(designerId) > -1;
    },
    onPaginationClick(e) {
      this.currentPage = e.items;
      this.pagination = e.pagination;
    },
    showSelectOneDesignerWarning() {
      if (!this.hasSelectedAtLeastOneDesigner) {
        this.showWarning = true;
        this.calloutIcon = this.icons.warningIcon;
        this.calloutTitle = 'Warning';
        this.calloutType = 'warning';
        this.calloutMessage = 'Please select at least 1 designer from the list';
        return true;
      }

      return false;
    },
    onClickStartNewProject() {
      if (!this.showSelectOneDesignerWarning()) {
        const url = `${
          this.startNewProjectBaseUrl
        }?designerId=${this.selectedDesigners.join(',')}`;

        window.location.href = url;
      }
    },
    onClickWork1on1() {
      if (!this.isAbleToWork1on1) {
        this.showWarning = true;
        this.calloutIcon = this.icons.warningIcon;
        this.calloutTitle = 'Warning';
        this.calloutType = 'warning';
        this.calloutMessage = 'Please select a single designer from the list';
      } else {
        const url = `${this.start1on1BaseUrl}${this.selectedDesigners[0]}`;
        window.location.href = url;
      }
    },
    async onClickInviteToProject() {
      if (!this.showSelectOneDesignerWarning()) {
        const res = await axios.post(
          `${this.inviteDesignerApi}${this.selectedBrief}`,
          {
            designerIds: this.selectedDesigners,
          },
        );

        if (res && res.status === 200) {
          this.showWarning = true;
          this.calloutIcon = this.icons.successIcon;
          this.calloutTitle = 'Success';
          this.calloutType = 'success';
          this.calloutMessage = `Successfully invited ${this.selectedDesigners.length} designers to your project! They will respond and submit shortly.`;
          this.selectedDesigners = [];
        }
      }
    },
    async onClickDesignerRemove(designerId) {
      const formData = new FormData();
      formData.append('designerid', designerId);
      formData.append('favorite', false);

      const res = await axios.post(this.unfavoriteDesignerApi, formData);

      if (res && res.status === 200) {
        const designers = await axios.get(this.pagination.apiBaseUrl, {
          params: {
            page: this.pagination.current,
          },
        });
        this.currentPage = designers.data.items;
      }
    },
  },
};
</script>
