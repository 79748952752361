<template>
  <fragment v-if="maxPage > 1" name="pagination">
    <Callout
      v-if="showError"
      type="alert"
      title="Error"
      :closable="true"
      @close="reloadPage"
    >
      <template #message>
        The application has encountered an unknown error.
      </template>
    </Callout>
    <ul
      id="paging"
      class="pagination text-center align-center align-middle pager"
      role="navigation"
      aria-label="Pagination"
    >
      <li
        :class="{
          ['pagination-previous']: true,
          disabled: current === 1,
        }"
      >
        <span v-if="current === 1">
          <img :src="leftArrowIconUrl" alt="Pagination left arrow" />
          <span class="show-for-sr">Go to previous page</span>
        </span>
        <a
          v-else
          title="Go to previous page"
          @click="onClickPageLink(current - 1)"
        >
          <img :src="leftArrowIconUrl" alt="Pagination left arrow" />
          <span class="show-for-sr">Go to previous page</span>
        </a>
      </li>
      <li v-if="shouldRenderMinPageLink">
        <a @click="onClickPageLink(1)">1</a>
      </li>
      <li v-if="shouldRenderMinPageLink" class="ellipsis"></li>
      <li
        v-for="page in pages"
        :key="page"
        :class="{ current: current === page }"
      >
        <span v-if="current === page" name="current-page-link">
          <span class="show-for-sr">You're on page</span>
          <span>{{ page }}</span>
        </span>
        <a v-else @click="onClickPageLink(page)">
          <span class="show-for-sr">Go to page</span>
          {{ page }}
        </a>
      </li>
      <li v-if="shouldRenderMaxPageLink" class="ellipsis"></li>
      <li v-if="shouldRenderMaxPageLink">
        <a @click="onClickPageLink(maxPage)">
          {{ maxPage }}
        </a>
      </li>
      <li class="pagination__page-no">
        Page {{ current }} of {{ maxPage }}
      </li>
      <li
        :class="{
          ['pagination-next']: true,
          disabled: current === maxPage,
        }"
      >
        <span v-if="current === maxPage">
          <img :src="rightArrowIconUrl" alt="pagination right arrow" />
          <span class="show-for-sr">Go to next page</span>
        </span>
        <a v-else title="Go to next page" @click="onClickPageLink(current + 1)">
          <img :src="rightArrowIconUrl" alt="pagination right arrow" />
          <span class="show-for-sr">Go to next page</span>
        </a>
      </li>
    </ul>
    <p class="p-small text-center mbn">
      {{ startOfPage }} - {{ numberOfResults }} of
      {{ totalResultsCount }} {{ description }}
    </p>
  </fragment>
</template>
<script>
import axios from 'axios';
import Callout from '../Callout/Callout.vue';

const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export default {
  components: {
    Callout,
  },
  props: {
    maxPage: {
      type: Number,
      required: true,
    },
    current: {
      type: Number,
      required: true,
    },
    start: {
      type: Number,
      required: true,
    },
    end: {
      type: Number,
      required: true,
    },
    startOfPage: {
      type: Number,
      required: true,
    },
    numberOfResults: {
      type: Number,
      required: true,
    },
    totalResultsCount: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      default: undefined,
    },
    apiBaseUrl: {
      type: String,
      default: undefined,
    },
    apiQueryData: {
      type: Object,
      default: undefined,
    },
    apiPostObject: {
      type: Object,
      default: undefined,
    },
    leftArrowIconUrl: {
      type: String,
      required: true,
    },
    rightArrowIconUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      minPageLinkThreshold: 4,
      isLoading: false,
      showError: false,
      axiosConfig,
    };
  },
  computed: {
    shouldRenderMaxPageLink() {
      return this.end < this.maxPage;
    },
    shouldRenderMinPageLink() {
      if (this.start === 1) {
        return false;
      }

      return this.current >= this.minPageLinkThreshold;
    },
    pages() {
      return this.getPages(this.start, this.end);
    },
  },
  watch: {
    async apiPostObject(newValue) {
      if (newValue.triggerPagination !== true) return;
      await this.onClickPageLink(newValue.page);
    },
  },
  methods: {
    getPages(x, y) {
      const numbers = [];

      for (let i = x; i <= y; i += 1) {
        numbers.push(i);
      }

      return numbers;
    },
    reloadPage() {
      window.location.reload();
    },
    async onClickPageLink(page) {
      if (this.apiBaseUrl && !this.isLoading) {
        this.isLoading = true;
        this.$emit('is-loading', this.isLoading);
        let res;
        try {
          if (this.apiPostObject) {
            this.$emit('page-link-click-pre');
            res = await axios.post(
              this.apiBaseUrl,
              {
                ...this.apiPostObject,
                ...{ page },
              },
              axiosConfig,
            );
          } else {
            res = await axios.get(this.apiBaseUrl, {
              params: {
                page,
                ...this.apiQueryData,
              },
            });
          }
        } catch (err) {
          this.isLoading = false;
          this.$emit('is-loading', this.isLoading);
          this.showError = true;
        }
        this.isLoading = false;
        this.$emit('is-loading', this.isLoading);

        if (res && res.status === 200) {
          window.scrollTo(0, 0);
          if (this.$router)
            this.$router.push({ query: { ...this.$route.query, page } });
          this.$emit('page-link-click', res.data);
        } else {
          this.showError = true;
        }
      }
    },
  },
};
</script>
